import { constants, countriesList } from "./constants";
import { green } from "@mui/material/colors";
import dayjs from "dayjs";
import moment from "moment";
import { Img } from "react-image";
import NavigationIcon from "../static/images/navigation.svg";
import closeIcon from "../static/images/closeIcon.svg";
import { razorpay, razorpayTest } from "./constants";
// let timeZone = 'Asia/Kolkata';
// var utc = require('dayjs/plugin/utc');
// var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
// dayjs.extend(utc);
// dayjs.extend(timezone);

// function convertTZ(date, tzString) {
//   return new Date(
//     (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
//       timeZone: tzString
//     })
//   );
// }

// usage: Asia/Jakarta is GMT+7
// console.log(
//   'convert time ',
//   convertTZ('2012/04/20 10:10:30 +0000', 'Asia/Jakarta')
// );
// Tue Apr 20 2012 17:10:30 GMT+0700 (Western Indonesia Time)

// console.log('timezone ', dayjs.tz.guess());

class Ls {
  static set(key, value) {
    window.localStorage.setItem(key, value);
  }
  static remove(key) {
    return window.localStorage.removeItem(key);
  }
  static get(key) {
    return window.localStorage.getItem(key);
  }
  static flush() {
    window.localStorage.clear();
  }
  static length() {
    return window.localStorage.length();
  }
}

class Tokens {
  static dockey() {
    return localStorage.getItem("docKey") || "";
  }
  static docToken() {
    return localStorage.getItem("docToken") || "";
  }
}

const getTimeSlots = (min = 30) => {
  let currentHour = 0;
  let helperHour = 0;
  let ampm = "AM";
  let currentMinute = 0;
  let timeArr = [];
  for (let i = 0; i <= 48; i++) {
    let hourString =
      helperHour < 10 || helperHour === 0 ? "0" + helperHour : helperHour;
    let minString = currentMinute === 0 ? "0" + currentMinute : currentMinute;

    timeArr.push({
      data: hourString + ":" + minString + " " + ampm,
      value: `${currentHour}.${
        currentMinute === 0 ? currentMinute : currentMinute / 10
      }`,
    });
    currentMinute = currentMinute + min;
    if (currentMinute % 60 === 0) {
      currentHour = currentHour + 1;
      helperHour = currentHour > 12 ? currentHour - 12 : currentHour;
      ampm = currentHour >= 12 ? "PM" : "AM";
      currentMinute = 0;
    }
  }
  console.log(timeArr);
  return timeArr;
};

const parseJson = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const ErrorComponent = ({ value, defaultt, isSpan, errorClass }) => {
  if (isSpan) {
    if (value) return <span className={`${errorClass}__style`}>{value}</span>;
    return (
      <span className={`errorcomponent ${errorClass}__style`}>{defaultt}</span>
    );
  }

  if (value) return <div className={`${errorClass}__style`}>{value}</div>;
  return (
    <div className={`errorcomponent ${errorClass}__style`}>{defaultt}</div>
  );
};

const Navigation = ({ text, close, navClass, handleClick, image }) => {
  return (
    <div
      onClick={handleClick}
      className={`${navClass} navigation__tab`}
      key={text}
    >
      <div
        className={`${navClass}__image navigation__tab__text`}
        onClick={handleClick}
      >
        {image?.left ? <Img src={image?.left} /> : <Img src={NavigationIcon} />}
        <h1 className={`${navClass}__text`}>{text}</h1>
      </div>
      {close && (
        <div>
          {image?.right ? <Img src={image?.right} /> : <Img src={closeIcon} />}
        </div>
      )}
    </div>
  );
};

function removeEmptyValues(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

const renameObjectKey = (object, key, new_key) => {
  object[new_key] = object[key];
  delete object[key];
};

const datePicker = (formate, forwardDates) => {
  let currentDate = moment().format(formate);
  if (forwardDates) {
    currentDate = moment().add(forwardDates, "days").format(formate);
  }
  return currentDate.toString();
};

const checkFutureToday = (date) => {
  let compareDate = "";
  if (date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {
    compareDate = "future";
  }
  if (date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
    compareDate = "today";
  }
  if (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
    compareDate = "past";
  }
  return compareDate;
};
const convertTime = function (timeArr, timeDiff = 30) {
  let helperTimeObject = {};
  for (let i = 0; i < timeArr.length; i++) {
    let { from_time, to_time } = timeArr[i];
    let from = parseFloat(from_time);
    let to = parseFloat(to_time);
    let from_hour = parseInt(from_time.split(".")[0]);
    let from_minute = from_time.split(".")[1]
      ? parseInt(from_time.split(".")[1]) * 10
      : 0;
    let j = from;
    while (j <= to) {
      let timeslot = j.toFixed(2);
      let [tH, tM] = timeslot.split(".");

      let value = `${parseInt(tH) < 10 ? "0" + tH : tH}.${
        tM === 0 ? "0" + tM : tM
      }`;

      // console.log(typeof value);

      helperTimeObject[value] = true;

      from_minute = from_minute + timeDiff;
      if (from_minute % 60 === 0) {
        from_minute = 0;
        from_hour = from_hour + 1;
      }

      let minute = from_minute > 0 ? from_minute / 100 : 0;
      j = from_hour + minute;
    }
  }
  // console.log(helperTimeArr);
  return helperTimeObject;
};

const razorpayCall = (
  order_id,
  callBack,
  doctorData,
  profiledata,
  subscriptionId,
  hospitalDetails
) => {
  // console.log("order id", order_id);
  // console.log("profiledata", profiledata);
  // console.log("appointmentid", subscriptionId);
  // console.log("callbackl", callBack);
  // console.log("doctordata", doctorData?.order_amount_subunits);
  // console.log("hospital", hospitalDetails);
  var options = {
    key: Ls.get("mode") === "test" ? razorpayTest : razorpay, // Enter the Key ID generated from the Dashboard    amount: doctorData[constants.consultCharge] * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    amount: doctorData?.order_amount_subunits * 100,
    //  * 100 * (1 - doctorData?.order_amount_subunits / 100),
    name: "RxOne",
    currency: doctorData?.currency,
    description: "RxOne EMR Subscription",
    image: "https://rxone-static.s3.ap-south-1.amazonaws.com/images/rx_192.png",
    order_id: order_id,
    //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function (response) {
      let callbackObject = {
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: order_id,
        razorpay_signature: response.razorpay_signature,
        razorpay_error_code: response.payment_cancelled,
        razorpay_error_description: "",
        razorpay_error_source: "",
        razorpay_error_step: "",
        razorpay_error_reason: "",
        razorpay_error_metadata_order_id: "",
        razorpay_error_metadata_payment_id: "",
      };

      callBack(subscriptionId, callbackObject);
    },
    prefill: {
      name: profiledata.name,
      email: profiledata.email,
      contact: profiledata.phone,
    },
    modal: {
      ondismiss: function () {
        // alert('This was called when the popup was closed');
        callBack(
          subscriptionId,
          {},
          {
            hasError: true,
            errorType: "ondismiss",
            errorMessage: <div className="text-center">Payment Cancelled</div>,
          }
        );
      },
    },
    
    notes: {
      reciept: doctorData?.subscription_id, //appointment number
    },
    theme: {
      color: "#199A8E",
    },
  };
  console.log(2828, options);
  var rzp1 = new window.Razorpay(options);
  console.log("opening", rzp1);

  rzp1.on("payment.failed", function (response) {
    document.body.style.overflow = 'visible';
    callBack(
      subscriptionId,
      {},
      {
        hasError: true,
        errorType: "failed",
        errorMessage: (
          <div className="text-center">
            <h3>
              It seems there has been some issue during the payment, please try
              booking the appointment again in some time.
            </h3>
            <h6>
              If amount is deducted from the account, it will be refunded back
              in 3-5 business days.
            </h6>
          </div>
        ),
      }
    );
  });
  rzp1.open();
};

const stringToColor = function (string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
const convertTime24to12 = (time12h) => {
  // let timeToConvert = parseFloat(time12h)
  let [hours, minutes] = time12h.split(".");
  let modifier = "AM";

  if (hours === "12") {
    hours = "00";
    modifier = "PM";
  } else if (parseInt(hours) > 12) {
    hours = parseInt(hours) - 12;
    modifier = "PM";
  } else {
    hours = parseInt(hours);
  }

  return `${hours}:${minutes} ${modifier}`;
};

let hex2rgba = (hex, a) => {
  console.log(hex, a);
  return `rgba(${hex
    .substr(1)
    .match(/../g)
    .map((x) => +`0x${x}`)},${a})`;
};

const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();
export {
  countriesList,
  parseJson,
  datePicker,
  Ls,
  Tokens,
  getTimeSlots,
  ErrorComponent,
  convertTime,
  razorpayCall,
  stringAvatar,
  convertTime24to12,
  datesAreOnSameDay,
  checkFutureToday,
  hex2rgba,
  Navigation,
  renameObjectKey,
  removeEmptyValues,
};
