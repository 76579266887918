import { useState, useEffect, useContext } from "react";
import PageContentLayout from "../../components/PageContentLayout";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { constants } from "../../utility/constants";
import Rating from "@mui/material/Rating";
import Reviewsvg from "../../static/images/reviews.svg";
import CloseIcon from "@material-ui/icons/Close";
import TooltipComponent from "../../components/TooltipComponent";
import NotesIcon from "@mui/icons-material/Notes";

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import { PatientpageContext } from "../../context/GlobalContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import Overlay from "../../components/Overlay";

const Patientexperience = () => {
  const [review, setReview] = useState({});
  const [experience, setExperience] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState({});
  const doctorContext = useContext(PatientpageContext);
  const [loading, setLoading] = useState(false); // State for managing loading state

  const [formdata, setFormdata] = useState({
    message: "",
  });
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const setState = doctorContext.setState;

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  useEffect(() => {
    const patientreview = async () => {
      setShowLoader(true);

      try {
        const reviews = await ServiceCall.gett("v2/doctor/overall/feedbacks", {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        });
        if (reviews) {
          setShowLoader(false);

          setReview(reviews.data);
        }
      } catch (err) {}
    };
    patientreview();
  }, []);

  useEffect(() => {
    const patientfeedback = async () => {
      setShowLoader(true);

      try {
        const feedback = await ServiceCall.gett(
          "v2/doctor/list/patients/feedbacks",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (feedback) {
          setShowLoader(false);

          setExperience(feedback.data.records);
        }
      } catch (err) {}
    };
    patientfeedback();
  }, []);

  const handleCloseMo = () => {
    setOpenModal(false);
    setMessage("");
  };

  const Handlemodal = () => {
    setOpenModal(true);
  };

  const handleMessageChange = (event) => {
    const { name, value } = event.target;

    setFormdata({ ...formdata, [name]: value });
  };

  const handlePredefinedMessage = (msg) => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      message: prevFormData.message + msg,
    }));
  };

  const handleExperienceSubmit = async (pt_profile_id) => {
    setLoading(true);
    try {
      const body = {
        message: formdata?.message,
      };
      const Experiencesubmit = await ServiceCall.postv2(
        `/v2/doctor/respond/feedback/`,
        `${pt_profile_id}`,
        body,
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Experiencesubmit) {
        setOpenModal(false);

        setState({
          msg: Experiencesubmit?.data?.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {}
  };

  return (
    <>
        {showLoader && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
        
          <PageContentLayout>
            <div className="overall-review-container">
              <span className="avg-rating">{review?.avg_ratings}</span>
              <div className="rating-container">
                <Rating
                  name="read-only"
                  size="large"
                  className="rating"
                  value={review.avg_ratings || 0}
                  readOnly
                  style={{ fontSize: "2.875rem" }}
                />
              </div>
              <p className="review-count">
                {review?.reviews_count}{" "}
                <span style={{ fontSize: "15px", fontWeight: "500" }}>
                  Reviews
                </span>
              </p>
            </div>
            <div style={{ marginTop: "2rem" }}>
              {experience.map((feedback, index) => (
                <Grid
                  key={index + 1}
                  className={`py-2 px-3 appointment__container patientRecordsContainer`}
                  container
                  spacing={2}
                  item
                  xs={11}
                  justifyContent="space-around"
                  alignItems="center"
                  style={{ cursor: "pointer", width: "72%" }}
                >
                  <Grid className="d-flex flex-column align" sm={4} xm={4}>
                    <div>
                      <h3 style={{ fontSize: "23px" }}>
                        {feedback?.patient_name}
                      </h3>
                      <span style={{ color: "gray" }}>
                        ({feedback?.patient_age}
                        {""}, {feedback?.patient_gender})
                      </span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    className="appointment__container__middleSection"
                  >
                    <div className="font-13 px-1 d-flex flex-column w-100 align-items-center">
                      <div className="patientRecordsContainer_time">
                        {" "}
                        <Rating
                          name="read-only"
                          size="large"
                          className="mt-1"
                          value={feedback.star_rating || 0}
                          readOnly
                          style={{ fontSize: "2.875rem" }}
                        />
                        <p style={{ fontSize: "15px", textAlign: "center" }}>
                          {" "}
                          {feedback?.submitted_on}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    sm={4}
                    className={`appointment__container__lastsection`}
                  >
                    <div className="appointment__container__lastsection__container">
                      {feedback?.review_comments ? (
                        <TooltipComponent
                          icon={
                            <NotesIcon
                              sx={{
                                width: "100%",
                                color: "#199a8e",
                                fontSize: "2rem",
                              }}
                            />
                          }
                          title={feedback?.review_comments}
                        />
                      ) : (
                        <NotesIcon
                          sx={{
                            width: "100%",
                            color: "#dddddd",
                            fontSize: "2rem",
                          }}
                          disabled
                        />
                      )}{" "}
                    </div>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    sm={4}
                    className={`appointment__container__lastsection`}
                  >
                    <div
                      className="appointment__container__lastsection__container"
                      onClick={Handlemodal}
                    >
                      <span className="rspnd">Respond</span>
                    </div>
                  </Grid>

                  <Modal
                    open={openModal}
                    onClose={handleCloseMo}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 430,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "20px",
                      }}
                    >
                      <CloseIcon
                        onClick={handleCloseMo}
                        style={{ float: "right", cursor: "pointer" }}
                      />

                      <div
                        style={{
                          marginBottom: "27px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                          gap: "10px",
                        }}
                      >
                        <span
                          onClick={() =>
                            handlePredefinedMessage(
                              "Thank you for your feedbacks"
                            )
                          }
                          style={{
                            cursor: "pointer",
                            border: "2px solid #aab4aa",
                            padding: "8px",
                            background: "#1b978b",
                            color: "#fff",
                            borderRadius: "10px",
                            // width: "31%",
                          }}
                        >
                          Thank you for your feedbacks
                        </span>
                        <span
                          onClick={() =>
                            handlePredefinedMessage("Appreciate your comments")
                          }
                          style={{
                            cursor: "pointer",
                            border: "2px solid #aab4aa",
                            padding: "8px",
                            background: "#1b978b",
                            color: "#fff",
                            borderRadius: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          Appreciate your comments
                        </span>
                        <span
                          onClick={() =>
                            handlePredefinedMessage(
                              "Glad to hear, you are feeling better now."
                            )
                          }
                          style={{
                            cursor: "pointer",
                            border: "2px solid #aab4aa",
                            padding: "8px",
                            background: "#1b978b",
                            color: "#fff",
                            borderRadius: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          Glad to hear, you are feeling better now.{" "}
                        </span>
                      </div>
                      <form>
                        <TextField
                          name="message"
                          multiline
                          rows={4}
                          fullWidth
                          value={formdata?.message}
                          onChange={handleMessageChange}
                          placeholder="Write a custom message for patient for his review."
                          style={{
                            border: "2px solid #1b978b",
                            padding: "5px",
                            borderRadius: "10px",
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            height: "40px",
                            marginTop: "10px",
                            background: "#1b978b",
                          }}
                          onClick={() =>
                            handleExperienceSubmit(feedback.pt_profile_id)
                          }
                          disabled={loading} // Disable button when loading is true
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </form>
                    </Box>
                  </Modal>
                </Grid>
              ))}
            </div>
          </PageContentLayout>
        
    
    </>
  );
};
export default Patientexperience;
