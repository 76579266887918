import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function DialogComponent({
  titleComponent,
  render,
  openProps,
  title,
  closeDialog,
  scroll,
  maxWidth = 'md',
  hideTitle = false,
  contentStyle,
  contentClass
}) {
  const [open, setOpen] = useState(openProps);

  const handleClose = () => {
    setOpen(false);
    if (closeDialog) {
      closeDialog();
    }
  };
  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth} scroll={scroll}>
      <div className="position-absolute" style={{ right: 0 }}>
        <IconButton style={{zIndex:"10"}} onClick={handleClose} color="success" component="span">
          <HighlightOffIcon />
        </IconButton>
      </div>
      {!hideTitle && (
        <DialogTitle className="d-flex justify-content-between">
          {title}
        </DialogTitle>
      )}
      {titleComponent && <DialogTitle>{titleComponent}</DialogTitle>}
      <DialogContent style={contentStyle} className={`${contentClass}`}>
        {render && render()}
      </DialogContent>
    </Dialog>
  );
}
