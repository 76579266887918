import React, { useContext, useState } from "react";
import { Grid, Button } from "@mui/material";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { PatientpageContext } from "../context/GlobalContext";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { OverlayType } from "../OverlayManager/Context";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { OverlayContext } from "../OverlayManager/Overlay";
import { apiCall } from "../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import { Ls } from "../utility/utility";
import dayjs from "dayjs";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@material-ui/icons/Close";
import {
  TextField,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";

const PatientRecordsList = ({ records, index }) => {
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  const [infoText, setInfoText] = useState("");
  const fullText = `The report provides a comprehensive health analysis, summarizing various test results with notable deviations in several parameters:
1.	HsCRP (High Sensitivity C-Reactive Protein): Slightly elevated at 3.46 mg/L, indicating potential inflammation. Recommendations include a healthy diet, regular exercise, and stress management techniques.
2.	Homocysteine: Elevated levels, suggesting a risk for cardiovascular disease. Suggested dietary adjustments include foods rich in B vitamins, physical activity, and avoiding smoking.
3.	HDL Cholesterol: Slightly low at 39.7 mg/dL. Increasing intake of healthy fats and regular exercise is advised.
4.	LDL Cholesterol and LDL/HDL Ratio: Both are higher than the normal range, indicating a need to limit saturated and trans fats, and incorporate healthier fats and regular exercise.
5.	SGOT/SGPT Ratio: Slightly lower than normal, suggesting possible liver dysfunction. Recommendations include limiting alcohol, avoiding toxins, and maintaining a balanced diet and hydration.
Overall, the health score is 92 out of 100. The report includes a detailed summary of deranged parameters, dietary and lifestyle suggestions, and future test recommendations for vitamins, hormones, and cardiovascular health
`;

  const generateInfoText = () => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setInfoText((prev) => prev + fullText[currentIndex]);
      currentIndex++;
      if (currentIndex === fullText.length) {
        clearInterval(interval);
      }
    }, 10); // Adjust the speed of typing effect here
  };
  const doctorContext = useContext(PatientpageContext);
  const Overlay = useContext(OverlayContext);

  const handleDonwnloadPrescription = async () => {
    let url = "/v2/doctor/download/record/";
    try {
      Overlay.show(OverlayType?.ModalWindow, {
        data: (
          <div className="prescripitionLoader">
            <h3>Please Wait...</h3>
            <CircularProgress color="success" />
          </div>
        ),
        removeCloseButton: true,
      });
      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: `${records.appointment_id}/${records?.category}/${records.file_name}`,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        const fileExtension = records.file_name.split('.').pop().toLowerCase();
        let blob = new Blob([PrescriptionDownload.data], {
          type: fileExtension === 'pdf' ? 'application/pdf' : `image/${fileExtension}`,
        });
        let url = window.URL.createObjectURL(blob);
        
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              {fileExtension === 'pdf' ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={url} />
                </Worker>
              ) : (
                <img src={url} alt="Medical Record" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
              )}
              <div className="prescription__pdf__container">
                <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a>
                <a
                  download={`medical-record-${records.appointment_id}.${fileExtension}`}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {
            window.URL.revokeObjectURL(url);
          },
        });
      }
    } catch (err) {
      console.log("recordsError", err);
      Overlay.hide();
      err &&
        doctorContext.setState({
          msg: err?.response?.data?.message,
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };
  
  const isPDF = (extension) => extension === 'pdf';
  const isImage = (extension) => ['jpg', 'jpeg', 'png'].includes(extension);

  const handleopen = () => {
    setShowProfilesModal(true);
    generateInfoText();
  };

  return (
    <>
      <Grid
        key={index + 1}
        className={`py-2 px-3 appointment__container patientRecordsContainer`}
        container
        spacing={2}
        item
        xs={11}
        justifyContent="space-around`"
        alignItems="center"
        style={{ cursor: "pointer", width: "72%" }}
      >
        <Grid className="d-flex flex-column align" sm={4} xm={4}>
          <h3 style={{ color: "#199a8e", margin: "0" }}>
            {records?.category || ""}
          </h3>
          <span className="mt-1">{`${records?.file_name
            .split("_")[1]
            ?.slice(0, 10)}.${
            records?.file_name?.split(".")[1] || "jpg"
          }`}</span>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          className=" appointment__container__middleSection"
        >
          <div className="font-13 px-1 d-flex flex-column w-100 align-items-center">
            <div className="patientRecordsContainer_time">
              {dayjs(records?.uploaded_on?.split(" ")[1], "HH:mm:ss").format(
                "hh:mm A"
              )}
            </div>
            <div className="patientRecordsContainer_date mt-1">
              {dayjs(records?.uploaded_on?.split(" ")[0]).format("D-MMM-YYYY")}
            </div>
          </div>
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={4}
          className={` appointment__container__lastsection`}
        >
          <div className="appointment__container__lastsection__container">
            <RemoveRedEyeIcon
              sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
              // onClick={handleDonwnloadPrescription}
              onClick={handleDonwnloadPrescription}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={4}
          className={`appointment__container__lastsection`}
        >
          {/* <div
            className="appointment__container__lastsection__container"
            onClick={handleopen}
          >
            <AutoAwesomeIcon
              sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
            />
          </div> */}
        </Grid>
      </Grid>
      <Modal
        open={showProfilesModal}
        onClose={() => setShowProfilesModal(false)}
        aria-labelledby="profiles-modal-title"
        aria-describedby="profiles-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
            height: "100%",
          }}
        >
          <CloseIcon
            onClick={() => setShowProfilesModal(false)}
            style={{ float: "inline-end", cursor: "pointer" }}
          />

          {/* <TextField
            // label="Information"
            variant="outlined"
            fullWidth
            value={infoText}
            multiline
            rows={26}
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              style: {
                fontSize: '14px',  // Adjust this value as needed
                fontWeight: 700,   // Normal weight, increase for bolder text
              },

            }}
            style={{height: "100%"}}
          /> */}
        </Box>
      </Modal>
    </>
  );
};

export default PatientRecordsList;
