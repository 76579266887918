import React from 'react'

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10.176.083c5.565 0 10.091 4.427 10.091 9.869 0 2.568-1.008 4.91-2.656 6.667l3.244 3.166a.747.747 0 01.001 1.074.787.787 0 01-1.1.002l-3.283-3.202a10.185 10.185 0 01-6.297 2.163C4.61 19.822.083 15.394.083 9.952.083 4.51 4.611.083 10.176.083zm0 1.52c-4.708 0-8.538 3.745-8.538 8.349s3.83 8.35 8.538 8.35c4.706 0 8.536-3.746 8.536-8.35 0-4.604-3.83-8.349-8.536-8.349z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SearchIcon