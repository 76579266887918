import { useState, useEffect, useContext, useRef } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  FormHelperText,
} from "@material-ui/core";
import Registrationpic from "../../static/images/Regitrationpic-removebg-preview.png";
import { useHistory } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Switch from "../../Tools/Swtich";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import Doctordetails from "./Doctordetails";
import { PatientpageContext } from "../../context/GlobalContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
}));

const Doctorotp = ({ forData, emaildata }) => {
  const classes = useStyles();

  const [showwhatps, setShowwhatps] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [Docdata, setDocdata] = useState("");
  const [apiError, setApiError] = useState("");
  const [Loader, setLoader] = useState(false);
  const doctorContext = useContext(PatientpageContext);
  const setState = doctorContext.setState;
  const history = useHistory();
  const [showOTP, setShowOTP] = useState(false);
  const [otpLoader, getOtpLoader] = useState(false);
  const [otpContent, setOtpContent] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [disableSendOtp, setDisableOtp] = useState(true);
  const timer = useRef(null);
  const otpField = useRef(null);
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const[sendTimer, setSendTimer] = useState(60);
  const [timers, setTimers] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [emailActive, setEmailActive] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91"); // State to hold the selected country code


  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        // doctorContext?.dialogController({
        //   open: true,
        //   content: (() => (
        //     <div className="appointment__screen__exit">
        //       Do you really wish to exit?
        //     </div>
        //   ))(),
        //   checkLoginPage: false,
        //   promptWindow: true,
        //   dialogAction: (() => (
        //     <div className="appointment__screen__exit__buttons">
        //       <Button
        //         handleClick={() => {
        //           doctorContext.handleDialogClose();
        //           Ls.remove("pt_key");
        //           Ls.remove("pt_token");
        //           history.push({
        //             pathname: "/",
        //           });
        //         }}
        //         className="mr-2"
        //         text="Exit"
        //         containerClass="m-auto appointment__screen__verify"
        //       ></Button>
        //       <Button
        //         handleClick={() => {
        //           doctorContext.handleDialogClose();
        //         }}
        //         className="mr-2"
        //         text="CANCEL"
        //         containerClass="m-auto appointment__screen__verify"
        //       ></Button>
        //     </div>
        //   ))(),
        //   maxWidth: "sm",
        //   disableBackdropClick: true,
        // });
      };
    }
    return () => {
      if (typeof window !== "window") {
        window.onpopstate = null;
      }
    };
  }, []);


  const otpButtonTimer = function () {
    timer.current = setTimeout(() => {
      setDisableOtp(false);
    }, 60000);
  };

  const [otpValidation, setOtpValidation] = useState({
    hasError: false,
    content: "",
  });

  const [formData, setFormData] = useState({
    phone_otp: "",
    email_otp: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFollowUP = async (checked) => {
    setShowwhatps(checked);
  };

  const getotp = async (event) => {
    event.preventDefault();
    setResendTimer(60);
    setTimerActive(true);

    try {
      const body = {
        first_name: forData?.first_name,
        last_name: forData?.last_name,
        phone: phoneCode+forData?.phone,
        gender: forData?.gender,
        title: forData?.title,
        wa_otp: showwhatps,
      };
      const resnd = await ServiceCall.put(
        "/v2/generate/doctor/registration/mobile/otp/",
        body
      );
      if (resnd) {
        getOtpLoader(false);
        setShowOTP(true);
        setState({
          msg: resnd?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
        setOtpContent(resnd?.data.message);
      }
    } catch (err) {
      // Handle errors
      getOtpLoader(false);
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    // Add the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let intervalId;

    if (timerActive && resendTimer > 0) {
      // Start the countdown
      intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      // Stop the countdown
      clearInterval(intervalId);
      // Deactivate the timer
      setTimerActive(false);
    }

    // Clean up the interval when the component unmounts or when the timer is not active
    return () => clearInterval(intervalId);
  }, [timerActive, resendTimer]);

  useEffect(() => {
    let intervalid;
    if (emailActive && sendTimer > 0) {
      intervalid = setInterval(() => {
        setSendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalid);
      setEmailActive(false);
    }

    return () => clearInterval(intervalid);
  }, [emailActive, sendTimer]);


  const getotp2 = async (event) => {
    event.preventDefault();
    setEmailActive(true);
    setSendTimer(60);
    try {
      const body = {
        email: emaildata?.email,
      };
      const getotpback = await ServiceCall.put(
        "/v2/generate/doctor/registration/email/otp/",
        body
      );
      if (getotpback) {
        getOtpLoader(false);
        setShowOTP(true);
        setState({
          msg: getotpback?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
        setOtpContent(getotpback?.data.message);
      }
    } catch (err) {
      // Handle errors
      getOtpLoader(false);
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };
  useEffect(() => {
    if (showOtp) {
      otpButtonTimer();
      otpField.current.focus();
    }
  }, [showOtp]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const body = {
        phone_otp: formData?.phone_otp,
        email_otp: formData?.email_otp,
        first_name: forData?.first_name,
        last_name: forData?.last_name,
        phone: phoneCode+forData?.phone,
        email: emaildata?.email,
        title: forData?.title,
        gender: forData?.gender,
      };
      const verifyaccount = await ServiceCall.postt(
        "/v2/verify/doctor/registration/mobile/email/otp/",
        body,
        {
          "Content-Type": "application/json",
        }
      );
      if (verifyaccount) {
        setLoading(false);

        setDocdata(verifyaccount.data);
        setApiSuccess(true);
      }
    } catch (err) {
      if (err.response) {
        setLoading(false);
        setApiError(err.response.data.message);
        setOtpValidation({
          hasError: true,
          content: err.response.data.message,
        });
      } else if (err.request) {
        setLoading(false);
        setApiError("Failed request " + err);

      } else {
        setLoading(false);
        setApiError("Failed in general " + err);
      }
    }
  };
  useEffect(() => {
    if (formData.phone_otp !== '' ) {
      // Reset the timer
      setResendTimer(60);
      // Deactivate the timer
      setTimerActive(false);
    }
  }, [formData.phone_otp]);
  useEffect(() => {
    if ( formData.email_otp !== '') {
      // Reset the timer
      setSendTimer(60);
      // Deactivate the timer
      setEmailActive(false);
    }
  }, [ formData.email_otp]);
  
  useEffect(() => {
    // Start the resend timer when the component mounts
    setTimerActive(true);
    setResendTimer(60); // Reset the timer
  
    const intervalId = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 0) {
          // If the timer reaches 0, deactivate the timer
          setTimerActive(false);
          clearInterval(intervalId);
          return 60; // Reset the timer
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  
    // Clear the interval when the component unmounts or when the timer is not active
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    // Start the resend timer when the component mounts
    setEmailActive(true);
    setSendTimer(60); // Reset the timer
  
    const intervalId = setInterval(() => {
      setSendTimer((prevTimer) => {
        if (prevTimer === 0) {
          // If the timer reaches 0, deactivate the timer
          setEmailActive(false);
          clearInterval(intervalId);
          return 60; // Reset the timer
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  
    // Clear the interval when the component unmounts or when the timer is not active
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <>
      {apiSuccess ? (
        <Doctordetails Docdata={Docdata} />
      ) : (
        <div className="loginCo bodyBg" style={{marginTop: "-2rem", height: "110vh"}}>
          <Container component="main" maxWidth="xs">
            <form className={classes.root} onSubmit={handleSubmit}>
              <img
                src={Registrationpic}
                alt="Registration"
                style={{ marginBottom: "-7px", borderRadius: "5px" }}
              />
              <h3 style={{ fontWeight: "600", color: "#8b9fb3" }}>
                Verify Contact Details
              </h3>
              <p style={{ color: "#8b9fb3", textAlign: "center" }}>
                Enter OTP Received on {phoneCode+forData?.phone} & {emaildata?.email}{" "}
              </p>

              <TextField
                id="phoneotp"
                name="phone_otp"
                label="Enter Phone OTP"
                variant="outlined"
                value={formData.phone_otp}
                onChange={handleChange}
                required
                autoFocus
                fullWidth
                style={{ marginBottom: "16px" }}
              />
              <span className="gtp" onClick={getotp} disabled={timerActive}>
                {timerActive ? ` 00:${resendTimer}` : "Resend"}
              </span>

              <div className="whtsp_switch2">
                <span className="label_with_whtsp">
                  <span className="label_txt">Get OTP on</span>
                  <WhatsAppIcon />
                </span>
                <Switch
                  labelInput="remind_patient"
                  checked={showwhatps}
                  ContainerClass="prescriptionpage__medication__reminder__switch"
                  handleChange={(e) => {
                    const isChecked = e.target.checked;
                    handleFollowUP(isChecked);
                  }}
                />
              </div>
              <TextField
                id="emailotp"
                name="email_otp"
                label="Enter Email OTP"
                variant="outlined"
                value={formData.email_otp}
                onChange={handleChange}
                required
                autoFocus
                fullWidth
                style={{ marginBottom: "16px" }}
              />
              <span
                className="gtp"
                style={{ marginBottom: "10px" }}
                onClick={getotp2}
                disabled={emailActive}
              >
                {emailActive ? ` 00:${sendTimer}` : "Resend"}
              </span>
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "700",
                  marginLeft: "-82px",
                  marginTop: "-23px",
                }}
              >
                Tip: Please check the Spam/Junk folder before Resend
              </p>
              {otpValidation.hasError && (
                <FormHelperText className="validation-error">
                  {otpValidation.content}
                </FormHelperText>
              )}

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verify Account"
                )}
              </Button>
            </form>
          </Container>
        </div>
      )}
    </>
  );
};
export default Doctorotp;
