import React, { useEffect } from "react";

export default function ServiceWorkerRegistration() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      let swUrl = `/serviceW.js`;
      
      const registerSW = async () => {
        try {
          const registration = await navigator.serviceWorker.register(swUrl, { scope: './' });
          console.log('Service Worker registered with scope:', registration.scope);
          
          // Request notification permission
          if (Notification.permission !== 'granted') {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
              console.log('Notification permission granted.');
            } else {
              console.log('Notification permission denied.');
            }
          }
          
          // Check if the service worker is already controlling the page
          if (navigator.serviceWorker.controller) {
            console.log('Service Worker is already controlling the page');
          }
          
        } catch (err) {
          console.error('Service Worker registration failed:', err);
        }
      };

      // Register the service worker when the page loads
      window.addEventListener('load', registerSW);
      
      // Cleanup function
      return () => {
        window.removeEventListener('load', registerSW);
      };
    }
  }, []);

  return null; // This component doesn't render anything
}