import React,{useContext, useEffect,useState} from 'react'
// import FreshChat from "react-freshchat";
import { PatientpageContext } from '../context/GlobalContext';

function FreshChatApp() {
  const [name, setName] = useState("Rxone.Doctor")
  const [phone, setPhone] = useState("")
  const patientContext = useContext(PatientpageContext);
  const patient =patientContext.profileData;
  
  useEffect(()=>{
    if(patient){
      setName(patient.name)
      setPhone(patient.phone)
    }
  },[patient])

  
  const userData ={
    name: name,
    email:phone ,
    }

  return (
    <div>
    {/* <FreshChat
   token={"d69fd409-d0b4-4880-a7cf-77cdb6e8e4ff"}
    host={"https://wchat.in.freshchat.com"}
    config={{
      headerProperty: {
        backgroundColor: "#16434d",
        foregroundColor: "#ffffff"
      }
    }}
    onInit={widget => {
    //   Use `widget` instead of `window.fcWidget`
        widget.user.setProperties({
          // email: user.email,
          firstName: userData.name,
          // lastName: "mandawariya",
          phone: userData.phone,
           
          
        })
        widget.on("user:created", function (resp) {
          console.log(11, resp);
          // var status = resp && resp.status,
          //   data = resp && resp.data;
        })
    
    }}
  /> */}
</div>
  )
}

export default FreshChatApp