import { useState, useContext, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceCall } from "../utility/service-call";
import { constants } from "../utility/constants";
import { Ls } from "../utility/utility";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { PatientpageContext } from "../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  errorText: {
    color: "red", // Set color to red
    fontSize: "0.75rem",
    marginTop: theme.spacing(1), // Add spacing to separate from the input field
  },
}));
const Addaccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const doctorContext = useContext(PatientpageContext);
  const [apiError, setApiError] = useState("");
  const setState = doctorContext.setState;

  const [saved, setSaved] = useState(false); // State to track whether form has been saved
  const [error, setError] = useState(""); // State variable for error message
  const [pldata, setPldata] = useState("");
  

  const [formData, setFormData] = useState({
    beneficiary_name: "",
    branch_ifsc_code: "",
    account_number: "",
    reentered_account_number: "", // New state variable for re-entered account number
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
    if (name === "re_account_number") {
      setError("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/account/details/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const { record } = response.data; // Extract social links from response data
        setPldata(response.data.record);
        // Set form data with fetched social links
        setFormData({
          beneficiary_name: record.beneficiary_name || "",
          branch_ifsc_code: record.branch_ifsc_code || "",
          account_number: record.account_number || "",
          reentered_account_number: record.account_number || "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handlle = () => {
    history.push({
      pathname: "/homie",
      search: history.location.search,
    });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (formData.account_number !== formData.reentered_account_number) {
      setError("Account numbers do not match");
      setLoading(false);
      return; // Prevent further execution
    }
    const body = {
      beneficiary_name: formData?.beneficiary_name,
      branch_ifsc_code: formData?.branch_ifsc_code,
      account_number: formData?.account_number,
    };
    try {
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/account/details/",
        `${constants.hospitalId("hosp_id")}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };
  return (
    <>
      <div className="loginConti bodyBg" style={{background: "rgb(232 243 241)"}}>
        <Container component="main" maxWidth="xs">
          <p style={{ fontSize: "18px", fontWeight: "700", marginLeft: "-32px" }}>
            <ArrowBackIosIcon onClick={handlle} />
            <div
              style={{
                marginTop: "-26px",
                marginLeft: "16px",
                cursor: "pointer",
              }}
              onClick={handlle} 
            >
              Account Details
            </div>
          </p>
          <div
            style={{
              border: "2px solid gray",
              width: "443px",
              borderRadius: "10px",
              marginLeft: "-22px",
              marginBottom: "-88px",
              height: "100px",

            }}
          >
            {pldata && (
              <span
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "500",
                  color: "#199a8e",
                  display: "block",
                  marginLeft: "-3px",
                }}
              >
               
                {pldata.title}
                <p style={{float: "inline-start", color: "gray", fontSize: "15px", marginLeft: "12px"}}>{pldata.details}</p>
              </span>
            )}
          </div>
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            style={{ marginTop: "150px" }}
          >
            <TextField
              id="beneficiary_name"
              name="beneficiary_name"
              value={formData.beneficiary_name}
              onChange={handleChange}
              label="Beneficiary Name"
              variant="outlined"
              fullWidth
              required
            />

            <TextField
              id="branch_ifsc_code"
              name="branch_ifsc_code"
              value={formData?.branch_ifsc_code}
              onChange={handleChange}
              label="Bank IFSC Code"
              variant="outlined"
              fullWidth
              required
            />

            <TextField
              id="account_number"
              name="account_number"
              value={formData?.account_number}
              onChange={handleChange}
              label="Account No."
              variant="outlined"
              fullWidth
              required
            />

            <TextField
              id="reentered_account_number"
              name="reentered_account_number"
              value={formData.reentered_account_number}
              onChange={handleChange}
              label="Re-type Account No."
              variant="outlined"
              fullWidth
              required
            />

            {error && <p className={classes.errorText}>{error}</p>}

            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ height: "40px", marginTop: "50px" }}
              disabled={saved}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : saved ? (
                "Saved"
              ) : (
                "Save"
              )}
            </Button>
          </form>
        </Container>
      </div>
    </>
  );
};
export default Addaccount;
