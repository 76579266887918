import React from "react";

const Button = ({ text, containerClass, loader = false, handleClick, props }) => {
  return (
    <button
      className={`${containerClass}_button ${
        loader ? "static_disable" : ""
      } static_button`}
      onClick={handleClick}
      {...props}
    >
      {text}
    </button>
  );
};

export default Button;
