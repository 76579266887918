import React, { createContext, useState, useEffect, useRef } from "react";
import { useParams, Redirect, useLocation } from "react-router-dom";
// import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./../styles/appointmentScreensStyle";
import axios from "axios";
import { baseUrl } from "./../utility/constants";
import { useHistory } from "react-router-dom";
import { bioMarksBloodPressure } from "../OverlayManager/ClinicalFindings/ClinicalUtils";
import {
  Ls,
  checkFutureToday,
  datesAreOnSameDay,
  Tokens,
} from "./../utility/utility";
import { fetchDosage } from "../static/staticData/staticData";
import ButtonTool from "../Tools/Button";
import { constants } from "./../utility/constants";
import { isEqual, reject } from "lodash";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { getLocal } from "./../utility/constants";
import { ServiceCall, apiCall, apiCallOld } from "./../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { datePicker } from "./../utility/utility";
const useStyles = makeStyles(styles);

export const PatientpageContext = createContext();

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const defaultDialogSettings = {
  open: false,
  content: "",
  contentType: null,
  maxWidth: "sm",
  disableBackdropClick: true,
  promptWindow: false,
  dialogAction: null,
};
const keymap = {
  body_temp_fr: {
    name: "Body Temperature",
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  },
  dias_blood_pressure: {
    containerLabel: "Blood Pressure",
    name: "Diastolic Blood Pressure",
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  },
  heart_rate: {
    name: "Heart Rate",
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  },
  spo2_level: {
    name: "SPO2 Level",
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  },
  syst_blood_pressure: {
    containerLabel: "Blood Pressure",
    name: "Systolic Blood Pressure",
    borderColor: "rgb(255, 99, 132)",
    backgroundColor: "rgba(255, 99, 132, 0.5)",
  },
};
export default function GlobalContext(props) {
  let history = useHistory();
  let query = useQuery();
  let globalTimer = useRef(null);
  const { search } = useLocation();
  const [appointmentLoader, setAppointmentLoader] = useState("loading");
  const [overlayLoading, setOverlayLoading] = useState("");
  const [clinicalLoader, setClinicalLoader] = useState(false);
  const [appointment, setAppointment] = useState("");
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointmentLastUpdate, setAppointmentLastUpdate] = useState("");
  const [toggleAppointmentLists, setToggleAppointmentLists] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [appDialog, setappDialog] = useState(defaultDialogSettings);
  const [recordData, setRecordData] = useState({});
  const [clinicalFindings, setClinicalFindings] = useState("");
  const [DynamicImageStore, setDynamicImageStore] = useState({});
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [prescriptionList, setPrescriptionList] = useState("");
  const [patientdat, setPatientdat] = useState({});
  const patientRecords = Array.isArray(patientdat.records) && patientdat.records.length > 0 ? patientdat.records : [];

  // Map over the patientRecords array and extract the pt_profile_id values
  const patientProfileIds = patientRecords.map((patient) => patient.pt_profile_id);
  
  const [appointmentDate, setAppointmentDate] = useState({
    StartDate: dayjs(),
    EndDate: dayjs().add(2, "days"),
  });
  const [prescriptionDate, setPrescriptionDate] = useState({
    StartDate: dayjs().subtract(30, "days"),
    EndDate: dayjs().add(1, "days"),
  });
  const [patientDate, setPatientDate] = useState({
    StartDate: dayjs(),
    EndDate:  dayjs().add(1, "days"),
  });

  const[patientdetailDate, setPatientdetailDate] = useState({
    StartDate: dayjs(),
    EndDate:  dayjs().add(1, "days"),
  })
  const [profileData, setProfileData] = useState("");
  const [submitProfileLoader, setSubmitProfileLoader] = useState(false);
  const [recordLoader, setRecordLoader] = useState(true);
  const [notesLoader, setNotesLoader] = useState(false);
  const [dialogSettings, setDialogSettings] = useState(defaultDialogSettings);
  const [hospitalDetails, setHospitalDetails] = useState("");
  const [receiptLoader, setReceiptLoader] = useState("loading");
  const [appStatsLoader, setAppStatsLoader] = useState("loading");
  const [receipts, setReceipts] = useState("loading");
  const [appStats, setAppStats] = useState("loading");
  const [downloadLoader, setDownloadLoader] = useState("");
  const [callActive, setCallActive] = useState(false);
  const [listMedications, setListMedications] = useState({
    dose_unit: "",
    dose_admin_method: "",
    dietary_instructions: "",
    dose_timings: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(Ls.get("userToken"));
  const [hospitalLoader, setHospitalLoader] = useState(true);
  const [joinActive, setJoinActive] = useState([]);
  const [currentTab, setCurrentTab] = useState("");
  const [clinicalsliderData, setClinicalSliderData] = useState({});
  const [hospitalError, setHospitalError] = useState({
    hasError: false,
    errorType: "missing",
  });
  const [state, setState] = useState({
    msg: "",
    type: "",
    timer: 3000,
    vertical: "top",
    horizontal: "center",
  });
  const [doctorAvailableState, setDoctorAvailableState] = useState(false);
  const [doctorAvailableStateLoader, setDoctorAvailableStateLoader] =
    useState("loaded");

  const [doctorSchedule, setDoctorSchedule] = useState("loading");
  const [doctorScheduleLoader, setDoctorScheduleLoader] = useState("loading");
  const appointmentRef = useRef(null);
  const [monitorData, setMonitorData] = useState(null);
  const [monitorDataLoader, setMonitorDataLoader] = useState("");
  const [patientInfo, setPatientInfo] = useState("");
  const [prescriptionTemplate, setPrescriptionTemplate] = useState(null);
  const [preTemplateLoader, setPreTemplateLoader] = useState(false);
  const [fetchMedication, setFetchMedication] = useState("");
  const [prescriptionSymptoms, setPrescriptionSymptoms] = useState("");
  const [fetchPrescription, setFetchPrescription] = useState("");
  const [moddata, setModdata] = useState("");
  const [pldata, setPldata] = useState("");
  const [prdata, setPrdata] = useState("");
  const [patientdata, setPatientdata] = useState("");
const[doctordetail, setDoctordetail] = useState("");
  let { mode, hospitalId } = useParams();
  const MODE = process.env.REACT_APP_MODE;
  const classes = useStyles();
  const fetchPlans = async () => {
    try {
      const plansdata = await ServiceCall.getv2(
        "/v2/doctor/list/opd/plans/",
        `${constants.hospitalId("hosp_id")}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (plansdata) {
        setPldata(plansdata.data.records);
        setPrdata(plansdata?.data);
      }
    } catch (err) {
      console.error("Error fetching plans:", err);
    }
  };

  // const getAppointmentss = function (startDate, endDate) {
  //   setOverlayLoading("loading");
  //   setAppointmentLoader("loading");
  //   // if (globalTimer !== null) {
  //   //   clearInterval(globalTimer.current);
  //   // }
  //   const patientProfileIdsString = patientProfileIds.join(","); // Convert the array to a comma-separated string

  //   ServiceCall.getv2(
  //     `/v2/doctor/list/patient/appointments/`,
  //     `${constants.hospitalId("hosp_id")}/${patientProfileIdsString}/${dayjs(startDate).format(
  //       "YYYY-MM-DD"
  //     )}/${dayjs(endDate).format("YYYY-MM-DD")}`,
  //     {
  //       doc_secret: Ls.get("docToken"),
  //       doc_key: Ls.get("docKey"),
  //     }
  //   )
  //     .then((res) => {
  //       // console.log("getAppointment", res);
  //       setAppointmentLoader("completed");
  //       setOverlayLoading("completed");

  //       // console.log(res.data.appointments);
  //       if (res.data.appointments) {
  //         let activeAppointment = setActiveAppointment(res.data.appointments);
  //         // setAppointmentList(activeAppointment);
  //         setAppointmentLastUpdate(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  //       } else {
  //         setAppointmentLastUpdate(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  //         // setAppointmentList([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("getAppointmenterrr", err);
  //       setAppointmentLoader("error");
  //       setOverlayLoading("error");
  //     });
  // };

  const Getmypatient = async () => {
    try {
      const response = await ServiceCall.newget("/facility/pt/data/filters");
      if (response && response.data && response.data.records) {
        setPatientdata(response.data.records);
      }
    } catch (err) {}
  };

  const setActiveAppointment = function (appointments) {
    let addedIsInActive = appointments.map((item) => {
      //logic to check active appointment start
      // if (!item['isInActive']) {
      //   item['isInActive'] = true;
      // }
      // item['isInActive'] = checkDisable(item);
      //logic to check active appointment end
      if (!item["dayStaus"]) {
        item["dayStatus"] = checkFutureToday(new Date(item.appointment_date));
      }
      return item;
    });

    return addedIsInActive;
  };
  const checkDisable = (item) => {
    // console.log('join button');
    // console.log('join button');
    var dateParts = item.appointment_date.split("-");

    // month is 0-based, that's why we need dataParts[1] - 1

    // var propsDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    var propsDate = new Date(item.appointment_date);
    // console.log(propsDate);
    var currentDate = new Date();
    // console.log(!datesAreOnSameDay(propsDate, currentDate))
    if (!datesAreOnSameDay(propsDate, currentDate)) {
      return true;
    }
    let allotedMin =
      parseInt(item.time_alloted.split(".")[0]) * 60 +
      parseInt(item.time_alloted.split(".")[1]);
    let allotedMax =
      parseInt(item.end_time.split(":")[0]) * 60 +
      parseInt(item.end_time.split(":")[1]);
    let allotedTimeslot = allotedMax - allotedMin;
    let date = new Date();
    let totalMin = date.getHours() * 60 + date.getMinutes();
    let minDiff = allotedMin - totalMin;

    // console.log(item.appointment_date, minDiff);
    if (minDiff <= 0 && minDiff >= parseFloat(allotedTimeslot) * -1) {
      return false;
    } else {
      return true;
    }
  };
  const activeJoinAppointment = function (appointmentList) {
    let currentAppointment = appointmentList.filter(
      (item) => item.isInActive === false
    );
    if (currentAppointment.length > 0) {
      setJoinActive([currentAppointment[0]]);
    } else {
      setJoinActive([]);
    }
  };

  const manifestFile = (data) => {
    const manifest = {
      short_name: `${
        `${(data?.short_name).toUpperCase()} PORTAL` || "Doctor Portal"
      }`,
      name: `${
        `${(data?.short_name).toUpperCase()} PORTAL` || "Doctor Portal"
      }`,
      display: "standalone",
      icons: [
        {
          src: "https://www.simpleimageresizer.com/_uploads/photos/68c99738/favicon_1_144x144.png",
          type: "image/png",
          sizes: "144x144",
        },
        // {
        //   src: `${baseUrl}${
        //     constants.testPath("mode") !== "test"
        //       ? ""
        //       : "/" + constants.testPath("mode")
        //   }/hospital/logo/${constants.testPath("hosp_id")}`,
        //   type: "image/png",
        //   sizes: "192x192",
        //   purpose: "maskable",
        // },
      ],

      start_url: `${data?.doctor_portal_abs_url}`,
      // "scope":"../../public/index.html",
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
    };

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#my-manifest-placeholder")
      .setAttribute("href", manifestURL);

    var link = document.createElement("Link");
    link.rel = "manifest";
    link.setAttribute(
      "href",
      "data:application/json;charset=8" + stringManifest
    );
  };

  const getAppointment = function (startDate, endDate) {
    setOverlayLoading("loading");
    setAppointmentLoader("loading");
    // if (globalTimer !== null) {
    //   clearInterval(globalTimer.current);
    // }
    ServiceCall.getv2(
      `/v2/fetch/doctor/appointments/`,
      `${constants.hospitalId("hosp_id")}/${dayjs(startDate).format(
        "YYYY-MM-DD"
      )}/${dayjs(endDate).format("YYYY-MM-DD")}`,
      {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      }
    )
      .then((res) => {
        // console.log("getAppointment", res);
        setAppointmentLoader("completed");
        setOverlayLoading("completed");

        // console.log(res.data.appointments);
        if (res.data.appointments) {
          let activeAppointment = setActiveAppointment(res.data.appointments);
          setAppointmentList(activeAppointment);
          setAppointmentLastUpdate(dayjs().format("YYYY-MM-DD HH:mm:ss"));
        } else {
          setAppointmentLastUpdate(dayjs().format("YYYY-MM-DD HH:mm:ss"));
          setAppointmentList([]);
        }
      })
      .catch((err) => {
        console.log("getAppointmenterrr", err);
        setAppointmentLoader("error");
        setOverlayLoading("error");
      });
  };
  /**
   *
   * @param {*} optionObject
   * commented as not required to run interval to check active appointment
   */
  useEffect(() => {
    initialModeChecking();
    return () => {
      typeof window !== "undefined" && window.localStorage.clear();
    };
  }, []);

  useEffect(() => {
    if (
      Ls.get("docKey") !== null &&
      Ls.get("docToken") !== null &&
      history?.location?.pathname?.search("home")
    ) {
      getAppointment(appointmentDate?.StartDate, appointmentDate?.EndDate);
      getProfile();
    }
  }, [Ls.get("docKey"), Ls.get("docToken")]);

  useEffect(() => {
    if (profileData !== "") {
      localStorage.setItem("user-name", profileData);
    }
  }, [profileData]);

  const dialogController = function (optionObject) {
    let dialogObject = {
      ...appDialog,
      dialogAction: (() => (
        <ButtonTool
          text="ok"
          containerClass="m-auto "
          handleClick={handleDialogClose}
        />
      ))(),
      ...optionObject,
    };
    setDialogSettings(dialogObject);
    setappDialog(dialogObject);
  };

  async function initailLookups() {
    let listMedication = { ...listMedications };
    const fetchPromises = fetchDosage.map(async (item) => {
      const list = await fetchMedicationType(item?.url);
      if (list && list?.records) {
        let obj = [...list?.records];
        switch (item.key) {
          case "dose_timings":
            obj.forEach((item) => {
              item.term = item.display;
              item.definition = item?.key;
              delete item.display;
              delete item.key;
            });
            listMedication[item.key] = obj;
            break;
          case "dose_admin_method":
          case "dietary_instructions":
            obj.forEach((item) => {
              item.term = item?.display;
              item.definition = item?.description;
              delete item.description;
              delete item.display;
            });
            listMedication[item.key] = obj;
            break;
          default:
            listMedication[item.key] = obj;
        }
      }
    });
    await Promise.all(fetchPromises).then((item) => {
      setListMedications(listMedication);
    });
  }
  const handleDialogClose = (event, reason) => {
    if (
      reason === "backdropClick" &&
      (dialogSettings.disableBackdropClick || dialogSettings.promptWindow)
    ) {
      return false;
    }
    setappDialog(defaultDialogSettings);
  };
  const logOutCall = async () => {
    dialogController({
      open: true,
      content: (() => (
        <div className="text-center">
          <h3>Logging Out</h3>
          <CircularProgress />
        </div>
      ))(),
      dialogAction: null,
      maxWidth: "sm",
      disableBackdropClick: true,
    });
  
    try {
      await axios.post(`${baseUrl}/v2/logout/doctor/${MODE}`, "", {
        headers: {
          doc_secret: Tokens.docToken(),
          doc_key: Tokens.dockey(),
        },
      });
  
      handleDialogClose();
      const url = new URL(window.location.href);
      url.searchParams.delete("hos_id");
      // Removed: url.searchParams.delete("mode");
  
      history.push({ pathname: "/" });
      // window.location.reload();
    } catch (err) {
      handleDialogClose();
      Ls.flush();
      // window.location.reload();
    }
  };

  const getProfile = async () => {
    console.log("profilecalledsuccessful");
    return new Promise(async (resolve, reject) => {
      try {
        let profileResponse = await ServiceCall.getv2(
          "/v2/fetch/doctor/details/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Tokens.docToken(),
            doc_key: Tokens.dockey(),
          }
        ).then((profileresponse) => {
          setDoctordetail(profileresponse?.data)
          if (profileresponse.data.message) {
            openProfileForm();
            dialogController({
              open: true,
              content: (() => (
                <div className="text-center">
                  <h3>
                    It seems you are login in for first time. Do you want to
                    create your profile with us?
                  </h3>
                  <h6>
                    We would need your basic details while booking the
                    appointment.
                  </h6>
                </div>
              ))(),
              checkLoginPage: true,
              dialogAction: (() => (
                <>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={openProfileForm}
                    className="mr-2"
                  >
                    Proceed
                  </Button>
                  <Button
                    className={`${classes.blackBtn}`}
                    variant="contained"
                    onClick={handleDialogClose}
                  >
                    Maybe Later
                  </Button>
                </>
              ))(),
              maxWidth: "sm",
              disableBackdropClick: true,
            });
          } else {
            let profileData = {
              ...profileresponse.data,
              name: profileresponse.data?.name
                ? profileresponse.data?.name
                : profileresponse.data?.firstname +
                  " " +
                  profileresponse.data?.lastname,
            };
            setProfileData(profileData);
          }
        });
        resolve(profileResponse);
      } catch (error) {
        console.log("catchErrorGetdoctor", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 401) {
            // handle401();`
          }
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        reject(error);
      }
    });
  };

  const ClinicalFindingsCall = async () => {
    setClinicalLoader(true);
    try {
      await ServiceCall.getnewv2("/v2/doctor/list/clinical/findings", "", {
        doc_secret: Tokens.docToken(),
        doc_key: Tokens.dockey(),
      }).then((res) => {
        const clinicalData = res?.data?.records;
        let systolicIndex = clinicalData?.findIndex(
          (obj) => obj?.display_name === bioMarksBloodPressure?.systolic
        );
        let diastolicIndex = clinicalData?.findIndex(
          (obj) => obj?.display_name === bioMarksBloodPressure?.diastolic
        );

        let newClinicalDataWithoutBloodPressure = clinicalData?.filter(
          function (items) {
            return (
              items?.display_name !== bioMarksBloodPressure?.systolic &&
              items?.display_name !== bioMarksBloodPressure?.diastolic
            );
          }
        );

        newClinicalDataWithoutBloodPressure?.push({
          display_name: bioMarksBloodPressure?.default,
          systolic: res?.data?.records[systolicIndex],
          diastolic: res?.data?.records[diastolicIndex],
        });

        let newClinicalData = newClinicalDataWithoutBloodPressure?.reduce(
          (acc, curr) => {
            curr.checked = false;
            acc.push(curr);
            return acc;
          },
          []
        );

        setClinicalFindings(newClinicalData);
      });
    } catch (err) {
    } finally {
      setClinicalLoader(false);
    }
  };

  const ClinicalSliderCall = async (appID, bioMarker) => {
    let newClinicalSliderData = {
      ...clinicalsliderData,
      [bioMarker]: {
        data: {},
        loader: true,
      },
    };
    setClinicalSliderData(newClinicalSliderData);
    try {
      await ServiceCall.getv2(
        "/v2/doctor/clinical/finding/ops/",
        `${appID}/${bioMarker}`,
        {
          doc_secret: Tokens.docToken(),
          doc_key: Tokens.dockey(),
        }
      ).then((res) => {
        const clinicalData = res?.data?.record;
        console.log("clinicaldataobject", res);
        setClinicalSliderData({
          ...newClinicalSliderData,
          [bioMarker]: {
            data: clinicalData,
            loader: false,
          },
        });
      });
    } catch (err) {
    } finally {
      // setClinicalSliderData({
      //   ...newClinicalSliderData,
      //   [bioMarker]: {
      //     data: newClinicalSliderData[bioMarker]?.data,
      //     loader: false,
      //   },
      // });
    }
  };
  const submitProfile = async (profileData) => {
    setSubmitProfileLoader(true);
    ServiceCall.putv2(
      "/v2/update/doctor/details/",
      constants.hospitalId("hosp_id"),
      profileData,
      {
        doc_secret: Tokens.docToken(),
        doc_key: Tokens.dockey(),
      }
    )
      .then((res) => {
        setSubmitProfileLoader(false);
        handleDialogClose();
        dialogController({
          open: true,
          content: <p className="text-center">Profile saved successfully.</p>,
          contentType: null,
        });
        getProfile();
      })
      .catch((err) => {
        console.log(err);
        setSubmitProfileLoader(false);
        handleDialogClose();
        dialogController({
          open: true,
          content: "Try again in sometime",
          contentType: null,
        });
      });
  };

  const openProfileForm = () => {
    dialogController({
      open: true,
      contentType: "profileForm",
      maxWidth: "sm",
      dialogAction: null,
      // disableBackdropClick: true,
    });
  };

  const openPatientProfileForm = () => {
    dialogController({
      open: true,
      contentType: "patientProfileForm",
      maxWidth: "sm",
      dialogAction: null,
    });
  };

  const getRecords = async function (appointmentId) {
    let userToken = Ls.get("userToken");
    setRecordLoader(true);
    try {
      let recordsRes = await ServiceCall.get(
        `/appointment/patient/records/${appointmentId}`,
        {
          doc_token: userToken,
        }
      );
      let recordObject = [];
      if (recordsRes.data.medical_records) {
        recordObject = recordsRes.data.medical_records.map((item) => {
          let itemObject = { ...item, downloadLoader: false };
          return itemObject;
          // return (item['downloadLoader'] = false);
        });
        let recordObjectClone = recordData;
        if (!recordObjectClone[appointmentId]) {
          recordObjectClone[appointmentId] = [];
        }
        recordObjectClone[appointmentId] = recordObject;
        setRecordData((oldValue) => ({ ...oldValue, ...recordObjectClone }));
      } else {
        setRecordData(recordsRes.data);
      }
      setRecordLoader(false);
    } catch (err) {
      setRecordLoader(false);
      console.log(err);
    }
  };
  const uploadRecords = (files) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "multipart/form-data");
    myHeaders.append("doc_token", Ls.get("userToken"));
    console.log(files[0].name);
    var formdata = new FormData();
    // formdata.append("medical_record", fileInput.files[0], "file");
    // for (const file of files) {
    formdata.append("medical_record", files[0], files[0].name); // appending every file to formdata
    // }
    // console.log(formdata);
    return new Promise((resolve, reject) =>
      ServiceCall.post("/patient/records", formdata, {
        doc_token: Ls.get("userToken"),
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject();
        })
    );
  };
  const downloadRecord = async (appointmentId, fileName, index) => {
    let userToken = Ls.get("userToken");
    let url = `/appointment/patient/record/${appointmentId}/${fileName}`;

    setDownloadLoader(index);
    apiCall({
      url,
      method: "get",
      responseType: "blob",
      headers: {
        doc_token: userToken,
      },
    })
      .then((res) => {
        setDownloadLoader("");
        var a = document.createElement("a");

        var url = window.URL.createObjectURL(res.data);
        a.href = url;
        a.download = fileName;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
        setDownloadLoader("");
      });
  };

  const updateClinicalFindingCall = async (url, data, appId, bioMark) => {
    setClinicalLoader(true);
    try {
      let updateCall = await ServiceCall.putv2(
        "/v2/doctor/clinical/finding/ops/",
        url,
        data,
        {
          doc_secret: Tokens.docToken(),
          doc_key: Tokens.dockey(),
        }
      );

      if (updateCall) {
        setState({
          msg: updateCall?.data?.message || "",
          type: "info",
          timer: 2000,
          vertical: "top",
          horizontal: "center",
        });
        setClinicalLoader(false);

        ClinicalSliderCall(appId, bioMark);
      }
    } catch (err) {
      err &&
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    } finally {
      setClinicalLoader(false);
    }
  };

  const editPatientInfo = async (appointmentID, profileData) => {
    setSubmitProfileLoader(true);
    ServiceCall.putv2(
      "/v2/doctor/appointment/note/patient/info/",
      appointmentID,
      profileData,
      {
        doc_secret: Tokens.docToken(),
        doc_key: Tokens.dockey(),
      }
    )
      .then((res) => {
        const appointmentData =
          appointment || JSON.parse(Ls.get("appointmentData"));
        setSubmitProfileLoader(false);
        handleDialogClose();
        dialogController({
          open: true,
          content: <p className="text-center">Profile saved successfully.</p>,
          contentType: null,
        });
        fetchPatientInfo(appointmentData?.appointmentItem?.appointment_id);
      })
      .catch((err) => {
        console.log(err);
        setSubmitProfileLoader(false);
        handleDialogClose();
        dialogController({
          open: true,
          content: "Try again in sometime",
          contentType: null,
        });
      });
  };

  const fetchPatientInfo = async (appointmentID) => {
    setPreTemplateLoader(true);
    try {
      const fetchProfile = await ServiceCall.getv2(
        "/v2/doctor/appointment/note/patient/info/",
        appointmentID,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (fetchProfile) {
        setPreTemplateLoader(false);
        setPatientInfo(fetchProfile?.data);
      }
    } catch (err) {
      setPreTemplateLoader(false);
    }
  };

  const sharedRecord = async (fileName, index, shareWith) => {
    let userToken = Ls.get("userToken");
    let url = `/patient/record/share/${shareWith}/${fileName}`;

    setDownloadLoader(index);
    apiCall({
      url,
      method: "put",
      headers: {
        doc_token: userToken,
      },
    })
      .then((res) => {
        setDownloadLoader("");
        getRecords();
      })
      .catch((err) => {
        console.log(err);
        setDownloadLoader("");
      });
  };
  const deleteRecord = async (fileName) => {
    // patient/del/record/testlabreport_10302021124148.jpg

    return new Promise((resolve, reject) =>
      apiCall({
        url: `/patient/del/record/${fileName}`,
        method: "DELETE",
        headers: {
          doc_token: Ls.get("userToken"),
        },
      })
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
    );
  };
  // get prescription on call
  const getNotes = (appointment_id) => {
    let userToken = Ls.get("userToken");
    // setOverlayLoading('loading');
    setNotesLoader(true);
    return new Promise((resolve, reject) => {
      ServiceCall.get(`/patient/prescription/${appointment_id}`, {
        doc_token: userToken,
      })
        .then((res) => {
          // setAppointmentLoader('completed');
          // setOverlayLoading('completed');
          setNotesLoader(false);
          console.log(res.data);
          let data = res.data;
          let helperObject = {};
          Object.keys(data).map((item) => {
            helperObject[item] = JSON.parse(data[item]);
          });
          // setReport(helperObject);
          resolve(helperObject);
          // setAppointmentList(res.data.appointments ? res.data.appointments : []);
        })
        .catch((err) => {
          // setAppointmentLoader('error');
          // setOverlayLoading('error');
          setNotesLoader(false);
          console.log(err);
          reject(err);
        });
    });
  };

  const initialModeChecking = () => {
    let dev2 = window.location.hostname.includes("dev2");
    let dev = window.location.hostname.includes("dev");
    if (window.location.hostname === "localhost" || dev2 || dev) {
      Ls.set("mode", "test");
      // getHospitalDetails("/test");
    } else {
      Ls.set("mode", "live");
      // getHospitalDetails("/live");
    }
  };

  // const initialRouteCheck = function () {
  //   if (localStorage.getItem("loadedInsideIframe") === "false") {
  //     // Ls.flush();
  //     setHospitalLoader(true);
  //     if (!query.get("hosp_id") || !query.get("mode")) {
  //       setHospitalError({
  //         hasError: true,
  //         errorType: "missing",
  //       });
  //       Ls.flush();
  //       dialogController({
  //         open: true,
  //         content: (() => (
  //           <div className="text-center">
  //             <h3>Please provide hospital id and environment mode</h3>
  //           </div>
  //         ))(),
  //         dialogAction: null,
  //         maxWidth: "sm",
  //         disableBackdropClick: true,
  //       });
  //     } else {
  //       let hospital_id = getLocal("hosp_id");
  //       let mode = getLocal("mode");
  //       query.forEach(function (value, key) {
  //         if (
  //           key === "hosp_id" &&
  //           localStorage.getItem("hosp_id") !== query.get("hosp_id")
  //         ) {
  //           localStorage.removeItem("hosp_id");
  //           console.log("no match");
  //           hospital_id = value;
  //         }
  //         if (
  //           key === "mode" &&
  //           localStorage.getItem("mode") !== query.get("mode")
  //         ) {
  //           localStorage.removeItem("mode");
  //           mode = value;
  //         }

  //         localStorage.setItem(key, value);
  //       });
  //       setHospitalError({
  //         hasError: false,
  //         errorType: "",
  //       });
  //       getHospitalDetails(hospital_id);
  //     }
  //   } else {
  //     setHospitalError({
  //       hasError: false,
  //       errorType: "",
  //     });
  //     getHospitalDetails();
  //   }
  // };
  const getHospitalDetails = function (mode, number) {
    return new Promise((resolve, reject) => {
      setHospitalLoader(true);
      ServiceCall.post(
        "/v2/list/doctor/facilities",
        mode,
        { phone: number },
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      )
        .then((res) => {
          setHospitalDetails(res.data?.records);
          setHospitalLoader(false);
          resolve(res.data);
          manifestFile(res.data);
        })
        .catch((err) => {
          console.log(err);
          setHospitalError({
            hasError: true,
            errorType: "missing",
          });
          setHospitalLoader(true);

          if (err.response) {
            dialogController({
              open: true,
              content: (() => (
                <div className="text-center">
                  <h3>
                    {err.response.data.message
                      ? err.response.data.message
                      : "Hospital details not found"}
                  </h3>
                </div>
              ))(),
              dialogAction: null,
              maxWidth: "sm",
              disableBackdropClick: true,
            });
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log("Error", err.message);
          }
          console.log(err.config);
          reject(err);
        });
      // console.log("hospitalDetails",hospitalDetails)
    });
  };
  const getBilling = () => {
    setReceiptLoader("loading");
    let userToken = Ls.get("userToken");
    let url = `/doctor/billing/${query.get("hosp_id")}`;
    ServiceCall.get(url, { doc_token: userToken })
      .then((res) => {
        setReceipts(res.data);
        setReceiptLoader("");
      })
      .catch((err) => {
        // setAppointmentLoader('');
        setReceiptLoader("error");
        if (err.response) {
          //   setApiError('Failed with response ' + err.response.data);
        } else if (err.request) {
          //   setApiError('Failed request ' + err);
        } else {
          //   setApiError('Failed in general ' + err);
        }
      });
  };
  const getAppointmentStats = (start, end) => {
    setAppStatsLoader("loading");
    let userToken = Ls.get("userToken");
    let url = "/v2/fetch/doctor/appointments/";
    let id = `${query.get("hosp_id") || constants.testPath("hosp_id")}`;
    ServiceCall.getv2(url, { doc_token: userToken })
      .then((res) => {
        setAppStats(res.data);
        setAppStatsLoader("");
      })
      .catch((err) => {
        // setAppointmentLoader('');
        setAppStatsLoader("error");
        if (err.response) {
          //   setApiError('Failed with response ' + err.response.data);
        } else if (err.request) {
          //   setApiError('Failed request ' + err);
        } else {
          //   setApiError('Failed in general ' + err);
        }
      });
  };

  const updateAvailability = (availableStat) => {
    setDoctorAvailableStateLoader("loading");
    let doc_available_stat = { is_available: availableStat };
    ServiceCall.put(
      `/doctor/availability/${getLocal("hosp_id")}`,
      { doc_available_stat },
      {
        doc_token: Ls.get("userToken"),
      }
    )
      .then((res) => {
        setDoctorAvailableState(res.data.is_available);
        setDoctorAvailableStateLoader("loaded");
      })
      .catch((err) => {
        console.log("error received");
        console.log(err);
        setDoctorAvailableStateLoader("loaded");
      });
  };

  const getDocAvailability = () => {
    setDoctorAvailableStateLoader("loading");
    ServiceCall.get(`/doctor/availability/${getLocal("hosp_id")}`, {
      doc_token: Ls.get("userToken"),
    })
      .then((res) => {
        setDoctorAvailableState(res.data.is_available);
        setDoctorAvailableStateLoader("loaded");
      })
      .catch((err) => {
        console.log("error received getDocAvailability");
        console.log(err);
        setDoctorAvailableStateLoader("error");
      });
  };

  // const saveDoctorSchedule = (hospitalId, scheduleData) => {
  //   setDoctorScheduleLoader("loading");
  //   ServiceCall.post(`/doctor/schedule/${hospitalId}`, scheduleData, {
  //     doc_token: Ls.get("userToken"),
  //   })
  //     .then((res) => {
  //       setDoctorSchedule(convertTime(res.data.current_schedule));
  //       setDoctorScheduleLoader("");
  //     })
  //     .catch((err) => {
  //       console.log("error received saveDoctorSchedule");
  //       console.log(err);
  //       setDoctorScheduleLoader("error");
  //     });
  // };

  const getDoctorSchedule = (date, appointmentType) => {
    setDoctorScheduleLoader("loading");
    return new Promise((resolve, reject) =>
      ServiceCall.getv2(
        `/v3/doctor/manage/day/schedule/`,
        `${constants.hospitalId("hosp_id")}/${appointmentType}/${date}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      )
        .then((res) => {
          console.log(res);
          setDoctorSchedule(res.data);
          setDoctorScheduleLoader("loaded");
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error received saveDoctorSchedule");
          console.log(err);
          reject(err);
          setDoctorScheduleLoader("error");
        })
    );
  };

  const getDoctorRecurringSchedule = (appointmentType) => {
    setDoctorScheduleLoader("loading");
    return new Promise((resolve, reject) =>
      ServiceCall.getv2(
        `/v3/doctor/manage/recurring/schedule/`,
        `${constants.hospitalId("hosp_id")}/${appointmentType}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      )
        .then((res) => {
          console.log(res);
          setDoctorSchedule(res.data);
          setDoctorScheduleLoader("loaded");
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error received saveDoctorSchedule");
          console.log(err);
          reject(err);
          setDoctorScheduleLoader("error");
        })
    );
  };

  const fetchMedicationDosage = async (med) => {
    setPreTemplateLoader(true);
    let url = `/v2/doctor/medication/dosage`;
    try {
      const fetchMedication = await ServiceCall.postnewv2(
        url,
        "",
        { medication: med },
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (fetchMedication) {
        setPreTemplateLoader(false);
        return fetchMedication?.data;
      }
    } catch (err) {
      setPreTemplateLoader(false);
    }
  };

  const listRecentMedication = async () => {
    setPreTemplateLoader(true);
    try {
      const recentMedication = await ServiceCall.getnewv2(
        "/v2/doctor/prescription/list/recent/medications",
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (recentMedication) {
        setPreTemplateLoader(false);
        return recentMedication?.data;
      }
    } catch (err) {
      setPreTemplateLoader(false);
    }
  };

  const fetchMedicationType = async (path) => {
    try {
      let url = `${baseUrl}${path}`;
      const medicationType = await axios.get(url);
      if (medicationType) {
        return medicationType?.data;
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const medicationFetch = async (appointmentId) => {
    setPreTemplateLoader(true);
    try {
      const medicationFetch = await ServiceCall.getv2(
        "/v2/doctor/appointment/edit/medic/",
        appointmentId,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (medicationFetch) {
        setFetchMedication(medicationFetch?.data);
        setPreTemplateLoader(false);
      }
    } catch (err) {
      setPreTemplateLoader(false);
    }
  };

  const appointmentNotes = async (appointmentId) => {
    setPreTemplateLoader(true);
    try {
      const appointmentSymptoms = await ServiceCall.getv2(
        "/v2/doctor/appointment/note/sft/",
        appointmentId,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      medicationFetch(appointmentId);
      if (appointmentSymptoms) {
        setPrescriptionSymptoms(appointmentSymptoms.data);
        setPreTemplateLoader(false);
      }
    } catch (err) {
      setPreTemplateLoader(false);
    }
  };

  const downloadPrescription = (appointmentId) => {
    return new Promise((resolve, reject) => {
      apiCall({
        url: `/doctor/prescription/download/${appointmentId}`,
        method: "get",
        responseType: "blob",
        headers: {
          doc_token: Ls.get("userToken"),
        },
      })
        .then((res) => {
          console.log(res.data);
          setPreTemplateLoader(false);

          resolve(res.data);
        })
        .catch((err) => {
          setPreTemplateLoader(false);
          reject(err);
        });
    });
  };

  const prescriptionFetch = (callType, keyWard) => {
    return new Promise((resolve, reject) => {
      apiCallOld(
        {
          url: `/${callType}?name=${keyWard}&limit=10`,
          method: "get",
          headers: {
            doc_token: Ls.get("userToken"),
          },
        },
        true
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("error received prescriptionFetch");
          console.log(err);
          reject("error");
        });
    });
  };
  const getMonitorData = (appointmentId) => {
    setMonitorDataLoader("loading");
    ServiceCall.get(`/doctor/appointment/patient/bio/${appointmentId}`, {
      doc_token: Ls.get("userToken"),
    })
      .then((res) => {
        let monitorData = res.data.bio_markers;
        setMonitorDataLoader("loaded");
        // console.log(monitorData);
        if (monitorData.length > 0) {
          let finalObject = {
            body_temp_fr: { labels: [], datasets: [] },
            blood_pressure: { labels: [], datasets: [] },
            heart_rate: { labels: [], datasets: [] },
            spo2_level: { labels: [], datasets: [] },
          };
          let createHelperArr = { labels: [], dataSet: {} };
          for (let i = 0; i < monitorData.length; i++) {
            let currentItem = monitorData[i];
            // console.log(currentItem);
            Object.keys(currentItem).map((item) => {
              // let helperObject =
              if (item === "mark_time") {
                if (!createHelperArr["labels"]) {
                  createHelperArr["labels"] = [];
                }
                createHelperArr["labels"].push(
                  dayjs(currentItem[item]).format("DD/MM/YY HH:MM")
                );
              } else {
                if (!createHelperArr.dataSet[item]) {
                  createHelperArr.dataSet[item] = {
                    label: keymap[item].name,
                    containerLabel: keymap[item].containerLabel || "",
                    data: [],
                    borderColor: keymap[item].borderColor,
                    backgroundColor: keymap[item].borderColor,
                  };
                }
                createHelperArr.dataSet[item].data.push(currentItem[item]);
              }
              return item;
            });
          }
          console.log(createHelperArr);
          Object.keys(createHelperArr.dataSet).map((item) => {
            // console.log(item);
            if (
              item === "dias_blood_pressure" ||
              item === "syst_blood_pressure"
            ) {
              finalObject["blood_pressure"]["datasets"].push(
                createHelperArr.dataSet[item]
              );

              finalObject["blood_pressure"]["labels"] = createHelperArr.labels;
            } else {
              finalObject[item]["datasets"].push(createHelperArr.dataSet[item]);
              finalObject[item]["labels"] = createHelperArr.labels;
            }
          });
          // finalObject.labels = createHelperArr.labels;
          // window.copy(finalObject);
          setMonitorData(finalObject);
        }
      })
      .catch((err) => {
        setMonitorDataLoader("loaded");
        console.log(err);
      });
  };
  function viewPrescription(appointmentId) {
    setPreTemplateLoader(true);
    return new Promise((resolve, reject) => {
      ServiceCall.get(`/doctor/prescription/template/${appointmentId}`, {
        doc_token: Ls.get("userToken"),
      })
        .then((res) => {
          setPreTemplateLoader(false);
          setPrescriptionTemplate(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  }
  function generatePrescription(appointmentId, prescriptionData) {
    setPreTemplateLoader(true);
    return new Promise((resolve, reject) => {
      ServiceCall.put(
        `/doctor/prescription/generate/${appointmentId}`,
        prescriptionData,
        {
          doc_token: Ls.get("userToken"),
        }
      )
        .then((res) => {
          // setPreTemplateLoader(false);
          // setPrescriptionTemplate(res.data)
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  }
  function createNotes(appointmentId, pData) {
    setPreTemplateLoader(true);
    let formData = {
      symptoms_list: [],
      findings_list: [],
      recommendations_list: [],
    };
    Object.keys(pData).map((elm) => {
      if (elm === "symptoms") {
        formData["symptoms_list"] = [
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      if (elm === "diseases") {
        formData["findings_list"] = [
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      if (elm === "diagnosis" || elm === "therapies") {
        formData["recommendations_list"] = [
          ...formData["recommendations_list"],
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      return elm;
    });
    return new Promise((resolve, reject) => {
      ServiceCall.put(`/doctor/appointment/notes/${appointmentId}`, formData, {
        doc_token: Ls.get("userToken"),
      })
        .then((res) => {
          setPreTemplateLoader(false);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          setPreTemplateLoader(false);
          console.log(err);
        });
    });
  }

  function dateFormate(date, type = "") {
    const formate = dayjs(date).format(`Do MMM, YYYY ${type}`);
    return formate;
  }

  function FetchPrescription(appointmentId) {
    setPreTemplateLoader(true);
    return new Promise((resolve, reject) =>
      ServiceCall.getv2("/v2/doctor/prescription/submission/", appointmentId, {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      })
        .then((res) => {
          resolve(res.data);
          setFetchPrescription(res?.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          setPreTemplateLoader(false);
        })
    );
  }

  const getv2Call = async (url, id, loader, getNewV2 = false) => {
    typeof loader === "function" && loader(true);
    let response = { data: "", error: "" };
    try {
      if (getNewV2) {
        response.data = await ServiceCall.getnewv2(url, id, {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        });
      } else {
        response.data = await ServiceCall.getv2(url, id, {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        });
      }
      if (response?.data) {
        typeof loader === "function" && loader(false);
        return response;
      }
    } catch (err) {
      typeof loader === "function" && loader(false);
      response.error = err?.response?.data?.message
        ? err?.response?.data?.message
        : "Internal Server Error";
      setState({
        msg: err?.response?.data?.message || "Internal Server Error",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      return response;
    }
  };

  const postPutV2Call = async (
    url,
    id,
    options,
    loader,
    put = false,
    postnew = false
  ) => {
    typeof loader === "function" && loader(true);
    let response = { data: "", error: "" };
    try {
      if (put) {
        response.data = await ServiceCall.putv2(url, id, options, {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        });
      } else if (postnew) {
        response.data = await ServiceCall.postnewv2(url, id, options, {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        });
      } else {
        response.data = await ServiceCall.postv2(url, id, options, {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        });
      }
      if (response?.data) {
        typeof loader === "function" && loader(false);
        return response;
      }
    } catch (err) {
      typeof loader === "function" && loader(false);
      response.error = err?.response?.data?.message
        ? err?.response?.data?.message
        : "Internal Server Error";
      setState({
        msg: err?.response?.data?.message || "Internal Server Error",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      return response;
    }
  };

  const deleteV2Call = async (url, id, loader) => {
    typeof loader === "function" && loader(true);
    let response = { data: "", error: "" };
    try {
      response.data = await ServiceCall.deletev2(url, id, {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      });
      if (response?.data) {
        typeof loader === "function" && loader(false);
        return response?.data;
      }
    } catch (err) {
      typeof loader === "function" && loader(false);
      response.error = err?.response?.data?.message
        ? err?.response?.data?.message
        : "Internal Server Error";
      setState({
        msg: err?.response?.data?.message || "Internal Server Error",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      return response;
    }
  };

  function getPrescription() {
    let url = `v2/doctor/prescriptions/list/`;
    return new Promise((resolve, reject) =>
      // ServiceCall.get(url,constants.hospitalId("hosp_id"), {
      //   doc_token: Ls.get("userToken"),
      // })
      ServiceCall.getv2(url, constants.hospitalId("hosp_id"), {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      })
        .then((res) => {
          console.log(res.data);
          res.data["lastupdated"] = dayjs().format("YYYY-MM-DD HH:mm:ss");
          resolve(res.data);
          setPrescriptionList(res?.data?.doctor_prescriptions);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
    );
  }

  function getPatientdata(startDate, endDate, payload) {
    console.log('payloads', payload);
    let url = `v2/doctor/list/facility/patients/`;
    let id =
      `${constants.hospitalId("hosp_id")}/` +
      `${dayjs(startDate).format("YYYY-MM-DD")}/` +
      `${dayjs(endDate).format("YYYY-MM-DD")}`;
  
    const headers = {
      doc_secret: Ls.get("docToken"),
      doc_key: Ls.get("docKey"),
    };
  
    return new Promise((resolve, reject) =>
      ServiceCall.postv2(url, id, payload || "", headers)
        .then((res) => {
          console.log(res.data);
          res.data["lastupdated"] = dayjs().format("YYYY-MM-DD HH:mm:ss");
          resolve(res.data);
          setPatientdat(res?.data?.records);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
    );
  }
  
  


  function timeDifference(appointmentItem) {
    // date1.diff(date2)
    //appointment_date, end_time
    // let date1 = dayjs();
    // let date2 = dayjs(
    //   `${appointmentItem.appointment_date} ${appointmentItem.end_time}`
    // );
    if (appointmentItem?.show_prescription_button) {
      if (
        appointmentItem?.prescription_submitted &&
        appointmentItem?.prescription_edited
      ) {
        return true;
      } else {
        return false;
      }
    }

    // console.log(
    //   date2,
    //   appointmentItem.appointment_date,
    //   appointmentItem.end_time,
    //   date1.diff(date2)
    // );
    return true;
  }

  const modaldta = async () => {
    try {
      const headers = {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      };
      const mdata = await ServiceCall.gett(
        "/v2/fetch/doctor/login/details",
        headers
      );
      if (mdata) {
        setModdata(mdata.data);
        // setRefdata(mdata.data)
      }
    } catch (err) {}
  };

  const contextOption = {
    modaldta,
    moddata,
    useQuery,
    getAppointment,
    setToggleAppointmentLists,
    toggleAppointmentLists,
    setAppointment,
    appointment,
    appointmentList,
    dialogController,
    handleDialogClose,
    appointmentLoader,
    appDialog,
    overlayLoading,
    getRecords,
    recordData,
    uploadRecords,
    getProfile,
    logOutCall,
    openProfileForm,
    openPatientProfileForm,
    submitProfile,
    patientInfo,
    submitProfileLoader,
    profileData,
    setProfileData,
    recordLoader,
    setRecordLoader,
    downloadRecord,
    getNotes,
    notesLoader,
    deleteRecord,
    getHospitalDetails,
    hospitalDetails,
    getBilling,
    getAppointmentStats,
    receiptLoader,
    setReceiptLoader,
    appStatsLoader,
    receipts,
    appStats,
    setReceipts,
    setDownloadLoader,
    downloadLoader,
    sharedRecord,
    callActive,
    setCallActive,
    hospitalError,
    hospitalLoader,
    updateAvailability,
    getDocAvailability,
    // saveDoctorSchedule,
    getDoctorSchedule,
    doctorAvailableState,
    setDoctorAvailableState,
    doctorAvailableStateLoader,
    setDoctorAvailableStateLoader,
    doctorSchedule,
    setDoctorSchedule,
    doctorScheduleLoader,
    setDoctorScheduleLoader,
    setJoinActive,
    joinActive,
    currentTab,
    setCurrentTab,
    prescriptionFetch,
    getMonitorData,
    monitorData,
    monitorDataLoader,
    prescriptionTemplate,
    viewPrescription,
    generatePrescription,
    preTemplateLoader,
    dateFormate,
    createNotes,
    appointmentDate,
    setAppointmentDate,
    appointmentNotes,
    downloadPrescription,
    setPrescriptionSymptoms,
    setFetchMedication,
    FetchPrescription,
    appointmentLastUpdate,
    getDoctorRecurringSchedule,
    getPrescription,
    checkDisable,
    fetchPatientInfo,
    timeDifference,
    prescriptionSymptoms,
    fetchPrescription,
    setFetchPrescription,
    ClinicalFindingsCall,
    clinicalLoader,
    setClinicalLoader,
    editPatientInfo,
    clinicalFindings,
    setClinicalFindings,
    clinicalsliderData,
    ClinicalSliderCall,
    updateClinicalFindingCall,
    setDynamicImageStore,
    fetchMedication,
    listRecentMedication,
    DynamicImageStore,
    fetchMedicationDosage,
    fetchMedicationType,
    setState,
    state,
    listMedications,
    setListMedications,
    prescriptionDate,
    setAppointmentLoader,
    setPrescriptionDate,
    initailLookups,
    medicationFetch,
    getv2Call,
    postPutV2Call,
    deleteV2Call,
    templateData,
    setTemplateData,
    setPldata,
    pldata,
    fetchPlans,
    checkoutLoader,
    setCheckoutLoader,
    Getmypatient,
    patientdata,
    setPatientdata,
    prdata,
    prescriptionList,
    setPrescriptionList,
    setPatientDate,
    patientDate,
    getPatientdata,
    patientdat, 
    setPatientdetailDate,
    patientdetailDate,
    setDoctordetail, 
    doctordetail,
  };
  return (
    <PatientpageContext.Provider value={contextOption}>
      {props.children}
    </PatientpageContext.Provider>
  );
}