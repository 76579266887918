import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { constants } from "../utility/constants";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { PatientpageContext } from "../context/GlobalContext";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { verifyMessage } from "../utility/config";
import congratsImage from "../../src/static/images/thankuImage.jpg";
// import CircularProgress from "@material-ui/core/CircularProgress";

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Input,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
  Card,
  CardContent,
  CardActions,
  CardMedia,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
}));

const Checkout = ({ location }) => {
  const doctorContext = useContext(PatientpageContext);
  const classes = useStyles();
  const setState = doctorContext.setState;
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [saved, setSaved] = useState(false); // State to track whether form has been saved
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const{plan_name} = useParams();
  

  const plan = location?.state?.plan || null; // Receive the plan object from props
  const history = useHistory();
  const [pldata, setPldata] = useState([]);
  const [data, setData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [verifyingRazorpayPayment, setVerifyingRazorpayPayment] =
    useState(false);
  const [processingOnlinePayment, setProcessingOnlinePayment] = useState(false);

  const [checkdata, setCheckdata] = useState("");
  let query = doctorContext.useQuery();
  // console.log(76267262, query.get("plan"));
  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* <CircularProgress /> */}
    </div>
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/list/opd/plan/details/",
          `${constants.hospitalId("hosp_id")}/${plan_name}`, // Access plan_name from props

          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (response) {
          setPldata(response.data.plans);
          setData(response.data);
          // Set default selected plan to 'ANNUAL' if available
          const annualPlan = response.data.plans.find(
            (plan) => plan.plan_name === "ANNUAL"
          );
          if (annualPlan) {
            setSelectedPlan(annualPlan);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlle = () => {
    history.push({
      pathname: "/plans",
      search: history.location.search,
    });
  };
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan); // Update selected plan state
  };
  const unicode = "\u20b9";

  

  const unsuccessfulOnlinePayment = async (razorpayErrorRes) => {
    console.log({ razorpayErrorRes });
  };

  const successfulOnlinePayment = async (razorpayRes, subscriptionId) => {
    setVerifyingRazorpayPayment(true);
    try {
      const body = {
        razorpay_payment_id: razorpayRes.razorpay_payment_id,
        razorpay_order_id: razorpayRes.razorpay_order_id,
        razorpay_signature: razorpayRes.razorpay_signature,
        razorpay_error_code: "",
        razorpay_error_description: "",
        razorpay_error_source: "",
        razorpay_error_step: "",
        razorpay_error_reason: "",
        razorpay_error_metadata_order_id: "",
        razorpay_error_metadata_payment_id: "",
      };

      const verifyRes = await ServiceCall.postv2(
        "/v2/doctor/verify/opd/plan/order/payment/",
        `${constants.hospitalId("hosp_id")}`,
        `${subscriptionId}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (verifyRes) {
        setLoading(false);
        setState({
          msg: verifyRes?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    } finally {
      setVerifyingRazorpayPayment(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const body = {
        service_id: selectedPlan?.service_id,
        plan_id: selectedPlan?.plan_id,
        order_gross_amount: selectedPlan.plan_mrp_subunits,
        order_discount: selectedPlan.plan_discount_subunits,
        order_gst: selectedPlan.plan_applicable_gst_subunits,
        order_grand_total:
          parseFloat(selectedPlan.plan_mrp_subunits) -
          parseFloat(selectedPlan.plan_discount_subunits) +
          parseFloat(selectedPlan.plan_applicable_gst_subunits), // Calculate total amount correctly
        plan_add_ons_list: [],
      };

      const Handlesub = await ServiceCall.postv2(
        "/v2/doctor/raise/opd/plan/order/",
        `${constants.hospitalId("hosp_id")}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        setCheckdata(Handlesub.data);
        doctorContext?.setCheckoutLoader(false);
        await processRazorpayPayment(
          Handlesub.data.razorpay_order_id,
          Handlesub.data.subscription_id
        ); // Pass razorpay_order_id and subscription_id
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  const processRazorpayPayment = async (razorpayOrderId, subscriptionId) => {
    try {
      const razorpayKey =
        Ls.get("mode") === "live"
          ? "rzp_live_Uy4jKL7ZDdslqp"
          : "rzp_test_BTj030BoE9feLH";
      const appointmentId = checkdata.subscription_id;
      const options = {
        key: razorpayKey, // Enter the Key ID generated from the Dashboard
        amount: checkdata.order_amount_subunits * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: checkdata.currency,
        name: "RxOne",
        description: "Subscription",
        image:
          "https://rxone-static.s3.ap-south-1.amazonaws.com/images/rx_192.png",
        order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          name: doctorContext.profileData.name,
          email: doctorContext.profileData.email,

          contact: doctorContext.profileData.phone,
        },
        notes: {
          reciept: appointmentId,
        },
        theme: {
          color: "#a5d6a7",
        },
        handler: (razorpayRes) => {
          successfulOnlinePayment(razorpayRes, subscriptionId);
        },
        modal: {
          ondismiss: function () {
            setApiError("info");
            setApiError("Payment cancelled by the user.");
            // setShowToast(true);
          },
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", unsuccessfulOnlinePayment);

      rzp1.open();
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    } finally {
      setProcessingOnlinePayment(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 1000);

    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "700",
                marginLeft: "10px",
              }}
            >
              <ArrowBackIosIcon onClick={handlle} />
              <div
                style={{
                  marginTop: "-26px",
                  marginLeft: "16px",
                  cursor: "pointer",
                }}
              >
                Plans
              </div>
            </p>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  padding: "5px",
                  border: "1px solid black",
                  width: "97px",
                  borderRadius: "5px",
                  backgroundColor: "#199a8e",
                  color: "#fff",
                  fontWeight: "700",
                  margin: "auto",
                  border: "none",
                }}
              >
                {plan?.plan_name}
              </div>
            </div>
            {Array.isArray(pldata) && pldata.length > 0 ? (
              pldata.map((plan, index) => (
                <div className="car" key={index}>
                  <input
                    type="radio"
                    name="planSelection"
                    checked={selectedPlan === plan}
                    onChange={() => handlePlanSelect(plan)}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                      verticalAlign: "middle",
                      position: "absolute",
                      marginTop: "55px",
                      marginLeft: "13px",
                      cursor: "pointer",
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "87px",
                      flex: "1 0 30%",
                      textAlign: "left",
                    }}
                  >
                    <h2
                      style={
                        {
                          // marginLeft: "-173px",
                          // marginTop: "0px",
                        }
                      }
                    >
                      {plan.plan_name}
                    </h2>
                    <p
                      style={{
                        fontWeight: "unset",
                        marginTop: "-16px",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {plan.plan_offered_price}
                      </span>{" "}
                      {plan.plan_duration}
                    </p>
                    <p
                      style={{
                        width: "138px",
                        float: "inline-end",
                        marginTop: "-39px",
                        marginRight: "10px",
                        background: "#ddd7d7",
                        display: "inline-block",
                        padding: "5px",
                        border: "1px solid black",
                        borderRadius: "5px",
                        color: "black",
                        fontWeight: "700",
                        border: "none",
                      }}
                    >
                      {plan.plan_monthly_price}
                    </p>
                    <p
                      style={{
                        color: "blue",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      <span style={{ textDecoration: "line-through" }}>
                        {plan.plan_mrp}
                      </span>{" "}
                      {plan.discount_text}
                    </p>
                    {plan.plan_id === data?.subscribed_plan_id && (
                      <p
                        style={{
                          marginTop: "-13px",
                          color: "#f20d0d",
                        }}
                      >
                        Plan Expires On: {data?.plan_expires_on}
                      </p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No plans available</p>
            )}

            <div style={{ margin: "2px", fontSize: "19px", fontWeight: "600" }}>
              <h2 style={{ marginLeft: "10px" }}>Order Details</h2>
              {selectedPlan && (
                <>
                  <div style={{ background: "#f1e9e9" }}>
                    <span
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontWeight: "500",
                        backgroundColor: "rgb(244 239 239)",
                        // color: "#199a8e",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          RxOne {selectedPlan.plan_name}
                        </span>
                        <span style={{ marginRight: "32px" }}>
                          {unicode}
                          {selectedPlan.plan_mrp_subunits / 100}
                        </span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>Discount</span>
                        <span style={{ marginRight: "33px" }}>
                          {unicode}
                          {selectedPlan.plan_discount_subunits / 100}
                        </span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ marginLeft: "10px" }}>
                          GST({selectedPlan.plan_applicable_gst_percent})
                        </span>
                        <span style={{ marginRight: "12px" }}>
                          {unicode}
                          {selectedPlan.plan_applicable_gst_subunits / 100}
                        </span>
                      </p>
                    </span>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-22px",
                    }}
                  >
                    <h3 style={{ marginLeft: "10px" }}>Total Amount</h3>
                    <span
                      style={{
                        marginRight: "10px",
                        fontSize: "18px",
                        fontWeight: "700",
                        marginTop: "23px",
                      }}
                    >
                      {unicode}
                      {(parseFloat(selectedPlan.plan_mrp_subunits) -
                        parseFloat(selectedPlan.plan_discount_subunits) +
                        parseFloat(selectedPlan.plan_applicable_gst_subunits)) /
                        100}
                    </span>
                  </p>
                </>
              )}
            </div>
            {selectedPlan && (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                style={{ maxWidth: "411px", margin: "auto" }}
              >
                Pay Now
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default Checkout;
