import React, { useState, useEffect, useContext } from "react";
import { PatientpageContext } from "../../context/GlobalContext";
import { OverlayContext } from "../../OverlayManager/Overlay";
import FormGroup from "@mui/material/FormGroup";
import { OverlayType } from "../../OverlayManager/Context";
import Carousel from "nuka-carousel/lib/carousel";
import Switch from "@mui/material/Switch";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PrinterSvg from "../../static/images/Printer.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Worker } from "@react-pdf-viewer/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ServiceCall, apiCall } from "../../utility/service-call";
import PrescriptionShareIcon from "../../static/images/prescriptionShare.png";
import { TextField, Button, Grid } from "@mui/material";
import { Ls } from "../../utility/utility";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Viewer } from "@react-pdf-viewer/core";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

const GeneratePrescriptionModal = ({ apiData }) => {
  const doctorContext = useContext(PatientpageContext);
  const templateData = doctorContext?.templateData;
 
  const setTemplateData = doctorContext?.setTemplateData;

  const increaseTopMargin = () => {
    if (templateData.print_header === true) {
      return;
    } else if (templateData.top_margin_cm < 10) {
      setTemplateData({
        ...templateData,
        top_margin_cm: templateData.top_margin_cm + 1,
      });
    }
  };

  const decreaseTopMargin = () => {
    if (templateData.print_header === true) {
      return;
    } else if (templateData.top_margin_cm > 1) {
      setTemplateData({
        ...templateData,
        top_margin_cm: templateData.top_margin_cm - 1,
      });
    }
  };

  const increaseBottomMargin = () => {
    if (templateData.print_footer === true) {
      return;
    } 
    else if (templateData.bottom_margin_cm < 6) {
      setTemplateData({
        ...templateData,
        bottom_margin_cm: templateData.bottom_margin_cm + 1,
      });
    }
  };

  const decreaseBottomMargin = () => {
    if (templateData.print_footer === true) {
      return;
    } else if (templateData.bottom_margin_cm > 2) {
      setTemplateData({
        ...templateData,
        bottom_margin_cm: templateData.bottom_margin_cm - 1,
      });
    }
  };

  const handleCellClick = (value) => {
    let fontSize = null;

    switch (value) {
      case "Small":
        fontSize = 8;
        break;
      case "Medium":
        fontSize = 10;
        break;
      case "Large":
        fontSize = 12;
        break;
      case "Extra Large":
        fontSize = 14;
        break;
      default:
        fontSize = null;
    }

    setTemplateData({
      ...templateData,
      font_size_px: fontSize, // Update font_size_px in the templateData
    });
  };

  return (
    <div className="prescripitionLoading">
      <h3 style={{ fontSize: "18px" }}>Print Preferences</h3>
      <div className="forms">
        <FormGroup column>
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_header} />}
            label="Digital Header"
            value={templateData.print_header}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_header: !templateData.print_header,
              });
            }}
          />
            <FormControlLabel
              required
              control={<Switch checked={templateData.print_footer} />}
              label="Digital Footer"
              value={templateData.print_footer}
              onChange={(e) => {
                setTemplateData({
                  ...templateData,
                  print_footer: !templateData.print_footer,
                });
              }}
            />
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_watermark} />}
            label="Watermark"
            value={templateData.print_watermark}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_watermark: !templateData.print_watermark,
              });
            }}
          />
          <FormControlLabel
            required
            control={<Switch checked={templateData.cf_chart_or_table} />}
            label="Clinical Findings as Charts"
            value={templateData.cf_chart_or_table}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                cf_chart_or_table: !templateData.cf_chart_or_table,
              });
            }}
          />
          <FormControlLabel
            required
            control={<Switch checked={templateData.print_on_both_sides} />}
            label="Print on both sides"
            value={templateData.print_on_both_sides}
            onChange={(e) => {
              setTemplateData({
                ...templateData,
                print_on_both_sides: !templateData.print_on_both_sides,
              });
            }}
          />
        </FormGroup>
      </div>

      <div style={{ margin: "auto", paddingLeft: "10px", fontSize: "14px" }}>
        <p style={{ fontSize: "16px", marginLeft: "-21px" }}>Font Size</p>
        <div
          style={{
            display: "flex",
            border: "1px solid #c0b0b0",
            padding: "3px",
            fontSize: "16px",
            borderRadius: "5px",
          }}
        >
          <div
            onClick={() => handleCellClick("Small")}
            style={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor:
                templateData.font_size_px === 8 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 8 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Small
          </div>
          <div style={{ color: "#c0b0b0" }}>|</div>
          <div
            onClick={() => handleCellClick("Medium")}
            style={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor:
                templateData.font_size_px === 10 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 10 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Medium
          </div>
          <div style={{ color: "#c0b0b0" }}>|</div>
          <div
            onClick={() => handleCellClick("Large")}
            style={{
              cursor: "pointer",
              marginRight: "5px",
              backgroundColor:
                templateData.font_size_px === 12 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 12 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Large
          </div>
          <div style={{ color: "#c0b0b0" }}>|</div>
          <div
            onClick={() => handleCellClick("Extra Large")}
            style={{
              cursor: "pointer",
              backgroundColor:
                templateData.font_size_px === 14 ? "#42c7c7" : "white",
              padding: "0 10px",
              color: templateData.font_size_px === 14 ? "white" : "black",
              caretColor: "transparent",
            }}
          >
            Extra Large
          </div>
        </div>
      </div>

      <div
        className="d-flex align-items-center"
        style={{
          gap: "2px",
          color: templateData.print_header ? "gray" : "black",
          caretColor: "transparent",
        }}
      >
        <p style={{ marginRight: "196px" }}>Top Margin: </p>
        <ControlPointIcon
          style={{
            cursor: "pointer",
            caretColor: "transparent",
            color: templateData.print_header ? "gray" : "#1976d2",
          }}
          onClick={increaseTopMargin}
          value={templateData.top_margin_cm}
        />
        <p style={{ marginRight: "1px" }}>
          {templateData.top_margin_cm < 10
            ? "0" + templateData.top_margin_cm
            : templateData.top_margin_cm}
        </p>
        <RemoveCircleOutlineIcon
          style={{
            cursor: "pointer",
            caretColor: "transparent",
            color: templateData.print_header ? "gray" : "#1976d2",
          }}
          value={templateData.top_margin_cm}
          onClick={decreaseTopMargin}
        />
        cm
      </div>

      <div
        className="d-flex align-items-center"
        style={{ caretColor: "transparent", gap: "2px",color: templateData.print_footer ? "gray" : "black",
      }}
      >
        <p style={{ marginRight: "170px" }}>Bottom Margin:</p>
        <ControlPointIcon
          style={{ cursor: "pointer", color: templateData.print_footer ? "gray" :"#1976d2" }}
          value={templateData.bottom_margin_cm}
          onClick={increaseBottomMargin}
        />
        {/* <p>{templateData.bottom_margin_cm}</p> */}
        <p>
          {templateData.bottom_margin_cm < 10
            ? "0" + templateData.bottom_margin_cm
            : templateData.bottom_margin_cm}
        </p>
        <RemoveCircleOutlineIcon
          style={{ cursor: "pointer", color: templateData.print_footer ? "gray" :"#1976d2" }}
          value={templateData.bottom_margin_cm}
          onClick={decreaseBottomMargin}
        />
        cm
      </div>

      {/* <Carousel
        nextDisabled={true}
        previousDisabled={true}
        renderCenterLeftControls={({ previousSlide }) => (
          <div
            onClick={() => {
              previousSlide();
              // setSelectedTemplateIndex(selectedTemplateIndex - 1);
            }}
          >
            <ChevronLeftIcon />
          </div>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          // <div onClick={nextSlide}>
          <div
            onClick={() => {
              nextSlide();
              // setSelectedTemplateIndex(selectedTemplateIndex + 1);
            }}
          >
            <ChevronRightIcon />
          </div>
        )}
        // selectedIndex={selectedTemplateIndex}
      >
        <div className="medi1">
          {apiData.map((template, index) => (
            <div
              key={index}
              className={`
              medi1_container ${
                templateData.print_template === template.title
                  ? "borderAdd"
                  : ""
              }`}
              onClick={() => {
                setTemplateData({
                  ...templateData,
                  print_template: template.title,
                });
              }}
            >
              <img src={template.img_path} alt={`Image ${index + 1}`} />
              <p className={`legend ${template.selected ? "selected" : ""}`}>
                {template.display_message}
              </p>
            </div>
          ))}
        </div>
      </Carousel> */}
    </div>
  );
};

const PrescriptionTemplate = ({ data }) => {
  // const [templateData, setTemplateData] = useState([]);
  const doctorContext = useContext(PatientpageContext);
  const templateData = doctorContext?.templateData;
  const setTemplateData = doctorContext?.setTemplateData;
  const Overlay = useContext(OverlayContext);
  const [view, setView] = useState([]);
  const [secondaryLanguages, setSecondaryLanguages] = useState({});
  const [isFinalPrescription, setFinalPrescription] = useState(false);
  const [sharePrescription, setSharePrescription] = useState(false);
  const setState = doctorContext.setState;
  const history = useHistory();
  const numbers = Array.from({ length: 30 }, (_, i) => i + 1);
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const templateObjData =
    Array.isArray(data) &&
    data?.filter((item) => item?.display_name === "Generate")[0];

  const provisionalPrescription =
    Array.isArray(templateObjData?.children) &&
    templateObjData?.children?.filter(
      (item) => item?.sub_section === "Provisional Prescription"
    );

  const finalPrescription =
    Array.isArray(templateObjData?.children) &&
    templateObjData?.children?.filter(
      (item) => item?.sub_section === "Final Prescription"
    );

  const fetchDefaultData = async () => {
    const { data } = await doctorContext.getv2Call(
      "/v2/doctor/prescription/template/defaults",
      "",
      "",
      true
    );

    if (data) {
      setTemplateData(data.data);
    }
  };
  const handlefetchSecondaryLanguage = async () => {
    const { data, errors } = await doctorContext.getv2Call(
      "/v2/doctor/prescription/list/secondary/langs",
      "",
      "",
      true
    );

    if (data?.data) {
      setSecondaryLanguages(data?.data);
    }
  };
  const fetchPrintTemplate = async () => {
    try {
      const template = await ServiceCall.getnewv3(
        "/v2/doctor/list/prescription/print/templates",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      const { data, error } = await doctorContext?.getv2Call(
        "/v2/doctor/manage/appointment/note/",
        appointment?.appointmentItem?.appointment_id + `/final_diagnosis`
      );
      if (data?.data) {
        let enableFinalPrescription =
          Array.isArray(data?.data?.final_diagnosis) &&
          !!data?.data?.final_diagnosis?.length;
        setFinalPrescription(enableFinalPrescription);
      }
      if (template?.data) {
        setView(template?.data?.records);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDefaultData();
    fetchPrintTemplate();
    handlefetchSecondaryLanguage();
  }, []);

  const printsub = async (apiData) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: <GeneratePrescriptionModal apiData={apiData} />,

      handleClose: () => {},
    });
  };

  const Loader = (text) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoader">
          <h3>Please Wait...</h3>
          <p>{text}</p>
          <CircularProgress color="success" />
        </div>
      ),
      removeCloseButton: true,
    });
  };

  const handleSharePrescription = async () => {
    Loader("1. Creating Medication Reminder Schedule for Patient");
    let params = history?.location?.search?.replace(
      `&app_id=${appointment?.appointmentItem?.appointment_id}`,
      ""
    );
    try {
      const generateMedication = await ServiceCall.postv2(
        "/v2/doctor/medic/schedule/",
        appointment?.appointmentItem?.appointment_id,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (
        generateMedication?.data?.message ===
        "Medications Schedule created successfully."
      ) {
        Loader("2. Sharing the Prescription with Patient");

        const sharePrescription = await ServiceCall.postv2(
          "/v2/doctor/prescription/submission/",
          appointment?.appointmentItem?.appointment_id,
          "",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (sharePrescription) {
          Overlay.show(OverlayType?.ModalWindow, {
            data: (
              <div className="prescripitionLoader">
                <img src={PrescriptionShareIcon} />
                <p>{sharePrescription?.data?.message}</p>
              </div>
            ),
            removeCloseButton: false,
            handleClose: function close() {
              history.push({
                pathname: "/my-appointments",
                search: params,
              });
            },
          });
        }
      } else {
        Overlay.hide();
        setState({
          msg: generateMedication?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handlePrescriptionDownload = async (section) => {

    let url = "/v2/doctor/fetch/prescription/file/";
    Loader("");
    const { secondary_language, ...rest } = templateData;
    console.log("resting", rest)
    try {
      const PrescriptionGenerate = await ServiceCall.putv2(
        "/v2/doctor/gen/prescription/file/",
        `${appointment?.appointmentItem?.appointment_id}/${section}`,
        rest,
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (PrescriptionGenerate) {
        try {
          const PrescriptionDownload = await apiCall({
            url,
            method: "get",
            responseType: "blob",
            hosID: appointment?.appointmentItem?.appointment_id,
            extraField: `/${section}`,
            headers: {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            },
          });
          if (PrescriptionDownload) {
            Overlay.hide();
            let blob = new Blob([PrescriptionDownload.data], {
              type: "application/pdf",
            });
            let url = window.URL.createObjectURL(blob);
            Overlay.show(OverlayType?.ModalWindow, {
              data: (
                <div className="prescription__pdf">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={url} />
                  </Worker>
                  <div className="prescription__pdf__container">
                    <a
                      href={url}
                      target="_blank"
                      className="prescription__pdf__button"
                    >
                      <LocalPrintshopIcon />
                    </a>
                    <a
                      download={`prescription-${appointment?.appointmentItem?.appointment_id}`}
                      href={url}
                      className="prescription__pdf__button"
                    >
                      <DownloadForOfflineIcon />
                    </a>
                  </div>
                </div>
              ),
              handleClose: () => {},
            });
            setSharePrescription(true);
          } else {
            return PrescriptionDownload.text();
          }
        } catch (err) {
          Overlay.hide();
          setState({
            msg: err?.message || "",
            type: "error",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const ChiefSubmit = async (buttonName, prescription_cat) => {
    if (buttonName === "View/Generate") {
      handlePrescriptionDownload(prescription_cat);
    } else if (buttonName === "Share") {
      handleSharePrescription(prescription_cat);
    }
  };

  return (
    <div container xs={12} sm={11} className="query_container">
      <div className="complaint_container">
        <h2 class="d-block complaint_container_heading">
          Generate/View and Share
        </h2>
        <div className="printsub">
          <div className="print-template-bar">
            <h2>Prescription Validity:</h2>
            <select
              onChange={(e) => {
                setTemplateData({
                  ...templateData,
                  validity: parseInt(e.target.value, 10),
                });
              }}
              name="validity"
            >
              {numbers.map((value) => (
                <option
                  selected={templateData?.validity == value}
                  value={value}
                >
                  {value}
                </option>
              ))}
            </select>
            <span>Days</span>
          </div>
          <div className="print-template-bar">
            {/* <h2>Select Print Format: {templateData.print_template}</h2> */}
            <h2>Printing Preferences:</h2>

            <div
              className="print-template-bar-print"
              onClick={() => printsub(view)}
            >
              <LocalPrintshopIcon sx={{ color: "#199a8e", fontSize: "30px" }} />
            </div>
            {/* <span>({templateData.print_template}) Selected</span> */}
          </div>
          {secondaryLanguages && Object.keys(secondaryLanguages)?.length ? (
            <div className="print-template-bar">
              <h2>Translate in:</h2>
              <select
                onChange={(e) => {
                  setTemplateData({
                    ...templateData,
                    secondary_language: e.target.value,
                    secondary_language_code: secondaryLanguages[e.target.value],
                  });
                }}
                name="language"
                value={templateData.secondary_language} // Set the value attribute to maintain the selected language

              >
                {Object.keys(secondaryLanguages)?.map((item) => (
                  <option
                    selected={
                      templateData?.secondary_language ===
                      secondaryLanguages[item]
                    }
                    value={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="query_container_provisional mt-4">
          <h3>Provisional Prescription</h3>
          <div className="d-flex justify-content-between mt-2 mb-2">
            {Array.isArray(provisionalPrescription) &&
              provisionalPrescription?.map((bl) => (
                <button
                  className="new_query_container"
                  onClick={() => {
                    ChiefSubmit(bl.display_name, "prov");
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <div className="query_container_provisional_text">
                      {bl.display_name}
                    </div>
                    <img src={bl.icon_url} alt={bl.display_name} />
                  </div>
                </button>
              ))}
          </div>
        </div>

        <div className="query_container_provisional mt-4">
          <h3>Final Prescription</h3>
          <div className="d-flex justify-content-between mt-2 mb-2">
            {Array.isArray(finalPrescription) &&
              finalPrescription?.map((bl) => (
                <button
                  className={`new_query_container ${
                    !isFinalPrescription ? "new_query_container_disable" : ""
                  }`}
                  onClick={() => {
                    ChiefSubmit(bl.display_name, "final");
                  }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <div className="query_container_provisional_text">
                      {bl.display_name}
                    </div>
                    <img src={bl.icon_url} alt={bl.display_name} />
                  </div>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionTemplate;
