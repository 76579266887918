import { Container, Typography, Button } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { CircularProgress, Grid, Box } from "@mui/material";
import { PatientpageContext } from "./../../context/GlobalContext";
import Overlay from "../../components/Overlay";
import { Ls } from "../../utility/utility";
export default function JoinPage(props) {
  let { roomid } = useParams();
  const doctorContext = useContext(PatientpageContext);
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const [callLoader, setCallLoader] = useState(false);
  const [report, setReport] = useState({});
  const { notesLoader } = doctorContext;
  let history = useHistory();
  const preUrl = `${window.location.origin}/my-appointments/${history?.location?.search}`

  useEffect(() => {
    if (
      !doctorContext.profileData ||
      !doctorContext.profileData ||
      !doctorContext.profileData.name
    ) {
      doctorContext
        .getProfile()
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          history.push("/");
        });
    }
    handleCallStart();
  }, []);

  const handleCallStart = async () => {
    try {
      const { data, error } = await doctorContext?.postPutV2Call(
        "/v2/doctor/start/call/",
        appointment?.appointmentItem?.appointment_id,
        "",
        setCallLoader,
        true
      );

      if(data?.data){
        window.open(data?.data?.room_url, '_blank')
        history.push({
          pathname: "/my-appointments",
          search: history?.location?.search,
        });
      }

      if (error) {
        history.push({
          pathname: "/my-appointments",
          search: history?.location?.search,
        });
      }
    } catch (err) {
      console.log("meetingStatus-error", err);
    }
  };


  return (
    <>
      <Header pageTitle={props.pageTitle} />
      <Container sx={{ minHeight: "100vh" }}>
        {callLoader ? (
          <Overlay opacity=".6">
            <CircularProgress size="20px" color="success" />
            <p>Loading Call....</p>
          </Overlay>
        ) : (
          <></>
        )}
        <div>
            {/* {doctorContext.profileData &&
              doctorContext.profileData &&
              doctorContext.profileData.name && (
                <div className="embed-responsive iframe-call embed-responsive-16by9">
                  <iframe
                    title={roomid}
                    scrolling="no"
                    className="embed-responsive-item"
                    allow="camera; microphone; fullscreen; speaker; display-capture"
                    src={`https://rxone.yourvideo.app/${roomid}?landing=no&username=yes&name=${doctorContext.profileData.name}&exit_url_target=parent&exit_url=${preUrl}`}
                    sandbox="allow-same-origin allow-scripts"
                    allowFullScreen
                  ></iframe>
                </div>
              )} */}
          {/* <Grid content item xs={12} sm={6} className='pos-relative p-3'>
            {doctorContext.notesLoader && (
              <Overlay>
                <div className='text-center'>
                  <CircularProgress size={16} />
                  <p>Loading Report..</p>
                </div>
              </Overlay>
            )}
            <Grid item>
              <Box></Box>
              {report.patient_symptoms &&
                Object.keys(report["patient_symptoms"]).map((item) => (
                  <div> {report["patient_symptoms"][item]}</div>
                ))}
            </Grid>
          </Grid> */}
        </div>
      </Container>
    </>
  );
}
