import React from 'react';
import { useState } from 'react';
import MaleBackBodyimg from '../static/male_back2.png';

const MaleBackBody = ({ pointAction, currentPoint, painList = [] }) => {
    const [selectedPoint, setSelectedPoint] = useState(null);
  const currentPoints = Array.isArray(currentPoint) ? currentPoint : [currentPoint?.toString()];

    const bodyParts = [
        { name: 'head', points: ["27", "28"], style: { top: '8%', left: '49.7%', transform: 'translateX(-50%)' } },
        { name: 'neck', points: ["29"], style: { top: '19%', left: '48%',  } },
        { name: 'chest', points: ["30", "31"], style: { top: '26%', left: '0', right: '0', display: 'flex', justifyContent: 'space-between', padding: '0 45%' } },
        { name: 'arm', points: ["32", "33", "34", "35"], style: { top: '34%', left: '4px', right: '11px', display: 'flex', justifyContent: 'space-between', padding: '0 40%' } },
        { name: 'forearms', points: ["36", "37", "38"], style: { top: '43%', left: '1px', right: '6px', display: 'flex', justifyContent: 'space-between', padding: '0 39%' } },
        { name: 'hands', points: ["39", "40", "41", "42"], style: { top: '52%', left: '2px', right: '9px', display: 'flex', justifyContent: 'space-between', padding: '0 38%' } },
        { name: 'leftLeg', points: ["43", "44", "45", "46"], style: { top: '62%', left: '46%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
        { name: 'rightLeg', points: ["47", "48", "49", "50"], style: { top: '62%', right: '46.5%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
    ];

    const getPainColor = (point) => {
     if (point === selectedPoint) return "blue";
      if (currentPoints.includes(point)) return "#f1506c";
      if (painList.includes(point)) return "red";
      return "green";
  };

  const handlePointClick = (point) => {
    pointAction(point);
    setSelectedPoint(point);
};


    const renderPoints = (points) => (
        points.map((point) => (
            <button
                key={point}
                onClick={() => handlePointClick(point)}
                style={{
                    ...styles.pain,
                    backgroundColor: getPainColor(point),
                }}
            />
        ))
    );

    return (
        <div style={{ position: 'relative', width: '100%', height: 460 }}>
            <img src={MaleBackBodyimg} alt="Male back body" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            {bodyParts.map(({ name, points, style }) => (
                <div key={name} style={{ position: 'absolute', ...style }}>
                    {renderPoints(points)}
                </div>
            ))}
        </div>
    );
};

const styles = {
    pain: {
        width: 17,
        height: 17,
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        margin: '2px',
    },
};

export default MaleBackBody;
