import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  overlay: {
    backgroundColor: '#ffffff',
    backdropFilter: 'blur(2px)',
    zIndex: 10,
    top:0,
    left: 0,
    right: 0,
  },
}));

export default function Overlay(props) {
  const classes = useStyles();
  const { opacity = '0.85' } = props;
  return (
    <div
      style={{ opacity: opacity, ...props.style }}
      className={`${classes.overlay} overlay pos-absolute w-100 h-100 d-flex justify-content-center align-items-center`}
    >
      {props.children}
    </div>
  );
}
