import React, { useEffect, useState, useContext } from "react";

import {
  Box,
  TextField,
  Grid,
  FormControl,
  Button,
  IconButton,
 CircularProgress }  from "@mui/material";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import { baseUrl, constants } from "../utility/constants";
import {
  convertTime,
  Ls,
  razorpayCall
} from "./../utility/utility";
import { ServiceCall } from "./../utility/service-call";
import styles from "./../styles/appointmentScreensStyle";
import TimeSelectedScreen from "./TimeSelectedScreen";
// import { constants } from "../utility/constants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import Brightness2OutlinedIcon from "@mui/icons-material/Brightness2Outlined";
import { PatientpageContext } from "./../context/GlobalContext";
import ImageAvatar from "./ImageAvatar";

const useStyles = makeStyles(styles, { index: 1 });
export default function AppointmentScreen({ toggleAppointment }) {
  const doctorContext = useContext(PatientpageContext);
  const classes = useStyles();
  const [appointmentType, setAppointmentType] = useState(true);
  const [paymentType, setPaymentType] = useState("online");
  const [doctorLoader, setDoctorLoader] = useState(false);
  const [bookAppLoader, setBookAppLoader] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [apiError, setApiError] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [appointments, setAppointment] = useState([]);
  const [appointmentLoader, setAppointmentLoader] = useState("");
  const [appointMentSlotMsg, setAppointMentSlotMsg] = useState(
    "Select doctor to get available slots"
  );
  // const [successDialog, setSuccessDialog] = useState(defaultDialog);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  // const { 'Registered Doctors': RegisteredDoctor } = doctorsList;

  const [selectedTime, setSelectedTime] = React.useState({});

  useEffect(() => {
    getAvailableDoctor();
  }, []);

  const handleChange = (value) => {
    setSelectedTime(value);
  };
  // const handleDialogClose = () => {
  //   setSuccessDialog(defaultDialog);
  // };
  const getAvailableDoctor = () => {
    setDoctorLoader(true);
    ServiceCall.get(`/doctor/available/${constants.hospitalId("hosp_id")}`)
      .then((res) => {
        setDoctorLoader(false);
        setDoctorList(res.data[constants.registeredDoctor]);
      })
      .catch((err) => {
        setDoctorLoader(false);
        if (err.response) {
          setApiError("Failed with response " + err.response.data);
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      });
  };

  const resetStates = () => {
    setSelectedTime({});
  };
  useEffect(() => {
    if (!doctorId) {
      setAppointment([]);
    }
  }, [doctorId]);

  const checkAppointmentType = () => {
    if (!appointmentType) {
      doctorContext.dialogController({
        open: true,
        content: (
          <div className='text-center'>
            <h3>How you would like to make the payment for the appointment?</h3>
            <h6>Appointment will be confirmed after successful payment.</h6>
          </div>
        ),
        maxWidth: "sm",
        dialogAction: (() => (
          <>
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setPaymentType("cash");
                doctorContext.handleDialogClose();
                bookAppointmentCall("cash");
              }}
              className='mr-2'
            >
              Cash
            </Button>
            <Button
              color='error'
              variant='contained'
              onClick={() => {
                setPaymentType("online");
                doctorContext.handleDialogClose();
                bookAppointmentCall("online");
              }}
            >
              Online
            </Button>
          </>
        ))(),
      });
    } else {
      bookAppointmentCall(paymentType);
    }
  };

  const bookAppointmentCall = async (typeofPayment) => {

    setBookAppLoader(true);
    try {
      const url = `/doctor/appointment/${constants.hospitalId(
        "hosp_id"
      )}/${doctorId}`;
      const appointmentRes = await ServiceCall.post(
        url,
        {
          appointment_date: selectedDate,
          time_alloted: selectedTime.value,
          is_inperson: !appointmentType ? "True" : "False",
          pay_cash: typeofPayment === "cash" ? "True" : "False",
        },
        { pt_token: Ls.get("userToken") }
      );

      if (typeofPayment === "online") {
        const fetchOrderUrl = `/order/appointment/${
          appointmentRes.data[constants.appointment_id]
        }`;
        const appointmentFetchRes = await ServiceCall.get(fetchOrderUrl, {
          pt_token: Ls.get("userToken"),
        });

        let doctorData = doctorList.filter((item) => {
          return item.doc_id === doctorId;
        });

        razorpayCall(
          appointmentFetchRes.data.message.razorpay_order_id,
          appointmentRes.data[constants.appointment_id],
          confirmPayment,
          doctorData,
          doctorContext.profileData
        );
      } else {
        setBookAppLoader(false);
        runAfterPayment(appointmentRes.data.message);
      }
    } catch (err) {
      // doctorContext.getAppointment();
      setBookAppLoader(false);
      toggleAppointment();
      if (err.response) {
        // setApiError('Failed with response ' + err.response.data);
        doctorContext.dialogController({
          open: true,
          content: err.response.data.message,
          maxWidth: "sm",
        });
      } else if (err.request) {
        // setApiError('Failed request ' + err);
        doctorContext.dialogController({
          open: true,
          content: err,
          maxWidth: "sm",
        });
      } else {
        // setApiError('Failed in general ' + err);
        doctorContext.dialogController({
          open: true,
          content: err,
          maxWidth: "sm",
        });
      }
    }
  };

  const confirmPayment = async (order_id, paymentObject, errorObject) => {
    if (errorObject) {
      if (errorObject.errorType === "ondismiss") {
        setBookAppLoader(false);
        toggleAppointment();
      } else {
        doctorContext.dialogController({
          open: true,
          content: errorObject.errorMessage,
          maxWidth: "sm",
        });
        setBookAppLoader(false);
        runAfterPayment();
      }
    } else {
      let url = `/order/online/payment/${order_id}`;
      ServiceCall.post(url, paymentObject, {
        pt_token: Ls.get("userToken"),
      })
        .then((res) => {

          setBookAppLoader(false);
          runAfterPayment(res.data.message);
        })
        .catch((err) => {
          setBookAppLoader(false);
          if (err.response) {
            setApiError("Failed with response " + err.response.data);
          } else if (err.request) {
            setApiError("Failed request " + err);
          } else {
            setApiError("Failed in general " + err);
          }
        });
    }
  };
  const runAfterPayment = function (displayMessage) {
    if (displayMessage) {
      doctorContext.dialogController({
        open: true,
        content: displayMessage,
        maxWidth: "sm",
      });
    }
    doctorContext.getAppointment();
    toggleAppointment();
  };
  const getAvailableSlots = function (e) {
    // /test/doctor/<str: hospital_id>/<str: doctor_id></str>
    let doctorId = e.target.value;
    setDoctorId(e.target.value);
    setAppointmentLoader("loading");
    resetStates();
    let url = `/doctor/${constants.hospitalId}/${doctorId}`;
    ServiceCall.get(url)
      .then((res) => {
        setDoctorLoader(false);
        // setDoctorList(res.data['Registered Doctors']);
        if (res.data.schedule) {
          setAppointment(convertTime(res.data.schedule));
        } else {
          setAppointment([]);
          setAppointMentSlotMsg("No Availability Found");
        }
        setAppointmentLoader("");
      })
      .catch((err) => {
        setAppointmentLoader("");
        setAppointMentSlotMsg("");
        if (err.response) {
          setApiError("Failed with response " + err.response.data);
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      });
  };
  const changeAppointmentType = function (e) {
    setAppointmentType((oldValue) => !oldValue);
  };

  return (
    <Grid
      container
      justifyContent='center'
      className='position-relative w-100 pt-0'
    >
      {doctorLoader ? (
        <Box sx={{ textAlign: "center" }} className='d-block pl-3 text-center'>
          <CircularProgress size={14} />
        </Box>
      ) : (
        <>
          <Grid
            item
            xs={12}
            className='position-relative w-100 pt-2'
            justifyContent='center'
          >
            {/* inperson selection start */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              className='font-13'
            >
              <Box className='w-100 text-center'>
                <h3 className='mb-2'>Appointment Type</h3>
              </Box>
              <Box>
                <input
                  type='radio'
                  name='inpersonoronline'
                  className={classes.radioTime}
                  checked={!appointmentType}
                  id='inpersonPayment'
                  value={appointmentType}
                  onChange={changeAppointmentType}
                />

                <label htmlFor='inpersonPayment'>
                  <Box
                    className={`${classes.timeButton} mx-sm-2 mx-1`}
                    sx={{
                      px: 1,
                      py: 1,
                      mb: 1,
                      borderRadius: 1,
                    }}
                  >
                    In person
                  </Box>
                </label>
              </Box>
              <Box>
                <input
                  type='radio'
                  name='inpersonoronline'
                  className={classes.radioTime}
                  checked={appointmentType}
                  id='onlinePayment'
                  value={appointmentType}
                  onChange={changeAppointmentType}
                />

                <label htmlFor='onlinePayment'>
                  <Box
                    className={`${classes.timeButton} mx-sm-2 mx-1`}
                    sx={{
                      px: 1,
                      py: 1,
                      mb: 1,
                      borderRadius: 1,
                    }}
                  >
                    Online
                  </Box>
                </label>
              </Box>
            </Box>
            {/* inperson selection start */}
            {/* <Stack
              justifyContent='center'
              direction={{ xs: "column", sm: "row" }}
              className='font-15'
            >
              <Stack
                className={`${classes.stackStyle} px-2 ${classes.listItem} d-flex justify-content-center`}
                direction='row'
                spacing={1}
                alignItems='center'
              >
                <p className='font-15 m-0'>In Person</p>
                <Switch
                  checked={appointmentType}
                  color='success'
                  onChange={changeAppointmentType}
                />
                <p className='font-15 m-0'>Online</p>
              </Stack>
            </Stack> */}
            <IconButton
              onClick={toggleAppointment}
              color='error'
              sx={{ position: "absolute", right: "0", top: "4px" }}
              component='span'
            >
              <HighlightOffIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={2} className='mt-4'>
            <Grid item xs={12} md={6}>
              <h3 className='mb-2 font-18'>Available Doctors</h3>

              {doctorList.map((item, index) => (
                <Box className='cursor-pointer' key={item.doc_id}>
                  <input
                    type='radio'
                    id={item.doc_id}
                    checked={item.doc_id === doctorId}
                    name='doctor_list'
                    className={classes.radio}
                    value={item.doc_id}
                    onChange={getAvailableSlots}
                  />
                  <label
                    className={`${classes.label} d-block cursor-pointer font-16`}
                    key={item.doc_id}
                    htmlFor={item.doc_id}
                  >
                    <Box
                      className={`${classes.listItem} font-14`}
                      sx={{
                        py: 1,
                        px: 1,
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 1,
                        boxShadow: 2,
                        mb: 2,
                      }}
                    >
                      <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={2} sm='auto'>
                          <ImageAvatar
                            size='40px'
                            imagePath={`${baseUrl}/${constants.testPath(
                              "mode"
                            )}/doctor/avatar/${item.doc_id}`}
                            name={`${item.firstname} ${item.lastname}`}
                          />
                        </Grid>
                        <Grid container item xs={10} className='top'>
                          <Grid item xs={12} sm={3} className=''>
                            <h4 className='my-0 pb-2 pb-sm-0'>
                              {item.firstname} {item.lastname}
                            </h4>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {item.qualification}, {item.speciality}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            className='text-sm-center pt-2 pt-sm-0'
                          >
                            INR {item.consult_charge}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </label>
                </Box>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6}>
                  <h3 className='mb-2 font-18'>Availability</h3>
                </Grid>
                {selectedTime.data && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className='d-none d-sm-block text-right'
                  >
                    <h3 className='mb-2'>Selected Time:{selectedTime.data}</h3>
                  </Grid>
                )}
              </Grid>
              {appointmentLoader === "loading" ? (
                <Box
                  sx={{ textAlign: "center" }}
                  className='d-block pl-3 text-center'
                >
                  <CircularProgress size={14} />
                </Box>
              ) : appointments.length > 0 ? (
                <Grid container spacing='1'>
                  <Grid item xs={12}>
                    <TextField
                      id='date'
                      label='Date'
                      className='w-100'
                      type='date'
                      InputProps={{
                        inputProps: {
                          min: dayjs().format("YYYY-MM-DD"),
                          max: dayjs().add(7, "day").format("YYYY-MM-DD"),
                          onKeyPress: (e) => {
                            e.preventDefault();
                          },
                        },
                      }}
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TimeSelectedScreen
                        classes={classes}
                        appointments={appointments}
                        handleChange={handleChange}
                        maxRange={11.3}
                        minRange={0}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        bookAppLoader={bookAppLoader}
                      >
                        <Box
                          variant='subtitle2'
                          gutterBottom
                          component='div'
                          sx={{
                            bgcolor: "grey.300",
                            color: "grey.900",
                            p: 1,
                            mb: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "23px" }}>
                            <img
                              alt=''
                              style={{
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                objectFit: "cover",
                                color: "transparent",
                                textIndent: "10000px",
                              }}
                              src={`${process.env.PUBLIC_URL}/img/morning-sun.png`}
                            />
                          </div>{" "}
                          Morning
                        </Box>
                      </TimeSelectedScreen>
                      <TimeSelectedScreen
                        classes={classes}
                        appointments={appointments}
                        handleChange={handleChange}
                        maxRange={17}
                        minRange={12}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        bookAppLoader={bookAppLoader}
                      >
                        <Box
                          variant='subtitle2'
                          gutterBottom
                          component='div'
                          sx={{
                            bgcolor: "grey.300",
                            color: "grey.900",
                            p: 1,
                            mb: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <WbSunnyOutlinedIcon /> Afternoon
                        </Box>
                      </TimeSelectedScreen>
                      <TimeSelectedScreen
                        classes={classes}
                        appointments={appointments}
                        handleChange={handleChange}
                        maxRange={23.3}
                        minRange={17.3}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        bookAppLoader={bookAppLoader}
                      >
                        <Box
                          variant='subtitle2'
                          gutterBottom
                          component='div'
                          sx={{
                            bgcolor: "grey.300",
                            color: "grey.900",
                            p: 1,
                            mb: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Brightness2OutlinedIcon /> Evening
                        </Box>
                      </TimeSelectedScreen>
                    </FormControl>
                    {selectedTime.data && (
                      <Button
                        variant='contained'
                        color='error'
                        onClick={checkAppointmentType}
                        size='small'
                        disabled={bookAppLoader}
                      >
                        Book Appointment
                        {bookAppLoader && <CircularProgress size={14} />}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <p className='text-center'>{appointMentSlotMsg}</p>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
