import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { PatientpageContext } from "./../../context/GlobalContext";
import Carousel from "nuka-carousel/lib/carousel";
import { LoginImage } from "../../static/images/ImageManager";
import {
  TextField,
  Select,
  MenuItem,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  IconButton,
  Link,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { red } from "@material-ui/core/colors";
import ContinueIcon from "../../static/images/Arrow--right.svg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import StarBorder from "@mui/icons-material/StarBorder";
import { countriesList, Ls, parseJson } from "./../../utility/utility";
import { constants, baseUrl } from "./../../utility/constants";
import { Create, MobileFriendlyOutlined } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ServiceCall } from "./../../utility/service-call";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoginButton from "../../Tools/LoginButton";
import CountdownButton from "../../components/CountdownButton";
import { Img } from "react-image";
import Switch from "../../Tools/Swtich";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Doctorlogo from "../../static/images/Doctor_Portal_144.png";
import Familyphoto from "../../static/images/ladydoc1.jpg";
import ded from "../../static/images/88.jpg";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import headerlogo from "../../static/images/Care_Portal_Logo.png";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { defineLocale } from "moment";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };
});

const Card = ({ data, className }) => (
  <div className={`${className}__box`}>
    <div className={`${className}__image`}>
      <Img src={data.image} loader={<CircularProgress />} />
    </div>
    <div className={`${className}__text`}>{data?.text}</div>
  </div>
);

const defaultError = {
  phoneNumber: {
    hasError: false,
    errorMsg: "",
  },
  remember: {
    hasError: false,
    errorMsg: "",
  },
};
const defaultFormData = {
  phoneNumber: "",
  remember: true,
};
export default function LogIn() {
  let history = useHistory();
  const mode = process.env.REACT_APP_MODE;

  const classes = useStyles();
  const [phoneCode, setPhoneCode] = useState("+91");
  const [loginLoader, setLoginLoader] = useState(false);
  const [otpLoader, getOtpLoader] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [formError, setFormError] = useState(defaultError);
  const [formData, setFormData] = useState(defaultFormData);
  const [hospId, setHospID] = useState("");
  const [hover, setHover] = useState(false);
  const [otp, setOtp] = useState("");
  const [apiError, setApiError] = useState("");
  const [otpValidation, setOtpValidation] = useState({
    hasError: false,
    content: "",
  });
  const doctorContext = useContext(PatientpageContext);
  const setState = doctorContext.setState;
  const { search } = useLocation();
  const [otpContent, setOtpContent] = useState("");
  const [disableSendOtp, setDisableOtp] = useState(true);
  const [facilityObj, setFacilityObj] = useState("");
  const [facilityEnable, setFacilityEnable] = useState(false);
  const [enableFacility, setEnableFacility] = useState(false);
  const [showwhatps, setShowwhatps] = useState(false);
  const timer = useRef(null);
  const otpField = useRef(null);
  useEffect(() => {
    Ls.remove("docToken");
    Ls.remove("docKey");
    Ls.remove("hosp_id");
    return function () {
      clearTimeout(timer.current);
    };
  }, []);
  const handleFollowUP = async (checked) => {
    setShowwhatps(checked);
  };
  const getHospitalDetails = async () => {
    if (
      formData?.phoneNumber.trim().length === 10 &&
      !formError?.phoneNumber?.hasError
    ) {
      const number = phoneCode + formData.phoneNumber;
      if (mode) {
        doctorContext.getHospitalDetails(`/${mode}`, number).then((item) => {
          Array.isArray(item?.records) &&
            !!item?.records?.length &&
            setFacilityEnable(true);
          setEnableFacility(true);
        });
      } 
      // else {
      //   doctorContext.getHospitalDetails("/live", number).then((item) => {
      //     Array.isArray(item?.records) &&
      //       !!item?.records?.length &&
      //       setFacilityEnable(true);
      //     setEnableFacility(true);
      //   });
      // }
    }
  };

  useEffect(() => {
    if (showOtp) {
      otpButtonTimer();
      otpField.current.focus();
    }
  }, [showOtp]);

  const firebaseConfig = {
    apiKey: "AIzaSyDBA7O-a2KiTC5kTVbb3eVd7AExd4onNyw",
    authDomain: "rxone-firebase.firebaseapp.com",
    projectId: "rxone-firebase",
    storageBucket: "rxone-firebase.appspot.com",
    messagingSenderId: "134528895499",
    appId: "1:134528895499:web:61f4045743c9726b69950e",
    measurementId: "G-LT2YW1B7QX",
  };

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  const showNotificationAlert = () => {
    return new Promise((resolve) => {
      const result = window.confirm("Would you like to receive notifications?");
      resolve(result);
    });
  };

  const requestNotificationPermission = async (phoneNumber) => {
    try {
      // const notificationKey = `notificationPromptShown_${phoneNumber}`;
      
      // if (localStorage.getItem(notificationKey) === "true") {
      //   return; // Don't show the prompt again for this user
      // }
  
      const userChoice = await showNotificationAlert();
  
      if (userChoice) {
        // User clicked "Yes" in the custom alert
        const permission = await Notification.requestPermission();
        
        if (permission === "granted") {
          // User granted permission in the browser prompt
          const token = await getToken(messaging, {
            vapidKey: "BGIQb9nlWxvawDdRSHVL-Sm6Jh64D09Qfkwbw8hYqG01EhP9ChWvAGJAdWcJVMNszA0cmVtrK43xcp2oz3DHbS8",
          });
  
          await ServiceCall.posttttv2(
            "/v2/doctor/register/device/",
            {
              device_type: "web",
              device_token: token,
              is_granted: true,
            },
            {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            }
          );
  
          // localStorage.setItem(notificationKey, "true");
        }
      }
  
    } catch (error) {
      console.error("An error occurred while setting up notifications: ", error);
    }
  };


  const otpButtonTimer = function () {
    timer.current = setTimeout(() => {
      setDisableOtp(false);
    }, 60000);
  };

  const handleChange = (event) => {
    setPhoneCode(event.target.value);
  };

  const handleSetHosID = (id) => {
    Ls.set("hosp_id", id);
    setHospID(id);
  };

  const handleregis = () => {
    history.push({
      pathname: "/signup",
      search: history?.location?.search,
    });
  };

  const doctorLogin = async (e) => {
    setLoginLoader(true);
    try {
      let loginSuccess = await ServiceCall.postv2(
        `/v2/auth/doctor/pwa/`,
        `${hospId || constants.hospitalId("hosp_id")}`,
        {
          phone: phoneCode + formData.phoneNumber,
          otp: otp,
        }
      );
      if (loginSuccess) {
        setLoginLoader(false);
        Ls.set("docToken", loginSuccess.data.doc_secret);
        Ls.set("docKey", loginSuccess.data.doc_key);

        history.push({
          pathname: "/home",
          // search: `?mode=${constants.testPath("mode")}&hosp_id=${hospId}`,
        });
      }
      await new Promise((resolve) => {
        setTimeout(async () => {
          await requestNotificationPermission(phoneCode + formData.phoneNumber);
          resolve();
        }, 2000);
      });
    
    } catch (err) {
      setLoginLoader(false);
      if (err.response) {
        setApiError(err.response.data.message);
        setOtpValidation({
          hasError: true,
          content: err.response.data.message,
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  const getOtp = async () => {
    getOtpLoader(true);
    try {
      const body = {
        phone: phoneCode + formData.phoneNumber,
        wa_otp: showwhatps,
      };
      let otpResponse = await ServiceCall.postv2(
        `/v2/generate/doctor/otp/`,
        `${constants.hospitalId("hosp_id")}`,
        body
      );
      if (otpResponse) {
        getOtpLoader(false);
        setShowOtp(true);
        setState({
          msg: otpResponse?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "right",
        });
        setOtpContent(otpResponse?.data.message);
      }
    } catch (err) {
      getOtpLoader(false);
      if (err.response) {
        setApiError(err.response.data.message);
        setOtpValidation({
          hasError: true,
          content: err.response.data.message,
        });
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };
  const errorHandling = (fieldName, value) => {
    let errorField = formError[fieldName];
    if (fieldName === "phoneNumber") {
      if (value.trim() === "" || value.length !== 10 || isNaN(value)) {
        errorField.hasError = true;
        errorField.errorMsg = "Invalid phone number";
        setShowOtp(false);
      } else {
        errorField.hasError = false;
        errorField.errorMsg = "";
      }
      setFormError({
        ...formError,
        [fieldName]: errorField,
      });
    }
    // else if (fieldName === "remember") {
    //   if (!value) {
    //     errorField.hasError = true;
    //     errorField.errorMsg = "This field is mandatory";
    //   } else {
    //     errorField.hasError = false;
    //     errorField.errorMsg = "";
    //   }
    //   setFormError({
    //     ...formError,
    //     [fieldName]: errorField,
    //   });
    // }
  };
  const updateForm = (e) => {
    let fieldName = e.target.name;
    let value = "";

    // if (e.target.type === "checkbox") {
    //   value = e.target.checked;
    // } else {
    value = e.target.value;
    // }
    errorHandling(fieldName, value);
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const submitPhoneNo = (e) => {
    e.preventDefault();
    let formErrorbolean = false;
    Object.keys(formData).map((item) => {
      errorHandling(item, formData[item]);
    });
    Object.keys(formError).map((item) => {
      if (formError[item].hasError) {
        formErrorbolean = true;
      }
    });
    if (!formErrorbolean) {
      // getOtp();
      setFacilityObj("");
      getHospitalDetails();
    }
  };

  const submitHospitalName = (e) => {
    e.preventDefault();
    if (!formError?.phoneNumber?.hasError) {
      getOtp();
    }
  };

  const setOtpValue = (e) => {
    let value = e.target.value;
    if (!value.trim()) {
      setOtpError(true);
    } else {
      setOtpError(false);
    }
    if (isNaN(value)) {
      setOtpValidation({ hasError: true, content: "Incorrect Entry" });
    } else {
      setOtpValidation({ hasError: false, content: "" });
    }
    if (value.length <= 6) {
      setOtp(value);
    }
  };

  const handleFacility = (item) => {
    handleSetHosID(item?.hos_id);
    setFacilityEnable(false);
    setFacilityObj(item?.name);
  };
  const resetOtp = () => {
    setFormError(defaultError);
    setFormData(defaultFormData);
    setShowOtp(false);
    setOtpValidation({ hasError: false, content: "" });
  };
  function onKeyPress(e) {
    if (e.key === "Enter") {
      if (otp.length === 6) {
        doctorLogin();
      }
    }
  }

  const handleswitch = () => {
    history.push({
      pathname: "/signup",
      search: history?.location?.search,
    });
  };

  // const onCloseEvent = () => setApiError('');
  var dynamicWidth = "calc(100vh - 56px)";
  return (
    <>
      {/* <PwaPrompt /> */}
      <Header page="login"></Header>
      <div className="loginContainer bodyBg">
        <div
          className="family-photo-background"
          style={{
            backgroundImage: `url(${Familyphoto})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "fixed" /* Change from 'absolute' to 'fixed' */,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
            // backgroundColor: "#3eaf96",
            borderImage:
              "linear-gradient(rgba(0, 0, 0, 0.067),  #199a8e) 0 fill / 1 / 0 stretch",
          }}
        />
        <img src={headerlogo} alt="Doctor Logo" className="doctor-logo" />

        <div className="gtr">
          <div className="gr">
            <h1>
              Grow your Services 10X,{" "}
              <span style={{ display: "block" }}>
                and Improve Patient Outcomes
              </span>
            </h1>
          </div>

          <div className="doctor-log">
            <div className="ft">
              <TaskAltIcon style={{ color: "#fff" }} />
              <p>OPD management solution</p>
            </div>

            <div className="ft">
              <TaskAltIcon style={{ color: "#fff" }} />
              <p>Personalized EMR</p>
            </div>
            <div className="ft">
              <TaskAltIcon style={{ color: "#fff" }} />
              <p>Medical eCommerce</p>
            </div>
            <div className="ft">
              <TaskAltIcon style={{ color: "#fff" }} />
              <p>ABDM Compliance</p>
            </div>
          </div>
        </div>

        <Container
          component="main"
          maxWidth="xs"
          style={{
            marginRight: "2rem", // Adjust the value as needed
            marginTop: "5rem", // Adjust the value as needed
            zIndex: "5px",
          }}
        >
          <CssBaseline />
          {/* <SnackBarComponent onCloseEvent={onCloseEvent} apiError={apiError} /> */}
          <form noValidate autoComplete="off" className="form-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: "-255px",
              }}
            >
              <img src={Doctorlogo} style={{ width: "100px" }} />
              <span
                onClick={handleswitch}
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  cursor: "pointer",
                  color: "#199a8e",
                  textDecoration: "underline",
                }}
              >
                New To RxOne? Sign up Now
              </span>
            </div>

            <Paper
              className="py-2 px-4 loginContainer_login"
              style={{ background: "none" }}
            >
              {/* <Carousel
                nextDisabled={true}
                previousDisabled={true}
                renderCenterLeftControls={false}
                renderCenterRightControls={false}
                className="loginContainer_carousel"
              >
                {LoginImage &&
                  Array.isArray(LoginImage) &&
                  LoginImage?.map((element) => (
                    <Card
                      data={element}
                      className="loginContainer_carousel_card"
                    />
                  ))}
              </Carousel> */}
              <div style={{ height: "0px" }}>
                <div className="whtsp_switch">
                  <span className="label_with_icon">
                    <span className="label_text">Get OTP on</span>
                    <WhatsAppIcon />
                  </span>
                  <Switch
                    labelInput="remind_patient"
                    checked={showwhatps}
                    ContainerClass="prescriptionpage__medication__reminder__switch"
                    handleChange={(e) => {
                      const isChecked = e.target.checked;
                      handleFollowUP(isChecked);
                      // getOtp(isChecked); // Call getOtp with the switch status
                    }}
                  />
                </div>

                <span className="login-text">
                  Enter Phone Number to continue
                </span>
                <Grid
                  container
                  className="loginContainer_number"
                  justifyContent="center"
                >
                  <Grid item xs={1} className="loginContainer_icon">
                    {/* <MobileFriendlyOutlined /> */}
                    <span class="material-symbols-outlined icon-glow">
                      call
                    </span>
                  </Grid>
                  <Grid item xs={2} className=" loginContainer_country">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={phoneCode}
                      onChange={handleChange}
                      disabled
                      mt={2}
                      className="w-100"
                    >
                      {countriesList &&
                        countriesList.map((item, index) => {
                          return (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                            >
                              {item.code}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={7} className="">
                    <TextField
                      error={formError.phoneNumber.hasError}
                      className=" loginContainer_phone-input"
                      type="tel"
                      name="phoneNumber"
                      id="standard-basic"
                      placeholder="Enter your number"
                      onChange={updateForm}
                      value={formData.phoneNumber}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <LoginButton
                    onSubmit={submitPhoneNo}
                    // loader={otpLoader}
                    color="secondary"
                  />
                  {/* <button
                    className="Arrow--right"
                    onClick={submitPhoneNo}
                    disabled={
                      parseJson(Ls.get("hosp_id")) == null ? true : false
                    }
                  >
                    {otpLoader ? (
                      <CircularProgress size={16} color="success" />
                    ) : (
                      <img src={ContinueIcon} />
                    )}
                  </button> */}
                </Grid>
                {formError.phoneNumber.hasError && (
                  <FormHelperText className="validation-error">
                    {formError.phoneNumber.errorMsg}
                  </FormHelperText>
                )}
                {enableFacility ? (
                  <>
                    {doctorContext?.hospitalDetails?.length ? (
                      <div
                        className={`${
                          !doctorContext?.hospitalDetails ||
                          formError?.phoneNumber?.hasError
                            ? "loginContainer_option_disabled"
                            : ""
                        } loginContainer__option`}
                      >
                        <img
                          src={`${baseUrl}${
                            constants.testPath("mode") !== "test"
                              ? ""
                              : "/" + constants.testPath("mode")
                          }/hospital/logo/${
                            hospId || constants.hospitalId("hosp_id")
                          }`}
                          className="loginContainer__option__img"
                        />
                        <div
                          className="loginContainer__option__select"
                          onClick={() => {
                            setFacilityEnable(true);
                            setFacilityObj("");
                          }}
                        >
                          <input
                            value={facilityObj}
                            placeholder="Select Facility"
                            autocomplete="off"
                            autocorrect="off"
                            onChange={(e) => {
                              setFacilityObj(e.target.value);
                            }}
                          />
                        </div>
                        {formData?.phoneNumber.trim().length === 10 &&
                        facilityObj ? (
                          <LoginButton
                            onSubmit={submitHospitalName}
                            loader={otpLoader}
                            color="secondary"
                          />
                        ) : (
                          <></>
                        )}
                        <div className="loginContainer__option__list">
                          {facilityEnable && doctorContext?.hospitalDetails ? (
                            <List
                              dense
                              className="autoSuggesionUl pos-absolute w-100 bg-white font-12"
                            >
                              {doctorContext?.hospitalDetails
                                ?.filter((list) =>
                                  list?.name
                                    ?.toLowerCase()
                                    ?.includes(facilityObj?.toLowerCase())
                                )
                                ?.map((item, index) => (
                                  <ListItem
                                    key={index}
                                    className="autoCompleteList p-0"
                                    onClick={() => {
                                      handleFacility(item);
                                    }}
                                  >
                                    <ListItemButton className="py-0">
                                      <ListItemText
                                        primary={item?.name}
                                        className="font-12"
                                      ></ListItemText>
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                            </List>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="no_registration">
                        {" "}
                        {/* Render without loginContainer__option class */}
                        No Registration Found
                        <span onClick={handleregis}>Register Now</span>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {showOtp && !formError?.phoneNumber?.hasError ? (
                  <Paper className="loginContainer_otp">
                    <div>
                      <div className="pt-2 loginContainer_otp__box">
                        <div className="loginContainer_otp__input">
                          <TextField
                            className="loginContainer_otp__input__box"
                            type="text"
                            placeholder="Enter OTP"
                            inputProps={{ min: 0, max: 999999, maxLength: 6 }}
                            onChange={setOtpValue}
                            value={otp}
                            inputRef={otpField}
                            onKeyPress={onKeyPress}
                          />

                          <LoginButton
                            onSubmit={doctorLogin}
                            loader={loginLoader}
                            color="secondary"
                            disabled={loginLoader || otpError || otp.length < 6}
                          />
                        </div>
                        {otpValidation.hasError && (
                          <FormHelperText className="validation-error">
                            {otpValidation.content}
                          </FormHelperText>
                        )}
                        <Box className="d-flex justify-content-center pt-2">
                          <Box className="loginContainer_otp_button-container">
                            {/* <Button
                              variant="contained"
                              size="small"
                              className="loginContainer_button"
                              color={"error"}
                              disabled={
                                loginLoader || otpError || otp.length < 6
                              }
                              onClick={doctorLogin}
                            >
                              VERIFY OTP
                              {loginLoader && (
                                <div className="d-inline-block pl-3">
                                  <CircularProgress size={14} />
                                </div>
                              )}
                            </Button> */}
                            {/* <Button
                              variant="contained"
                              size="small"
                              className="loginContainer_button"
                              color={"error"}
                              disabled={
                                disableSendOtp ||
                                otpLoader ||
                                doctorContext.hospitalLoader
                              }
                              onClick={getOtp}
                            >
                              Resend OTP{" "}
                              {otpLoader && (
                                <div className="d-inline-block pl-3">
                                  <CircularProgress size={14} />
                                </div>
                              )}
                              {disableSendOtp && !otpLoader && (
                                <Box className="loginContainer_otp_countdown">
                                  <CountdownButton
                                    disableButton={disableSendOtp}
                                    initCount={60000}
                                  />
                                </Box>
                              )}
                            </Button> */}
                          </Box>
                        </Box>
                      </div>
                    </div>
                  </Paper>
                ) : (
                  <></>
                )}
              </div>
            </Paper>
          </form>
        </Container>
        {/* <Footer /> */}
      </div>
    </>
  );
}
