import React, { useEffect, useContext } from "react";
import { PatientpageContext } from "./../context/GlobalContext";
import dayjs from "dayjs";
import { CircularProgress, Box, List, ListItem, Grid, Button } from "@mui/material";
const helperObject = {
  'diagnosis': 'Reported Issues',
  'diseases': 'Findings',
  'symptoms': 'Prescription',
  'therapies': 'Prescription',
}
export default function PrescriptionTemplate({ appointmentId, preValue, generatePresData, activeAppointment, checkShareDisable }) {
  const doctorContext = useContext(PatientpageContext);
  const [preMeta, setPreMeta] = React.useState({});
  const [pdfUrl, setPdfUrl] = React.useState('');
  useEffect(() => {
    // apiCall({
    //   url: `/doctor/appointment/notes/${appointmentId}`,
    //   method: "put",
    //   data:generatePresData,
    //   headers: {
    //     doc_token: Ls.get("userToken")
    //   }
    // }).then((res) => {
    //   console.log(res.data)
    // }).catch((err) => {});


    doctorContext.appointmentNotes(generatePresData, appointmentId)
      .then((res) => {
        doctorContext.generatePrescription(appointmentId, generatePresData)
          .then((res) => {
            console.log(res);
            checkShareDisable();
            doctorContext.downloadPrescription(appointmentId).then((res) => {
              console.log(res);
              var url = window.URL.createObjectURL(res);
              // setPdfUrl(url+"#toolbar=0");
              setPdfUrl(url);
            }).catch((err) => { });
          })

      })
      .catch((err) => { });

    //   doctorContext.viewPrescription(appointmentId).then((res) => {
    //     setPreMeta(res);
    //   });

    // //   {
    // //     "symptoms_list": ["Cold","Cough","Fever"],
    // //     "findings_list": ["Raised Heartbeats","High Fever"],
    // //     "recommendations_list":["Administer Dengue Test","Administer Covid-19 Test", "Paracetemol - 2 Times daily"]
    // // }

    //   doctorContext.generatePrescription(appointmentId, generatePresData).then((res) => {
    //     // setPreMeta(res);
    //     console.log(res);
    //   });

  }, []);

  function printListItem(arrVal = [], arrVal2 = []) {
    let lists = "";
    if (arrVal.length > 0 || arrVal2.length > 0) {
      lists = (
        <List dense>
          {arrVal.map((val, index) => {
            return <ListItem key={index}>{val.term}</ListItem>;
          })}
          {arrVal2 && arrVal2.map((val, index) => {
            return <ListItem key={index}>{val.term}</ListItem>;
          })}
        </List>
      );
    }
    return lists;
  }


  return (
    <div>
      <h3 className="text-center">Prescription</h3>
      {doctorContext.preTemplateLoader ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <div className="pre-template-container text-left">
          <Box className="embed-responsive embed-responsive-16by9"
            sx={{
              display: {
                xs: 'none',
                sm: 'block'
              },
            }}
          >
            <object className="embed-responsive-item" width="100%" height="100%" aria-label="prescription" data={pdfUrl}></object>
          </Box>
          <Box sx={{
            display: {
              xs: 'block',
              sm: 'none'
            },
          }}>
            <p style={{ textAlign: "center" }}>Please download and review prescription before submission.</p>
            <Box sx={{ textAlign: "center" }}><Button variant="contained" href={pdfUrl} download>Download</Button></Box>
          </Box>
        </div>
      )}
      {/* {Object.keys(preValue).length} */}
    </div>
  );
}
