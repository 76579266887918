import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { Ls, ErrorComponent } from "../utility/utility";
import BloodGroupSvg from "../static/images/bloodgroup.svg";
import { OverlayContext } from "../OverlayManager/Overlay";
import { OverlayType } from "../OverlayManager/Context";
import RecordIcon from "../static/images/record.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import { PatientpageContext } from "../context/GlobalContext";
import ImageAvatar from "./ImageAvatar";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import MedicationRoundedIcon from "@mui/icons-material/MedicationRounded";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { Img } from "react-image";
import { ServiceCall } from "../utility/service-call";
import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputAdornment,
  IconButton,
  Popper,
  Paper,
  Grow,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DoctorsAppointmentListItemNew = ({
  appointmentItem,
  tabRoute = "",
  index,
  handleOnClick,
}) => {
  const isPrescription = tabRoute === "prescription";
  const overlay = useContext(OverlayContext);
  const doctorContext = useContext(PatientpageContext);
  const appointmentItemss = doctorContext.appointmentList;
  // console.log(3333, appointmentItemss)
  const history = useHistory();

  // const anchorRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (anchorRef.current && !anchorRef.current.contains(event.target)) {
  //       handleClose();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [handleClose]);

  return (
    <>
      <div
        key={index + 1}
        className={`py-2 px-3 appointment__container prescription__container
      ${
        tabRoute === "patientRecords"
          ? "patientRecordsAppointment"
          : "prescriptionReportAppointment"
      }`}
        container
        spacing={2}
        item
        xs={11}
        justifyContent="space-around`"
        alignItems="center"
        style={{ cursor: "pointer", background: "#e8f3f1" }}
        // onClick={() => handleOnClick(appointmentItem)}
      >
        <div>
          {appointmentItem && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Age</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Gender</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Blood Group
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentItem?.patient_name}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentItem?.patient_age}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {appointmentItem?.patient_gender  || "NA"}
                  </TableCell>
                  {appointmentItem?.patient_blood_group && (
                    <TableCell style={{ textAlign: "center" }}>
                      <Img src={BloodGroupSvg} />
                      {appointmentItem?.patient_blood_group || ""}{" "}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      </div>
      {/* {tabRoute === "prescription" ? (
        <div
          className="appointment__container"
          style={{ background: "#e8f3f1", marginTop: "19px" }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            className="appointment__container__lastsection prescription__container__lastsection"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {tabRoute === "prescription" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "19px 20px", // Add padding to create gap from the outer container
                  gap: "27px",
                }}
              >
                <div
                  className="appointment__date__record"
                  onClick={() => {
                    history.push({
                      pathname: "/prescription/patient_notes",
                      search: history?.location?.search,
                    });
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Patient Notes{" "}
                    <MedicationRoundedIcon sx={{ fontSize: "25px" }} />
                  </span>
                </div>
                <div
                  className="appointment__date__record"
                  onClick={() => {
                    overlay.show(OverlayType?.ClinicalFindings);
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Clinical Findings{" "}
                    <DeviceThermostatRoundedIcon sx={{ fontSize: "30px" }} />
                  </span>
                </div>
                <FormControl variant="filled" style={{ width: "100%" }}>
                  <InputLabel id="salutation-label">Select Template</InputLabel>

                  <Select
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    placeholder="Select The Template"
                    style={{ width: "100%" }}
                  >
                    {tempdata.templates && tempdata.templates.length > 0 ? (
                      tempdata.templates.map((template) => (
                        <MenuItem
                          key={template.prescription_template_id}
                          value={template.prescription_template_id}
                        >
                          {template.template_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No templates available</MenuItem>
                    )}
                  </Select>
                </FormControl>

                <div
                  className="appointment__date__record"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "15%",
                  }}
                  onClick={hadledoctordata}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "23px",
                    }}
                  >
                    Save{" "}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
};

export default DoctorsAppointmentListItemNew;
