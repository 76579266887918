import React, { useContext, useEffect } from "react";
import { bioMarks } from "./ClinicalUtils";
import { bioMarksBloodPressure } from "./ClinicalUtils";
import Button from "../../Tools/Button";
import { PatientpageContext } from "../../context/GlobalContext";

const ClinicalBubbels = ({ handleOnClick }) => {
  const doctorContext = useContext(PatientpageContext);
  console.log("doctorContextdsfsdf", doctorContext?.clinicalFindings);
  return (
    <div className="clinicalFindings_bubbles">
      {doctorContext?.clinicalLoader
        ? bioMarks?.map((items, index) => (
            <React.Fragment key={index + 1}>
              <Button
                text={items}
                loader={doctorContext?.clinicalLoader}
                containerClass="clinicalFindings_bubbles_button"
                handleClick={() => {}}
              />
            </React.Fragment>
          ))
        : Array.isArray(doctorContext?.clinicalFindings) &&
          doctorContext?.clinicalFindings?.map((items, index) => (
            <React.Fragment key={index + 1}>
              <Button
                text={items?.display_name}
                handleClick={() => {
                  handleOnClick(items, index);
                }}
                containerClass={`${
                  !items?.checked ? "clinicalFindings_bubbles_disable" : ""
                } clinicalFindings_bubbles_button`}
              />
            </React.Fragment>
          ))}
    </div>
  );
};

export default ClinicalBubbels;
