import React from 'react'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarIcon from "../static/images/Combined-Shape.svg";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const CalendarMonth = ({labelInput, label, formate, handleChange, date, icon,containerClass, props}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`newappointment__container__calender__datePicker appointment__date__calendar__date ${containerClass} `}
      >
        <MobileDatePicker
          label={label}
          inputFormat={formate}
          value={date}
          onChange={(value) => handleChange(value, labelInput)}
          renderInput={(params) => <TextField {...params} />}
          {...props}
        />
        <img src={icon || CalendarIcon} />
      </div>
    </LocalizationProvider>
  );
};

export default CalendarMonth