import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PageContentLayout from "../../components/PageContentLayout";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import Deleteicon from "../../static/Deleteicon.svg";
import SubmitIcon from "../../static/SubmitIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccountIcon from "../../static/images/AccountIcon.svg";

const Notes = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prescription = location.state?.prescription;
  const patientId = prescription?.pt_profile_id;
  const history = useHistory();

  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitloader, setSubmitloader] = useState(false);
  const [deleteloader, setDeleteloader] = useState({});
  const [patientInfo, setPatientInfo] = useState({
    name: "",
    gender: "",
    age: null,
  });

  const handleNoteChange = (event) => {
    setNewNote(event.target.value);
  };

  const handleNoteSubmit = async () => {
    if (newNote.trim() !== "") {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      const newNoteWithDate = `${formattedDate}: ${newNote}`;
      setSubmitloader(true);
      try {
        const response = await ServiceCall.postv2(
          "v2/doctor/patient/personal/profile/note/",
          patientId,
          {
            note: newNote,
          },
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (response.status === 200) {
          setSubmitloader(false);
          fetchData();
          setNotes([
            ...notes,
            {
              note_datetime: formattedDate,
              note: newNote,
              note_key: response.data.note_key,
            },
          ]);
          setNewNote("");
        }
      } catch (error) {
        setSubmitloader(false);
        // console.error("Error submitting note:", error);
      }
    }
  };

  const handleNoteDelete = async (note_key) => {
    setDeleteloader({ ...deleteloader, [note_key]: true });
    try {
      const response = await ServiceCall.deletev2(
        `/v2/doctor/remove/patient/profile/personal/note/`,
        `${patientId}/${note_key}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (response.status === 200) {
        setDeleteloader({ ...deleteloader, [note_key]: false });
        fetchData();
        const updatedNotes = notes.filter((note) => note.note_key !== note_key);
        setNotes(updatedNotes);
      }
    } catch (error) {
      setDeleteloader({ ...deleteloader, [note_key]: false });
      // console.log("Error deleting note:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const patientData = await ServiceCall.getv2(
        "/v2/doctor/list/patient/profile/personal/notes/",
        patientId,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (patientData.status === 200) {
        setNotes(patientData.data.notes);
        setPatientInfo({
          name: patientData.data.patient_name,
          gender: patientData.data.patient_gender,
          age: patientData.data.patient_age,
        });
        setLoading(false);
      }

      // console.log(patientNotes);
    } catch (error) {
      // console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchData();
    }
  }, [patientId]);

  const handlle = () => {
    history.push("/my-patients");
  };

  return (
    <PageContentLayout>
      <div className="patient_note">
        {/* <p
          style={{
            fontSize: "21px",
            fontWeight: "700",
            cursor: "pointer"
          }}
          onClick={handlle}
        >
          <ArrowBackIosIcon style={{ marginBottom: "-0.3rem", marginLeft: "1rem" }} />
          <span style={{ marginTop: "0.5rem" }}> Patient Notes</span>
        </p> */}
        <div className="pnh-inner" onClick={handlle}>
          <ArrowBackIosIcon />

          <img src={AccountIcon} alt="accountIcon" />
          <p>{patientInfo.name}</p>
          <span className="pnh-time">
            ({patientInfo.age}, {patientInfo.gender})
          </span>
        </div>
        <div className="para">
          <p>
            *Please note, the recorded notes here are your personal diary on the
            patient, the notes will not be visible to patient or any other
            doctor.
          </p>
        </div>
        <div className="patient_cl">
          <ul>
            {notes.map((item, index) => (
              <li key={index}>
                <span className="note-time">{item.note_datetime}</span>
                <div className="notes-outer">
                  <span className="note-text">{item.note}</span>
                  <span
                    className="btn"
                    onClick={() => handleNoteDelete(item.note_key)}
                  >
                    {deleteloader[item.note_key] ? (
                      <CircularProgress
                        style={{
                          height: "inherit",
                          width: "inherit",
                        }}
                      />
                    ) : (
                      <img src={Deleteicon} alt="deleteicon" />
                    )}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="write_notes">
          <input
            type="text"
            className="form-control"
            value={newNote}
            onChange={handleNoteChange}
            placeholder="Enter a new note"
          />
          <button onClick={handleNoteSubmit} disabled={submitloader}>
            {submitloader ? (
              <CircularProgress
                size="30px"
                style={{
                  color: "#eee",
                  borderRadius: "50px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                }}
              />
            ) : (
              <img src={SubmitIcon} alt="submitIcon" />
            )}
          </button>
        </div>

        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              opacity: "0.6",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
            onClick={() => {}}
          >
            <CircularProgress size="20px" color="inherit" />
          </Backdrop>
        )}
      </div>
    </PageContentLayout>
  );
};

export default Notes;
