import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import { PatientpageContext } from './../context/GlobalContext';
// import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ChartComponent from './ChartComponent';
import Overlay from './Overlay';

export default function MonitorComponent({
  appointment_id,
  monitorList,
  monitorGraphs
}) {
  const patientContext = useContext(PatientpageContext);

  useEffect(() => {
    if (patientContext.monitorDataLoader !== 'loaded') {
      patientContext.getMonitorData(appointment_id);
    }
  }, [patientContext, appointment_id]);

  return (
    <Grid container className="position-relative">
      {patientContext.monitorDataLoader === 'loading' && (
        <Overlay opacity=".6">
          <Box className="d-flex pt-5 justify-content-center align-items-center flex-column">
            <CircularProgress size="20px" color="success" />
            <div className="py-3 font-16">Loading Reports</div>
          </Box>
        </Overlay>
      )}

      {patientContext.monitorData ? (
        <Grid item container spacing={3} alignItems={'center'} xs={12}>
          {Object.keys(patientContext.monitorData).map((item) => {
            return (
              <Grid
                key={item}
                item
                xs={12}
                className={`${
                  monitorList.indexOf(item) === -1 ? 'd-none' : ''
                }`}
              >
                <h3 className="text-center mt-0">
                  {patientContext.monitorData[item].datasets[0].containerLabel
                    ? patientContext.monitorData[item].datasets[0]
                        .containerLabel
                    : patientContext.monitorData[item].datasets[0].label}
                </h3>
                <ChartComponent
                  collection={{ data: patientContext.monitorData[item] }}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid item xs={12} className="text-center">
          No Data Found
        </Grid>
      )}
    </Grid>
  );
}
