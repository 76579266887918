import React from "react";
import { OverlayType } from "./Context";

// export function useOverlay() {
//     return useContext(OverlayContext);
// }

export const OverlayContext = React.createContext({
  context: null,
  hide: () => {},
  show: (type,context) => {},
  type: null,
});

export default class Overlay extends React.Component {
  // const [state, setState] = useState({
  //   context: null,
  //   hide: hide,
  //   show: show,
  //   theme: null,
  //   type: null,
  // });

  // const show = ({ type, context }) => {
  //   setState({ type, context });
  // };

  // const hide = () => {
  //   setState({...state, type: null });
  // };

  constructor(props) {
    super(props);
    this.state = {
      context: null,
      hide: this.hide,
      show: this.show,
      type: null,
    };
  }

  componentDidUpdate(prevProps) {
    // debugger;
    if (
      this.props.location?.pathname !== prevProps.location?.pathname &&
      this.state.type !== OverlayType.message
    ) {
      this.hide();
    }
  }

  show = (type, context) => {
    // debugger;
    //
    this.setState({ type, context });
    // document.body.style.overflow = type !== OverlayType.message ? "hidden" : "";
  };

  hide = () => {
    this.setState({ type: null });
    // document.body.style.overflow = "";
  };

  // return (
  //   <OverlayContext.Provider value={state}>{children}</OverlayContext.Provider>
  // );
  render() {
    return (
      <OverlayContext.Provider value={this.state}>
        {this.props.children}
      </OverlayContext.Provider>
    );
  }
}
