import React, { useState, useContext, useEffect } from "react";
import AppointmentTab from "./AppointmentTab";
import SearchIcon from "../static/images/svg/SearchIcon";
import { ServiceCall } from "../utility/service-call";
import CalendarMonth from "../Tools/CalendarMonth";
import { PatientpageContext } from "../context/GlobalContext";
import dayjs from "dayjs";
import Header from "./Header";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Grid, Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import RxoneImage from "../static/images/Rx_symbol-removebg-preview 1.svg";
import TooltipComponent from "./TooltipComponent";
import { Img } from "react-image";
import NotesIcon from "@mui/icons-material/Notes";
import { AppointmentIcons } from "../static/images/ImageManager";
import { Ls } from "../utility/utility";
import { apiCall } from "../utility/service-call";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { OverlayType } from "../OverlayManager/Context";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { OverlayContext } from "../OverlayManager/Overlay";
import PageContentLayout from "./PageContentLayout";
import NoAppointmentGif from "../static/images/no-appointment.gif";
import AutorenewIcon from '@mui/icons-material/Autorenew';


const NewPrescriptionTab = (props) => {
  const [searchData, setSearchData] = useState("");
  const [appointmentLi, setAppointmentList] = useState([]);
 
  const doctorContext = useContext(PatientpageContext);
  const appointmentList = doctorContext?.prescriptionList;
  const Overlay = useContext(OverlayContext);

  

  useEffect(() => {
    if (appointmentList) {
      setAppointmentList(appointmentList || []);
    }
  }, [appointmentList]);

  const handleDateChange = (value, label) => {
    let start =
      label === "StartDate"
        ? value
        : doctorContext?.prescriptionDate?.StartDate;
    let end =
      label === "EndDate" ? value : doctorContext?.prescriptionDate?.EndDate;

    doctorContext.getPrescription(start, end);
    doctorContext?.setPrescriptionDate({
      ...doctorContext?.prescriptionDate,
      [label]: value,
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    let appointmentListUpdated = appointmentList;

    const updatedAppointmentList =
      Array.isArray(appointmentListUpdated) &&
      !!appointmentListUpdated?.length &&
      appointmentListUpdated?.filter(
        (item) =>
          item?.patient_name
            ?.toLowerCase()
            ?.includes(value?.trim()?.toLowerCase()) ||
          item?.patient_age?.toString()?.includes(value?.toString()?.trim()) ||
          item?.patient_gender
            ?.toLowerCase()
            ?.includes(value?.trim()?.toLowerCase())
      );

    setAppointmentList(updatedAppointmentList);
    setSearchData(value);
  };

  const AppointmentIcon = ({ type }) => {
    if (type?.includes("Online")) {
      return <Img src={AppointmentIcons.online} />;
    } else if (type?.includes("In-Person")) {
      return <Img src={AppointmentIcons.inperson} />;
    }
    return <Img src={AppointmentIcons.walkin} />;
  };

  const StatusButton = ({ type, color }) => {
    if (type === "Confirmed") {
      return (
        <button className="loginContainer_button appointment__container__status__button">
          {type}
        </button>
      );
    } else {
      return (
        <button className="loginContainer_button appointment__container__status__button button_token">
          {type}
        </button>
      );
    }
    return <></>;
  };

  const handleDownloadPrescription = async (appointment_id) => {
    let url = "/v2/doctor/prescriptions/download/";
    try {
      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: appointment_id,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        let blob = new Blob([PrescriptionDownload.data], {
          type: "application/pdf",
        });
        let url = window.URL.createObjectURL(blob);
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={url} />
              </Worker>
              <div className="prescription__pdf__container">
                <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a>
                <a
                  download={`prescription-${appointment_id}`}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {},
        });
        // a.download = appointmentItem?.appointment_id;
        // document.body.append(a);
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log("error", err);
      err &&
        doctorContext.setState({
          msg:
            err?.response?.data?.message ||
            "Network Error please try after some time",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };

  const groupByDate = (appointments) => {
    return appointments.reduce((groups, appointment) => {
      const date = appointment.prescription_date.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(appointment);
      return groups;
    }, {});
  };

  const groupedAppointments = groupByDate(appointmentLi);
  const handlerefresh = () =>{
    doctorContext.getPrescription();
  }
  return (
    <>
      <PageContentLayout>
        <div className="prescriptionContainer">
          <div >
          <div className="prescriptionContainer__heading" style={{marginLeft: "2rem"}}>
            Select start date and end date from calendar to list prescription
          </div>
          <div style={{ float: "inline-end", marginRight: "1rem", cursor: "pointer" }} onClick={handlerefresh}>
  <AutorenewIcon style={{ width: "32px", height: "32px", marginTop: "-1rem" }} />
</div>
          <div className="prescriptionContainer__dates">
            <CalendarMonth
              label="StartDate"
              formate="YYYY-MM-DD"
              date={doctorContext?.prescriptionDate?.StartDate}
              handleChange={handleDateChange}
              props={{
                maxDate: doctorContext?.prescriptionDate?.EndDate,
                closeOnSelect: true,
              }}
            />
            <CalendarMonth
              label="EndDate"
              formate="YYYY-MM-DD"
              date={doctorContext?.prescriptionDate?.EndDate}
              handleChange={handleDateChange}
              props={{
                maxDate: dayjs(),
                minDate: doctorContext?.prescriptionDate?.StartDate,
                closeOnSelect: true,
              }}
            />
          </div>
          <div className="prescriptionContainer__search">
            <input
              type="text"
              placeholder="Search for Name, Age or Gender"
              onChange={(e) => handleSearch(e)}
              value={searchData}
            />
            <SearchIcon />
          </div>
          </div>
          {Object.keys(groupedAppointments).length > 0 ? (
            Object.keys(groupedAppointments).map((date) => {
              const dayOfWeek = dayjs(date).format("ddd");
              return (
                <div key={date}>
                  <div className="date-banner">
                    <span className="day">{dayOfWeek.substring(0, 3)}, </span>
                    {dayjs(date).format("DD MMM YYYY")}
                  </div>
                  <div className="py-2 px-3 appointment__container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }}>
                            Action
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Patient Name
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Gender
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Age
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Type
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Ratings
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Feedback
                          </TableCell>
                          {/* <TableCell style={{ textAlign: "center" }}>
                          Appointment Status
                        </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupedAppointments[date].map(
                          (prescription, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ textAlign: "center" }}>
                                <Grid sm={4} xm={4}>
                                  <RemoveRedEyeIcon
                                    sx={{
                                      width: "100%",
                                      color: "#199a8e",
                                      fontSize: "3rem",
                                    }}
                                    onClick={() =>
                                      handleDownloadPrescription(
                                        prescription?.appointment_id
                                      )
                                    }
                                    style={{ textAlign: "center" }}
                                  />
                                </Grid>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {prescription?.patient_name}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {prescription?.patient_gender  || "NA"}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {prescription?.patient_age}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <AppointmentIcon
                                  type={prescription?.appointment_type}
                                />{" "}
                                {prescription?.appointment_type
                                  ?.replace("Scheduled ", "")
                                  ?.replace("(", "")
                                  ?.replace(")", "")}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <Rating
                                  name="read-only"
                                  value={prescription?.star_rating || 0}
                                  readOnly
                                />
                              </TableCell>
                              <TableCell style={{ textAlign: "center" , display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <div
                                  style={{ gap: "0.2rem" }}
                                  className="d-flex align-items-center"
                                >
                                  {prescription?.review_comments ? (
                                    <TooltipComponent
                                      icon={
                                        <NotesIcon
                                          sx={{
                                            width: "100%",
                                            color: "#199a8e",
                                            fontSize: "2rem",
                                          }}
                                        />
                                      }
                                      title={prescription?.review_comments}
                                    />
                                  ) : (
                                    <NotesIcon
                                      sx={{
                                        width: "100%",
                                        color: "#dddddd",
                                        fontSize: "2rem",
                                      }}
                                      disabled
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <img
                style={{ width: "60%",marginLeft: "1rem", marginBottom: "5rem", marginTop: "2rem" }}
                src={NoAppointmentGif}
                alt="no appointment found"
              />
            </div>
          )}
        </div>
      </PageContentLayout>
    </>
  );
};

export default NewPrescriptionTab;
