import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
const ModalComponent = ({ overlay }) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="dosage"
    >
      <div className="dosage__container duration__container">
        {overlay?.context?.removeCloseButton ? (
          <></>
        ) : (
          <div
            className="dosage__close"
            onClick={() => {
              handleClose();
              overlay?.context?.handleClose()
              overlay.hide();
            }}
          >
            <CloseIcon />
          </div>
        )}
        {overlay?.context?.data}
      </div>
    </Modal>
  );
};

export default ModalComponent;
