export const dose_unit = [
  "Tablet",
  "Capsule",
  "Drop",
  "Injection",
  "Tbsp",
  "Antibiotic",
  "Lozenge",
  "Granule",
  "Inhale",
  "Use",
  "Apply",
  "ML"
];

export const fetchDosage = [
  {
    url: "/v2/doctor/list/drug/measuring/units",
    key: "dose_unit",
  },
  {
    url: "/v2/doctor/list/drug/admin/methods",
    key: "dose_admin_method",
  },
  {
    url: "/v2/doctor/list/drug/dietary/instructions",
    key: "dietary_instructions",
  },
  {
    url: "/v2/doctor/list/drug/dosage/timings",
    key: "dose_timings",
  },
];

export const medication_form = [
  {
    medication_form: "CAPSULE",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "TABLET",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "LOZENGE",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "SYRUP",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "OINTMENT",
    typical_administration_route: "Topical",
  },
  {
    medication_form: "GEL",
    typical_administration_route: "Topical",
  },
  {
    medication_form: "SACHET",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "RESPULES",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "ML",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "SPRAY",
    typical_administration_route: "Topical",
  },
  {
    medication_form: "DROP",
    typical_administration_route: "Oral",
  },
  {
    medication_form: "INJECTION",
    typical_administration_route: "Intramuscularly",
  },
  {
    medication_form: "POWDER",
    typical_administration_route: "Oral",
  },
];

export const dose_admin_method = [
  {
    term: "P.O. (Orally)",
    definition: "Orally",
  },
  {
    term: "I.M. (Intramuscularly)",
    definition: "Intramuscularly",
  },
  {
    term: "Subq. (Subcutaneous)",
    definition: "Subcutaneous",
  },
  {
    term: "Rectally",
    definition: "Rectally",
  },
  {
    term: "I.V. (Intravenous)",
    definition: "Intravenous",
  },
  {
    term: "O.D. (In the right eye)",
    definition: "In the right eye",
  },
  {
    term: "O.S. (In the left eye)",
    definition: "In the left eye",
  },
  {
    term: "O.U. (In both eyes)",
    definition: "In both eyes",
  },
  {
    term: "A.U. (In both ears)",
    definition: "In both ears",
  },
  {
    term: "A.S. (In left ear)",
    definition: "In left ear",
  },
  {
    term: "A.D. (In right ear)",
    definition: "In right ear",
  },
  {
    term: "Inhale",
    definition: "Inhale",
  },
  {
    term: "Vaginal",
    definition: "Vaginal",
  },
  {
    term: "Topically (On Skin)",
    definition: "On Skin",
  },
];

export const dose_timings = [
  {
    term: "od",
    definition: "One time in a day",
  },
  {
    term: "bid",
    definition: "Two times in a day",
  },
  {
    term: "tid",
    definition: "Three times in a day",
  },
  {
    term: "qid",
    definition: "Four times in a day",
  },
  {
    term: "5x",
    definition: "Five times in a day",
  },
  {
    term: "q2h",
    definition: "Every Two hours",
  },
  {
    term: "q4h",
    definition: "Every Four hours",
  },
  {
    term: "q6h",
    definition: "Every Six hours",
  },
  {
    term: "q12h",
    definition: "Every Twelve hours",
  },
  {
    term: "qod",
    definition: "Every Other Day",
  },
  {
    term: "prn",
    definition: "as needed",
  },
  {
    term: "sos",
    definition: "Emergency only",
  },
];

export const dietary_instructions = [
  {
    term: "NA (Not Applicable)",
    definition: "NA",
  },
  {
    term: "a.m. (Morning)",
    definition: "Morning",
  },
  {
    term: "a.c. (Before Meals)",
    definition: "Before Meals",
  },
  {
    term: "p.c. (After Meals)",
    definition: "After Meals",
  },
  {
    term: "a.p. (Before Dinner)",
    definition: "Before Dinner",
  },
  {
    term: "q.h.s. (Before bed)",
    definition: "Before bed",
  },
  {
    term: "Qs (As much as needed)",
    definition: "As much as needed",
  },
];

export const bloodGroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];