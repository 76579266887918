import React from "react";
import CautionIcon from "../static/images/caution.svg";

const Caution = ({ data }) => {
  return (
    <div className="caution">
      <div className="caution__container">
        <img className="caution__img" src={CautionIcon} />
        <h2 className="caution__h2">Caution</h2>
      </div>
      <p className="caution__error">
        Common side effects of {data?.medicine_name} include:
      </p>
      <div className="caution__effects">
        <ul className="caution__effects__list">
          {data?.listed_side_effects?.map((effects, index) => (
            <>
              <li key={index}>{effects}</li>
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Caution;
