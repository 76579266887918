import React, { useEffect, useState } from "react";
import { Input } from "@mui/material";
const InputButton = ({ placeholder, icon, input, onChange }) => {
  
  return (
    <div className="pos-relative mb-4">
      {" "}
      <Input
        placeholder={placeholder}
        value={input}
        onChange={onChange}
        className="w-100 pr-3 font-12"
      />
      {icon && (
        <div className="autocompLeteLoader pos-absolute">
          <img src={icon} />
        </div>
      )}
    </div>
  );
};

export default InputButton;
