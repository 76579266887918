import React, { useState, useEffect, useContext } from "react";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { constants } from "../../utility/constants";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { PatientpageContext } from "../../context/GlobalContext";
import Carousel from "nuka-carousel/lib/carousel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";

const PlanSubcrip = ({ docSecret, docKey, hosId }) => {
  // const [pldata, setPldata] = useState("");
  const history = useHistory();
  const { search } = useLocation();
  
  const [openModal, setOpenModal] = useState(false);
  const [opensubModal, setOpensubModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null); // State to store selected plan
  const [userplan, setUserplan] = useState(null);
  const doctorContext = useContext(PatientpageContext);
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const [selectedpt, setSelectedpt] = useState(null);
  const [subprice, setSubprice] = useState("");
  const [checkdata, setCheckdata] = useState("");
  const [data, setData] = useState("");
  const [selectedPlans, setSelectedPlans] = useState(null);
  const [verifyingRazorpayPayment, setVerifyingRazorpayPayment] =
  useState(false);
const [processingOnlinePayment, setProcessingOnlinePayment] = useState(false);
const setState = doctorContext.setState;
const [apiError, setApiError] = useState("");
const [loading, setLoading] = useState(false); // State for managing loading state
const [saved, setSaved] = useState(false); // State to track whether form has been saved


  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
  useEffect(() => {
    doctorContext?.fetchPlans();
  }, []);

  const handlle = () => {
    history.push({
      pathname: "/homie",
      search: history.location.search,
    });
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handlePlanClick = (plan) => {
    setSelectedPlan(plan);
    setOpenModal(true);
  };

  const handlePlans = async(plan) => {
    setUserplan(plan);
    try {
      const response = await ServiceCall.getv2(
        "/v2/doctor/list/opd/plan/details/",
        `${constants.hospitalId("hosp_id")}/${plan.plan_name}`, // Access plan_name from props

        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (response) {
        setSubprice(response.data.plans);
        setData(response.data);
        setSelectedpt(plan); // Set selected plan
        const annualPlan = response.data.plans.find(
          (plan) => plan.plan_name === "ANNUAL"
        );
        if (annualPlan) {
          setSelectedPlans(annualPlan);
        }

        setOpensubModal(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }  };

  const unicode = "✓"; // Using the check mark character directly

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 1000);

    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  const handleCloseModal1 = () => {
    setOpensubModal(false);
  };
  const unicode1 = "\u20b9";
  const handlePlanSelect = (plan) => {
    setSelectedPlans(plan); // Update selected plan state
  };

  const unsuccessfulOnlinePayment = async (razorpayErrorRes) => {
    console.log({ razorpayErrorRes });
  };

  const successfulOnlinePayment = async (razorpayRes, subscriptionId) => {
    setVerifyingRazorpayPayment(true);
    try {
      const body = {
        razorpay_payment_id: razorpayRes.razorpay_payment_id,
        razorpay_order_id: razorpayRes.razorpay_order_id,
        razorpay_signature: razorpayRes.razorpay_signature,
        razorpay_error_code: "",
        razorpay_error_description: "",
        razorpay_error_source: "",
        razorpay_error_step: "",
        razorpay_error_reason: "",
        razorpay_error_metadata_order_id: "",
        razorpay_error_metadata_payment_id: "",
      };

      const verifyRes = await ServiceCall.postv2(
        "/v2/doctor/verify/opd/plan/order/payment/",
        `${constants.hospitalId("hosp_id")}`,
        `${subscriptionId}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (verifyRes) {
        setLoading(false);
        setState({
          msg: verifyRes?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    } finally {
      setVerifyingRazorpayPayment(false);
    }
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called"); // Add debug log to check if handleSubmit is called
    try {
      if (!selectedPlans) {
        console.error("Error in handleSubmit: selectedPlan is null or undefined");
        return; // Exit function if selectedPlan is null or undefined
      }
  
      const body = {
        service_id: selectedPlans?.service_id,
        plan_id: selectedPlans?.plan_id,
        order_gross_amount: selectedPlans.plan_mrp_subunits || 0,
        order_discount: selectedPlans.plan_discount_subunits || 0,
        order_gst: selectedPlans.plan_applicable_gst_subunits || 0,
        order_grand_total:
          parseFloat(selectedPlans.plan_mrp_subunits || 0) -
          parseFloat(selectedPlans.plan_discount_subunits || 0) +
          parseFloat(selectedPlans.plan_applicable_gst_subunits || 0),
        plan_add_ons_list: [],
      };
  
      const Handlesub = await ServiceCall.postv2(
        "/v2/doctor/raise/opd/plan/order/",
        `${constants.hospitalId("hosp_id")}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        await processRazorpayPayment(Handlesub.data.razorpay_order_id, Handlesub.data.subscription_id); // Pass razorpay_order_id and subscription_id
        setCheckdata(Handlesub.data);
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err); // Log any errors that occur
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };
  


  
  const processRazorpayPayment = async (razorpayOrderId, subscriptionId) => {
    try {
      const razorpayKey =
        Ls.get("mode") === "live"
          ? "rzp_live_Uy4jKL7ZDdslqp"
          : "rzp_test_BTj030BoE9feLH";
      const appointmentId = checkdata.subscription_id;
      const options = {
        key: razorpayKey, // Enter the Key ID generated from the Dashboard
        amount: checkdata.order_amount_subunits * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: checkdata.currency,
        name: "RxOne",
        description: "Subscription",
        image:
          "https://rxone-static.s3.ap-south-1.amazonaws.com/images/rx_192.png",
        order_id: razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          name: doctorContext.profileData.name,
          email: doctorContext.profileData.email,

          contact: doctorContext.profileData.phone,
        },
        notes: {
          reciept: appointmentId,
        },
        theme: {
          color: "#a5d6a7",
        },
        handler: (razorpayRes) => {
          console.log("Razorpay Response:", razorpayRes); // Log the razorpayRes object
          successfulOnlinePayment(razorpayRes, subscriptionId);
        },
        modal: {
          ondismiss: function () {
            setApiError("info");
            setApiError("Payment cancelled by the user.");
            // setShowToast(true);
          },
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", unsuccessfulOnlinePayment);

      rzp1.open();
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    } finally {
      setProcessingOnlinePayment(false);
    }
  };

  return (
    <>
    {showLoader ? (
      <Loader />
    ) : (

    <>
      <div className="loginConti bodyBg">
        <p style={{ fontSize: "18px", fontWeight: "700", marginLeft: "10px" }}>
          <ArrowBackIosIcon onClick={handlle} />
          <div
            style={{
              marginTop: "-26px",
              marginLeft: "16px",
              cursor: "pointer",
            }}
          >
            Plans
          </div>
        </p>
        <h2 style={{ textAlign: "center" }}>Pick your plan</h2>
        <p style={{ textAlign: "center", color: "gray" }}>
          RxOne offers a number of solutions to meet the needs of professionals
          like you.
        </p>
        <div className="plns">
          {Array.isArray(doctorContext?.pldata) &&
          doctorContext?.pldata.length > 0 ? (
            doctorContext.pldata.map((plan, index) => (
              <div className="card" key={index}>
                <h4>{plan.plan_name}</h4>
                <h3>{plan.plan_monthly_price}</h3>
                <p>{plan.plan_description}</p>
                <span
                  style={{ textDecoration: "underline", color: "blue" }}
                  onClick={() => handlePlanClick(plan)}
                >
                  Read more
                </span>
                <span
                  style={{
                    textAlign: "center", // Fix: Enclose text-align within quotes
                    border: "2px solid #199a8e",
                    borderWidth: "7px", // You can use camelCase for CSS properties with hyphens
                    backgroundColor: "#199a8e",
                    color: "#fff",
                    width: "106px",
                    margin: "auto",
                    display: "block", // Ensure the span takes the full width
                    cursor: "pointer", // Add pointer cursor to indicate interactivity
                  }}
                  onClick={() => handlePlans(plan)}
                >
                  Select Plan
                </span>
              </div>
            ))
          ) : (
            <p>No plans available</p>
          )}
        </div>
      </div>

      <Modal
        open={openModal} // Control modal visibility using state
        onClose={handleCloseModal} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <CloseIcon
            onClick={handleCloseModal}
            style={{ float: "right", cursor: "pointer" }}
          />
          {/* <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Award
          </p> */}
          <Typography
            variant="h5"
            align="center" // Align center
            style={{
              fontWeight: "500",
              marginBottom: "20px",
              padding: "5px",
              border: "1px solid black",
              width: "153px",
              borderRadius: "5px",
              backgroundColor: "#199a8e",
              color: "#fff",
              fontWeight: "700",
              margin: "auto",
              border: "none",
            }}
          >
            {selectedPlan && selectedPlan.plan_name}
          </Typography>

          <h2 style={{ textAlign: "center", fontSize: "20px" }}>
            {selectedPlan && selectedPlan.plan_monthly_price}/month
          </h2>
          <Typography
            variant="body1"
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            {selectedPlan && selectedPlan.plan_description}
          </Typography>

          <Typography variant="h6" style={{ textAlign: "center" }}>
            Inclusions
          </Typography>
          <ul
  style={{
    listStyleType: "none",
    margin: 0,
    padding: 0,
    background: "#ece6e6",
    width: "465px",
    height: "145px",
    marginLeft: "-32px",
  }}
>
  {selectedPlan &&
    selectedPlan.plan_inclusions &&
    selectedPlan.plan_inclusions.inclusions.map((item, index) => (
      <li key={index} style={{ listStyle: 'none', position: 'relative', paddingLeft: '40px' }}>
      <span style={{ position: 'absolute', left: '0', color: "black", marginLeft: "10px" }}>{unicode}</span>
      {item}
      </li>
    ))}
</ul>

          <div>
  {selectedPlan && selectedPlan.plan_details &&
    <Carousel
    renderCenterLeftControls={() => null} // Hides the left control button
    renderCenterRightControls={() => null} // Hides the right control button

    >
      {selectedPlan.plan_details.solutions.map((solution, index) => (
        <div
          key={index} // You can use index as the key since it's unique in this case
          className="card"
          style={{
            border: "2px solid gray",
            borderRadius: "27px",
            marginTop: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" 

          }}
        >
          <h3 style={{textAlign: "center"}}>{solution.title}</h3>
          <ul>
  {solution.points.map((point, pointIndex) => (
    <li key={pointIndex} style={{ listStyle: 'none', position: 'relative', paddingLeft: '20px' }}>
      <span style={{ position: 'absolute', left: '0', color: "black" }}>{unicode}</span>
      {point}
    </li>
  ))}
</ul>

        </div>
      ))}
    </Carousel>
  }
</div>


        </Box>
      </Modal>


      
      <Modal
        open={opensubModal} // Control modal visibility using state
        onClose={handleCloseModal1} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 466,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
            overflowY: "auto", // Make content scrollable vertically

          }}
        >
          <CloseIcon
            onClick={handleCloseModal1}
            style={{ float: "right", cursor: "pointer" }}
          />
          <span
            onClick={handleCloseModal1}
            style={{ float: "right", cursor: "pointer" }}
          />
          <div>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "inline-block",
                  padding: "5px",
                  border: "1px solid black",
                  width: "121px",
                  borderRadius: "5px",
                  backgroundColor: "#199a8e",
                  color: "#fff",
                  fontWeight: "700",
                  margin: "auto",
                  border: "none",
                  marginBottom: "10px",
                }}
              >
                {selectedpt && selectedpt.plan_name}{" "}
                {/* Display selected plan name */}
              </div>
            </div>
            <div className="card" style={{ marginTop: "15px", border: "none" }}>
              {Array.isArray(subprice) && subprice.length > 0 ? (
                subprice.map((plan, index) => (
                  <div
                    key={index}
                    style={{
                      margin: "7px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <input
                      type="radio"
                      name="planSelection"
                      checked={selectedPlans === plan}
                      onChange={() => handlePlanSelect(plan)}
                      className="form-check-input"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        marginTop: "55px",
                        marginLeft: "13px",
                      }}
                    />
                    <div
                      className="card-body"
                      style={{ marginLeft: "54px", textAlign: "left" }}
                    >
                      <h4
                        className="card-title"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        {plan.plan_name}
                      </h4>
                      <p className="card-text" style={{ marginTop: "-16px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {plan.plan_offered_price}
                        </span>{" "}
                        {plan.plan_duration}
                      </p>
                      <p
                        style={{
                          width: "138px",
                          float: "inline-end",
                          marginTop: "-39px",
                          background: "#ddd7d7",
                          display: "inline-block",
                          padding: "5px",
                          border: "1px solid black",
                          borderRadius: "5px",
                          color: "black",
                          fontWeight: "700",
                          border: "none",
                        }}
                      >
                        {plan.plan_monthly_price}
                      </p>
                      <p
                        className="card-text"
                        style={{ color: "blue", fontSize: "14px" }}
                      >
                        <span style={{ textDecoration: "line-through" }}>
                          {plan.plan_mrp}
                        </span>{" "}
                        {plan.discount_text}
                      </p>
                      {plan.plan_id === data?.subscribed_plan_id && (
                        <p style={{ marginTop: "-13px", color: "#f20d0d" }}>
                          Plan Expires On: {data?.plan_expires_on}
                        </p>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No plans available</p>
              )}

              <div
                style={{ margin: "2px", fontSize: "19px", fontWeight: "600" }}
              >
                <h4 style={{ marginLeft: "10px" }}>Order Details</h4>
                {selectedPlans && (
                  <>
                    <div style={{ background: "#f1e9e9" }}>
                      <span
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          fontWeight: "500",
                          backgroundColor: "rgb(244 239 239)",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ marginLeft: "10px" , fontSize: "19px"}}>
                            RxOne {selectedPlans.plan_name}
                          </span>
                          <span style={{ marginRight: "32px" }}>
                            {unicode1}
                            {selectedPlans.plan_mrp_subunits / 100}
                          </span>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ marginLeft: "10px", fontSize: "19px" }}>Discount</span>
                          <span style={{ marginRight: "33px" }}>
                            {unicode1}
                            {selectedPlans.plan_discount_subunits / 100}
                          </span>
                        </p>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ marginLeft: "10px", fontSize: "19px" }}>
                            GST({selectedPlans.plan_applicable_gst_percent})
                          </span>
                          <span style={{ marginRight: "12px" }}>
                            {unicode1}
                            {selectedPlans.plan_applicable_gst_subunits / 100}
                          </span>
                        </p>
                      </span>
                    </div>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "-22px",
                      }}
                    >
                      <h4 style={{ marginLeft: "5px", marginTop: "21px" }}>
                        Total Amount
                      </h4>
                      <span
                        style={{
                          marginRight: "10px",
                          fontSize: "18px",
                          fontWeight: "700",
                          marginTop: "23px",
                        }}
                      >
                        {unicode1}
                        {(parseFloat(selectedPlans.plan_mrp_subunits) -
                          parseFloat(selectedPlans.plan_discount_subunits) +
                          parseFloat(
                            selectedPlans.plan_applicable_gst_subunits
                          )) /
                          100}
                      </span>
                    </p>
                  </>
                )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    style={{
                      maxWidth: "411px",
                      margin: "auto",
                      backgroundColor: "#199a8e",
                      marginBottom: "10px",
                    }}
                  >
                    Pay Now
                  </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>

)}
</>

  );
};

export default PlanSubcrip;
