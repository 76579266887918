import React, { useContext, useState, useEffect } from "react";
import ButtonTool from "../Tools/Button";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { Ls } from "../utility/utility";
import { bloodGroup } from "../static/staticData/staticData";
import GlobalContext, { PatientpageContext } from "../context/GlobalContext";

const PatientProfileForm = () => {
  const doctorContext = useContext(PatientpageContext);
    const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const patientData = doctorContext?.patientInfo
  const [profileVal, setProfileVal] = useState({
    patient_name:"",
    patient_age:"",
    patient_gender:"male",
    patient_blood_group:"",
  });
  const changeProfile = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setProfileVal({ ...profileVal, [name]: value });
  };
  useEffect(() => {
    if (patientData) {
      setProfileVal({
        ...profileVal,
        ...patientData
      })
    }
  },[patientData])
  const submitProfile = (e) => {
    e.preventDefault();
    doctorContext.editPatientInfo(
      appointment?.appointmentItem?.appointment_id,
      profileVal
    );
  };
  return (
    <form onSubmit={submitProfile} className="px-1">
      <Typography
        className="text-center d-none"
        variant="h6"
        gutterBottom
        component="div"
      >
        Profile Form
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="name"
            name="patient_name"
            fullWidth
            label="Name"
            value={profileVal.patient_name}
            variant="standard"
            // inputProps={{ autoComplete: 'off' }}
            onChange={changeProfile}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="age"
            fullWidth
            required
            name="patient_age"
            label="Age"
            variant="standard"
            type="number"
            min="18"
            value={profileVal.patient_age}
            onChange={changeProfile}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
            <Select
              labelId="genderLabel"
              label="Gender"
              id="gender"
              name="patient_gender"
              value={profileVal.patient_gender}
              fullWidth
              onChange={changeProfile}
            >
              <MenuItem value="">Select Gender</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="dontdisclose">Don't want to disclose</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container spacing={1}>
          <div className="profile_form__blood">
            <label className="profile_form__blood__label" for="gender">
              Blood Group
            </label>
            <div>
              {bloodGroup?.map((item) => (
                <>
                  <label
                    className={`profile_form__blood_circle ${
                      profileVal?.patient_blood_group === item
                        ? "profile_form__blood_circle_selected"
                        : ""
                    } `}
                    for={item}
                  >
                    {item}
                    <input
                      type="radio"
                      name="patient_blood_group"
                      value={item}
                      onChange={changeProfile}
                    />
                  </label>
                </>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className="text-center profileLoginButton justify-content-center"
      >
        <ButtonTool
          text={
            doctorContext.submitProfileLoader ? (
              <CircularProgress color="success" size={15} />
            ) : (
              "Save"
            )
          }
          disabled={doctorContext.submitProfileLoader}
        >
          {doctorContext.submitProfileLoader && (
            <CircularProgress color="success" size={15} />
          )}
        </ButtonTool>
        {/* <Button
          variant="contained"
          color="error"
          className="mx-1"
          disabled={doctorContext.submitProfileLoader}
          onClick={doctorContext.handleDialogClose}
        >
          Cancel
        </Button> */}
      </Grid>
    </form>
  );
};

export default PatientProfileForm;
