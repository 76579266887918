import { useState, useEffect, useContext } from "react";
import PageContentLayout from "../../components/PageContentLayout";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import NotesIcon from "@mui/icons-material/Notes";
import SearchIcon from "../../static/images/svg/SearchIcon";
import { PatientpageContext } from "../../context/GlobalContext";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { constants } from "../../utility/constants";
import { Img } from "react-image";
import { AppointmentIcons } from "../../static/images/ImageManager";
import RxoneImage from "../../static/images/Rx_symbol-removebg-preview 1.svg";

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccountIcon from "../../static/images/AccountIcon.svg";

const PatientDetails = () => {
  const [appointmentLoader, setAppointmentLoader] = useState("loading");
  const [overlayLoading, setOverlayLoading] = useState("");
  const history = useHistory("");

  const doctorContext = useContext(PatientpageContext);
  const [patientdetailDate, setPatientdetailDate] = useState({
    StartDate: dayjs(),
    EndDate: dayjs().add(1, "days"),
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prescription = location.state?.prescription;
  console.log(233637, prescription?.pt_profile_id)


  const patientId = prescription?.pt_profile_id;
  const [appointmentLi, setAppointmentList] = useState([]);
  // console.log(4959505, appointmentLi);

  // console.log(574747, patientId);

  const handleChange = (value, label) => {
    let start = label == "StartDate" ? value : patientdetailDate?.StartDate;
    let end = label == "EndDate" ? value : patientdetailDate?.EndDate;

    getAppointmentss(start, end);
    setPatientdetailDate({
      ...patientdetailDate,
      [label]: value,
    });
  };

  const getAppointmentss = function (startDate, endDate) {
    setOverlayLoading("loading");
    setAppointmentLoader("loading");

    ServiceCall.getv2(
      `/v2/doctor/list/patient/appointments/`,
      `${constants.hospitalId("hosp_id")}/${patientId}/${dayjs(
        startDate
      ).format("YYYY-MM-DD")}/${dayjs(endDate).format("YYYY-MM-DD")}`,
      {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      }
    )
      .then((res) => {
        setAppointmentLoader("completed");
        setOverlayLoading("completed");

        if (res) {
          setAppointmentList(res.data.records);
        }
      })
      .catch((err) => {
        console.log("getAppointmenterrr", err);
        setAppointmentLoader("error");
        setOverlayLoading("error");
      });
  };

  const AppointmentIcon = ({ type }) => {
    if (type?.includes("Online")) {
      return <Img src={AppointmentIcons.online} />;
    } else if (type?.includes("In-Person")) {
      return <Img src={AppointmentIcons.inperson} />;
    }
    return <Img src={AppointmentIcons.walkin} />;
  };

  const handlle = () => {
    history.push("/my-patients");
  };

  const CalendarMonth = ({ label, formate, handleChange, date }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="newappointment__container__calender__datePicker">
          <MobileDatePicker
            label={label}
            inputFormat={formate}
            value={date}
            onChange={(value) => handleChange(value, label)}
            renderInput={(params) => <TextField {...params} />}
          />
          <img src={CalendarIcon} />
        </div>
      </LocalizationProvider>
    );
  };
  return (
    <PageContentLayout>
     
      <div className="patientdetails">
      <div className="pnh-upr" onClick={handlle}>
        <ArrowBackIosIcon style={{cursor: "pointer"}}/>
            <img src={AccountIcon} alt="accountIcon" />
            <p>{prescription.patient_name}</p>
            <span className="pnh-time">
              ({prescription.patient_age}, {prescription.patient_gender})
            </span>
          </div>
        <div className="prescriptionContainer__dates">
          <CalendarMonth
            label="StartDate"
            formate="YYYY-MM-DD"
            date={doctorContext?.patientdetailDate?.StartDate}
            handleChange={handleChange}
          />
          <CalendarMonth
            label="EndDate"
            formate="YYYY-MM-DD"
            date={doctorContext?.patientdetailDate?.EndDate}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div
        className="py-2 px-3 appointment__container"
        style={{ marginTop: "3rem" }}
      >
        <Table>
          {" "}
          {Array.isArray(appointmentLi) && appointmentLi.length > 0 && (
            <TableHead>
              {" "}
              <TableRow>
                {" "}
                <TableCell style={{ textAlign: "center" }}>
                  Appointment Date
                </TableCell>{" "}
                <TableCell style={{ textAlign: "center" }}>
                  Appointment Time
                </TableCell>{" "}
                <TableCell style={{ textAlign: "center" }}>
                  Appointment Status
                </TableCell>{" "}
                <TableCell style={{ textAlign: "center" }}>Appointment Status</TableCell>{" "}
              </TableRow>{" "}
            </TableHead>
          )}
          <TableBody>
            {Array.isArray(appointmentLi) && appointmentLi.length > 0
              ? appointmentLi.map((prescription, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ textAlign: "center" }}>
                      {prescription?.appointment_date}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {prescription?.time_alloted}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <AppointmentIcon type={prescription?.appointment_type} />{" "}
                      {prescription?.appointment_type
                        ?.replace("Scheduled ", "")
                        ?.replace("(", "")
                        ?.replace(")", "")}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
  <Img src={RxoneImage} />{" "}
  {prescription && prescription.prescription_submitted ? "Completed" : "Pending"}
</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </div>
    </PageContentLayout>
  );
};
export default PatientDetails;
