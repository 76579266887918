import React from 'react';
import { useState } from 'react';
import femalebackimg from "../static/female_back.png";

const FemaleBackBody = ({ pointAction, currentPoint, painList = [] }) => {
    const [selectedPoint, setSelectedPoint] = useState(null);
    const currentPoints = Array.isArray(currentPoint) ? currentPoint : [currentPoint?.toString()];

    const bodyParts = [
        { name: 'headRow', points: ["27", "28"], style: { top: '7%', left: '51%', transform: 'translateX(-50%)' } },
        { name: 'neckRow', points: ["29"], style: { top: '20%', left: '51%', transform: 'translateX(-50%)' } },
        { name: 'chestRow', points: ["30", "31"], style: { top: '28%', left: '33px', right: '16px', display: 'flex', justifyContent: 'space-between', padding: '0 43%' } },
        { name: 'armRow', points: ["32", "33", "34", "35"], style: { top: '37%', left: '16px', right: '4px', display: 'flex', justifyContent: 'space-between', padding: '0 41%' } },
        { name: 'forearmsRow', points: ["36", "37", "38"], style: { top: '43%', left: '11px', right: '-3px', display: 'flex', justifyContent: 'space-between', padding: '0 41%' } },
        { name: 'handsRow', points: ["39", "40", "41", "42"], style: { top: '48%', left: '13px', right: '-2px', display: 'flex', justifyContent: 'space-between', padding: '0 39%' } },
        { name: 'leftLegCol', points: ["43", "44", "45", "46"], style: { top: '62%', left: '48%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
        { name: 'rightLegCol', points: ["47", "48", "49", "50"], style: { top: '62%', right: '46%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
    ];

    const getPainColor = (point) => {
        if (point === selectedPoint) return "blue";
        if (currentPoints.includes(point)) return "#f1506c";
        if (painList.includes(point)) return "red";
        return "green";
    };

    const handlePointClick = (point) => {
        pointAction(point);
        setSelectedPoint(point);
    };

    const renderPoints = (points) => (
        points.map((point) => (
            <button
                key={point}
                onClick={() => handlePointClick(point)}
                style={{
                    ...styles.pain,
                    backgroundColor: getPainColor(point),
                }}
            />
        ))
    );

    return (
        <div style={{ position: 'relative', width: '100%', height: 460 }}>
            <img src={femalebackimg} alt="Female back body" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            {bodyParts.map(({ name, points, style }) => (
                <div key={name} style={{ position: 'absolute', ...style }}>
                    {renderPoints(points)}
                </div>
            ))}
        </div>
    );
};

const styles = {
    pain: {
        width: 17,
        height: 17,
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        margin: '2px',
    },
};

export default FemaleBackBody;
