import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
//   import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
let delayed;
const options = {
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true
    },
    x: {
      ticks: {
        display: false
      }
    }
  },
  plugins: {
    // legend: {
    //   labels: {
    //     boxWidth: 0,
    //   }
    // }
  }
};
export default function ChartComponent({
  backgroundColor = 'rgba(53, 162, 235, 0.5)',
  collection,
  noDataText
}) {
  return (
    <div>
      {collection.data !== null ? (
        <Line
          height={275}
          options={collection.options || options}
          data={collection.data}
        />
      ) : (
        <div className="text-center pt-5">{noDataText}</div>
      )}
    </div>
  );
}
