import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PatientpageContext } from "../context/GlobalContext";
import Stack from "@mui/material/Stack";
import { Skeleton } from "@mui/material";
import { Navigation } from "../utility/utility";
import { Ls } from "../utility/utility";
import DoctorsAppointmentListItemNew from "./DoctorsAppointmentListItemNew";
import PatientRecordsList from "./PatientRecordsList";
import { ServiceCall } from "../utility/service-call";
import PageContentLayout from "./PageContentLayout";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";

const PatientRecords = (props) => {
  const doctorContext = useContext(PatientpageContext);
  const history = useHistory();
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const setState = doctorContext.setState;
  const [loader, setLoader] = useState(false);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [infoText] = useState(`The patient's overall health indicators appear to be within normal limits, suggesting a generally healthy status with no immediate concerns based on the provided data. 

Complete Blood Count (CBC)
•	Hemoglobin: 15.0 g/dL (Normal range: 13.0 - 17.0 g/dL)
•	Packed Cell Volume (PCV): 45.0% (Normal range: 40.0 - 50.0%)
•	RBC Count: 4.50 million/mm³ (Normal range: 4.50 - 5.50 million/mm³)
•	Total Leukocyte Count (TLC): 8.0 thousand/mm³ (Normal range: 4.0 - 10.0 thousand/mm³)
•	Differential Leucocyte Count:
•	Neutrophils: 60% (High)
•	Lymphocytes: 30% (Normal)
•	Monocytes: 5% (Normal)
•	Eosinophils: 5% (High)
•	Basophils: 0% (Normal)
•	Platelet Count: 200 thousand/mm³ (Normal range: 150 - 410 thousand/mm³)

Liver and Kidney Panel
•	Creatinine: 0.90 mg/dL (Normal range: 0.70 - 1.30 mg/dL)
•	Urea: 13.0 mg/dL (Normal range: 13.0 - 43.0 mg/dL)
•	Liver Enzymes:
•	AST: 21.0 U/L (Normal range: 15.0 - 40.0 U/L)
•	ALT: 11.0 U/L (Normal range: 10.0 - 49.0 U/L)
•	Alkaline Phosphatase: 150.0 U/L (High, Normal range: 30.0 - 120.0 U/L)

Lipid Profile
•	Total Cholesterol: 130.0 mg/dL (Normal: <200.0 mg/dL)
•	Triglycerides: 46.0 mg/dL (Normal: <150.0 mg/dL)
•	HDL Cholesterol: 33.0 mg/dL (Low, Normal: >40.0 mg/dL)
•	LDL Cholesterol: 26.0 mg/dL (Normal: <100.0 mg/dL)

Glycemic Control
•	HbA1c: 5.3% (Normal range: 4.0 - 5.6%, indicates non-diabetic status)
•	Fasting Glucose: 90.0 mg/dL (Normal range: 70 - 100 mg/dL)

Thyroid Profile
•	T3: 2.0 ng/mL (Normal range: 0.6 - 1.81 ng/mL)
•	T4: 4.0 µg/dL (Normal range: 5.01 - 12.45 µg/dL)
•	TSH: 4.0 µIU/mL (Normal range: 0.55 - 4.78 µIU/mL)

Vitamin Levels
•	Vitamin B12: 280.0 pg/mL (Normal range: 211.0 - 911.0 pg/mL)
`);
    

  // const [infoText, setInfoText] = useState("");
//   const fullText = `Couldn’t decide about the right treatment for you? Allopathy, Homeopathy, Ayurveda, Unani – many treatment types, but which one for you? Consult with RxOne-Care Advisor today to find which treatment suits your condition.

// Our feet are the unsung heroes of our bodies, carrying us through our daily lives with little recognition or care. They endure hours of standing, walking, and running, often squeezed into tight shoes or neglected altogether. It's time to give our feet the attention they deserve with these happy feet tips. Whether you're a marathon runner or someone who just enjoys a leisurely walk, these tips will help keep your feet healthy, comfortable, and happy.`;

  useEffect(() => {
    handlePatientRecords();
    // generateInfoText();
  }, []);

  // const generateInfoText = () => {
  //   let currentIndex = 0;
  //   const interval = setInterval(() => {
  //     setInfoText((prev) => prev + fullText[currentIndex]);
  //     currentIndex++;
  //     if (currentIndex === fullText.length) {
  //       clearInterval(interval);
  //     }
  //   }); // Adjust the speed of typing effect here
  // };

  const handlePatientRecords = async () => {
    let newMedicalRecords = [];
    setLoader(true);
    try {
      const records = await ServiceCall.getv2(
        "/v2/doctor/list/patient/records/",
        `${appointment?.appointmentItem?.appointment_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (records) {
        setLoader(false);

        newMedicalRecords = records?.data?.medical_records?.reduce(
          (acc, curr) => {
            curr.appointment_id = appointment?.appointmentItem?.appointment_id;
            acc.push(curr);
            return acc;
          },
          []
        );
        setMedicalRecords(newMedicalRecords);
      }
    } catch (err) {
      setLoader(false);
      doctorContext?.setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const DoctorsAppointmentList = ({ classes }) => {
    function renderAppointmentList(item) {
      return (
        <DoctorsAppointmentListItemNew
          index={0}
          tabRoute="patientRecords"
          key={appointment?.appointmentItem?.appointment_id}
          appointmentItem={appointment?.appointmentItem}
          classes={classes}
        />
      );
    }

    return (
      <>
        <Grid item xs={12}>
          {/* <FollowUpComponent appointmentDate={appointmentDate} /> */}
        </Grid>
        {renderAppointmentList()}
      </>
    );
  };

  return (
    <PageContentLayout>
      <div className="prescriptionpage" style={{ marginTop: '1rem' }}>
        <Navigation
          text="Patient Records"
          navClass="appointmentpage__navigation"
          handleClick={() => {
            history.push({
              pathname: "/my-appointments",
              search: history?.location?.search,
            });
          }}
        />

        {/* Add Textbox */}
        {/* <TextField
          label="Information"
          variant="outlined"
          fullWidth
          value={infoText}
          multiline
          rows={6}
          InputProps={{
            readOnly: true,
          }}
          style={{ marginBottom: '1rem' }}
        /> */}

        {appointment ? (
          <DoctorsAppointmentList
            appointmentDate={
              appointment?.appointmentItem?.appointment_date_formatted
            }
            classes={appointment?.classes}
          />
        ) : (
          <div>
            <Stack
              spacing={2}
              style={{ width: "96%" }}
              className="d-flex justify-content-around flex-row align-items-center m-auto"
            >
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="rectangular" width="80%" height={60} />
            </Stack>
          </div>
        )}
        <div className="mt-5">
          {loader ? (
            <>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
              <Stack
                spacing={2}
                style={{ width: "96%" }}
                className="d-flex justify-content-around flex-row align-items-center m-auto"
              >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="rectangular" width="80%" height={60} />
              </Stack>
            </>
          ) : (
            <>

            {/* <TextField
                   label="Information"
                   variant="outlined"
                   fullWidth
                   value={infoText}
                   multiline
                   rows={6}
                   InputProps={{
                     readOnly: true,
                   }}
                   inputProps={{
                    style: {
                      fontSize: '14px',  // Adjust this value as needed
                      fontWeight: 700,   // Normal weight, increase for bolder text
                    },
                  }}                
         
          style={{ marginBottom: '1rem', marginTop: "-3rem", fontSize: "14px", fontWeight: "700" }}
        /> */}
              {medicalRecords?.map((record, index) => (
                <PatientRecordsList key={index} records={record} index={index} />
              ))}
            </>
          )}
        </div>
      </div>
    </PageContentLayout>
  );
};

export default PatientRecords;






