import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TooltipComponent from "./TooltipComponent";
import NotesIcon from "@mui/icons-material/Notes";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const Refredpro = ({ treatData, handleDeletePrompt }) => {
  const [loader, setLoader] = useState(false);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  if (treatData && Array.isArray(treatData) && treatData.length > 0) {
    return (
      <TableContainer component={Paper} style={{marginBottom: ".5rem"}}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ textAlign: "center" }}>
              SN
            </StyledTableCell>
            {/* <StyledTableCell style={{ textAlign: "center" }}>
                Salutation
            </StyledTableCell> */}
            <StyledTableCell style={{ textAlign: "center" }}>
              Name
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: "center" }}>
                Speciality
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: "center" }}>
              Email
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: "center" }}>
              Mobile
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: "center" }}>
              Address
            </StyledTableCell>
            {/* <StyledTableCell style={{ textAlign: "center" }}>
                Messages
            </StyledTableCell> */}
         

            <StyledTableCell
              style={{ textAlign: "center" }}
            ></StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {treatData &&
            Array.isArray(treatData) &&
            treatData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell style={{ textAlign: "center" }}>
                  {index + 1}
                </StyledTableCell>
                {/* <StyledTableCell style={{ textAlign: "center" }}>
                  {row?.salutation || ""}
                </StyledTableCell> */}
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ textAlign: "center" }}
                >
                  {row?.salutation || ""}{""}{row?.name || ""}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  {row?.speciality || ""}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  {row?.email || ""}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  {row?.mobile || ""}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  {row?.address || ""}
                </StyledTableCell>
                {/* <StyledTableCell style={{ textAlign: "center" , display: "flex", alignItems: "center", justifyContent: "center"}}>

                  <div
                    style={{ gap: "0.2rem"}}
                    className="d-flex align-items-center"
                  >
                    {row?.message ? (
                      <TooltipComponent
                        icon={
                          <NotesIcon
                            sx={{
                              width: "100%",
                              color: "#199a8e",
                              fontSize: "2rem",
                            }}
                          />
                        }
                        title={row?.message}
                      />
                    ) : (
                      <NotesIcon
                        sx={{
                          width: "100%",
                          color: "#dddddd",
                          fontSize: "2rem",
                        }}
                        disabled
                      />
                    )}
                  </div>
                </StyledTableCell> */}
            
             
                <StyledTableCell style={{ textAlign: "center" }}>
                  <RemoveCircleOutlineIcon
                    onClick={() => {
                      handleDeletePrompt(row, index);
                    }}
                    sx={{ fontSize: "15px" }}
                  />{" "}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>

    );
  }

  return <></>;
};

export default Refredpro;