import React, { useState, useEffect, useContext } from "react";
import PageContentLayout from "../../components/PageContentLayout";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import LineChart from "./LineChart";
import AccountIcon from "../../static/images/AccountIcon.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Overlay from "../../components/Overlay";
import { PatientpageContext } from "../../context/GlobalContext";
import MaleFrontBody from "../../components/MaleFrontbody";
import MaleBackBody from "../../components/MaleBackbody";
import FemaleFrontBody from "../../components/FemaleFrontBody";
import FemaleBackBody from "../../components/FemaleBackBody";
import color from "../../components/color";
import SelectEmoji from "../../components/SelectEmoji";

const Vital1 = () => {
  const history = useHistory("");
  const location = useLocation();
  const doctorContext = useContext(PatientpageContext);
  const patientId = sessionStorage.getItem("profileid");
  const patientname = sessionStorage.getItem("patientname");
  const patientage = sessionStorage.getItem("patientage");
  const patientgen = sessionStorage.getItem("patientgen");

  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [graphData, setGraphData] = useState([]);
  const [graphName, setGraphName] = useState("");
  const [measureName, setMeasureName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedButton, setSelectionButton] = useState("");
  const [count, setCount] = useState();
  const [bodyPartRec, setBodypartRec] = useState([]);
  const [bodyPart, setBodypart] = useState([]);
  const [isMale, setIsMale] = useState(true);
  const [isFront, setIsFront] = useState(true);
  const [loading, setLoading] = useState();
  const [painPoints, setPainPoints] = useState([]);
  const [painChartData, setPainChartData] = useState({ labels: [], datasets: [] });
  const [selectedBodyPart, setSelectedBodyPart] = useState(null);
  useEffect(() => {
    const fetchChartData = async () => {
      setIsLoading(true);
      try {
        const charts = await ServiceCall.getv2(
          "/v2/doctor/patient/master/trackers/",
          patientId,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (charts) {
          const buttonList = charts?.data?.records;

          const hasDiastolic = buttonList.some(bl => bl.bio_marker === "blood_pressure_dias");
          const hasSystolic = buttonList.some(bl => bl.bio_marker === "blood_pressure_syst");

          const filteredButtonList = buttonList.filter(
            (bl) => bl.bio_marker !== "blood_pressure_dias" && bl.bio_marker !== "blood_pressure_syst"
          );

          if (hasDiastolic && hasSystolic) {
            filteredButtonList.push({
              bio_marker: "blood_pressure",
              display_name: "Blood Pressure",
              measuring_unit: "mmHg"
            });
          }

          setGraphData(filteredButtonList);
          if (filteredButtonList.length > 0) {
            const initialBioMarker = filteredButtonList[0]?.bio_marker;
            const initialDisplayName = filteredButtonList[0]?.display_name;
            const initialMeasure = filteredButtonList[0]?.measuring_unit;

            setSelectionButton(initialBioMarker);
            setGraphName(initialDisplayName);
            setMeasureName(initialMeasure);
            fetchPatientData(initialBioMarker);
          }
        }
      } catch (err) {
        console.error("Error fetching chart data:", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchChartData();
  }, [patientId]);

  const fetchPatientData = async (bio_marker) => {
    setIsLoading(true);
    try {
      let datasets = [];
      if (bio_marker === "blood_pressure") {
        const systResponse = await ServiceCall.getv2(
          "/v2/doctor/fetch/bio/trend/",
          `${patientId}/blood_pressure_syst`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const diasResponse = await ServiceCall.getv2(
          "/v2/doctor/fetch/bio/trend/",
          `${patientId}/blood_pressure_dias`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        const systolicRecords = systResponse?.data?.records || [];
        const diastolicRecords = diasResponse?.data?.records || [];

        const labels = diastolicRecords.map(
          (record) => `${record.measured_date} ${record.measured_time}`
        );

        datasets = [
          {
            label: "Systolic",
            data: systolicRecords.map((record) => parseFloat(record.latest_measure)),
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
          },
          {
            label: "Diastolic",
            data: diastolicRecords.map((record) => parseFloat(record.latest_measure)),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ];

        setChartData({ labels, datasets });
      } else {
        const patientSelect = await ServiceCall.getv2(
          "/v2/doctor/fetch/bio/trend/",
          `${patientId}/${bio_marker}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const records = patientSelect?.data?.records || [];
        const labels = records.map(
          (record) => `${record.measured_date} ${record.measured_time}`
        );
        datasets = [
          {
            label: bio_marker,
            data: records.map((record) => parseFloat(record.latest_measure)),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ];
        setChartData({ labels, datasets });
      }
    } catch (err) {
      console.error("Error fetching patient data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = () => {
    const sourceRoute = location.state?.sourceRoute;

    if (sourceRoute === 'write-prescriptions') {
      history.push('/write-prescriptions');
    } else if (sourceRoute === 'dental-prescriptions') {
      history.push('/dental-prescriptions');
    } else {
      history.push('/my-patients');
    }
  };

  const fetchPainAreas = async(bio_marker) => {
    try {
      const fetchchronic = await ServiceCall.getv2('/v2/doctor/list/patient/pain/areas/', 
        `${patientId}/${bio_marker}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if(fetchchronic){
        const dataa = fetchchronic.data
        setBodypartRec(dataa?.records)
        const uniquePainPoints = [...new Set(dataa?.records.map(record => record.body_part_no))];
        setPainPoints(uniquePainPoints);
      }
    } catch(err) {
      console.error("Error fetching pain areas:", err);
    }
  };

  const handleButtonClick = (bio_marker, display_name, measuring_unit) => {
    setSelectionButton(bio_marker);
    setGraphName(display_name);
    setMeasureName(measuring_unit);
    
    if (bio_marker === "chronic_pain") {
      fetchPainAreas(bio_marker);
    } else {
      fetchPatientData(bio_marker);
    }
  };

  const handleBodyParts = async (bodyPartNo) => {
    try {
      setIsLoading(true);
      setSelectedBodyPart(bodyPartNo);
      const response = await ServiceCall.getv2(
        `/v2/doctor/patient/cronic/pain/trend/`,
        `${patientId}/${selectedButton}/${bodyPartNo}/10`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (response) {
        const datas = response.data.records;
        const labels = datas.map(
          (item) => `${item.measured_date} ${item.measured_time}`
        );
        const datasets = [
          {
            label: "Pain Intensity",
            data: datas.map((item) => parseFloat(item.latest_measure)),
            borderColor: 'rgba(0, 0, 255, 1)', // Changed to blue
            backgroundColor: 'rgba(0, 0, 255, 0.2)', // Changed to blue with opacity
          },
        ];
        setPainChartData({ labels, datasets });
      }
    } catch (error) {
      console.error("Error fetching pain trend data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFront = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000)
    setIsFront(previousState => !previousState);
  };

  const toggleGender = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000)
    setIsMale(previousState => !previousState);
  };

  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <PageContentLayout>
        <div className="vitals">
          <div className="pnh-upr" onClick={handleBackClick}>
            <ArrowBackIosIcon style={{ cursor: "pointer" }} />
            <img src={AccountIcon} alt="accountIcon" />
            <p>{patientname}</p>
            <span className="pnh-time">
              ({patientage}, {patientgen})
            </span>
          </div>
          {graphData && Array.isArray(graphData) && graphData.length > 0 ? (
            <div className="query_container">
              <div className="prescription_toolbar">
                {graphData.map((bl) => (
                  <button
                    key={bl.bio_marker}
                    className={`prescription_toolbar_button ${
                      bl.bio_marker === selectedButton
                        ? "prescription_toolbar_button_selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleButtonClick(
                        bl?.bio_marker,
                        bl?.display_name,
                        bl?.measuring_unit
                      )
                    }
                  >
                    <span>{bl.display_name}</span>
                  </button>
                ))}
              </div>
            </div>
          ) : null}
  
          {selectedButton === 'chronic_pain' ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
              <div style={{ flex: 1, marginRight: '20px' }}>
                <div style={{ marginBottom: '10px' }}>
                  <p style={{ fontSize: '14px', color: '#666' }}>
                    Click on body part to see pain intensity
                  </p>
                </div>
                <div
                  style={{
                    backgroundColor: '#e0e0e0',
                    width: '100%',
                    height: '500px',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={isFront}
                        onChange={toggleFront}
                        style={{
                          appearance: 'none',
                          width: '40px',
                          height: '20px',
                          backgroundColor: isFront ? "#81b0ff" : "#ccc",
                          borderRadius: '9999px',
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      />
                      <span
                        style={{
                          position: 'absolute',
                          top: '15px',
                          left: isFront ? '34px' : '18px',
                          width: '16px',
                          height: '16px',
                          borderRadius: '9999px',
                          backgroundColor: color.primary,
                          transition: 'all 0.3s ease',
                        }}
                      />
                      <span style={{ marginLeft: '10px' }}>{isFront ? "Front" : "Back"}</span>
                    </div>
  
                  </div>
    
                  {patientgen && (patientgen.toLowerCase() === 'male' 
              ? (isFront 
                  ? <MaleFrontBody pointAction={handleBodyParts} painList={count} currentPoint={painPoints} selectedPoint={selectedBodyPart} />
                  : <MaleBackBody pointAction={handleBodyParts} painList={count} currentPoint={painPoints} selectedPoint={selectedBodyPart} />
                )
              : (isFront
                  ? <FemaleFrontBody pointAction={handleBodyParts} painList={count} currentPoint={painPoints} selectedPoint={selectedBodyPart} />
                  : <FemaleBackBody pointAction={handleBodyParts} painList={count} currentPoint={painPoints} selectedPoint={selectedBodyPart} />
                )
            )}
                </div>
              </div>
              
              <div style={{ flex: 1, marginTop: "10rem" }}>
              {selectedBodyPart && (
              <LineChart
                data={painChartData}
                name=""
                measurename=""
                isLoading={isLoading}
                isPainData={true}
                selectedBodyPart={selectedBodyPart}
              />
            )}

              </div>
            </div>
          ) : (
            <LineChart
              data={chartData}
              name={graphName}
              measurename={measureName}
              isLoading={isLoading}
            />
          )}
        </div>
      </PageContentLayout>
    </>
  );
};

export default Vital1;
 