import React from "react";
import Container from "@material-ui/core/Container";
import { Grid } from "@mui/material";

export default function HospitalErrorPage() {
  return (
    <Container component='main' maxWidth='xs'>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Error in getting hospital details</h3>
        </Grid>
      </Grid>
    </Container>
  );
}
