import React, { useEffect } from 'react';
import PrescriptionInput from './PrescriptionInput';
import { Box, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function PrescriptionEditor({
  fetchType,
  editable,
  placeholder,
  changeHandler,
  asyncData,
  classes,
  oldValue,
  pdataObj,
  receiveNotes,
  listQuery,
  icon=""
}) {
  const [selectedValue, setSelectedValue] = React.useState([]);
  function addSearchValue(value, key) {
    let oldValue = [...selectedValue];
    oldValue.push(value[key]);
    setSelectedValue(oldValue);
    changeHandler(oldValue, fetchType);
  }
  function removeItem(index) {
    let oldValue = [...selectedValue];
    oldValue.splice(index, 1);
    setSelectedValue(oldValue);
    changeHandler(oldValue, fetchType);
  }
  function updateEditData(index, content) {
    // console.log(index + "-index");
    // console.log(content + "-content");
    let oldValue = [...selectedValue];
    // console.log(oldValue);
    oldValue[index] = content;
    setSelectedValue(oldValue);
    changeHandler(oldValue, fetchType);
  }
  useEffect(() => {
    if (Array.isArray(receiveNotes) && receiveNotes.length > 0) {
      setSelectedValue(receiveNotes);
    }
  }, [receiveNotes]);
  
  useEffect(() => {
    console.log(receiveNotes);
  
    if (Array.isArray(receiveNotes)) {
      setSelectedValue(receiveNotes);
      changeHandler(receiveNotes, fetchType);
    }
  }, [receiveNotes]);
  



  // useEffect(() => {
  //   if (receiveNotes.length > 0) {
  //     setSelectedValue(receiveNotes);
  //   }
  // }, [receiveNotes]);
  // useEffect(() => {
  //   console.log(receiveNotes);

  //   setSelectedValue(receiveNotes);
  //   changeHandler(receiveNotes, fetchType);
  // }, [receiveNotes]);

  
  return (
    <Box component={Paper} className="p-2">
      <PrescriptionInput
        // appointment_id={doctorContext.joinActive[0].appointment_id}
        fetchType={fetchType}
        label="Input Reported Problems"
        onSelect={addSearchValue}
        placeholder={placeholder}
        asyncData={asyncData}
        pdataObj={pdataObj}
        icon={icon}
        listQuery={listQuery}
      />
      <Box className="textContent">
        {selectedValue.length > 0 && (
          <div>
            {selectedValue.map((item, index) => (
              <Box
                sx={{}}
                key={index}
                className={`d-flex mb-1 ${classes?.listItem} font-12 prescription__input__reports`}
              >
                {editable ? (
                  <div
                    contentEditable
                    onBlur={(e) => updateEditData(index, e.target.innerText)}
                    suppressContentEditableWarning={true}
                    style={{ flexBasis: '100%' }}
                    className="d-flex align-items-center py-2 px-3"
                  >
                    {item}
                  </div>
                ) : (
                  <div
                    style={{ flexBasis: '100%' }}
                    className="d-flex align-items-center px-2"
                  >
                    {item}
                  </div>
                )}
                <IconButton
                  size="small"
                  className="font-12"
                  onClick={() => removeItem(index)}
                >
                  <CloseIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </Box>
            ))}
          </div>
        )}
      </Box>
    </Box>
  );
}
