import React, { useState, useEffect, useContext, useRef } from "react";
import PageContentLayout from "./PageContentLayout";
import DoctorsAppointmentListItemNew from "./DoctorsAppointmentListItemNew";
import { PatientpageContext } from "../context/GlobalContext";
import { Ls } from "../utility/utility";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Modal, Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";
import { constants } from "../utility/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PrescriptionShareIcon from "../static/images/prescriptionShare.png";

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  tableCellClasses,
  Checkbox,
} from "@mui/material";
import { ServiceCall } from "../utility/service-call";
import { styled } from "@mui/material/styles";
import { RemoveCircle } from "@mui/icons-material";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiCall } from "../utility/service-call";
import { OverlayType } from "../OverlayManager/Context";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { OverlayContext } from "../OverlayManager/Overlay";
import Overlay from "./Overlay";
import SendIcon from "@mui/icons-material/Send";

const AddFile = () => {
  const doctorContext = useContext(PatientpageContext);
  const Overlays = useContext(OverlayContext);

  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const appointmntid = appointment?.appointmentItem?.appointment_id;
  const case_file_id = sessionStorage.getItem("case_file_id");
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const [open, setOpen] = useState(false);
  const history = useHistory("");
  const [errors, setErrors] = useState({
    file: "",
    fileType: "",
    description: "",
  });
  const setState = doctorContext.setState;
  const [fileType, setFileType] = useState("xray");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // New state to store selected file
  const [newfile, setNewfile] = useState("");
  const [showLoader, setShowLoader] = useState(false); // State to control loader visibility
  const [allFilesShared, setAllFilesShared] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedFileCodes, setSelectedFileCodes] = useState([]);
  const selectdata = selectedFileCodes;
  const [sharePrescription, setSharePrescription] = useState(false);
  const [bookletChoices, setBookletChoices] = useState(false);
  const reference = useRef();
  reference.current = selectdata;

  const checkAllFilesShared = (files) => {
    const allShared = files.every((file) => file.status);
    setAllFilesShared(allShared);
  };

  const fetchdata = async () => {
    setShowLoader(true);
    try {
      const res = await ServiceCall.getv2(
        "/v2/doctor/list/patient/file/",
        `${constants.hospitalId("hosp_id")}/${case_file_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (res && res.data && res.data.records) {
        setShowLoader(false);
        setNewfile(res.data.records);
        checkAllFilesShared(res.data.records); // Add this line
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  useEffect(() => {
    fetchdata(); // Call the function
  }, []);

  const handleDeletePrompt = async (row, index) => {
    setShowLoader(true);

    try {
      //   setDeletingFile(index);

      const response = await ServiceCall.deletev2(
        "/v2/doctor/delete/patient/file/",
        `${constants.hospitalId("hosp_id")}/${case_file_id}/${row.file_code}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (response) {
        setShowLoader(false);

        await fetchdata();
        setState({
          msg: response.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });

        // For example, update your state or UI
        console.log("File deleted successfully");
        // You might want to remove the deleted file from your state here
      } else {
        throw new Error(response.data?.message || "Failed to delete file");
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const DoctorsAppointmentList = ({ appointmentDate, classes }) => {
    return (
      <DoctorsAppointmentListItemNew
        index={0}
        tabRoute="prescription"
        key={appointment?.appointmentItem?.appointment_id}
        appointmentItem={
          doctorContext?.patientInfo || appointment?.appointmentItem
        }
        classes={classes}
      />
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileInputChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);

      // Remove file extension from the name
      const fileNameWithoutExtension = file.name.replace(
        /\.(pdf|jpg|jpeg|png)$/i,
        ""
      );
      setDescription(fileNameWithoutExtension);
    }
  };
  const handleSaveFile = async () => {
    // Check if all required fields are filled
    const newErrors = {
      file: !selectedFile ? "Please select a file" : "",
      fileType: !fileType ? "Please select a file type" : "",
      description: !description ? "Please enter a file name" : "",
    };

    setErrors(newErrors);

    // If any error exists, don't proceed with the save
    if (Object.values(newErrors).some((error) => error !== "")) {
      setState({
        msg: "Please fill all required fields",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    setShowLoader(true);

    try {
      const encodedFileType = encodeURIComponent(fileType);
      const encodedFileName = encodeURIComponent(description); // Use description as file name
      const formData = new FormData();
      formData.append("patient_file", selectedFile);

      const datasave = await ServiceCall.postv2(
        "/v2/doctor/upload/patient/file/",
        `${constants.hospitalId(
          "hosp_id"
        )}/${appointmntid}/${case_file_id}/${encodedFileType}/${encodedFileName}`,
        formData,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (datasave) {
        setOpen(false);
        setShowLoader(false);
        setDescription("");
        setFileType("");
        setSelectedFile("");
        await fetchdata(); // Refetch the data to update the table
        setState({
          msg: datasave.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      setShowLoader(false);
      setState({
        msg: "An error occurred while saving the file",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleSharePrescription = async () => {
    Loaders("Sharing File");
    try {
      const body = {
        file_code_list: reference.current,
      };
      const sharePrescription = await ServiceCall.putv2(
        "/v2/doctor/share/patient/files/",
        `${constants.hospitalId("hosp_id")}/${case_file_id}`,
        body,

        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (sharePrescription) {
        // setShowLoader(false)

        Overlays.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescripitionLoader">
              <img src={PrescriptionShareIcon} />
              <p>{sharePrescription?.data?.message}</p>
            </div>
          ),
          removeCloseButton: false,
          handleClose: function close() {
            history.push({
              pathname: "/add-files",
            });
          },
        });
        await fetchdata();
        setSelectedRows("");
      } else {
        Overlays.hide();
        setState({
          msg: "Failed to share prescription",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "An error occurred",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const Loaders = (text) => {
    Overlays.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoader">
          <h3>Please Wait...</h3>
          <p>{text}</p>
          <CircularProgress color="success" />
        </div>
      ),
      removeCloseButton: true,
    });
  };
  const handleback = () => {
    history.push({
      pathname: "/my-appointments",
    });
  };

  const handleDownloadPrescription = async (row) => {
    let url = "/v2/doctor/patient/file/download/";
    try {
      Overlays.show(OverlayType?.ModalWindow, {
        data: (
          <div className="prescripitionLoader">
            <h3>Please Wait...</h3>
            <CircularProgress color="success" />
          </div>
        ),
        removeCloseButton: true,
      });

      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: `${case_file_id}/${row?.file_code}`,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        let blob = new Blob([PrescriptionDownload.data], {
          type: PrescriptionDownload.data.type,
        });
        let url = window.URL.createObjectURL(blob);

        const fileExtension = row?.file_name.split(".").pop().toLowerCase();
        const isPDF = fileExtension === "pdf";
        const isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtension);
        Overlays.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              {isPDF ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={url} />
                </Worker>
              ) : isImage ? (
                <img
                  src={url}
                  alt="Prescription"
                  style={{ maxWidth: "100%", maxHeight: "80vh" }}
                />
              ) : (
                <div>Unsupported file type</div>
              )}
              <div className="prescription__pdf__container">
                <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a>
                <a
                  download={`prescription-${case_file_id}.${fileExtension}`}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {},
        });
      }
    } catch (err) {
      console.log("recordsError", err);
      Overlays.hide();
      err &&
        doctorContext.setState({
          msg: err?.response?.data?.message,
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };
  
  const Handleshare = async () => {
    const selectedFileCodes = selectedRows
      .map((index) => newfile[index]?.file_code)
      .filter(Boolean);
    setSelectedFileCodes(selectedFileCodes);

    let bookletChoicess = null;

    try {
      bookletChoicess = await new Promise((resolve, reject) => {
        Overlays.show(OverlayType?.ModalWindow, {
          data: (
            <div
              style={{
                position: "fixed",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                  boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
                  padding: "1.5rem",
                  maxWidth: "25rem",
                  width: "100%",
                  marginTop: "9rem",
                }}
              >
                <h2
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    textAlign: "center",
                    color: "#1f2937",
                    marginTop: "-1rem",
                  }}
                >
                  Print Options
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <button
                    onClick={() => resolve(true)}
                    style={{
                      backgroundColor: "rgb(16 149 137)",
                      color: "white",
                      fontWeight: "600",
                      padding: "0.5rem 1rem",
                      borderRadius: "0.25rem",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "rgb(15 112 103)")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "rgb(16 149 137)")
                    }
                  >
                    With Booklet
                  </button>
                  <button
                    onClick={() => resolve(false)}
                    style={{
                      backgroundColor: "#d1d5db",
                      color: "#1f2937",
                      fontWeight: "600",
                      padding: "0.5rem 1rem",
                      borderRadius: "0.25rem",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#9ca3af")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#d1d5db")
                    }
                  >
                    Without Booklet
                  </button>
                </div>
              </div>
            </div>
          ),
          handleClose: () => reject(new Error("User closed the dialog")),
        });
      });
    } catch (error) {
      // User closed the dialog, so we just return from the function
      Overlay.hide();
      return;
    }
    setBookletChoices(bookletChoicess);

    const body = {
      file_code_list: selectedFileCodes,
      print_booklet: bookletChoicess,
    };

    let url = "/v2/doctor/fetch/patient/case/file/";
    Loaders("");

    try {
      const PrescriptionGenerate = await ServiceCall.postv2(
        "v2/doctor/generate/patient/case/file/",
        `${constants.hospitalId("hosp_id")}/${case_file_id}`,
        body,
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (PrescriptionGenerate) {
        try {
          const PrescriptionDownload = await apiCall({
            url,
            method: "get",
            responseType: "blob",
            hosID: `${constants.hospitalId("hosp_id")}/${case_file_id}`,
            headers: {
              doc_secret: Ls.get("docToken"),
              doc_key: Ls.get("docKey"),
            },
          });
          if (PrescriptionDownload) {
            Overlays.hide();
            let blob = new Blob([PrescriptionDownload.data], {
              type: "application/pdf",
            });
            let url = window.URL.createObjectURL(blob);
            Overlays.show(OverlayType?.ModalWindow, {
              data: (
                <div className="prescription__pdf">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={url} />
                  </Worker>
                  <div className="prescription__pdf__container">
                    <a
                      href={url}
                      target="_blank"
                      className="prescription__pdf__button"
                    >
                      <LocalPrintshopIcon />
                    </a>
                    <a
                      download={`prescription-${case_file_id}`}
                      href={url}
                      className="prescription__pdf__button"
                    >
                      <DownloadForOfflineIcon />
                    </a>
                    <div
                      className="prescription__pdf__button"
                      onClick={handleSharePrescription}
                      style={{ fontSize: "1.4rem" }}
                    >
                      <SendIcon
                        sx={{ fontSize: "20px", marginLeft: ".4rem" }}
                      />
                    </div>
                  </div>
                </div>
              ),
              handleClose: () => {},
            });
            setSharePrescription(true);
          } else {
            return PrescriptionDownload.text();
          }
        } catch (err) {
          Overlays.hide();
          setState({
            msg: err?.message || "",
            type: "error",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      }
    } catch (err) {
      Overlays.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };
  const isDisabled = selectedRows.length === 0;



  return (
    <>
      {showLoader && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <PageContentLayout>
        <div className="">
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              fontSize: "22px",
              cursor: "pointer",
            }}
            onClick={handleback}
          >
            <ArrowBackIosNewIcon
              style={{ marginTop: "-0.5rem", marginBottom: "-0.5rem" }}
            />
            Add File
          </h1>
          <div className=""></div>
          <DoctorsAppointmentList
            appointmentDate={
              appointment?.appointmentItem?.appointment_date_formatted
            }
            classes={appointment?.classes}
          />
          <Button
            variant="contained"
            style={{
              background: "#199a8e",
              color: "#fff",
              marginTop: "1rem",
              marginLeft: "1rem",
              textAlign: "center",
            }}
            onClick={handleOpen}
          >
            Add File+
          </Button>

          {newfile && Array.isArray(newfile) && newfile.length > 0 ? (
            <>
              <TableContainer
                component={Paper}
                style={{ marginBottom: ".5rem", marginTop: "1rem" }}
              >
                <Table sx={{ minWidth: 300 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ textAlign: "center" }}
                      ></StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        Updated Date
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        File Name
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        File Type
                      </StyledTableCell>
                      <StyledTableCell style={{ textAlign: "center" }}>
                        Status
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ textAlign: "center" }}
                      ></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newfile.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {(row?.file_type !== "PRESCRIPTION" ||
                            (row?.file_type === "PRESCRIPTION" &&
                              row?.status === false)) && (
                            <Checkbox
                              checked={selectedRows.includes(index)}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.upload_date || ""}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            color: "blue",
                          }}
                          onClick={() => handleDownloadPrescription(row)}
                        >
                          {row?.file_name || ""}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{ textAlign: "center" }}
                        >
                          {row?.file_type || ""}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {row?.status ? "SHARED" : "PENDING" || ""}
                        </StyledTableCell>
                        {!row?.status && (
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <RemoveCircle
                              onClick={() => {
                                handleDeletePrompt(row, index);
                              }}
                              sx={{ fontSize: "15px" }}
                            />
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    textAlign: "center",
                    background: isDisabled ? "#cccccc" : "#199a8e", // Gray when disabled, original color when enabled
                    color: isDisabled ? "#666666" : "#fff", // Darker text when disabled for better contrast
                    textAlign: "center",
                    padding: "10px 12px",
                    cursor:  "pointer", // Change cursor when disabled
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                  onClick={Handleshare}
                  disabled={selectedRows.length === 0}

                  // disabled={allFilesShared}
                >
                  View/Generate
                </Button>
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center", marginTop: "1rem" }}></div>
          )}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              className=""
              style={{ float: "inline-end", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </div>
            <div className="mt-3">
              <span
                style={{
                  textAlign: "center",
                  fontSize: "0.875rem",
                  fontWeight: "500",
                }}
              >
                Click on upload icon to browse & select file. Select type and
                edit name as per your choice.
              </span>
              <FormControl
                fullWidth
                margin="normal"
                style={{ border: "1px solid" }}
              >
                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  placeholder="Add File Case"
                />
                <label
                  htmlFor="file-input"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                  }}
                >
                  {selectedFile ? `File selected` : "Add File Case"}
                  {/* : ${selectedFile.name} */}
                  <DriveFolderUploadIcon
                    style={{ float: "inline-end", margin: "16px" }}
                  />
                </label>
              </FormControl>
              {errors.file && (
                <div
                  style={{
                    color: "red",
                    marginTop: "-8px",
                    marginBottom: "8px",
                  }}
                >
                  {errors.file}
                </div>
              )}

              <FormControl fullWidth margin="normal">
                <InputLabel id="file-type-label">File Type</InputLabel>
                <Select
                  labelId="file-type-label"
                  id="file-type-select"
                  value={fileType}
                  label="File Type"
                  onChange={handleFileTypeChange}
                >
                  <MenuItem value="prescription">Prescription</MenuItem>
                  <MenuItem value="labreport">Lab Report</MenuItem>
                  <MenuItem value="xray">X-ray</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              {errors.fileType && (
                <div
                  style={{
                    color: "red",
                    marginTop: "-8px",
                    marginBottom: "8px",
                  }}
                >
                  {errors.fileType}
                </div>
              )}

              <TextField
                fullWidth
                margin="normal"
                id="file-description"
                label="File Name"
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
              />
              {errors.description && (
                <div
                  style={{
                    color: "red",
                    marginTop: "-8px",
                    marginBottom: "8px",
                  }}
                >
                  {errors.description}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleSaveFile}
                  variant="contained"
                  style={{
                    background: "#199a8e",
                    color: "#fff",
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    textAlign: "center",
                    padding: "10px 12px",
                  }}
                >
                  SAVE
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </PageContentLayout>
    </>
  );
};
export default AddFile;
