import { useState, useEffect } from "react";
import PageContentLayout from "../../components/PageContentLayout";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import LineChart from "./LineChart";
import AccountIcon from "../../static/images/AccountIcon.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Overlay from "../../components/Overlay";

const Vital = () => {
  const history = useHistory("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const prescription = location.state?.prescription;
  // const patientId = prescription?.pt_profile_id;
  const patientId = sessionStorage.getItem("profileid");
  const patientname = sessionStorage.getItem("patientname");
  const patientage = sessionStorage.getItem("patientage");
  const patientgen = sessionStorage.getItem("patientgen");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [graphData, setGraphData] = useState([]);
  const [graphName, setGraphName] = useState("");
  const [measureName, setMeasureName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedButton, setSelectionButton] = useState("");

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const charts = await ServiceCall.getv2(
          "/v2/doctor/patient/master/trackers/",
          patientId,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (charts) {
          const buttonList = charts?.data?.records;

          // Check for the existence of both diastolic and systolic blood pressure
          const hasDiastolic = buttonList.some(bl => bl.bio_marker === "blood_pressure_dias");
          const hasSystolic = buttonList.some(bl => bl.bio_marker === "blood_pressure_syst");

          // Filter out individual blood pressure markers
          const filteredButtonList = buttonList.filter(
            (bl) => bl.bio_marker !== "blood_pressure_dias" && bl.bio_marker !== "blood_pressure_syst"
          );

          // Add combined "Blood Pressure" button if both markers are present
          if (hasDiastolic && hasSystolic) {
            filteredButtonList.push({
              bio_marker: "blood_pressure",
              display_name: "Blood Pressure",
              measuring_unit: "mmHg"
            });
          }

          setGraphData(filteredButtonList);
          if (filteredButtonList.length > 0) {
            const initialBioMarker = filteredButtonList[0]?.bio_marker;
            const initialDisplayName = filteredButtonList[0]?.display_name;
            const initialMeasure = filteredButtonList[0]?.measuring_unit;

            setSelectionButton(initialBioMarker);
            setGraphName(initialDisplayName);
            setMeasureName(initialMeasure);
            fetchPatientData(initialBioMarker);
          }
        }
      } catch (err) {}
    };
    fetchChartData();
  }, [patientId]);

  const fetchPatientData = async (bio_marker) => {
    setIsLoading(true);
    try {
      let datasets = [];
      if (bio_marker === "blood_pressure") {

        const systResponse = await ServiceCall.getv2(
          "/v2/doctor/fetch/bio/trend/",
          `${patientId}/blood_pressure_syst`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const diasResponse = await ServiceCall.getv2(
          "/v2/doctor/fetch/bio/trend/",
          `${patientId}/blood_pressure_dias`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        
        const systolicRecords = systResponse?.data?.records || [];
        const diastolicRecords = diasResponse?.data?.records || [];
        
        const labels = diastolicRecords.map(
          (record) => `${record.measured_date} ${record.measured_time}`
        );

        datasets = [
          
          {
            label: "Systolic",
            data: systolicRecords.map((record) => parseFloat(record.latest_measure)),
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
          },
          {
            label: "Diastolic",
            data: diastolicRecords.map((record) => parseFloat(record.latest_measure)),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ];

        setChartData({ labels, datasets });
      } else {
        const patientSelect = await ServiceCall.getv2(
          "/v2/doctor/fetch/bio/trend/",
          `${patientId}/${bio_marker}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const records = patientSelect?.data?.records || [];
        const labels = records.map(
          (record) => `${record.measured_date} ${record.measured_time}`
        );
        datasets = [
          {
            label: bio_marker,
            data: records.map((record) => parseFloat(record.latest_measure)),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
        ];
        setChartData({ labels, datasets });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleBackClick = () => {
    history.push("/my-patients");
  };

  const handleButtonClick = (bio_marker, display_name, measuring_unit) => {
    setSelectionButton(bio_marker);
    setGraphName(display_name);
    fetchPatientData(bio_marker);
    setMeasureName(measuring_unit);
  };

  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <PageContentLayout>
        <div className="vitals">
          <div className="pnh-upr" onClick={handleBackClick}>
            <ArrowBackIosIcon style={{ cursor: "pointer" }} />
            <img src={AccountIcon} alt="accountIcon" />
            <p>{patientname}</p>
            <span className="pnh-time">
              ({patientage}, {patientgen})
            </span>
          </div>
          {graphData && Array.isArray(graphData) && graphData.length > 0 ? (
            <div className="query_container">
              <div className="prescription_toolbar">
                {graphData.map((bl) => (
                  <button
                    key={bl.bio_marker}
                    className={`prescription_toolbar_button ${
                      bl.bio_marker === selectedButton
                        ? "prescription_toolbar_button_selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleButtonClick(
                        bl?.bio_marker,
                        bl?.display_name,
                        bl?.measuring_unit
                      )
                    }
                  >
                    <span>{bl.display_name}</span>
                  </button>
                ))}
              </div>
            </div>
          ) : null}
          <LineChart
            data={chartData}
            name={graphName}
            measurename={measureName}
          />
          {/* <div>
            <h1>Human Body Chart</h1>
            <HumanBodyChart data={data2} />
          </div> */}
        </div>
      </PageContentLayout>
    </>
  );
};

export default Vital;
