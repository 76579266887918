import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import DoctorsAppointmentListItem from "./DoctorsAppointementListItem";
import { PatientpageContext } from "./../context/GlobalContext";
import dayjs from "dayjs";
import { Img } from "react-image";
import FilterIcon from "../static/images/prescriptionFilter.svg";
import { Ls } from "../utility/utility";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

export default function DoctorsAppointmentList({
  appointmentDate,
  item,
  classes,
  containerClass,
  noDataNavbar,
  tabRoute="",
  navigation,
  noNavBar,
}) {

  const doctorContext = useContext(PatientpageContext);
  const history = useHistory();

  const handleOnClick = (appointmentItem, index) => {
    doctorContext.setAppointment({
      appointmentItem: appointmentItem,
      classes: classes,
      selectedDetailIndex:index,
    });
    Ls.set("appointmentData", JSON.stringify({
      appointmentItem: appointmentItem,
      classes: classes,
    }));
    navigation &&
      history.push({
        pathname: navigation,
        search: history?.location?.search,
      });
  };

  function renderAppointmentList(item) {
    let listItem = [];
      listItem = <DoctorsAppointmentListItem
          tabRoute={tabRoute}
          appointmts={item}
          classes={classes}
          handleOnClick={handleOnClick}
          navigation={navigation}
        />
    return listItem;
  }


  return (
    <>
      {!noNavBar ? (
        <Grid item xs={12}>
          <div className={`Appointment__date ${containerClass}__date`}>
            <div>
              <span>{dayjs(appointmentDate).format("ddd,")}</span>
              {dayjs(appointmentDate).format("D MMM YYYY")}
            </div>
            {noDataNavbar && <Img src={FilterIcon} />}
          </div>
        </Grid>
      ) : (
        <></>
      )}
      {renderAppointmentList(item)}
    </>
  );
}
