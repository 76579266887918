import React, { useContext } from "react";
import { Dialog, Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PatientpageContext } from "../context/GlobalContext";
import ProfileForm from "../components/ProfileForm";
import PatientProfileForm from "../components/PatientProfileForm";

const DialogScreen = ({ page }) => {
  const doctorContext = useContext(PatientpageContext);
  const checkLoginPage = () => {
    if (doctorContext.checkLoginPage && page === "login") {
      return false;
    } else {
      return true;
    }
  };

  const renderDialogContent = (contentType, content) => {
    switch (contentType) {
      case 'profileForm':
        return <ProfileForm />; 
      case 'patientProfileForm':
        return <PatientProfileForm />;
      default: return content;
    }
  };

  return (
    <div>
      {" "}
      <Dialog
        disableEnforceFocus
        open={doctorContext.appDialog.open && checkLoginPage()}
        maxWidth={doctorContext.appDialog.maxWidth}
        // fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={doctorContext.handleDialogClose}
        disableEscapeKeyDown={true}
        scroll={doctorContext.appDialog.scroll}
      >
        <div
          className={`d-flex justify-content-flex-end pb-2 ${
            doctorContext.appDialog.promptWindow ? "pt-4" : ""
          }`}
        >
          {!doctorContext.appDialog.promptWindow && (
            <IconButton
              onClick={doctorContext.handleDialogClose}
              sx={{
                color: (theme) => theme?.palette?.black,
              }}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <div className="pb-5 px-3 px-sm-5 text-break">
          {doctorContext.appDialog.contentType
            ? renderDialogContent(
                doctorContext.appDialog.contentType,
                doctorContext.appDialog.content
              )
            : doctorContext.appDialog.content}
        </div>
        {doctorContext.appDialog.dialogAction && (
          <div className="pb-5 px-2 text-center">
            {doctorContext.appDialog.dialogAction}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default DialogScreen;
