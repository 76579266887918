import React, { useState, useEffect } from "react";
import PatientNoteIcon from "../../static/PatientNote.svg";
import AccountIcon from "../../static/images/AccountIcon.svg";
import Deleteicon from "../../static/Deleteicon.svg";
import SubmitIcon from "../../static/SubmitIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import PageContentLayout from "../../components/PageContentLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useHistory } from "react-router-dom";
import { ServiceCall } from "../../utility/service-call";
import { constants } from "../../utility/constants";
import { Ls } from "../../utility/utility";

const Doctornote = () => {
  const [newNote, setNewNote] = useState("");
  const [conversationData, setConversationData] = useState([]);
  const [patientName, setPatientName] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const profileId = location?.state?.profileId;

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    try {
      const response = await ServiceCall.getv2(
        "/v2/doctor/patient/all/conversation/",
        `${constants.hospitalId("hosp_id")}/${profileId?.pt_profile_id}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (response && response.data) {
        console.log("Received conversation data:", response.data);
        setConversationData(response.data.conversation);
        setPatientName(response.data.name);
      }
    } catch (err) {
      console.error("Error fetching conversation:", err);
    }
  };

  const handleInputChange = (e) => {
    setNewNote(e.target.value);
  };

  const handleSubmitNote = async () => {
    if (newNote.trim() !== "") {
      setIsLoading(true);
      try {
        const payload = {
          msg: newNote,
        };

        const response = await ServiceCall.postv2(
          "v2/doctor/patient/conversation/",
          `${constants.hospitalId("hosp_id")}/${profileId?.pt_profile_id}`,
          payload,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (response && response.data) {
          setNewNote("");
          await fetchdata(); // Refresh the conversation data
        }
      } catch (error) {
        console.error("Error submitting note:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleshift = () => {
    history.push("/my-messages");
  };

  console.log("Rendering with conversationData:", conversationData);

  return (
    <PageContentLayout>
      <div className="patient_note">
        {/* <div className="para" onClick={handleshift}>
          <h2>
            <ArrowBackIosIcon style={{marginTop:"7px"}}/>
          <img src={AccountIcon} alt="accountIcon" style={{height: "45px", width:"32px"}}/>
            {patientName}
          </h2>
        </div> */}

        <div className="pnh-inner" onClick={handleshift}>
          <ArrowBackIosIcon />

          <img src={AccountIcon} alt="accountIcon" />
          <p> {patientName}</p>
          {/* <span className="pnh-time">
            ({patientInfo.age}, {patientInfo.gender})
          </span> */}
        </div>
        <div style={{ height: "calc(86vh - 212px)", overflowY: "scroll" }}>
          {conversationData.length === 0 ? (
            <p>No messages yet</p>
          ) : (
            conversationData.map((dateGroup, dateIndex) => (
              <div key={dateIndex} className="date-group">
                <div className="date-header">
                  <span>{dateGroup.date}</span>
                </div>
                <div className="message-list">
                  {dateGroup.messages.map((msg, msgIndex) => (
                    <div key={msgIndex} className={`message ${msg.person}`}>
                      <div className="message-content">
                        <span className="message-text">{msg.chat}</span>
                      </div>
                      <span className="message-time">
                        {msg.message_on_time}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>

        <div className="write_notes">
          <input
            type="text"
            className="form-control"
            placeholder="Enter a new note"
            value={newNote}
            onChange={handleInputChange}
          />
          <button onClick={handleSubmitNote}>
            <img src={SubmitIcon} alt="submitIcon" />
          </button>
        </div>

        <Backdrop
          sx={{
            color: "#fff",
            opacity: "0.6",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
          onClick={() => {}}
        >
          <CircularProgress size="20px" color="inherit" />
        </Backdrop>
      </div>
    </PageContentLayout>
  );
};

export default Doctornote;
