import { useState, useEffect, useContext } from "react";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import PageContentLayout from "../../components/PageContentLayout";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import MedicationRoundedIcon from "@mui/icons-material/MedicationRounded";
import { OverlayContext } from "../../OverlayManager/Overlay";
import { OverlayType } from "../../OverlayManager/Context";
import PrescriptionNotes from "./PrescriptionNotes";
import { PatientpageContext } from "../../context/GlobalContext";
import Customprescription from "./Customprescription";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Overlay from "../../components/Overlay";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

const Customtemp = () => {
  const history = useHistory("");
  const [buttonList, setButtonList] = useState([]);
  const [selectedButton, setSelectionButtion] = useState("");
  const [tempdata, setTempdata] = useState([]);
  const [customTemplate, setCustomTemplate] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [prescriptiondata, setPrescriptiondata] = useState("");
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [openModal, setOpenModal] = useState(false); // State for managing modal open status
  const [openModal1, setOpenModal1] = useState(false); // State for managing modal open status

  const [newTemplateName, setNewTemplateName] = useState(""); // State for new template name
  const [newTemplateName1, setNewTemplateName1] = useState(""); // State for new template name
  const [isLoading, setIsLoading] = useState(true); // Initial state is loading

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const doctorContext = useContext(PatientpageContext);
  const setState = doctorContext.setState;
  const appointmentid = doctorContext?.patientInfo?.appointment_id;

  const overlay = useContext(OverlayContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const ChiefComplaint = await ServiceCall.getnewv2(
          "/v2/doctor/custom/template/buttons",
          "",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        // Handle the fetched data if necessary
        if (ChiefComplaint) {
          setIsLoading(false);

          const buttonList = ChiefComplaint?.data?.buttons;
          // const excludedButtons = ["Patient History","Share", "View/Generate"];
          // const filteredButtonList = buttonList.filter(
          //   (bl) => !excludedButtons.includes(bl.display_name)
          // );
          setButtonList(buttonList);
          setSelectionButtion(buttonList[0]?.button_action);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData(); // Invoke the fetchData function
  }, []);


  const fetchTemplates = async () => {
    setIsLoading(true);

    try {
      const tempdata = await ServiceCall.gett(
        "v2/doctor/prescription/templates",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (tempdata) {
        setIsLoading(false);

        setTempdata(tempdata.data);
      }
      // Add any additional logic to handle the fetched data
    } catch (err) {
      console.error("Error fetching templates:", err);
      // Add any additional error handling logic
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleTemplateChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTemplate(selectedValue);
    handleTemplateSelect(selectedValue);
  };

  const handleNewTemplateSelect = () => {
    if (selectedTemplate.trim()) {
      setIsDropdownDisabled(true); // Disable the dropdown
      // Handle the selection of the new template here
      // You might want to create a new template object and add it to your tempdata.templates array
      handleTemplateSelect(selectedTemplate);
    }
  };

  const handleTemplateSelect = async (prescriptionTemplateId) => {
    setIsLoading(true);
    try {
      const response = await ServiceCall.getv2(
        `v2/doctor/prescription/custom/templates/`,
        `${prescriptionTemplateId}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      // Handle the response data as needed
      if (response) {
        setSelectionButtion(buttonList[0]?.button_action);

        setIsLoading(false);

        setPrescriptiondata(response.data);
        // handlePostApi(response.data);
      }
    } catch (err) {
      console.error("Error fetching template data:", err);
    }
  };
  let templateNames = [];

  if (tempdata && tempdata.templates) {
    templateNames = tempdata.templates.map(
      (template) => template.template_name
    );
  }

  const hadledoctordata1 = () => {
    setOpenModal1(true);
  };

  const hadledoctordata = async () => {
    // setLoading(true);

    if (!selectedTemplate) {
      setOpenModal(true); // Show modal when no template is selected
    }
    try {
      // Get the selected prescription_template_id
      const selectedTemplateId = selectedTemplate;

      // If a template is selected
      if (selectedTemplateId) {
        // Get the response data from the GET API
        const responseData = prescriptiondata;

        // Extract the required data from the responseData object
        const requestPayload = {
          template_name: responseData.templates.template_name,
          medication_list: responseData.templates.medication_list,
          schedule_list: responseData.templates.schedule_list,
          patient_symptoms_list: responseData.templates.patient_symptoms_list,
          doc_findings_list: responseData.templates.doc_findings_list,
          doc_recommended_tests_list:
            responseData.templates.doc_recommended_tests_list,
        };

        const datadoctor = await ServiceCall.putv2(
          "/v2/doctor/prescription/templates/",
          selectedTemplateId,

          requestPayload,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        // Handle the response from the PUT API
        if (datadoctor) {
          setLoading(false);

          // Update the prescriptiondata state without overwriting existing data
          setPrescriptiondata((prevData) => ({
            ...prevData,
            templates: {
              ...prevData.templates,
              ...datadoctor.data,
            },
          }));

          // Preserve the selected template value
          setSelectedTemplate(selectedTemplateId);

          setState({
            msg: datadoctor?.data?.message || "",
            type: "success",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      } else {
        console.log("Please select a template first.");
      }
    } catch (err) {
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      console.error("Error in PUT API:", err);
    }
  };

  const handlePostApi = async (responseData) => {
    try {
      const requestPayload = {
        template_name: responseData.templates.template_name,
        medication_list: responseData.templates.medication_list,
        schedule_list: responseData.templates.schedule_list,
        patient_symptoms_list: responseData.templates.patient_symptoms_list,
        doc_findings_list: responseData.templates.doc_findings_list,
        doc_recommended_tests_list:
          responseData.templates.doc_recommended_tests_list,
      };
      const handlepost = await ServiceCall.putv2(
        "v2/doctor/prefilled/prescription/templates/",
        appointmentid,
        requestPayload,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      // Handle the response from the POST API if needed
      if (handlepost) {
        // console.log("POST API response:", handlepost.data);
        // Add any additional logic to handle the POST API response
      }
    } catch (err) {
      console.error("Error with POST API:", err);
      // Add any additional error handling logic
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const deletetemp = await ServiceCall.deletev2(
        "/v2/doctor/prescription/custom/templates/",
        `${templateId}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (deletetemp) {
        // Template deleted successfully
        // You can update the tempdata state to remove the deleted template
        const updatedTemplates = tempdata.templates.filter(
          (template) => template.prescription_template_id !== templateId
        );
        setTempdata({ ...tempdata, templates: updatedTemplates });

        // Show a success message or perform any other necessary actions
        setState({
          msg: deletetemp?.data?.message,
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      // Handle error
      console.error("Error deleting template:", err);
      setState({
        msg: err?.response?.data?.message || "Error deleting template",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleSaveNewTemplate = async () => {
    console.log("clicking");
    try {
      const requestPayload = {
        template_name: newTemplateName1, // Include the new template name from the state
      };
      const savetemp = await ServiceCall.posttttv2(
        "/v2/doctor/custom/template/name/",
        requestPayload,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      // Handle the response from the POST API if needed
      if (savetemp) {
        console.log("POST API response:", savetemp.data);

        // Update the selectedTemplate state with the prescription_template_id from the response
        setSelectedTemplate(savetemp.data.prescription_template_id);

        // Update the tempdata state with the new template data
        setTempdata((prevData) => ({
          ...prevData,
          templates: [
            ...prevData.templates,
            {
              prescription_template_id: savetemp.data.prescription_template_id,
              template_name: savetemp.data.template_name,
            },
          ],
        }));

        // Add any additional logic to handle the POST API response
        // For example, you can close the modal, reset the state, or update the template list
        setOpenModal1(false);
        setNewTemplateName1("");
      }
    } catch (err) {
      console.error("Error with POST API:", err);
      // Add any additional error handling logic
      setState({
        msg: err?.response?.data?.message || "Error saving template",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleCloseMo = () => {
    setOpenModal1(false);
  };
  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <PageContentLayout>
        <div style={{ margin: "0px" }}>
          <div
            className="appointment__container"
            style={{ background: "#e8f3f1", marginTop: "19px" }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              className="appointment__container__lastsection prescription__container__lastsection"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "19px 20px", // Add padding to create gap from the outer container
                  gap: "27px",
                }}
              >
                <span
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    lineHeight: "15px",
                    color: "#50555c",
                    marginTop: "1rem",
                  }}
                >
                  Click “New” to create template or select from drop down to
                  edit
                </span>
                <div
                  className="appointment__date__record"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                  }}
                  onClick={hadledoctordata1}
                  disabled={loading}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "New"
                    )}{" "}
                    <PostAddIcon
                      sx={{ fontSize: "25px", marginLeft: "0.5rem" }}
                    />
                  </span>
                </div>
                <FormControl
                  variant="filled"
                  style={{ flex: 2, minWidth: "250px" }}
                >
                  <InputLabel id="salutation-label">Select Template</InputLabel>
                  <Select
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    placeholder="Select The Template"
                    style={{ width: "100%" }}
                    disabled={isDropdownDisabled} // Disable the dropdown based on the state
                  >
                    {tempdata.templates && tempdata.templates.length > 0 ? (
                      tempdata.templates.map((template) => (
                        <MenuItem
                          key={template.prescription_template_id}
                          value={template.prescription_template_id}
                        >
                          {template.template_name}
                          {selectedTemplate !==
                            (template.prescription_template_id ||
                              template.template_name) && (
                            <RemoveCircleOutlineIcon
                              style={{
                                marginLeft: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteTemplate(
                                  template.prescription_template_id
                                )
                              }
                            />
                          )}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No templates available</MenuItem>
                    )}
                  </Select>
                </FormControl>

                {/* <div
                className="appointment__date__record"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  justifyContent: "center",
                }}
                onClick={hadledoctordata}
                disabled={loading}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Save Template"
                  )}
                  <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                </span>
              </div> */}
              </div>
            </Grid>
          </div>

          {buttonList &&
          Array.isArray(buttonList) &&
          buttonList.length > 0 &&
          selectedTemplate ? (
            <div className="query_container">
              <div className="prescription_toolbar">
                {buttonList.map((bl) => (
                  <button
                    key={bl.button_id}
                    className={`prescription_toolbar_button ${
                      bl.button_action === selectedButton
                        ? "prescription_toolbar_button_selected"
                        : ""
                    }`}
                    onClick={() => setSelectionButtion(bl.button_action)}
                  >
                    <span>{bl.display_name}</span>
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <div>
          {selectedTemplate && (
            <Customprescription
              id={selectedButton}
              buttonData={buttonList}
              prescriptionTemplateId={selectedTemplate}
            />
          )}
        </div>

        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Enter Template Name
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Template Name"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
            <Button
              variant="contained"
              // color="#199a8e"
              fullWidth
              style={{ background: "#199a8e", color: "#fff" }}
              // onClick={handleSaveNewTemplate}
            >
              Save Template
            </Button>
          </Box>
        </Modal>

        <Modal
          open={openModal1}
          onClose={() => setOpenModal1(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <CloseIcon
              onClick={handleCloseMo}
              style={{ float: "right", cursor: "pointer" }}
            />
            <Typography id="modal-title" variant="h6" component="h2">
              Enter Template Name
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Template Name"
              value={newTemplateName1}
              onChange={(e) => setNewTemplateName1(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSaveNewTemplate}
              sx={{
                mt: 2,
                color: "white",
                backgroundColor: "#199a8e",
                "&:hover": {
                  backgroundColor: "#28b8ab",
                },
              }}
              disabled={loading || newTemplateName1.trim() === ""}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Save Template"
              )}{" "}
            </Button>
          </Box>
        </Modal>
      </PageContentLayout>
    </>
  );
};

export default Customtemp;