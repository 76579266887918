import React, { useRef, useContext, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { PatientpageContext } from "../../context/GlobalContext";
import Skeleton from "@mui/material/Skeleton";
import { useCallback } from "react";
import { debounce } from "lodash";
import { Tokens } from "../../utility/utility";
import { ServiceCall } from "../../utility/service-call";
import { bioMarksBloodPressure } from "./ClinicalUtils";
import Button from "../../Tools/Button";

const ClinicalSlider = ({
  clinicalFinding,
  appointment,
  setBloodPressure = null,
  bloodPressure,
}) => {
  const doctorContext = useContext(PatientpageContext);
  const [disable, setDisable] = useState(false);
  const defaultValue = Number(
    doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.data
      ?.latest_measure || clinicalFinding?.median
  );
  const isBloodPressure =
    clinicalFinding?.display_name === bioMarksBloodPressure?.systolic ||
    clinicalFinding?.display_name === bioMarksBloodPressure?.diastolic;

  useEffect(() => {
    if (defaultValue && typeof setBloodPressure === "function") {
      if (clinicalFinding?.display_name === bioMarksBloodPressure?.systolic) {
        setBloodPressure({
          ...bloodPressure,
          systolic: defaultValue,
          enable:false
        })
      }
      if (clinicalFinding?.display_name === bioMarksBloodPressure?.diastolic) {
        setBloodPressure({
          ...bloodPressure,
          diastolic: defaultValue,
          enable:false
        })
      }
    }
  }, [defaultValue]);

  const [sliderValue, setSliderValue] = useState(0);
  useEffect(() => {
    if (appointment) {
      doctorContext?.ClinicalSliderCall(
        appointment?.appointmentItem?.appointment_id,
        clinicalFinding?.bio_marker
      );
    }
  }, []);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [doctorContext?.clinicalsliderData]);

  // useEffect(() => {
  //   if (
  //     sliderValue &&
  //     !doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.loader &&
  //     doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.data !==
  //       undefined &&
  //     sliderValue !==
  //       Number(
  //         doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.data
  //           ?.latest_measure
  //       )
  //   ) {
  //     updateClinicalFinding();
  //   }
  //   return () => {
  //     updateClinicalFinding.cancel();
  //   };
  // }, [
  //   sliderValue,
  //   doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.loader,
  // ]);

  // const updateClinicalFinding = useCallback(
  //   debounce(() => {
  //     if (
  //       sliderValue !==
  //       Number(
  //         doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.data
  //           ?.latest_measure
  //       )
  //     ) {
  //       updateClinicalFindingCall();
  //     }
  //   }, 2000),
  //   [sliderValue]
  // );

  const onChangeValue = (e) => {
    const value = e.target.value;
    setSliderValue(value);
    if (
      Number(value) < clinicalFinding?.lower_limit ||
      Number(value) > clinicalFinding?.upper_limit
    ) {
      setDisable(true);
      if (isBloodPressure) {
        typeof setBloodPressure === "function" &&
          setBloodPressure({
            systolic:
              clinicalFinding?.display_name === bioMarksBloodPressure?.systolic
                ? value
                : bloodPressure?.systolic,
            diastolic:
              clinicalFinding?.display_name === bioMarksBloodPressure?.diastolic
                ? value
                : bloodPressure?.diastolic,
            enable: false,
          });
      }
    } else {
      setDisable(false);
      typeof setBloodPressure === "function" &&
        setBloodPressure({
          systolic:
            clinicalFinding?.display_name === bioMarksBloodPressure?.systolic
              ? value
              : bloodPressure?.systolic,
          diastolic:
            clinicalFinding?.display_name === bioMarksBloodPressure?.diastolic
              ? value
              : bloodPressure?.diastolic,
          enable: true,
        });
    }
  };

  const handleSubmit = () => {
    if (
      sliderValue &&
      !doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.loader &&
      doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.data !==
        undefined &&
      sliderValue !==
        Number(
          doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]?.data
            ?.latest_measure
        )
    ) {
      let url = `${appointment?.appointmentItem?.appointment_id}/${clinicalFinding?.bio_marker}`;
      doctorContext?.updateClinicalFindingCall(
        url,
        {
          measure: sliderValue.toString(),
        },
        appointment?.appointmentItem?.appointment_id,
        clinicalFinding?.bio_marker
      );
    }
  };

  // const updateClinicalFindingCall = async () => {
  //   doctorContext.setClinicalLoader(true);
  //   try {
  //     let updateCall = await ServiceCall.putv2(
  //       "/v2/doctor/clinical/finding/ops/",
  //       `${appointment?.appointmentItem?.appointment_id}/${clinicalFinding?.bio_marker}`,
  //       {
  //         measure: sliderValue.toString(),
  //       },
  //       {
  //         doc_secret: Tokens.docToken(),
  //         doc_key: Tokens.dockey(),
  //       }
  //     );

  //     if (updateCall) {
  //       doctorContext.setState({
  //         msg: updateCall?.data?.message || "",
  //         type: "info",
  //         timer: 2000,
  //         vertical: "top",
  //         horizontal: "center",
  //       });
  //       doctorContext?.ClinicalSliderCall(
  //         appointment?.appointmentItem?.appointment_id,
  //         clinicalFinding?.bio_marker
  //       );
  //     }
  //   } catch (err) {
  //     err &&
  //       doctorContext.setState({
  //         msg: err?.response?.data?.message || "",
  //         type: "error",
  //         timer: null,
  //         vertical: "top",
  //         horizontal: "center",
  //       });
  //   } finally {
  //     doctorContext.setClinicalLoader(false);
  //   }
  // };

  const marks = [
    {
      value: Number(clinicalFinding?.lower_limit),
      label: Number(clinicalFinding?.lower_limit),
    },
    {
      value: Number(clinicalFinding?.upper_limit),
      label: Number(clinicalFinding?.upper_limit),
    },
  ];

  return (
    <div>
      {doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]
        ?.loader ? (
        <>
          <Skeleton animation="wave" />
        </>
      ) : (
        <div className="duration__slider">
          <Slider
            aria-label="Duration"
            value={sliderValue === 0 ? defaultValue : sliderValue}
            onChange={onChangeValue}
            // getAriaValueText={(value) => {
            //   setSliderValue(value);
            // }}
            valueLabelDisplay="on"
            marks={marks}
            step={Number(clinicalFinding?.step)}
            min={Number(clinicalFinding?.lower_limit)}
            max={Number(clinicalFinding?.upper_limit)}
          />
          <div className="duration__slider__manual">
            <p>
              Current Measure for {clinicalFinding?.display_name} (
              {clinicalFinding?.measuring_unit ||
                clinicalFinding?.diastolic?.measuring_unit}
              )
            </p>
            <input
              type="number"
              value={sliderValue === 0 ? defaultValue : sliderValue}
              min={Number(clinicalFinding?.lower_limit)}
              max={Number(clinicalFinding?.upper_limit)}
              onChange={onChangeValue}
            />
            {!isBloodPressure ? (
              <Button
                loader={
                  doctorContext?.clinicalsliderData[clinicalFinding?.bio_marker]
                    ?.loader ||
                  sliderValue ===
                    Number(
                      doctorContext?.clinicalsliderData[
                        clinicalFinding?.bio_marker
                      ]?.data?.latest_measure
                    ) ||
                  disable
                }
                handleClick={handleSubmit}
                containerClass="duration__slider__submit"
                text="Save"
              />
            ) : (
              <></>
            )}
          </div>
          {clinicalFinding?.display_name === bioMarksBloodPressure?.systolic ? (
            <div className="duration_slider_name mb-4">Systolic</div>
          ) : (
            <></>
          )}
          {clinicalFinding?.display_name ===
          bioMarksBloodPressure?.diastolic ? (
            <div className="duration_slider_name">Diastolic</div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default ClinicalSlider;
