import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton } from "@mui/material";

export default function TooltipComponent({ title, icon = "" }) {
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      TransitionProps={{ timeout: 1000 }}
      title={title}
    >
      <IconButton onClick={() => setOpen((oldValue) => !oldValue)}>
        {icon ? icon : <HelpOutlineIcon fontSize="small" />}
      </IconButton>
    </Tooltip>
  );
}
