import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Ls } from "../../utility/utility";
import { PatientpageContext } from "../../context/GlobalContext";
import DoctorsAppointmentListItemNew from "../../components/DoctorsAppointmentListItemNew";
import PrescriptionEditor from "../../components/PrescriptionEditor";
import { FormHelperText } from "@material-ui/core";
import Button from "../../Tools/Button";
import Drug_medi1 from "./Drug_medi1";
import PrescriptionTemplate from "./PrescriptionTemplate";
import PatientNotes from "./PatientNotes";
import Treatment_Procedure1 from "./Treatment_Procedure1";
import CircularProgress from "@mui/material/CircularProgress";
import Refered from "./Refered";

const Customprescription = ({ id, buttonData, prescriptionTemplateId }) => {
  const defaultReferalValues = {
    salutation: "Dr.",
    name: "",
    speciality: "",
    mobile: "",
    email: "",
    address: "",
  };
  const history = useHistory();
  const params = useParams();
  const doctorContext = useContext(PatientpageContext);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState(defaultReferalValues);
  const [displayName, setDisplayName] = useState("");

  const prescriptionInnerData = {
    patient_history:{
      fetchType:"static_list",
      placeholder: "Input Patient History"
    },
    reported_problems: {
      fetchType: "symptoms_list",
      placeholder: "Input Reported Problems/diagnosis",
      hasError: true,
    },
    provisional_diagnosis: {
      fetchType: "symptoms_list",
      placeholder: "Input Reported Provisional Diagnosis",
      hasError: true,
    },
    diagnosis_tests: {
      fetchType: "tests_list",
      placeholder: "Input Recommended Tests",
    },
    investigation_reports: {
      fetchType: "findings_list",
      placeholder: "Input Your Findings",
      hasError: true,
    },
    final_diagnosis: {
      fetchType: "static_list",
      placeholder: "Input final diagnosis",
    },
    advice: {
      fetchType: "static_list",
      placeholder: "Input advice",
    },
    additional_notes: {
      fetchType: "static_list",
      placeholder: "Input additional note",
    },
  };

  const idToDisplayNameMapping = {
    patient_history: "Patient History",
    reported_problems: "Chief Complaints",
    provisional_diagnosis: "Provisional Diagnosis",
    diagnosis_tests: "Diagnosis Test",
    investigation_reports: "Investigation Report Findings",
    final_diagnosis: "Final Diagnosis",
    advice: "Advice",
    additional_notes: "Additional Notes",
    treatments_procedures: "Treatment/Procedure/Schedule",
    medications_drugs: "Drugs/Medication",
    referred_to: "Referred To",
  };

  const [receiveNotes, setReceiveNotes] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
  });

  const pdataObj = {
    symptoms_list: "symptoms",
    findings_list: "diseases",
    tests_list: "diagnosis",
    drugs_list: "therapies",
  };

  const DefaultpData = {
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
    static_list: [],
    diagnosis_tests: [],
  };

  const [pData, setPData] = useState(DefaultpData);

  const defaultMedicationError = {
    medication: {
      hasError: false,
      errorMsg: "",
    },
    medication_form: {
      hasError: false,
      errorMsg: "",
    },
    dose_qty: {
      hasError: false,
      errorMsg: "",
    },
    dose_unit: {
      hasError: false,
      errorMsg: "",
    },
    dose_admin_method: {
      hasError: false,
      errorMsg: "",
    },
    dose_timings: {
      hasError: false,
      errorMsg: "",
    },
    dietary_instructions: {
      hasError: false,
      errorMsg: "",
    },
    // remind_patient: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    medication_start_date: {
      hasError: false,
      errorMsg: "",
    },
    side_effects_explained: {
      hasError: false,
      errorMsg: "",
    },
    duration: {
      hasError: false,
      errorMsg: "",
    },
    // symptoms_list: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    // findings_list: {
    //   hasError: false,
    //   errorMsg: "",
    // },
  };

  function updatePData(value, type) {
    let addedValue = { ...pData, [type]: value };
    setPData(addedValue);
  }

  const [medicationError, setMedicationError] = useState(
    defaultMedicationError
  );

  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const handleSubmit = async (id, newPdata= null) => {
    try {
      if (
        id !== "medications_drugs" &&
        id !== "treatments_procedures" &&
        id !== "referred_to"
      ) {
        const { data, error } = await doctorContext.postPutV2Call(
          "/v2/doctor/custom/template/button_action/",
          prescriptionTemplateId + `/${id}`,
          { note_list:newPdata? newPdata[prescriptionInnerData[id].fetchType]:  pData[prescriptionInnerData[id].fetchType] },
          setButtonLoader,
          true
        );
        if (data.data) {
          !newPdata && doctorContext?.setState({
            msg: data?.data?.message || "",
            type: "success",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !!id &&
          id !== "medications_drugs" &&
          id !== "treatments_procedures" &&
          id !== "referred_to" &&
          id !== "generate"
        ) {
          const { data, error } = await doctorContext?.getv2Call(
            "/v2/doctor/custom/template/button_action/",
            prescriptionTemplateId + `/${id}`
          );
          if (data?.data) {
            // setChief(Chiefcomplaints.data);
            let newData = { ...pData };
            newData[prescriptionInnerData[id].fetchType] = data?.data[id];
            setPData(newData);
          }
        }
      } catch (error) {}
    };

    fetchData();
    setDisplayName(idToDisplayNameMapping[id]);

    return () => {
      setPData((prev) => {
        if(id &&!!prev[prescriptionInnerData[id]?.fetchType]?.length){
            handleSubmit(id, prev)
          }
        return DefaultpData;
      });
    };
  }, [id]);

  const DoctorsAppointmentList = ({ appointmentDate, classes }) => {
    return (
      <>
        <DoctorsAppointmentListItemNew
          index={0}
          tabRoute="prescription"
          key={appointment?.appointmentItem?.appointment_id}
          appointmentItem={
            doctorContext?.patientInfo || appointment?.appointmentItem
          }
          classes={classes}
        />
      </>
    );
  };

  const handlebuttonclick = () => {
    history.push("/prescription");
    setDisplayName(id.display_name);
  };

  const PrescriptionType = (key) => {
    switch (key) {
      case "treatments_procedures":
        return <Treatment_Procedure1  templid={prescriptionTemplateId} />;
      case "medications_drugs":
        return <Drug_medi1 templid={prescriptionTemplateId} />;
      default:
        return (
          <>
            <div className="prescription_editor">
              <PrescriptionEditor
                fetchType={prescriptionInnerData[id]?.fetchType}
                editable={true}
                placeholder={prescriptionInnerData[id]?.placeholder}
                changeHandler={updatePData}
                asyncData={true}
                listQuery={
                  prescriptionInnerData[id]?.fetchType !== "static_list"
                }
                classes={appointment?.classes}
                pdataObj={pdataObj}
                receiveNotes={
                  pData?.[prescriptionInnerData[id]?.fetchType] || []
                }
              />
              {prescriptionInnerData[id]?.hasError &&
                medicationError?.[prescriptionInnerData[id]?.fetchType]
                  ?.hasError && (
                  <FormHelperText className="validation-error">
                    {
                      medicationError?.[prescriptionInnerData[id]?.fetchType]
                        ?.errorMsg
                    }
                  </FormHelperText>
                )}
            </div>
            <div className="save-btn">
              <Button
                text={
                  buttonLoader ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Save"
                  )
                }
                handleClick={() => {
                  handleSubmit(id);
                }}
              />
            </div>
          </>
        );
    }
  };
  return (
    <div className="cheif_complaints">{PrescriptionType(id || params?.id)}</div>
  );
};

export default Customprescription;