import React, { useEffect, useContext } from 'react';
import {
  Grid,
  Table,
  TableHead,
  Typography,
  TableRow,
  TableBody,
  IconButton,
  TableCell,
  Link,
  Chip
} from '@mui/material';
import { PatientpageContext } from '../context/GlobalContext';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import TableContainer from '@mui/material/TableContainer';
export default function PatientReportComponent({ appointmentId }) {
  const doctorContext = useContext(PatientpageContext);
  useEffect(() => {
    // console.log(appointmentId);
    doctorContext.getRecords(appointmentId);
  }, [appointmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  function showListItem(recordData) {
    return recordData.map((record, index) => {
      return (
        // <MyRecordsListItem doctorContext={doctorContext} recordItem={record} index={index} />

        <TableRow key={index}>
          <TableCell>
            <Link
              onClick={() =>
                doctorContext.downloadRecord(
                  appointmentId,
                  record.file_name,
                  index
                )
              }
            >
              <IconButton>
                <ArrowCircleDownIcon fontSize="large" color="success" />
              </IconButton>
              {record.file_name}
            </Link>
          </TableCell>
          <TableCell align="center">
            <Chip
              size="small"
              label={record.category}
              color="success"
              variant="outlined"
            />
          </TableCell>
          <TableCell align="center">{record.uploaded_on}</TableCell>
        </TableRow>
      );
    });
  }

  if (doctorContext && doctorContext.recordLoader) {
    return <Grid item>Loading Records</Grid>;
  } else {
    return (
      <Grid item container className="collapseContentWrapper p-2" xs={12}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom component="div">
            Available Medical Records
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead className="d-none">
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Uploaded On</TableCell>
                  <TableCell align="right">Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctorContext.recordData[appointmentId] &&
                  showListItem(doctorContext.recordData[appointmentId])}
              </TableBody>
              {/* { doctorContext.recordData[appointmentId].map((item)=>(
                showListItem(item)           
            ))} */}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}
