const RegitrationCouncil = [
  { label: "National Medical Council (NMC)", value: "NMC" },
  { label: "Andhra Pradesh Medical Council (APMC)", value: "APMC" },
  { label: "Arunachal Pradesh Medical Council (ARUNAPMC)", value: "ARUNAPMC" },
  { label: "Assam Medical Council (ASSAMC)", value: "ASSAMC" },
  { label: "Bihar Medical Council (BMC)", value: "BMC" },
  { label: "Chattisgarh Medical Council (CGMC)", value: "CGMC" },
  { label: "Delhi Medical Council (DMC)", value: "DMC" },
  { label: "Goa Medical Council (GOAMC)", value: "GOAMC" },
  { label: "Gujarat Medical Council (GMC)", value: "GMC" },
  { label: "Haryana Medical Council (HMC)", value: "HMC" },
  { label: "Himanchal Pradesh Medical Council (HPSMC)", value: "HPSMC" },
  { label: "Jammu & Kashmir Medical Council (JKMC)", value: "JKMC" },
  { label: "Jharkhand Medical Council (JMC)", value: "JMC" },
  { label: "Karnataka Medical Council (KMC)", value: "KMC" },
  { label: "Madhya Pradesh Medical Council (MPMC)", value: "MPMC" },
  { label: "Maharashtra Medical Council (MAMC)", value: "MAMC" },
  { label: "Manipur Medical Council (MMC)", value: "MMC" },
  { label: "Mizoram Medical Council (MSMC)", value: "MSMC" },
  { label: "Nagaland Medical Council (NAGAMC)", value: "NAGAMC" },
  { label: "Orissa Council of Medical Registration (OCMR)", value: "OCMR" },
  { label: "Punjab Medical Council (PMC)", value: "PMC" },
  { label: "Rajasthan Medical Council (RMC)", value: "RMC" },
  { label: "Sikkim Medical Council (SMC)", value: "SMC" },
  { label: "Tamil Nadu Medical Council (TNMC)", value: "TNMC" },
  { label: "Telangana State Medical Council (TSMC)", value: "TSMC" },
  {
    label: "Travancore Cochin Medical Council, Trivandrum (TCMC)",
    value: "TCMC",
  },
  { label: "Tripura State Medical Council (TSMC)", value: "TSMC" },
  { label: "Uttarakhand Medical Council (UKMC)", value: "UKMC" },
  { label: "Uttar Pradesh Medical Council (UPMC)", value: "UPMC" },
  { label: "West Bengal Medical Council (WBMC)", value: "WBMC" },
  { label: "Jharkhand State Chikitsa Parishad", value: "JSCP" },
  { label: "Andhra Boards for Ayurveda & Homoeopathy", value: "ABAH" },
  { label: "Andhra Pradesh State Dental Council", value: "APSDC" },
  { label: "Arunachal Pradesh Indian Medicine Council", value: "APIMC" },
  { label: "Arunachal Pradesh State Dental Council", value: "APSDC" },
  { label: "Assam State Council of Indian Medicine", value: "ASCIM" },
  { label: "Assam State Dental Council", value: "ASDC" },
  { label: "Bhartiya Chikitsa Parishad, Uttaranchal", value: "BCPU" },
  { label: "Bihar State Dental Council", value: "BSDC" },
  { label: "Bihar State Homeopathy Council", value: "BSHC" },
  {
    label: "Board of Ayurvedic & Unani Systems of Medicine, Haryana",
    value: "BAUSMH",
  },
  {
    label: "Board of Ayurvedic & Unani Systems of Medicine, Himachal Pradesh",
    value: "BAUSMHP",
  },
  {
    label: "Board of Ayurvedic & Unani Systems of Medicine, Jammu",
    value: "BAUSMJ",
  },
  { label: "Board of Indian Medicine, Rajasthan", value: "BIMR" },
  { label: "Board of Indian Medicine, Tamil Nadu", value: "BIMTN" },
  { label: "Board of Indian Medicines, Telangana", value: "BIMT" },
  { label: "Board of Indian Medicines, Uttar Pradesh", value: "BIMUP" },
  { label: "Chhatisgarh State Dental Council", value: "CSDC" },
  { label: "Chhatisgarh State Homeopathy Council", value: "CSHC" },
  {
    label:
      "Chhattisgarh Board of Ayurvedic and Unani System of Medicine and Naturopathy",
    value: "CBAUSM",
  },
  { label: "Delhi State Dental Council", value: "DSDC" },
  { label: "Delhi State Homeopathy Council", value: "DSHC" },
  { label: "Dental Council of Chandigarh", value: "DCC" },
  {
    label: "Goa Board of Indian Systems of Medicine and Homoeopathy",
    value: "GBISMH",
  },
  { label: "Goa State Dental Council", value: "GSDC" },
  { label: "Goa State Homeopathy Council", value: "GSHC" },
  {
    label: "Gujarat Board of Ayurvedic & Unani Systems of Medicine",
    value: "GBAUSM",
  },
  { label: "Gujarat State Dental Council", value: "GSDC" },
  { label: "Gujarat State Homeopathy Council", value: "GSHC" },
  { label: "Haryana State Dental Council", value: "HSDC" },
  { label: "Haryana State Homeopathy Council", value: "HSHC" },
  { label: "Himachal Pradesh State Dental Council", value: "HPSDC" },
  { label: "Himachal Pradesh State Homeopathy Council", value: "HPSHC" },
  { label: "Homeopathy Council of Chandigarh", value: "HCC" },
  { label: "J & K State Dental Council", value: "JKSDC" },
  { label: "J & K State Homeopathy Council", value: "JKSHC" },
  { label: "Jharkhand State Dental Council", value: "JSDC" },
  { label: "Jharkhand State Homeopathy Council", value: "JSHC" },
  { label: "Karnataka Ayurvedic & Unani Practitioner’s Board", value: "KAUPB" },
  { label: "Karnataka State Dental Council", value: "KSDC" },
  { label: "Karnataka State Homeopathy Council", value: "KSHC" },
  { label: "Kerala State Dental Council", value: "KSDC" },
  { label: "Kerala State Homeopathy Council", value: "KSHC" },
  {
    label: "Madhya Pradesh Ayurvedic, Unani & Prakritic Chikitsa Board",
    value: "MPAUPCB",
  },
  { label: "Madhya Pradesh State Dental Council", value: "MPSDC" },
  { label: "Madhya Pradesh State Homeopathy Council", value: "MPSHC" },
  { label: "Maharashtra Council of Indian Medicine", value: "MCIM" },
  { label: "Maharashtra State Dental Council", value: "MSDC" },
  { label: "Maharashtra State Homeopathy Council", value: "MSHC" },
  { label: "Manipur State Dental Council", value: "MSDC" },
  { label: "Manipur State Homeopathy Council", value: "MSHC" },
  { label: "Meghalaya State Dental Council", value: "MGSDC" },
  { label: "Meghalaya State Homeopathy Council", value: "MGSHC" },
  { label: "Mizoram State Registration Tribunal", value: "MSRT" },
  { label: "Mizoram State Registration Tribunal", value: "MSRT" },
  { label: "Nagaland State Dental Council", value: "NSDC" },
  { label: "Nagaland State Homeopathy Council", value: "NSHC" },
  { label: "Orissa State Council of Ayurvedic Medicine", value: "OSCAM" },
  { label: "Orissa State Dental Council", value: "OSDC" },
  { label: "Orissa State Homeopathy Council", value: "OSHC" },
  { label: "Paschim Banga Ayurved Parishad, West Bengal", value: "PBAWB" },
  { label: "Punjab State Dental Council", value: "PSDC" },
  { label: "Punjab State Homeopathy Council", value: "PSHC" },
  { label: "Rajasthan State Dental Council", value: "RSDC" },
  { label: "Rajasthan State Homeopathy Council", value: "RSHC" },
  { label: "Sikkim Dental Registration Tribunal", value: "SDRT" },
  { label: "Sikkim Homeopathy Registration Tribunal", value: "SHRT" },
  { label: "State Council of Ayurvedic & Unani Medicine", value: "SCAUM" },
  { label: "State Council of Unani Medicine, West Bengal", value: "SCUMWB" },
  { label: "State Dental Council, Puducherry", value: "SDCP" },
  { label: "State Homeopathy Council, Puducherry", value: "SHCP" },
  { label: "Tamil Nadu Siddha Medical Council", value: "TNSMC" },
  { label: "Tamil Nadu State Dental Council", value: "TNSDC" },
  { label: "Tamil Nadu State Homeopathy Council", value: "TNSHC" },
  { label: "Telangana State Dental Council", value: "TSDC" },
  { label: "Telangana State Homeopathy Council", value: "TSHC" },
  { label: "Travancore-Cochin Medical Councils", value: "TCMC" },
  { label: "Tripura State Dental Council", value: "TSDC" },
  { label: "Tripura State Homeopathy Council", value: "TSHC" },
  { label: "Uttar Pradesh State Dental Council", value: "UPSDC" },
  { label: "Uttar Pradesh State Homeopathy Council", value: "UPSHC" },
  { label: "Uttarakhand Dentists Registration Tribunals", value: "UDRT" },
  { label: "Uttarakhand Homeopathy Registration Tribunals", value: "UHRT" },
  { label: "West Bengal State Dental Council", value: "WBSDC" },
  { label: "West Bengal State Homeopathy Council", value: "WBSHC" },
];

export default RegitrationCouncil;
