import Col from "react-bootstrap/Col";
import { useContext } from "react";
import Row from "react-bootstrap/Row";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import { Button } from "react-bootstrap";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { PatientpageContext } from "../context/GlobalContext";
function ProfileCard(props) {
  const { name, gender, age, email, profileType, onClick, oneditClick, profile_id } = props;
  const doctorContext = useContext(PatientpageContext);

  const handleEditClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    oneditClick(profile_id, event);
  };

  return (
    <>
      <Row
        className="align-items-center bg-body shadow rounded-3 py-2 mb-3 position-relative"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <Col
          xs={3}
          className="text-center text-capitalize d-flex flex-column justify-content-center align-items-center"
          style={{float: "inline-start", marginLeft: "1rem"}}
        >
          {profileType === "self" ? (
            <PersonIcon style={{ fontSize: "3rem" }} />
          ) : (
            <GroupsIcon style={{ fontSize: "3rem" }} />
          )}
          <small style={{ fontSize: "0.8rem" }}>({profileType})</small>
        </Col>

        <Col className="d-flex flex-column" style={{marginLeft: "10rem"}}>
          <h3 className="h5 text-capitalize m-0">
            {name} ({gender}, {age})
          </h3>
          <span style={{ fontSize: "0.92rem" }}>
            {doctorContext?.profileData?.phone}
          </span>
          <span style={{ fontSize: "0.92rem" }}>{email}</span>
        </Col>

        <div 
          onClick={handleEditClick} 
          style={{ 
            cursor: 'pointer',
            position: 'absolute',
            top: '1rem',
            right: '1.5rem'
          }}
        >
          <BorderColorIcon />
        </div>
      </Row>
    </>
  );
}
{/* <span style={{float: "inline-end", marginTop: "-3rem", marginRight: "1.5rem"}}><BorderColorIcon /> */}

export default ProfileCard;
