import React from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";

const IncrementDecrement = ({
  IncNum,
  DecNum,
  count,
  containerClass,
  inputNum,
}) => {
  return (
    <div className={`${containerClass}__container`}>
      <Button className={`${containerClass}__dec`} onClick={DecNum}>
        <RemoveIcon />
      </Button>
      <input
        type="number"
        min="0"
        max="5"
        onChange={inputNum}
        className={`${containerClass}__count`}
        value={count}
      />
      <Button className={`${containerClass}__inc`} onClick={IncNum}>
        <AddIcon />
      </Button>
    </div>
  );
};

export default IncrementDecrement;
