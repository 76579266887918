import React from "react";
import PrescriptionInput from "./PrescriptionInput";

const PrescriptionEditorNew = ({
  fetchType,
  label,
  editable,
  placeholder,
  changeHandler,
  asyncData,
  classes,
  oldValue,
  pdataObj,
  icon = "",
  receiveNotes,
  feildsReset,
  data,
}) => {
  function addSearchValue(value, key) {
    // let oldValue = [...selectedValue];
    // oldValue.push(value[key]);
    if (fetchType === "drugs_list") {
      changeHandler(value, label);
    } else {
      changeHandler(value[key], label);
    }
  }
  return (
    <div className={classes}>
      <PrescriptionInput
        // appointment_id={doctorContext.joinActive[0].appointment_id}
        fetchType={fetchType}
        onSelect={addSearchValue}
        placeholder={placeholder}
        asyncData={asyncData}
        label={label}
        pdataObj={pdataObj}
        feildsReset={feildsReset}
        icon={icon}
        data={data}
      />
    </div>
  );
};

export default PrescriptionEditorNew;
