import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";
import { PatientpageContext } from "./../context/GlobalContext";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PrescriptionInput from "./PrescriptionInput";
import MonitorComponent from "./MonitorComponent";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import PatientReportComponent from "./PatientReportComponent";
import PrescriptionEditor from "./PrescriptionEditor";
import PrescriptionTemplate from "./PrescriptionTemplate";

const patientDetails = {
  patient_name: "",
  room_url: "",
  patient_age: "",
  patient_gender: "",
  patient_konwnDesease: "",
  patient_bloodtype: "",
  patient_lastReportedIssue: "",
  patient_weight: "",
  patient_height: "",
  appointment_id: "",
};
const settings = {
  module: {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  },
  formats: [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ],
};
export default function JoinTab({ classes }) {
  const doctorContext = useContext(PatientpageContext);
  const [patientBio, setPatientBio] = useState([]);
  const [showMonitor, setShowMonitor] = useState(true);
  const [showPrescription, setShowPrescription] = useState(true);
  const [editorHtml, setEditorHtml] = useState("");
  const [disableView, setDisableView] = useState(true);
  const [disableShare, setdisableShare] = useState(true);
  const [pData, setPData] = useState({
    symptoms: [],
    diseases: [],
    diagnosis: [],
    therapies: [],
  });

  const {
    patient_name,
    room_url,
    patient_age,
    patient_gender,
    patient_konwnDesease,
    patient_bloodtype,
    patient_lastReportedIssue,
    patient_weight,
    patient_height,
    appointment_id,
  } =
    doctorContext.joinActive.length > 0
      ? doctorContext.joinActive[0]
      : patientDetails;

  useEffect(() => {
    if (doctorContext.joinActive.length > 0) {
      let userToken = Ls.get("userToken");
      const getPatientBio = () => {
        ServiceCall.get(`/doctor/appointment/patient/bio/${appointment_id}`, {
          doc_token: userToken,
        })
          .then((res) => {
            setPatientBio(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      // {{url}}/test/doctor/appointment/patient/bio/e6fcb379-2d1d-443f-95c9-19419ac1
      getPatientBio();
    }
  }, [doctorContext.joinActive, appointment_id]);

  function handleChange(html) {
    setEditorHtml(html);
  }
  function generatePresData() {
    let dataFormat = {
      symptoms_list: [],
      findings_list: [],
      tests_list: [],
      drugs_list: [],
    };
    Object.keys(pData).map((elm) => {
      if (elm === "symptoms") {
        dataFormat["symptoms_list"] = [
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      if (elm === "diseases") {
        dataFormat["findings_list"] = [
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      if (elm === "diagnosis") {
        dataFormat["tests_list"] = [
          ...dataFormat["tests_list"],
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      if (elm === "therapies") {
        dataFormat["drugs_list"] = [
          ...pData[elm].reduce((acc, item) => {
            acc.push(item.term);
            return acc;
          }, []),
        ];
      }
      return elm;
    });
    return dataFormat;
  }

  function checkDisable() {
    Object.keys(pData).find((key) => {
      if (pData[key].length >= 0) {
        setDisableView(false);
      }
    });
  }
  function checkShareDisable() {
    setdisableShare(false);
  }

  function viewPrescription() {
    doctorContext.dialogController({
      open: true,
      scroll: "body",
      content: (
        <div className="text-center">
          <PrescriptionTemplate
            checkShareDisable={checkShareDisable}
            preValue={pData}
            appointmentId={appointment_id}
            activeAppointment={doctorContext.joinActive[0]}
            generatePresData={generatePresData()}
          />
        </div>
      ),
      maxWidth: "md",
      dialogAction: null,
    });
  }
  function updatePData(value, type) {
    setPData({
      ...pData,
      [type]: value,
    });
    checkDisable();
  }

  function openReportModal() {
    doctorContext.dialogController({
      open: true,
      content: (
        <div className="text-center">
          <h3>Patient reports</h3>
          <PatientReportComponent
            appointmentId={appointment_id}
            checkShareDisable={checkShareDisable}
          />
        </div>
      ),
      maxWidth: "xl",
      dialogAction: null,
    });
  }
  function switchMonitorView(e) {

    setShowMonitor(e.target.checked);
  }
  const sharePrescription = () => {
    doctorContext.submitPrescription(appointment_id, generatePresData())
      .then(res => {
        doctorContext.dialogController({
          open: true,
          content: res.message,
          maxWidth: "sm",
        })
        setdisableShare(true);
      }).catch(err => {
        doctorContext.dialogController({
          open: true,
          content: 'Error occured while sharing prescription',
          maxWidth: "sm",
        })
        setdisableShare(true);
      });
  };
  // function addSearchValue(value, type) {
  //   if (type === "diagnosis") {
  //     let oldValue = [...selectedDiagnosis];
  //     oldValue.push(value);
  //     setSelectedDiagnosis(oldValue);
  //   }
  //   if (type === "symptoms") {
  //     let oldValue = [...selectedSymptoms];
  //     oldValue.push(value);
  //     setSelectedSymptoms(oldValue);
  //   }
  //   if (type === "diseases") {
  //     let oldValue = [...selectedDiseases];
  //     oldValue.push(value);
  //     setSelectedDiseases(oldValue);
  //   }
  //   // setSelectedDiagnosis(oldValue=>[...oldValue,value])
  // }
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        Date: {dayjs().format("DD/MM/YYYY")}
      </Grid>
      <Grid item xs={12} className="text-center">
        {/* <h3>Filter Layout</h3> */}
        {/* <Switch defaultChecked={showMonitor} label /> */}
        <FormControlLabel
          className="px-3"
          control={
            <Switch
              color="success"
              checked={showMonitor}
              inputProps={{ "aria-label": "controlled" }}
              onClick={(e) => setShowMonitor(e.target.checked)}
              size="small"
            />
          }
          label="Show Patient Charts"
        />
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={showPrescription}
              inputProps={{ "aria-label": "controlled" }}
              onClick={(e) => setShowPrescription(e.target.checked)}
              size="small"
            />
          }
          label="Show Prescription Inputs"
        />
      </Grid>
      <Grid container item xs={12} sm className="px-4 minWidth33">
        {doctorContext.joinActive.length > 0 ? (
          <Grid container item xs={12}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title={doctorContext.joinActive[0].patient_name}
                scrolling="no"
                className="embed-responsive-item"
                allow="camera; microphone; fullscreen; speaker; display-capture"
                src={`${room_url}?landing=no&name=${doctorContext.profileData.name}`}
                allowfullscreen
              ></iframe>
            </div>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <h3 className="font-15 my-1">Patient’s Details:</h3>
                <List dense={true} className="pt-0">
                  <ListItem className="my-0 py-0">
                    <ListItemText primary={`Name: ${patient_name || ""}`} />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText primary={`Age: ${patient_age || ""}`} />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText
                      primary={`Years Sex: ${patient_gender || ""}`}
                    />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText primary={`Weight: ${patient_weight || ""}`} />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText primary={`Height: ${patient_height || ""}`} />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText
                      primary={`Blood Type: ${patient_bloodtype || ""}`}
                    />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText
                      primary={`Known Disease: ${patient_konwnDesease || ""}`}
                    />
                  </ListItem>
                  <ListItem className="my-0 py-0">
                    <ListItemText
                      primary={`Last Reported Issue: ${patient_lastReportedIssue || ""
                        }`}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} className="text-center" color>
                <h3 className="font-15 my-1">Medical Records</h3>
                <IconButton color="info" onClick={openReportModal}>
                  <DownloadForOfflineIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <h3 className="text-center">"No Active appointment"</h3>
        )}
      </Grid>
      {showMonitor && doctorContext.joinActive.length > 0 && (
        <Grid item xs={12} sm className="px-4 minWidth33">
          <MonitorComponent
            appointment_id={doctorContext.joinActive[0].appointment_id}
          />
        </Grid>
      )}

      {doctorContext.joinActive.length > 0 && (
        <Grid
          item
          xs={12}
          sm
          className={`px-4 minWidth33 ${showPrescription ? "" : "d-none"}`}
        >
          <Box sx={{ marginBottom: "3px" }}>
            <PrescriptionEditor
              fetchType="symptoms"
              editable={true}
              placeholder={"Input Reported Problems"}
              changeHandler={updatePData}
              asyncData={true}
              classes={classes}
            />
          </Box>
          <Box sx={{ marginBottom: "3px" }}>
            <PrescriptionEditor
              fetchType="diseases"
              editable={true}
              placeholder={"Input Your Findings"}
              changeHandler={updatePData}
              asyncData={true}
              classes={classes}
            />
          </Box>
          <Box sx={{ marginBottom: "3px" }}>
            <PrescriptionEditor
              fetchType="diagnosis"
              editable={true}
              placeholder={"Input Recommended Tests"}
              changeHandler={updatePData}
              asyncData={true}
              classes={classes}
            />{" "}
          </Box>
          <Box sx={{ marginBottom: "3px" }}>
            <PrescriptionEditor
              fetchType="therapies"
              editable={true}
              placeholder={"Input Drug Prescription"}
              changeHandler={updatePData}
              classes={classes}
            />
          </Box>
          <Grid container spacing={1} className="font-14">
            <Grid xs={6} item>
              <Button
                variant="contained"
                size="small"
                onClick={viewPrescription}
                disabled={disableView}
                className={`${classes.blackBtn} d-block w-100 mb-2 font-12`}
              >
                View Prescription
              </Button>
            </Grid>
            <Grid xs={6} item>
              <Button
                variant="contained"
                size="small"
                className="d-block w-100 font-12"
                disabled={disableShare}
                onClick={sharePrescription}
                color="error"
              >
                Share Prescription
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
