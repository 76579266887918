import React from 'react';
import { useState } from 'react';
import malefront from "../static/male_front.png";

const MaleFrontBody = ({ pointAction, currentPoint, painList = [] }) => {
    const [selectedPoint, setSelectedPoint] = useState(null);
    const currentPoints = Array.isArray(currentPoint) ? currentPoint : [currentPoint?.toString()];

    const bodyParts = [
        { name: 'head', points: ["1", "2"], style: { top: '8%', left: '50%', transform: 'translateX(-50%)' } },
        { name: 'neck', points: ["3"], style: { top: '22%', left: '50%', transform: 'translateX(-50%)' } },
        { name: 'shoulder', points: ["4", "5"], style: { top: '22%', left: '0', right: '0', display: 'flex', justifyContent: 'space-between', padding: '0 42%' } },
        { name: 'chest', points: ["6", "7"], style: { top: '28%', left: '0', right: '0', display: 'flex', justifyContent: 'space-between', padding: '0 45%' } },
        { name: 'arm', points: ["8", "9", "10", "11"], style: { top: '37%', left: '8px', right: '6px', display: 'flex', justifyContent: 'space-between', padding: '0 40%' } },
        { name: 'forearms', points: ["12", "13", "14", "15"], style: { top: '43%', left: '0', right: '0', display: 'flex', justifyContent: 'space-between', padding: '0 40%' } },
        { name: 'hands', points: ["16", "17", "18"], style: { top: '53%', left: '0', right: '0', display: 'flex', justifyContent: 'space-between', padding: '0 39%' } },
        { name: 'leftLeg', points: ["19", "20", "21", "22"], style: { top: '64%', left: '46.5%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
        { name: 'rightLeg', points: ["23", "24", "25", "26"], style: { top: '64%', right: '46.5%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
    ];

    const getPainColor = (point) => {
        if (point === selectedPoint) return "blue";
        if (currentPoints.includes(point)) return "#f1506c";
        if (painList.includes(point)) return "red";
        return "green";
    };

    const handlePointClick = (point) => {
        pointAction(point);
        setSelectedPoint(point);
    };

    const renderPoints = (points) => (
        points.map((point) => (
            <button
                key={point}
                onClick={() => handlePointClick(point)}
                style={{
                    ...styles.pain,
                    backgroundColor: getPainColor(point),
                }}
            />
        ))
    );

    return (
        <div style={{ position: 'relative', width: '100%', height: 460 }}>
            <img src={malefront} alt="Male front body" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            {bodyParts.map(({ name, points, style }) => (
                <div key={name} style={{ position: 'absolute', ...style }}>
                    {renderPoints(points)}
                </div>
            ))}
        </div>
    );
};

const styles = {
    pain: {
        width: 17,
        height: 17,
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        margin: '2px',
    },
};

export default MaleFrontBody;