import { green, red } from "@mui/material/colors";
import { hex2rgba } from "../utility/utility";

const appointmentScreensStyle = (theme) => {
  // console.log(theme.palette);
  return {
    listItem: {
      "&:hover": {
        backgroundColor: hex2rgba(theme.palette.primary.main, 0.2),
      },
    },
    listHover: {
      backgroundColor: green[50],
    },
    label: {
      borderColor: "green",
      // backgroundColor: 'green',
      // borderRadius: theme.borderRadius(1),
      // borderRadius: theme.borderRadius;
    },
    radioTime: {
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      "&:checked + label": {
        "& >div": {
          backgroundColor: theme.palette.primary.main,
          color: "#ffffff",
        },
      },
      "&:checked:disabled + label": {
        "& >div": {
          backgroundColor: "#858985",
          color: "#ffffff",
        },
      },
      "&:disabled + label": {
        "& >div": {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.grey[500],
          border: `1px solid ${theme.palette.grey[500]}`,
        },
      },
    },
    timeButton: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      transition: "all 0.2s linear",
      cursor: "pointer",
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
        color: "white",
        backgroundColor: theme.palette.primary.main,
      },
    },
    radio: {
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",

      "&:checked + label": {
        "& >div": {
          backgroundColor: green[50],
          // color: '#ffffff',
        },
      },
    },
    headerBg: {
      backgroundColor: theme.palette.grey[600],
      color: "#000000",
    },
    input: {
      display: "none",
    },
    stackStyle: {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    danger: {
      color: red[800],
    },
    apiProgress: {
      height: "2px !important",
      "& span": {},
    },
    blackBtn: {
      backgroundColor: "#212121 !important",
      color: "#ffffff !important",
      "&:disabled, &.Mui-disabled":{
        color: "rgba(0, 0, 0, 0.26) !important",
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important' }
    },
  };
};
export default appointmentScreensStyle;
