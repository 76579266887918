import React, { useState, useContext, useEffect } from "react";
import { ServiceCall } from "../../utility/service-call";
import { PatientpageContext } from "../../context/GlobalContext";
import { countriesList, Ls } from "./../../utility/utility";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, ErrorMessage } from "formik";
import Refredpro from "../../components/Refredpro";

const Refered = ({ formData, setFormData }) => {
  const defaultValues = {
    salutation: "Dr.",
    name: "",
    speciality: "",
    mobile: "",
    email: "",
    address: "",
  };

  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [refermsg, setRefermsg] = useState({});
  const [showData, setShowData] = useState(false);
  const [addMore, setAddMore] = useState(false);

  const doctorContext = useContext(PatientpageContext);
  const mobileRegex = /^[6-9][0-9]{9}$/;

  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber);
  };

  // const newErrors = {};

  // if (formData.email && !validateEmail(formData.email)) {
  //   newErrors.email = "Invalid email address";
  // }

  // if (formData.phoneNumber && !validatePhoneNumber(formData.phoneNumber)) {
  //   newErrors.phoneNumber = "Invalid phone number (10 digits required)";
  // }

  // setErrors(newErrors);

  const handleFillData = (name) => {
    const selectReferral = data?.filter((item) => item?.name === name)[0];
    setFormData({
      ...selectReferral,
      mobile: selectReferral?.mobile?.replace("+91", ""),
    });
    setOpenList(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true);
    try {
      const submitdata = await ServiceCall.putv2(
        "/v2/doctor/manage/appointment/note/referral/",
        appointment?.appointmentItem?.appointment_id,
        { ...values, mobile: `+91${values?.mobile}` },
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (submitdata.status === 200) {
        Referaldata();
        setLoader(false);
        setFormData(defaultValues);
        resetForm({
          values: {
            ...defaultValues,
          },
        });
        doctorContext?.setState({
          msg: submitdata?.data?.message || "",
          type: "success",
          timer: 2000,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (error) {
      // Handle the error here
      setLoader(false);
      resetForm({
        values: {
          ...defaultValues,
        },
      });
      doctorContext?.setState({
        msg: error?.response?.data?.message || "Internal Server Error",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await doctorContext.getv2Call(
          "/v2/doctor/list/opd/referrals",
          "",
          "",
          true
        );

        if (data?.data) {
          setData(data?.data?.records);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const Referaldata = async () => {
    try {
      const Refered = await ServiceCall.getv2(
        "/v2/doctor/manage/appointment/note/referral/",
        appointment?.appointmentItem?.appointment_id,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Refered) {
        setShowData(true);
        setRefermsg(Refered.data);
      } else {
        setRefermsg('');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
 
    Referaldata();
  }, []);

  const handleDeletePrompt = async (treat, index) => {
    
    let newTreatPrompt = refermsg?.referred_to;
    newTreatPrompt = newTreatPrompt
      .slice(0, index)
      .concat(newTreatPrompt.slice(index + 1));
    try {
      const deleteTreatment = await ServiceCall.deletev2(
        "/v2/doctor/remove/appointment/note/referral/",
        `${appointment?.appointmentItem?.appointment_id}/${index}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (deleteTreatment) {
        Referaldata();
        if (newTreatPrompt?.length === 0) {
          setAddMore(false);
        }
        setRefermsg(newTreatPrompt);
      }
    } catch (error) {}
  };

  return (
    <div className="prescription_editor">
      <div className="Refferel">
      <div className="presctiption_prompt" style={{padding: "0"}}>
        {showData && <Refredpro treatData={refermsg?.referred_to}
                      handleDeletePrompt={handleDeletePrompt}

        />}
      </div>
        <Formik
          enableReinitialize
          validateOnMount
          initialTouched={{ zip: true }}
          initialValues={{
            ...formData,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            mobile: Yup.number("Only Numbers")
              .test("", "Invalid Phone Number", (val) => {
                if (val) {
                  return mobileRegex.test(val);
                }
                return true;
              })
              .required("Required"),
            // email: Yup.string().email("Invalid Mail").required("Required"),
            // address: Yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <>
                <div className="form-group">
                  <label htmlFor="referredTo">Referred To</label>
                  <div className="d-flex">
                    <div className="dr-text">
                      <select
                        class="form-control"
                        id="salutation"
                        name="salutation"
                        value={values?.salutation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="Dr">Dr.</option>
                        <option value="Mr">Mr.</option>
                        <option value="Mrs">Mrs.</option>
                        <option value="Ms">Ms.</option>
                        <option value="NA">NA</option>
                      </select>
                    </div>
                    <div className="w-100 position-relative">
                      <input
                        type="text"
                        id="referredTo"
                        name="name"
                        className="form-control"
                        placeholder="Enter Name Doctor or Faculty"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClick={() => {
                          if (!values?.name?.trim() && !openList) {
                            setOpenList(true);
                          } else {
                            setOpenList(false);
                          }
                        }}
                      />
                      {Array.isArray(data) && data?.length && openList ? (
                        <ul className="Refferel_list">
                          {data?.map((item, index) => (
                            <li
                              onClick={() => {
                                handleFillData(item?.name);
                              }}
                              key={item?.name + index}
                            >
                              {item?.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <ErrorMessage
                    component="span"
                    name="name"
                    className="error"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="speciality">Speciality</label>
                  <input
                    type="text"
                    id="speciality"
                    className="form-control"
                    placeholder="Optional"
                    name="speciality"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.speciality}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <div className="no-with-code">
                    <span>+91</span>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      value={values.mobile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      component="div"
                      name="mobile"
                      className="error"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    className="form-control"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="span"
                    name="email"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <textarea
                    id="address"
                    name="address"
                    placeholder="Enter Address"
                    className="form-control"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="span"
                    name="address"
                    className="error"
                  />
                </div>

                <div className={`saveli ${loader ? "static_disable" : ""}`}>
                  <button onClick={handleSubmit}>
                    {loader ? (
                      <CircularProgress size="1rem" color="inherit" />
                    ) : (
                      <>SAVE</>
                    )}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Refered;
