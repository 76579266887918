import { useState, useEffect } from "react";
import PageContentLayout from "../../components/PageContentLayout";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import { constants } from "../../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import Overlay from "../../components/Overlay";


const Doctortrends = () => {
  const [trendsdata, setTrendsdata] = useState([]);
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const [update, setupdate] = useState("");
  const[data, setData] = useState([]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  useEffect(() => {
    const fetchdoctortrend = async () => {
      setShowLoader(true)
      try {
        const Doctortrends = await ServiceCall.getv2(
          "v2/doctor/list/trend/cards/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (Doctortrends) {
          setShowLoader(false)

          setTrendsdata(Doctortrends.data);
          setData(Doctortrends.data)
          setupdate(Doctortrends?.data?.main_tiles[0]?.updated_on);

        }
      } catch (err) {}
    };
    fetchdoctortrend();
  }, []);


  const rupeeSymbol = '\u20B9';


  return (
    <>
       {showLoader && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
       
    <PageContentLayout>
   <div  style={{marginRight:'10px'}} className="d-flex justify-content-end">  
      {update ? <p  className="mx-3  mb-0 text-secondary">Last updated : {update}</p> : null}

      </div>
      <Container as="main" className="w-100  ">
              
      <Row className="my-3 d-flex flex-wrap"style={{marginLeft: "1rem"}}>
          {data?.main_tiles?.map((item, index) => (
            <>
              <Col
                key={index}
                // xs={12}
                md={3}
                className="shadow p-2 mx-1   border solid my-2"
                style={{ width: "250px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{marginLeft:'15px'}}>
                    <p style={{ fontSize: "15px", color: "rgb(77, 154, 166)" }}>
                      {item.title}
                    </p>
                    <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {item.value}
                    </p>
                  </div>
                  <div className="w-25 " style={{ alignSelf: "center" }}>
                    <img
                      src={item.icon_url}
                      alt="Icon"
                      width="40"
                      height="40"
                    />
                  </div>
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Container>
      
    </PageContentLayout>
    
      

    </>
  );
};

export default Doctortrends;