import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { constants } from "../utility/constants";
import { ServiceCall } from "../utility/service-call";
import { Ls } from "../utility/utility";
import { PatientpageContext } from "../context/GlobalContext";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Input,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
  Card,
  CardContent,
  CardActions,
  CardMedia,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
}));
const Verifyac = () => {
  const classes = useStyles();
  const [pldata, setPldata] = useState(null);
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const doctorContext = useContext(PatientpageContext);
  const [apiError, setApiError] = useState("");
  const [selectedFileName, setSelectedFileName] = useState(""); 

  const setState = doctorContext.setState;

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansdata = await ServiceCall.getv2(
          "/v2/doctor/upload/registration/document/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (plansdata && plansdata.data) {
          setPldata(plansdata.data);
        } else {
          console.error("Invalid data format received from the server.");
        }
      } catch (err) {
        console.error("Error fetching plans:", err);
      }
    };
    fetchPlans();
  }, []);

  const handlle = () => {
    history.push({
      pathname: "/homie",
      search: history.location.search,
    });
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name); // Set selected file name

  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("registration_certificate", selectedFile);

      try {
        const upload = await ServiceCall.postv2(
          "/v2/doctor/upload/registration/document/",
          `${constants.hospitalId("hosp_id")}`,
          formData,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (upload) {
          setState({
            msg: upload?.data.message || "",
            type: "success",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        }

        console.log("Upload response:", upload);
      } catch (err) {
        if (err.response) {
          setApiError(err.response.data.message);
          setState({
            msg: err?.response?.data?.message || "",
            type: "error",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        } else if (err.request) {
          setApiError("Failed request " + err);
        } else {
          setApiError("Failed in general " + err);
        }
      }
    }
  };

  return (
    <>
      <div className="loginConti bodyBg">
        <p style={{ fontSize: "18px", fontWeight: "700", marginLeft: "24px" }}>
          <ArrowBackIosIcon onClick={handlle}  style={{margin: "-5px"}}/>
            Verify
        </p>
        <div style={{ border: "2px solid gray",width: "443px", borderRadius: "10px", marginLeft: "14px", marginBottom: "-21px"}}>
        {pldata && (
          <p style={{ textAlign: "center", marginTop: "10px",fontWeight: "500",color:"#199a8e" }}>
            <LocalPhoneIcon style={{marginTop: "3px", marginBottom: "-14px"}}/>
            {pldata.call_text}
          </p>
        )}
        </div>

        <div className="ver">
          {pldata ? (
            <div className="card">
              <img src={pldata.icon_url} alt="icon" />
              <h2>{pldata.title}</h2>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #e7ccee",
                  borderWidth: "10px",
                  width: "95px",
                  marginLeft: "120px",
                  backgroundColor: "#e7cce5",
                  borderRadius: "10px",
                  color: "#aa162f",
                }}
              >
                {pldata.status}
              </div>
              <p style={{ marginTop: "30px" }}>{pldata.disclaimer_text}</p>
              <p style={{ marginTop: "30px" }}>{pldata.upload_text}</p>
              <label htmlFor="fileInput">
                <TextField
                  fullWidth
                  label="Upload certificate"
                  variant="outlined"
                  name="designation"
                  margin="normal"
                  required
                  value={selectedFileName} 
                  onClick={(e) => {
                    document.getElementById("fileInput").click();
                  }}
                  InputProps={{
                    readOnly: true,
                    style: { cursor: "pointer" },
                  }}
                />
              </label>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleUpload}
                style={{ marginTop: "20px" }}
              >
                Request Callback
              </Button>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Verifyac;
