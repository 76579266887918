import { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceCall } from "../utility/service-call";
import { constants } from "../utility/constants";
import { Ls } from "../utility/utility";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { PatientpageContext } from "../context/GlobalContext";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import PageContentLayout from "./PageContentLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
}));
const EducaNexp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);
  const [apiError, setApiError] = useState("");
  const [filledFields, setFilledFields] = useState([]);

  const setState = doctorContext.setState;

  const [formData, setFormData] = useState({
    education_list: [
      {
        college_or_university: "", // Changed key name
        degree: "",
        year_of_passing: "",
      },
    ],
    experiences_list: [
      {
        facility_name: "",
        designation: "",
        duration_in_years: "",
      },
    ],
  });

  const [openModal, setOpenModal] = useState(false);
  const [openMemModal, setOpenMemModal] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [saved, setSaved] = useState(false); // State to track whether form has been saved
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/myprofile/education/experiences/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        const { education, experiences } = response.data;

        // Set form data with fetched education and experiences
        setFormData({
          education_list: education.map((education) => ({
            college_or_university: education.college_or_university || "",
            degree: education.degree || "",
            year_of_passing: education.year_of_passing || "",
          })),
          experiences_list: experiences.map((experience) => ({
            facility_name: experience.facility_name || "",
            designation: experience.designation || "",
            duration_in_years: experience.duration_in_years || "",
          })),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEducationInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      education_list: [
        {
          ...prevFormData.education_list[0],
          [name]: value,
        },
      ],
    }));
  };
  const handleExperienceInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences_list: [
        {
          ...prevFormData.experiences_list[0],
          [name]: value,
        },
      ],
    }));
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/myprofile/education/experiences/",
        `${constants.hospitalId("hosp_id")}`,
        formData,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };
  useEffect(() => {
    const isFormChanged =
      formData.education_list.some((education) =>
        Object.values(education).some((value) => value)
      ) ||
      formData.experiences_list.some((experience) =>
        Object.values(experience).some((value) => value)
      );
    setFormChanged(isFormChanged);
  }, [formData]);

  const handlle = () => {
    if (formChanged) {
      const confirmBack = window.confirm(
        "Are you sure you want to go back? Your changes will not be saved."
      );
      if (confirmBack) {
        history.push({
          pathname: "/my-profile",
          search: history.location.search,
        });
      }
    } else {
      history.push({
        pathname: "/my-profile",
        search: history.location.search,
      });
    }
  };

  const handlemem = () => {
    setOpenMemModal(true);
  };

  const handleCloseMo = () => {
    setOpenMemModal(false);
  };
  const handlememo = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // const handleEducationChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     education_list: [{ ...prevFormData.education_list[0], [name]: value }],
  //   }));
  // };
  const handleEducationChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      education_list: prevFormData.education_list.map((education, i) => {
        if (i === index) {
          return {
            ...education,
            [name]: value,
          };
        }
        return education;
      }),
    }));
  };

  const handleAddService = () => {
    const newRegistrationLength = formData.education_list.length + 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      education_list: [
        ...prevFormData.education_list,
        {
          college_or_university: "",
          degree: "", // Initialize degree field with an empty string
          year_of_passing: "",
        },
      ],
    }));
    setFilledFields((prevFilledFields) => [
      ...prevFilledFields,
      newRegistrationLength,
    ]);
  };

  const handleAddServic = () => {
    const newRegistrationLength = formData.experiences_list.length + 1;

    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences_list: [
        ...prevFormData.experiences_list,
        {
          facility_name: "",
          designation: "", // Initialize degree field with an empty string
          duration_in_years: "",
        },
      ],
    }));
    setFilledFields((prevFilledFields) => [
      ...prevFilledFields,
      newRegistrationLength,
    ]);
  };

  const handleRemoveEducation = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      education_list: prevState.education_list.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
    setFilledFields((prevFilledFields) => {
      const newFilledFields = [...prevFilledFields];
      newFilledFields.splice(indexToRemove, 1);
      return newFilledFields;
    });
  };

  const handleRemoveExperince = (indexToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      experiences_list: prevState.experiences_list.filter(
        (_, index) => index !== indexToRemove
      ),
    }));

    setFilledFields((prevFilledFields) => {
      const newFilledFields = [...prevFilledFields];
      newFilledFields.splice(indexToRemove, 1);
      return newFilledFields;
    });
  };

  // Modify the function to handle changes in experience data
  const handleExperienceChange = (index, event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences_list: prevFormData.experiences_list.map((experiences, i) => {
        if (i === index) {
          return {
            ...experiences,
            [name]: value,
          };
        }
        return experiences;
      }),
    }));
  };

  // Modify the functions to handle submission of education and experience data
  const handleEducationSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    handleCloseModal();
  };

  const handleExperienceSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    handleCloseMo();
  };

  return (
    <>
      <div className="homepage">
        <Container component="main" maxWidth="xs">
          <div
            className="prescriptionContainer__heading"
            style={{ marginLeft: "0.5rem" }}
          >
            Add Education & Experiences to display on your profile, You can add
            multiple educations and experiences. Once done, please click SAVE.
          </div>
          <form
            className={classes.root}
            onSubmit={handleSubmit}
            style={{ marginTop: "2rem" }}
          >
            <TextField
              label="Add Education"
              id="education_list"
              name="education_list"
              variant="outlined"
              fullWidth
              onClick={handlememo}
              value={formData.education_list
                .map((education) => education.degree)
                .join(", ")}
              onChange={handleEducationInputChange}
              InputProps={{
                endAdornment: (
                  <ArrowRightIcon
                    style={{ cursor: "pointer" }}
                    onClick={handlememo}
                  />
                ),
              }}
              style={{ caretColor: "transparent", background: "#fff" }}
            />

            <TextField
              label="Add Experience"
              id="experiences_list"
              name="experiences_list"
              value={formData.experiences_list
                .map((experiences) => experiences.designation)
                .join(", ")}
              onChange={handleExperienceInputChange}
              variant="outlined"
              fullWidth
              onClick={handlemem}
              style={{ marginTop: "20px", background: "#fff" }}
              InputProps={{
                // Add InputProps prop
                // Add endAdornment to place the icon at the end
                endAdornment: (
                  <ArrowRightIcon
                    style={{ cursor: "pointer" }}
                    onClick={handlemem}
                  />
                ),
              }}
            />

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              style={{ height: "40px", marginTop: "50px" }}
              // disabled={saved}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : saved ? (
                "Saved"
              ) : (
                "Save"
              )}
            </Button>
          </form>
        </Container>
      </div>
      <Modal
        open={openModal} // Control modal visibility using state
        onClose={handleCloseModal} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            maxHeight: "80vh", // Set maximum height to 80% of viewport height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CloseIcon
            onClick={handleCloseModal}
            style={{ float: "right", cursor: "pointer" }}
          />
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Education
          </p>
          <Box
            sx={{
              overflowY: "auto", // Enable vertical scrolling
              flexGrow: 1, // Allow this box to grow and enable scrolling
              marginBottom: "20px", // Add some space before the buttons
            }}
          >
            <form className={classes.root}>
              {formData.education_list.map((education, index) => (
                <div
                  key={index}
                  style={{
                    border: filledFields[index]
                      ? "2px solid lightgray"
                      : "none",
                    width: "385px",
                    margin: "10px",
                    marginLeft: "2px",
                    borderRadius: filledFields[index] ? "10px" : "none",
                    // backgroundColor: filledFields[index] ? "lightgray" : "",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    style={{ marginLeft: "26px", marginBottom: "-20px" }}
                  >
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Enter Degree"
                        variant="outlined"
                        name="degree"
                        value={education.degree || ""}
                        onChange={(event) =>
                          handleEducationChange(index, event)
                        }
                        margin="normal"
                        required
                        style={{
                          marginBottom: "21px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Year of Passing"
                        variant="outlined"
                        name="year_of_passing"
                        value={education.year_of_passing || ""}
                        onChange={(event) =>
                          handleEducationChange(index, event)
                        }
                        margin="normal"
                        required
                        style={{
                          marginBottom: "30px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    label="Enter Your College/University Name"
                    variant="outlined"
                    name="college_or_university"
                    value={education.college_or_university || ""}
                    onChange={(event) => handleEducationChange(index, event)}
                    margin="normal"
                    required
                    style={{
                      marginLeft: "35px",
                      maxWidth: "336px",
                      marginBottom: "10px",
                      backgroundColor: "#fff",
                    }}
                  />
                  <RemoveCircleOutlineIcon
                    style={{
                      marginTop: "-21px",
                      cursor: "pointer",
                      marginBottom: "44px",
                      float: "right",
                      marginRight: "-27px",
                    }}
                    onClick={() => handleRemoveEducation(index)}
                  />
                </div>
              ))}

              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddService}
                style={{ height: "40px", width: "50px", marginLeft: "336px" }}
              >
                Add+
              </Button>
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ height: "40px", marginLeft: "18px" }}
                onClick={handleEducationSubmit}
              >
                Add Education
              </Button>
            </form>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openMemModal} // Control modal visibility using state
        onClose={handleCloseMo} // Close modal on outside click
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            maxHeight: "80vh", // Set maximum height to 80% of viewport height
            width: "500px",
            p: 4,
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CloseIcon
            onClick={handleCloseMo}
            style={{ float: "right", cursor: "pointer" }}
          />
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "500" }}
          >
            Add New Experience
          </p>
          <Box
            sx={{
              overflowY: "auto", // Enable vertical scrolling
              flexGrow: 1, // Allow this box to grow and enable scrolling
              marginBottom: "20px", // Add some space before the buttons
            }}
          >
            <form className={classes.root}>
              {formData.experiences_list.map((experiences, index) => (
                <div
                  key={index}
                  style={{
                    border: filledFields[index]
                      ? "2px solid lightgray"
                      : "none",
                    width: "385px",
                    margin: "10px",
                    marginLeft: "2px",
                    borderRadius: filledFields[index] ? "10px" : "none",
                    // backgroundColor: filledFields[index] ? "lightgray" : "",
                  }}
                >
                  <Grid container spacing={2} style={{ marginLeft: "22px" }}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Enter Designation"
                        variant="outlined"
                        name="designation"
                        value={experiences.designation}
                        onChange={(event) =>
                          handleExperienceChange(index, event)
                        } // Use the experience change handler
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Input Years"
                        variant="outlined"
                        name="duration_in_years"
                        value={experiences.duration_in_years}
                        onChange={(event) =>
                          handleExperienceChange(index, event)
                        } // Use the experience change handler
                        margin="normal"
                        required
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    label="Enter Facility Name"
                    variant="outlined"
                    name="facility_name"
                    value={experiences.facility_name}
                    onChange={(event) => handleExperienceChange(index, event)} // Use the experience change handler
                    margin="normal"
                    required
                    style={{
                      marginLeft: "35px",
                      maxWidth: "336px",
                      marginBottom: "10px",
                      backgroundColor: "#fff",
                    }}
                  />
                  <RemoveCircleOutlineIcon
                    style={{
                      marginTop: "-21px",
                      cursor: "pointer",
                      marginBottom: "44px",
                      float: "right",
                      marginRight: "-27px",
                    }}
                    onClick={() => handleRemoveExperince(index)}
                  />
                </div>
              ))}
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddServic}
                style={{ height: "40px", width: "50px", marginLeft: "336px" }}
              >
                Add+
              </Button>
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ height: "40px" }}
                onClick={handleExperienceSubmit} // Use the experience submit handler
              >
                Add Experience
              </Button>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default EducaNexp;
