import React, { useState, useEffect, useContext  } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { Ls } from "../utility/utility";
import { ServiceCall } from "../utility/service-call";
import { constants } from "../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { PatientpageContext } from "../context/GlobalContext";
import PageContentLayout from "./PageContentLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      backgroundColor: "#199a8e",
    },
  },
}));

const Offerservice = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);
  const [apiError, setApiError] = useState("");
  const setState = doctorContext.setState;
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [services, setServices] = useState([
    {
      type: "",
      amount: "",
      currency: "INR",
    },
  ]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedServices = [...services];
    updatedServices[index] = { ...updatedServices[index], [name]: value };
    setServices(updatedServices);
  };

  const handleAddService = () => {
    setServices([
      ...services,
      {
        type: "",
        amount: "",
        currency: "INR",
      },
    ]);
  };

  const handlle = () => {
    history.push({
      pathname: "/my-profile",
      search: history.location.search,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/myprofile/add/services/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        // Assuming response.data contains the list of offered_services
        if (response.data && response.data.offered_services) {
          // Mapping the received data to match the structure of your services state
          const mappedServices = response.data.offered_services.map(
            (service) => ({
              type: service.type,
              amount: service.amount,
              currency: service.currency,
            })
          );
          // Setting the mapped services to the state
          setServices(mappedServices);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    try {
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/myprofile/add/services/",
        `${constants.hospitalId("hosp_id")}`,
        { services_list: services },
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  const handleRemoveService = (indexToRemove) => {
    setServices((prevServices) =>
      prevServices.filter((_, index) => index !== indexToRemove)
    );
  };


  
  


  return (
    <div style={{background: "rgb(232 243 241)"}}>
      <Container component="main" maxWidth="xs">
        <div className="prescriptionContainer__heading" style={{marginLeft: "0.5rem"}}>

          Add the offered services by you to display on your profile. You can
          click Add+ to add a new service. Once details are filled, please click
          SAVE.
        </div>

        <form
          className={classes.root}
          onSubmit={handleSubmit}
          style={{ marginTop: "2rem" }}
        >
          {services.map((service, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id={`type_${index}`}
                  name="type"
                  label="Offered Service"
                  variant="outlined"
                  value={service.type}
                  onChange={(event) => handleChange(index, event)}
                  style={{ width: "107%", background: "#fff" }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl
                  variant="outlined"
                  className={classes.salutationFormControl}
                  required
                  style={{ width: "100%",background: "#fff" }}
                >
                  <Select
                    id={`currency_${index}`}
                    name="currency"
                    value={service.currency}
                    onChange={(event) => handleChange(index, event)}
                  >
                    <MenuItem value="INR">INR</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id={`amount_${index}`}
                  name="amount"
                  label="Enter amount"
                  variant="outlined"
                  value={service.amount}
                  onChange={(event) => handleChange(index, event)}
                  style={{ width: "100%",background: "#fff" }}
                />
              </Grid>
              <RemoveCircleOutlineIcon
                style={{
                  marginTop: "-58px",
                  cursor: "pointer",
                  marginLeft: "419px",
                }}
                onClick={() => handleRemoveService(index)}
              />
            </Grid>
          ))}

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddService}
            style={{ height: "40px", width: "50px", marginLeft: "336px" }}
          >
            Add+
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            style={{ height: "40px", marginTop: "50px" }}
            disabled={saved}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : saved ? (
              "Saved"
            ) : (
              "Save"
            )}
          </Button>
        </form>
      </Container>
    </div>

  );
};

export default Offerservice;
