import React, { useState, useEffect, useContext } from "react";
import Button from "../../Tools/Button";
import Radio from "@mui/material/Radio";
import { ServiceCall } from "../../utility/service-call";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useParams, useHistory } from "react-router-dom";
import { Select } from "material-ui";
import InputLabel from "@mui/material/InputLabel";
import { Ls, datePicker } from "../../utility/utility";
import CalendarMonth from "../../Tools/CalendarMonth";
import Checkbox from "material-ui/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import { PatientpageContext } from "../../context/GlobalContext";
import Input from "@mui/material/Input";
import DownArrowIcon from "../../static/images/down_arrow.svg";
import TreatPrompt from "../../components/TreatPrompt";
import Cromptpro from "../../components/Cromptpro";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import MuiAlert from "@mui/material/Alert";

const Treatment_Procedure1 = ({templid}) => {
  const { id } = useParams();
  const history = useHistory();
  const [selectedTemp, setSelectedTemp] = useState("");
  const [data, setData] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [TreatPromptData, setTreatPromptData] = useState([]);
  const [showData, setShowData] = useState(false);

  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });

  const [treat, setTreat] = useState({
    category: "Schedule",
    instruction: "",
    start_date: dayjs().format("YYYY-MM-DD"),
    days_list: [0, 1, 2, 3, 4, 5, 6],
    duration: 5,
  });

  const [errorTreat, setErrorTreat] = useState({
    category: {
      hasError: false,
      errorMsg: "*Required",
    },
    instruction: {
      hasError: false,
      errorMsg: "*Required",
    },
    start_date: {
      hasError: false,
      errorMsg: "*Required",
    },
    days_list: {
      hasError: false,
      errorMsg: "*Required",
    },
    duration: {
      hasError: false,
      errorMsg: "*Required",
    },
  });
  // console.log(11111, treat)

  const errorHandling = (key) => {
    let value = treat[key];
    let Obj = errorTreat;
    switch (key) {
      case "days_list":
        Obj[key] = {
          errorMsg: "*Required",
          hasError: Array.isArray(value) && !!value.length ? false : true,
        };
        break;
      default:
        Obj[key] = {
          errorMsg: "*Required",
          hasError:
            value?.toString()?.trim() && !!value?.toString()?.length
              ? false
              : true,
        };
    }

    setErrorTreat(Obj);
  };

  const doctorContext = useContext(PatientpageContext);

  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const numbers = Array.from({ length: 100 }, (_, i) => i + 1);

  const handleChangeDate = (value, label) => {
    // const isoDateString = new Date(value).toISOString();
    // const dateWithoutTime = isoDateString.split('T')[0];

    const selectedDate = new Date(value);

    selectedDate.setDate(selectedDate.getDate() + 1);

    const formattedDate = selectedDate.toISOString().split("T")[0];

    // console.log(1112222, value)
    setTreat((prevState) => ({
      ...prevState,
      [label]: formattedDate,
    }));
  };

  const handleAddMore = () => {
    if (!selectedTemp) {
      setTreat({
        category: "",
        instruction: "",
        start_date: "",
        days_list: [],
        duration: "",
      });
    }
    setAddMore((prev) => !prev);
  };

  const handleCheckDosage = async () => {
    try {
      const Treat_pro = await ServiceCall.postv2(
        "/v2/doctor/fetch/treatment/details/",
        { instruction: treat.instruction },
        {
          "Content-Type": "application/json",
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
    } catch (error) {}
  };

  const handleSubmit = async () => {
    // Object.keys(treat).map((item) => {
    //   if (treat[item]) {
    //     formErrorbolean = true;
    //     setSnackbar({
    //       ...snackbar,
    //       open: true,
    //       msg: `** ${treat[item]}`,

    //     });
    //   }
    //   console.log(231231, treat[item])
    // });
    let formErrorbolean = false;
    Object.keys(treat)?.forEach((item) => {
      errorHandling(item);
    });

    Object.keys(errorTreat)?.forEach((item) => {
      if (errorTreat[item]?.hasError) {
        formErrorbolean = true;
      }
    });
    if (!formErrorbolean) {
      setButtonLoader(true);
      try {
        const data = await ServiceCall.putv2(
          "/v2/doctor/custom/template/schedule/",
          templid,
          JSON.stringify(treat),
          {
            "Content-Type": "application/json",
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (data) {
          setButtonLoader(false);
          setAddMore(true);
          // const allData = await ServiceCall.getv2(
          //   "/v2/doctor/appointment/note/treatments/",
          //   appointment?.appointmentItem?.appointment_id,
          //   {
          //     doc_secret: Ls.get("docToken"),
          //     doc_key: Ls.get("docKey"),
          //   }
          // );
          // // console.log("allData", allData)
          // setShowData(true)
          // setTreatPromptData(allData.data.records);
          getTreatment();
        }
      } catch (error) {
        setButtonLoader(false);
        console.log(error);
      }
    } else {
      doctorContext?.setState({
        msg: "*All fields are required*",
        type: "error",
        timer: 2000,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const getTreatment = async () => {
    try {
      const allData = await ServiceCall.getv2(
        "/v2/doctor/custom/template/schedule/",
        templid,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      // console.log("allData", allData)
      setShowData(true);
      setTreatPromptData(allData.data.schedule_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ServiceCall.getnewv2(
          "/v2/doctor/list/treatments","",

          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (data) {
          setData(data?.data?.records);
          //  setTreat(treat.instruction)
        }
      } catch (error) {}
    };
    fetchData();
    getTreatment();
  }, []);

  const handleDeletePrompt = async (treat, index) => {
    
    let newTreatPrompt = TreatPromptData;
    newTreatPrompt = newTreatPrompt
      .slice(0, index)
      .concat(newTreatPrompt.slice(index + 1));
    try {
      const deleteTreatment = await ServiceCall.putv2(
        "/v2/doctor/custom/template/remove/schedule/",
        `${templid}/${index}`,"",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (deleteTreatment) {
        if (newTreatPrompt?.length === 0) {
          setAddMore(false);
        }
        setTreatPromptData(newTreatPrompt);
      }
    } catch (error) {}
  };

  const handleAddMoreButton = () => {
    setAddMore(false);
    setTreat({
      category: "Schedule",
      instruction: "",
      start_date: dayjs().format("YYYY-MM-DD"),
      days_list: [0, 1, 2, 3, 4, 5, 6],
      duration: 5,
    });
    setTreatPromptData([]);
  };

  return (
    <div className="prescription_editor">
      <div className="treament_pro">
        <div className="presctiption_prompt">
          {showData && (
            <Cromptpro
              treatData={TreatPromptData}
              handleDeletePrompt={handleDeletePrompt}
            />
          )}
        </div>

        {addMore && TreatPromptData.length > 0 ? (
          <div className="savely-btn mt-2">
            <Button
              text="Add More"
              containerClass={`prescriptionpage__button__save`}
              handleClick={handleAddMoreButton}
              loader={false}
            />
          </div>
        ) : (
          <>
            <h2 className="treatment_pro_btn">
              Add Treament/Procedure/Schedule
            </h2>

            <div className="selection_finding">
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={treat.category}
                  onChange={(e) => {
                    setTreat({
                      ...treat,
                      category: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="Treatment"
                    control={<Radio />}
                    label="Treatment"
                  />
                  <FormControlLabel
                    value="Procedure"
                    control={<Radio />}
                    label="Procedure"
                  />
                  <FormControlLabel
                    value="Schedule"
                    control={<Radio />}
                    label="Schedule"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {errorTreat?.category?.hasError ? (
              <div className="prescription_error">
                {errorTreat?.category?.errorMsg}
              </div>
            ) : (
              <></>
            )}
            <FormControl fullWidth>
              <div className="pos-relative mb-4">
                <Input
                  placeholder="Input Treatment/Procedure/Findings"
                  value={treat?.instruction}
                  onChange={(e) =>{
                    setTreat({
                      ...treat,
                      instruction: e.target.value,
                    })
                    if(e.target?.value?.trim() && openList){
                       setOpenList(false)
                    }
                  }}
                  onClick={() => {
                    if (!treat?.instruction?.trim() && !openList) {
                      setOpenList(true);
                    } else {
                      setOpenList(false);
                    }
                  }}
                  // style={{ pointerEvents: "none" }}
                  className="w-100 pr-3 font-12"
                />
                <div
                  className="autocompLeteLoader pos-absolute"
                  // onClick={handleCheckDosage}
                >
                  <img src={DownArrowIcon} />
                </div>
                {Array.isArray(data) && data?.length && openList ? (
                  <ul className="Refferel_list">
                    {data?.map((item, index) => (
                      <li
                        onClick={() => {
                          setTreat({
                            ...treat,
                            instruction: item,
                          })
                          setOpenList(false)
                        }}
                        key={item + index}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              {errorTreat?.instruction?.hasError ? (
                <div className="prescription_error">
                  {errorTreat?.instruction?.errorMsg}
                </div>
              ) : (
                <></>
              )}
            </FormControl>
            <div className="prescriptionpage__medication mt-3">
              <div className="my-1">
                <CalendarMonth
                  label="StartDate"
                  formate="YYYY-MM-DD"
                  date={treat.start_date}
                  handleChange={(value) =>
                    handleChangeDate(value, "start_date")
                  }
                />
              </div>
              {errorTreat?.start_date?.hasError ? (
                <div className="prescription_error">
                  {errorTreat?.start_date?.errorMsg}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="check_days my-3">
              <div className="w-100">
                Frequency
                {errorTreat?.days_list?.hasError ? (
                  <span className="prescription_error ms-3">
                    {errorTreat?.days_list?.errorMsg}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="prescription_weeks">
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((day, index) => (
                  <div key={day}>
                    <input
                      type="checkbox"
                      checked={
                        treat?.days_list && treat?.days_list?.includes(index)
                      }
                      onChange={() => {
                        setTreat((prevState) => {
                          const updatedDaysList = prevState.days_list.includes(
                            index
                          )
                            ? prevState.days_list.filter(
                                (dayIndex) => dayIndex !== index
                              )
                            : [...prevState.days_list, index];

                          return {
                            ...prevState,
                            days_list: updatedDaysList,
                          };
                        });
                      }}
                      name={day}
                      value="60"
                    ></input>
                    <label>{day}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="days prescription_days">
              <FormControl fullWidth>
                <label id="demo-simple-select-label">Duration</label>
                <div className="d-flex align-items-center">
                  <select
                    onChange={(e) => {
                      setTreat({
                        ...treat,
                        duration: parseInt(e.target.value, 10),
                      });
                    }}
                    name="validity"
                  >
                    {numbers.map((value) => (
                      <option selected={treat?.duration == value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <p className="mx-3 mt-0 mb-0">Days</p>
                </div>
              </FormControl>
              {errorTreat?.duration?.hasError ? (
                <div className="prescription_error mt-2">
                  {errorTreat?.duration?.errorMsg}
                </div>
              ) : (
                <></>
              )}
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbar?.open}
              onClose={() => {
                setSnackbar({ ...snackbar, msg: "", open: false });
              }}
              autoHideDuration={3000}
            >
              <MuiAlert
                elevation={6}
                severity="error"
                onClose={() => {
                  setSnackbar({ ...snackbar, msg: "", open: false });
                }}
              >
                {snackbar?.msg}
              </MuiAlert>
            </Snackbar>
            <div className="savely-btn mt-5">
              <Button
                text={buttonLoader ? <CircularProgress size={16} /> : "Save"}
                containerClass={`${
                  (addMore && TreatPromptData.length > 0) || buttonLoader
                    ? "prescriptionpage__button__disable"
                    : ""
                } prescriptionpage__button__save`}
                handleClick={handleSubmit}
                loader={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Treatment_Procedure1;
