import { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceCall } from "../utility/service-call";
import { constants } from "../utility/constants";
import { countriesList } from "../utility/constants";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import RegitrationCouncil from "../pages/Doctor/Regitration-Council";
import { Ls } from "../utility/utility";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PatientpageContext } from "../context/GlobalContext";
import Map from "./Map";
import PageContentLayout from "./PageContentLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(20),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      backgroundColor: "#199a8e", // Change hover background color
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999 !important",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 10),
  },
}));
const Facility = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);

  const [phoneCode, setPhoneCode] = useState("+91");
  const setState = doctorContext.setState;
  const [apiError, setApiError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [addressFieldFocused, setAddressFieldFocused] = useState(false);
  const [addressFieldBlurred, setAddressFieldBlurred] = useState(false);
  const [getMapData, setMapData] = useState({});
  const [showMapModal, setShowMapModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  const [formData, setFormData] = useState({
    facility_name: "",
    facility_phone: "",
    facility_address: "",
    hosp_registration_no: "",
    hosp_accreditation_by: "",
    google_place_id: "",
    google_geo_lat: "",
    google_geo_lng: "",
  });
  const [add, setAdd] = useState({
    facility_address: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.getv2(
          "/v2/doctor/myprofile/facility/details/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (response) {
          const formDat = response.data;

          // Update formData state with response data
          setFormData({
            facility_name: formDat.facility_name || "",
            facility_phone: formDat.facility_phone || "",
            hosp_registration_no: formDat.hosp_registration_no || "",
            hosp_accreditation_by: formDat.hosp_accreditation_by || "",
            google_place_id: formDat.google_place_id || "",
            google_geo_lat: formDat.google_geo_lat || "",
            google_geo_lng: formDat.google_geo_lng || "",
          });
          setAdd({ facility_address: formDat.facility_address || "" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to ensure the effect runs only once on component mount

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
    if (name === "facility_phone") {
      if (!isValidPhoneNumber(value)) {
        // Define isValidPhoneNumber according to your validation logic
        setPhoneError(true); // Set phone number error state
      } else {
        setPhoneError(false); // Reset phone number error state
      }
    }
    if (name === "facility_address") {
      // Update the locaData state with the new address data
      setAdd({ ...add, [name]: value });
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Remove any non-numeric characters from the phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has exactly 10 digits and contains only numeric characters
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const handleShowMapModal = () => {
    setShowMapModal(true);
  };
  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const handleUpdateGoogleMapPlace = (values) => {
    setFormData({
      ...formData,
      // facility_address: values.address,
      google_place_id: values.google_place_id,
      google_geo_lat: values.google_geo_lat,
      google_geo_lng: values.google_geo_lng,
    });
  };

  const saveGoogleMapValues = () => {
    setMapData(formData);
    handleCloseMapModal();
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const body = {
        facility_name: formData?.facility_name,
        facility_phone: formData?.facility_phone,
        facility_address: add?.facility_address,
        hosp_registration_no: formData?.hosp_registration_no,
        hosp_accreditation_by: formData?.hosp_accreditation_by,
        google_place_id: formData?.google_place_id,
        google_geo_lat: formData?.google_geo_lat,
        google_geo_lng: formData?.google_geo_lng,
      };
      const Handlesub = await ServiceCall.putv2(
        "/v2/doctor/myprofile/facility/details/",
        `${constants.hospitalId("hosp_id")}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (Handlesub) {
        setLoading(false);
        setState({
          msg: Handlesub?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      setSaved(true); // Update state to indicate form has been saved
    } catch (err) {
      if (err.response) {
        setApiError(err.response.data.message);
        setState({
          msg: err?.response?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      } else if (err.request) {
        setApiError("Failed request " + err);
      } else {
        setApiError("Failed in general " + err);
      }
    }
  };

  const handlle = () => {
    history.push({
      pathname: "/my-profile",
      search: history.location.search,
    });
  };


  return (
    <>
      <div  style={{background: "rgb(232 243 241)"}}>
        <Container component="main" maxWidth="xs">
     
          <div className="prescriptionContainer__heading" style={{marginLeft: "0.5rem"}}>

            Add your medical facility (Practice/Clinic/Office) details, Once
            done, please click SAVE.
          </div>
          <form
            className={classes.root}
            //  onSubmit={handleSubmit}
            style={{ marginTop: "2rem" }}
          >
            <TextField
              id="facility_name"
              name="facility_name"
              label="Facility Name"
              variant="outlined"
              value={formData?.facility_name}
              onChange={handleChange}
              required
              fullWidth
              disabled
              style={{background: "#fff"}}
            />
            <FormControl variant="outlined" style={{ width: "95%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={phoneCode}
                  // onChange={handleChan}
                  className="w-23"
                  classes={{ select: classes.select }}
                  disabled
                >
                  {countriesList &&
                    countriesList.map((item, index) => (
                      <MenuItem value={item.code} key={item.code + "-" + index}>
                        {item.code}
                      </MenuItem>
                    ))}
                </Select>
                <InputLabel
                  htmlFor="facility_phone"
                  style={{ marginLeft: "101px" }}
                >
                  Phone Number for bookings*
                </InputLabel>
                <Input
                  id="facility_phone"
                  name="facility_phone"
                  value={formData?.facility_phone}
                  onChange={handleChange}
                  label="Phone Number"
                  required
                  error={phoneError}
                  style={{ flex: 1, marginLeft: 6, width: "100px", background: "#fff" }}
                  disabled
                />
              </div>
              {phoneError && (
                <FormHelperText error>
                  Please enter a valid phone number.
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              id="facilityAddress"
              name="facility_address"
              variant="outlined"
              value={add?.facility_address}
              onChange={handleChange}
              required
              fullWidth
              disabled
              onFocus={() => setAddressFieldFocused(true)}
              onBlur={() => setAddressFieldBlurred(true)}
              error={addressFieldBlurred && !getMapData?.google_place_id}
              helperText={
                addressFieldBlurred && !getMapData?.google_place_id
                  ? "Please select the location."
                  : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowMapModal}
                      style={{ fontSize: "15px", fontWeight: "700" }}
                    >
                      Select Location
                      <AddLocationIcon style={{ color: "#cf3939" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{background: "#fff"}}
            />
            <TextField
              id="firstName"
              name="hosp_registration_no"
              label="Enter your Registration ID (NMC)"
              variant="outlined"
              value={formData?.hosp_registration_no}
              onChange={handleChange}
              required
              fullWidth
              disabled
              style={{background: "#fff"}}

            />
            <FormControl variant="outlined" fullWidth               style={{background: "#fff"}}
>
              <InputLabel id="registration-council-label">
                Select registration council*
              </InputLabel>
              <Select
                labelId="registration-council-label"
                id="registration-council"
                name="hosp_accreditation_by"
                value={formData?.hosp_accreditation_by}
                onChange={handleChange}
                label="Registration Council"
                // Set the required attribute for validation
                required
                disabled
              >
                {RegitrationCouncil.map((council) => (
                  <MenuItem key={council.value} value={council.value}>
                    {council.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={true} // Set disabled attribute to true to disable the button
              style={{ height: "40px", marginTop: "50px" }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "SAVE"
              )}
            </Button>
            <a
              href="https://www.provider.rxone.app/user/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              If you want to change then visit Provider Portal
            </a>
          </form>
        </Container>
      </div>
      <Modal
        // open={showMapModal}
        onClose={handleCloseMapModal}
        className={classes.modal}
        disabled
      >
        <div className={classes.paper}>
          <Typography variant="h6" component="h2">
            Map
          </Typography>
          <div
            style={{
              width: "356px",
              height: "414px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Map
              currentValues={getMapData}
              updateGoogleMapValues={handleUpdateGoogleMapPlace}
            />
          </div>
          <Button
            onClick={saveGoogleMapValues}
            style={{
              backgroundColor: "rgb(77, 154, 166)",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Save
          </Button>
        </div>
      </Modal>

    </>
  );
};
export default Facility;
