// import Col from "react-bootstrap/Col";
import { Col } from "react-bootstrap";

function TimeSlot(props) {
  const { slot, disabled, isSelected, onClick } = props;

  return (
    // <Col xs={4} md={3}>
      <button
        className={`time-slot-btn p-1 p-md-2 mb-2 ${
          isSelected ? "active-time-slot" : ""
        }`}
        type="button"
        onClick={onClick}
        disabled={disabled}
        style={{
          backgroundColor: isSelected ? '#199a8e' : '',
          color: isSelected ? 'white' : '',


        }}
      >
        {slot} {+slot.substring(0, 2) < 12 ? "AM" : "PM"}
      </button>
    // </Col>
    // const {selectedAppointmentType, slectslots, profilename, appointmentid, amounts } = props;

  //   <p className="my-2">
  //   Amount Due: ₹
  //   <span className="fw-bold text-capitalize" style={{textDecoration: "underline", fontWeight: "500"}}>
  //     {amounts}
  //   </span>
  // </p>
  );
}

export default TimeSlot;
