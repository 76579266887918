import React from "react";
import { parseJson, Ls } from "../utility/utility";
import ContinueIcon from "../static/images/Arrow--right.svg";
import { CircularProgress } from "@material-ui/core";
const LoginButton = ({
  onSubmit,
  loader = false,
  color,
  disabled = false,
  props,
}) => {
  return (
    <button
      className="Arrow--right"
      onClick={onSubmit}
      disabled={disabled}
      {...props}
    >
      {loader ? (
        <CircularProgress size={16} color={color || "primary"} />
      ) : (
        <img src={ContinueIcon} />
      )}
    </button>
  );
};

export default LoginButton;
