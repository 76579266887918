import Header from "../../components/Header";
import { useEffect, useState, useContext } from "react";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ImageAvatar from "../../components/ImageAvatar";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { constants } from "../../utility/constants";
import { PatientpageContext } from "../../context/GlobalContext";
import BasicDetails from "../../components/BasicDetails";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@mui/system";
import PageContentLayout from "../../components/PageContentLayout";
import Sociallink from "../../components/Sociallink";
import EducaNexp from "../../components/EducaNexp";
import Offerservice from "../../components/Addservice";
import Facility from "../../components/facility";
import AwardNRew from "../../components/AwardsNRew";
import Overlay from "../../components/Overlay";


const Doctorbutton = (props) => {
  const [btndata, setBtnData] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const doctorContext = useContext(PatientpageContext);
  const [showBasicDetails, setShowBasicDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(true); // State to control loader visibility
  const [homedata, setHomedata] = useState("");
  const [selectedButton, setSelectionButtion] = useState("");
  const [selectedPage, setSelectedPage] = useState(null); // State to track the selected page
  const [isLoading, setIsLoading] = useState(true); // Initial state is loading


  // const Loader = () => (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       height: "100vh",
  //     }}
  //   >
  //     <CircularProgress />
  //   </div>
  // );

  const history = useHistory();

  const getPageNameFromTags = (tags) => {
    switch (tags) {
      case "Basic Details":
        return "BasicDetails";
      case "Awards & Publications":
        return "AwardsAndPublications";
      case "Education & Experiences":
        return "EducationAndExperiences";
      case "Offered Services":
        return "OfferedServices";
      case "Social Accounts":
        return "SocialAccounts";
      case "Facility Details":
        return "FacilityDetails";
      default:
        return null;
    }
  };
 useEffect(() => {
  const getpro = async () => {
    // setIsLoading(true);
    try {
      const getData = await ServiceCall.gett("/v2/doctor/myprofile/buttons", {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      });
      if (getData) {
        setIsLoading(false);

        const buttonslist = getData?.data?.buttons;
        const excludedButtons = ["Manage Calendar"];
        const filteredButtonList = buttonslist.filter(
          (bl) => !excludedButtons.includes(bl.tags)
        );

        setBtnData(filteredButtonList);
        setSelectionButtion(filteredButtonList[0]?.title);

        // Set the selectedPage state based on the first button's tags
        const firstButtonTags = filteredButtonList[0]?.tags;
        setSelectedPage(getPageNameFromTags(firstButtonTags));
      }
    } catch (err) {}
  };
  getpro();
}, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  const handleButtonClick = (button) => {
    setSelectionButtion(button.title); // Update the selectedButton state
  
    if (button.tags === "Basic Details") {
      setSelectedPage("BasicDetails");
    } else if (button.tags === "Awards & Publications") {
      setSelectedPage("AwardsAndPublications");
    } else if (button.tags === "Education & Experiences") {
      setSelectedPage("EducationAndExperiences");
    } else if (button.tags === "Offered Services") {
      setSelectedPage("OfferedServices");
    } else if (button.tags === "Social Accounts") {
      setSelectedPage("SocialAccounts");
    } else if (button.tags === "Facility Details") {
      setSelectedPage("FacilityDetails");
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleUpload(file); // Pass the selectedFile as an argument
    window.location.reload(); // Refresh the page
  };

  const handleUpload = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const upload = await ServiceCall.putv2(
          "/v2/update/doctor/pic/",
          `${constants.hospitalId("hosp_id")}`,
          formData,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handlle = () => {
    history.push({
      pathname: "/home",
      search: history.location.search,
    });
  };

  return (
    <>
        {isLoading && (
      <Overlay opacity=".6">
        <CircularProgress size="20px" color="success" />
      </Overlay>
    )}

      <PageContentLayout>
        {btndata && Array.isArray(btndata) && btndata.length > 0 ? (
          <div className="query_container">
            <div className="prescription_toolbar">
              {btndata.map((bl) => (
                <button
                  // key={bl.button_id} // Make sure each button has a unique key
                  className={`prescription_toolbar_button ${
                    bl.title === selectedButton
                      ? "prescription_toolbar_button_selected"
                      : ""
                  }`}
                  onClick={() => handleButtonClick(bl)}
                >
                  <span>{bl.tags}</span>
                </button>
              ))}
            </div>
          </div>
        ) : (
          <p></p>
        )}

        {/* Render the selected page component */}
        {selectedPage === "BasicDetails" && <BasicDetails />}
        {selectedPage === "AwardsAndPublications" && <AwardNRew />}
        {selectedPage === "EducationAndExperiences" && <EducaNexp />}
        {selectedPage === "OfferedServices" && <Offerservice />}
        {selectedPage === "SocialAccounts" && <Sociallink />}
        {selectedPage === "FacilityDetails" && <Facility />}
    </PageContentLayout>
    </>
  );
};

export default Doctorbutton;