import React, { useContext, useEffect, useState } from "react";
import AddBusinessSharpIcon from "@mui/icons-material/AddBusinessSharp";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbTwilightOutlinedIcon from "@mui/icons-material/WbTwilightOutlined";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { ServiceCall } from "../utility/service-call";
import { PatientpageContext } from "../context/GlobalContext";
import { constants } from "../utility/constants";
import { Ls } from "../utility/utility";
import TimeSlot from "./TimeSlot";
import CircularProgress from "@mui/material/CircularProgress";
import Overlay from "./Overlay";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarMonth1 from "../Tools/CalendarMonth1";
import Switch from "../Tools/Swtich";
import { useHistory } from "react-router-dom";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Box,
  TextField,
  Typography,
  FormHelperText,
  Input,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import Payment from "../pages/Doctor/Payment";
import dayjs from "dayjs";
const appointmentType = [
  { label: "Online (Video)", value: "online" },
  { label: "In-Person", value: "in-person" },
];

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function SelectDoctor(props) {
  const { profileid, profill, setopn, proage, progen } = props;
  const doctorContext = useContext(PatientpageContext);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const history = useHistory("");

  const [fetchingDocs, setFetchingDocs] = useState(true);
  const [fetchingAvailableSlots, setFetchingAvailableSlots] = useState(false);
  const [bookingAppointment, setBookingAppointment] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [morningSlots, setMorningSlots] = useState([]);
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const [noDocsMsg, setNoDocsMsg] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedSlotVal, setSelectedSlotVal] = useState({});
  const [iszeroOrder, setIsZeroOrder] = useState(false);
  const [isFollowup, setIsFollowup] = useState(false);
  const [isWalkIn, setIsWalkIn] = useState(false);
  const [iszerores, setIszerores] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Initial state is loading
  const casefileid = sessionStorage.getItem("casefileid");
  const reportedProblem = sessionStorage.getItem("reportedproblem")

  const [amount, setAmount] = useState("");
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState("in-person");
  const [showSelectDoctorModal, setShowSelectDoctorModal] = useState(false);

  const handleTimeSlotClick = (slot, isAvailable) => {
    if (!isAvailable) return;
    setSelectedSlotVal(slot);
    setSelectedSlot(slot);
  };
  const setState = doctorContext.setState;

  const fetchAvailableSlots = async (date) => {
    const formattedDate = date.toString().substring(0, 10);

    setIsLoading(true);
    try {
      setFetchingAvailableSlots(true);
      const availableSlotsRes = await ServiceCall.getv2(
        "/v2/doctor/fetch/available/slots/",
        `${constants.hospitalId(
          "hosp_id"
        )}/${selectedAppointmentType}/${formattedDate}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (availableSlotsRes) {
        setIsLoading(false);

        const allSlots = availableSlotsRes.data.time_slots;
        setAvailableSlots(allSlots);

        const morning = [];
        const afternoon = [];
        const evening = [];

        for (const slot of allSlots) {
          const hh = +slot.slot.substring(0, 2);
          const mm = +slot.slot.substring(3);

          if (hh < 12) {
            morning.push(slot);
          } else if (hh < 17 || (hh === 17 && mm < 30)) {
            afternoon.push(slot);
          } else {
            evening.push(slot);
          }
        }

        setMorningSlots(morning);
        setAfternoonSlots(afternoon);
        setEveningSlots(evening);
      }
    } catch (err) {
      console.error("Error fetching available slots:", err);
      setApiError("Failed to fetch available slots. Please try again later.");
    } finally {
      setFetchingAvailableSlots(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailableSlots(selectedDate);
  }, [selectedAppointmentType]);

  const handleNext = async () => {
    setIsLoading(true);
    setBookingAppointment(true);
  
    try {
      const requestBody = {
        is_inperson: selectedAppointmentType === "in-person",
        appointment_date: selectedDate,
        is_walkin_appointment: isWalkIn ? "True" : "False",
        is_zero_order: iszeroOrder ? "True" : "False",
        is_followup: isFollowup ? "True" : "False",
      };
  
      if (!isWalkIn && !(iszeroOrder && isFollowup)) {
        requestBody.time_alloted = selectedSlot.slot.replace(":", ".");
        requestBody.slot_duration = selectedSlot.slot_duration;
        requestBody.end_time = selectedSlot.end_time.replace(":", ".");
      }
      if (reportedProblem) {
        requestBody.reported_problem = reportedProblem;
      } else if (casefileid) {
        requestBody.case_file_id = casefileid;
      }
  
      const nextbtn = await ServiceCall.postv2(
        "v2/doctor/initiate/appointment/booking/",
        `${constants.hospitalId("hosp_id")}/${profileid}`,
        requestBody,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
  
      if (nextbtn) {
        setIsLoading(false);
        setAppointment(nextbtn?.data?.appointment_id);
        setAmount(nextbtn?.data?.consult_charge);
        
      if ((selectedSlot || isWalkIn) && !iszeroOrder && !isFollowup) {
          setShowSelectDoctorModal(true);
        } else {
          history.push({
            pathname: "/home/0",
          });
  
          setTimeout(() => {
            history.replace("/home");
          }, 0);
  
          doctorContext.getAppointment();
        }
        
        setLoading(false);
        setState({
          msg: nextbtn?.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      console.error("Error booking appointment:", err);
      setIsLoading(false);
      setBookingAppointment(false);
      setState({
        msg: "Failed to book appointment. Please try again.",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    } finally {
      setBookingAppointment(false);
    }
  };
  const handleFollowUP = (checked) => {
    setIsWalkIn(checked);
  };
  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <Container
        className="w-100"
        style={{ marginTop: "-12px", backgroundColor: "#f2f2f2" }}
      >
        <Row className="align-items-center mb-2">
          <span className="w-auto pe-1">Booking for - </span>
          <span
            className="fw-bold w-auto px-0"
            style={{ fontSize: "17px", fontWeight: "500" }}
          >
            {profill}({progen}, {proage})
          </span>
        </Row>

        <Row className="align-items-center my-2">
          <Col xs={12} md={8}>
            <div className="px-0 w-100 d-flex justify-content-evenly mt-2 mt-md-0 justify-content-md-between align-items-center">
              <div className="d-flex align-items-center ms-2">
                <AddBusinessSharpIcon />
                <button
                  type="button"
                  className={`time-slot-btn p-2 px-md-3 ms-2 ${
                    selectedAppointmentType === "in-person"
                      ? "active-time-slot"
                      : ""
                  }`}
                  style={{
                    backgroundColor:
                      selectedAppointmentType === "in-person" ? "#199a8e" : "",
                    color:
                      selectedAppointmentType === "in-person" ? "white" : "",
                  }}
                  onClick={() => setSelectedAppointmentType("in-person")}
                >
                  In-Person
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <CalendarMonth1
          label="Select Date"
          format="YYYY-MM-DD"
          date={selectedDate}
          handleChange={(value) => {
            const formattedDate = dayjs(value).format("YYYY-MM-DD");
            setSelectedDate(formattedDate);
            fetchAvailableSlots(formattedDate);
          }}
          InputProps={{
            inputProps: {
              min: dayjs().format("YYYY-MM-DD"),
              onKeyPress: (e) => {
                e.preventDefault();
              },
            },
          }}
        />
        <Switch
          checked={isWalkIn}
          label="Walk-in Appointment?"
          labelInput="remind_patient"
          handleChange={(e) => {
            handleFollowUP(e.target.checked);
            if (e.target.checked) {
              setIsZeroOrder(false);
              setIsFollowup(false);
            }
          }}
        />
        {isWalkIn && (
          <Switch
            label="Zero Order (No Payment)"
            labelInput="remind_patient"
            checked={iszeroOrder}
            handleChange={(e) => {
              setIsZeroOrder(e.target.checked);
              if (e.target.checked) {
                setIsFollowup(false);
              }
            }}
          />
        )}
        {isWalkIn && iszeroOrder && (
          <Switch
            label="Follow-Up Appointment"
            labelInput="remind_patient"
            checked={isFollowup}
            handleChange={(e) => setIsFollowup(e.target.checked)}
          />
        )}
        {!isWalkIn && !(iszeroOrder && isFollowup) ? (
          !fetchingAvailableSlots ? (
            availableSlots?.length ? (
              <>
                <h2 className="align-items-center mb-2">Available Slots</h2>
                <Row>
                  <Col xs={12} className="time-period-heading">
                    <WbTwilightOutlinedIcon className="me-2" /> Morning
                  </Col>

                  {morningSlots.map((availableSlot, index) => (
                    <TimeSlot
                      key={index}
                      slot={availableSlot.slot}
                      disabled={!availableSlot.is_available}
                      onClick={() => {
                        handleTimeSlotClick(
                          availableSlot.slot,
                          availableSlot.is_available
                        );
                        setSelectedSlot(availableSlot);
                      }}
                      isSelected={availableSlot.slot === selectedSlot?.slot}
                    />
                  ))}
                </Row>
                <Row>
                  <Col xs={12} className="time-period-heading">
                    <WbSunnyIcon className="me-2" /> Afternoon
                  </Col>

                  {afternoonSlots.map((availableSlot, index) => (
                    <TimeSlot
                      key={index}
                      slot={availableSlot.slot}
                      disabled={!availableSlot.is_available}
                      onClick={() => {
                        handleTimeSlotClick(
                          availableSlot.slot,
                          availableSlot.is_available
                        );
                        setSelectedSlot(availableSlot);
                      }}
                      isSelected={availableSlot.slot === selectedSlot?.slot}
                    />
                  ))}
                </Row>

                <Row>
                  <Col xs={12} className="time-period-heading">
                    <ModeNightIcon className="me-2" /> Evening
                  </Col>

                  {eveningSlots.map((availableSlot, index) => (
                    <TimeSlot
                      key={index}
                      slot={availableSlot.slot}
                      disabled={!availableSlot.is_available}
                      onClick={() => {
                        handleTimeSlotClick(
                          availableSlot.slot,
                          availableSlot.is_available
                        );
                        setSelectedSlot(availableSlot);
                      }}
                      isSelected={availableSlot.slot === selectedSlot?.slot}
                    />
                  ))}
                </Row>
              </>
            ) : (
              !noDocsMsg && (
                <p className="w-100 text-center fw-bold">
                  {selectedDocId
                    ? "No Slots Available"
                    : "Select a Doctor to get time slots"}
                </p>
              )
            )
          ) : (
            <div className="w-100 text-center">
              <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                className="mx-auto"
              />
            </div>
          )
        ) : (
          <p className="w-100 text-center fw-bold">
            Slot selection not required for this appointment type
          </p>
        )}

        <div className="w-100 d-flex justify-content-end my-2">
        <Button
  className="w-100"
  disabled={bookingAppointment || (
    !isWalkIn && !selectedSlot && !(iszeroOrder && isFollowup)
  )}
  onClick={handleNext}
  style={{
    backgroundColor: (isWalkIn || selectedSlot || (iszeroOrder && isFollowup)) ? "#199a8e" : "",
    color: (isWalkIn || selectedSlot || (iszeroOrder && isFollowup)) ? "white" : "",
    height: "45px",
    cursor: "pointer",
  }}
>
  {bookingAppointment ? (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      className="mx-auto"
    />
  ) : (
    "Next"
  )}
</Button>
        </div>
      </Container>
      <Modal
        open={showSelectDoctorModal}
        onClose={() => setShowSelectDoctorModal(false)}
        aria-labelledby="select-doctor-modal-title"
        aria-describedby="select-doctor-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <ArrowBackIosIcon
            onClick={() => setShowSelectDoctorModal(false)}
            style={{ float: "left", cursor: "pointer" }}
          />

          <Payment
            selectedAppointmentType={selectedAppointmentType}
            slectslots={selectedSlot}
            profilename={profill}
            appointmentid={appointment}
            amounts={amount}
            setopen={setopn}
            isWalkIn={isWalkIn}  // Add this line

          />
        </Box>
      </Modal>
    </>
  );
}

export default SelectDoctor;
