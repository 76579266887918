import React, { useContext, useState, useRef } from "react";
import PrescriptionEditorNew from "../../components/PrescriptionEditorNew";
import { useHistory, useLocation } from "react-router-dom";
import {
  Navigation,
  removeEmptyValues,
  datePicker,
  Ls,
} from "../../utility/utility";
import { PatientpageContext } from "../../context/GlobalContext";
import MuiAlert from "@mui/material/Alert";
import { Grid, Typography, Box } from "@mui/material";
import DoctorsAppointmentListItemNew from "../../components/DoctorsAppointmentListItemNew";
import Snackbar from "@mui/material/Snackbar";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Slide from "@mui/material/Slide";
import DownArrowIcon from "../../static/images/down_arrow.svg";
import DialogScreen from "../../Tools/DialogScreen";
import { Skeleton } from "@mui/material";
import { Input } from "@mui/material";
import Stack from "@mui/material/Stack";
import { dose_admin_method } from "../../static/staticData/staticData";
import PrescriptionShareIcon from "../../static/images/prescriptionShare.png";
import Backdrop from "@mui/material/Backdrop";
import { OverlayContext } from "../../OverlayManager/Overlay";
import { OverlayType } from "../../OverlayManager/Context";
import button from "../../Tools/Button";
import { FormHelperText } from "@material-ui/core";
import dayjs from "dayjs";
import RightArrowIcon from "../../static/images/Arrow--right.svg";
import { ServiceCall } from "../../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import PrescriptionPrompt from "../../components/PrescriptionPrompt";
import { useEffect } from "react";
import EyeIcon from "../../static/images/eyeSvg.svg";
import ShareIcon from "../../static/images/shareSvg.svg";
import { medication_form } from "../../static/staticData/staticData";
import SwipableButton from "../../components/SwipableButton";
import { apiCall } from "../../utility/service-call";
import PrinterSvg from "../../static/images/Printer.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Carousel from "nuka-carousel/lib/carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PrescriptionNotes from "./PrescriptionNotes";
import PageContentLayout from "../../components/PageContentLayout";
import CloseIcon from "@material-ui/icons/Close";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import QuadrantGrid from "../../components/QuadrantGrid";

import {
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputAdornment,
  IconButton,
  Popper,
  Paper,
  Grow,
  InputLabel,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MedicationRoundedIcon from "@mui/icons-material/MedicationRounded";
import DeviceThermostatRoundedIcon from "@mui/icons-material/DeviceThermostatRounded";
import Vitalimg from "../../static/health-filled-svgrepo-com.svg";
const Prescription = (props) => {
  const location = useLocation();
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);
  const [openMemModal, setOpenMemModal] = useState(false);
  const Overlay = useContext(OverlayContext);
  const setState = doctorContext.setState;
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const [addMore, setAddMore] = useState(false);
  const [selectedRecords, setToSelectRecords] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });
  const listMedication = doctorContext?.listMedications;
  const [sharePrescription, setSharePrescription] = useState(false);
  const [openModal, setOpenModal] = useState(false); // State for managing modal open status
  const [newTemplateName, setNewTemplateName] = useState(""); // State for new template name
  const [newTemplateNames, setNewTemplateNames] = useState("");

  const [buttonList, setButtonList] = useState([]);

  const [view, setView] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [print, setPrint] = useState([]);
  const [tempdata, setTempdata] = useState({ templates: [] });
  const [customTemplate, setCustomTemplate] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const anchorRef = useRef(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [prescriptiondata, setPrescriptiondata] = useState("");
  const [newTemplate, setNewTemplate] = useState("");
  const overlay = useContext(OverlayContext);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [openModals, setOpenModals] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [newTemplateNameValue, setNewTemplateNameValue] = useState("");

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const appointmentid = doctorContext?.patientInfo?.appointment_id;
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);

  const handleCloseMo = () => {
    setOpenModal(false);
  };

  const handleCloseMos = () => {
    setOpenModals(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        doctorContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment__screen__exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment__screen__exit__buttons">
              <button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                  Ls.remove("pt_key");
                  Ls.remove("pt_token");
                  history.push({
                    pathname: "/",
                  });
                }}
                className="mr-2"
                text="Exit"
                containerClass="m-auto appointment__screen__verify"
              ></button>
              <button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                }}
                className="mr-2"
                text="CANCEL"
                containerClass="m-auto appointment__screen__verify"
              ></button>
            </div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      };
      if (appointment?.appointmentItem?.appointment_id) {
        doctorContext.fetchPatientInfo(
          appointment?.appointmentItem?.appointment_id
        );
      }
    }
    return () => {
      if (typeof window !== "window") {
        window.onpopstate = null;
        doctorContext?.setPrescriptionSymptoms("");
        doctorContext?.setFetchPrescription("");
        doctorContext?.setListMedication &&
          doctorContext?.setListMedication({
            dose_unit: "",
            dose_admin_method: "",
            dietary_instructions: "",
            dose_timings: "",
          });
      }
    };
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const tempdata = await ServiceCall.gett(
          "v2/doctor/prescription/templates",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (tempdata) {
          setTempdata(tempdata.data);
        }
        // Add any additional logic to handle the fetched data
      } catch (err) {
        console.error("Error fetching templates:", err);
        // Add any additional error handling logic
      }
    };

    fetchTemplates();
  }, []);

  const handleTemplateSelect = async (prescriptionTemplateId) => {
    try {
      const response = await ServiceCall.getv2(
        `/v2/doctor/prescription/custom/templates/`,
        `${prescriptionTemplateId}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (response) {
        setPrescriptiondata(response.data);
        // After setting prescriptiondata state, call the POST API
        if (buttonList.length > 0) {
          setSelectionButtion(buttonList[0]?.button_action);
        }
        await handlePostApi(response.data);
      }
    } catch (err) {
      console.error("Error fetching template data:", err);
    }
  };

  let templateNames = [];

  if (tempdata && tempdata.templates) {
    templateNames = tempdata.templates.map(
      (template) => template.template_name
    );
  }

  const handleTemplateChange = (event) => {
    const selectedValue = event.target.value;
    const selectedTemplate = tempdata.templates.find(
      (template) =>
        template.template_name === selectedValue ||
        template.prescription_template_id === selectedValue
    );
    setSelectedTemplate(selectedValue);
    setSelectedTemplateId(selectedTemplate.prescription_template_id);
    setSelectedTemplateName(selectedTemplate.template_name);
    setNewTemplateNameValue(selectedTemplate.template_name);
    handleTemplateSelect(selectedTemplate.prescription_template_id);
  };

  const hadledoctordata = async () => {
    if (!selectedTemplate) {
      setOpenModal(true); // Show modal when no template is selected
      setLoading(false);
    } else {
      setOpenModals(true); // Show modal when no template is selected
    }
  };

  const Loader = (text) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoader">
          <h3>Please Wait...</h3>
          <p>{text}</p>
          <CircularProgress color="success" />
        </div>
      ),
      removeCloseButton: true,
    });
  };

  const handlePostApi = async (responseData) => {
    try {
      const requestPayload = {
        template_name: responseData.templates.template_name,
        medication_list: responseData.templates.medication_list,
        schedule_list: responseData.templates.schedule_list,
        patient_symptoms_list: responseData.templates.patient_symptoms_list,
        doc_findings_list: responseData.templates.doc_findings_list,
        doc_recommended_tests_list:
          responseData.templates.doc_recommended_tests_list,
      };

      const handlepost = await ServiceCall.putv2(
        "v2/doctor/prefilled/prescription/templates/",
        appointmentid,
        requestPayload,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      // Handle the response from the POST API if needed
      if (handlepost) {
        console.log("POST API response:", handlepost.data);
        // Add any additional logic to handle the POST API response
      }
    } catch (err) {
      console.error("Error with POST API:", err);
      // Add any additional error handling logic
    }
  };

  const ExtraFeilds = {
    dosage: "",
    duration: "",
    medication: "",
    medication_form: "Tablet",
  };
  const [PrescriptionPromptData, setPrescriptionPromptData] = useState([]);
  const [finalDiagnosisFilled, setFinalDiagnosisFilled] = useState(false);
  const [selectedButton, setSelectionButtion] = useState("");

  const dateBeforeValidation = (value) => {
    let before = true;
    let today = dayjs();
    if (today.$y <= value.$y && today.$M <= value.$M && today.$D <= value.$D) {
      before = false;
    }
    return before;
  };

  const handleClick = () => {
    history.push({
      pathname: "/my-appointments",
      search: history?.location?.search,
    });
  };

  const DoctorsAppointmentList = ({ appointmentDate, classes }) => {
    return (
      <DoctorsAppointmentListItemNew
        index={0}
        tabRoute="prescription"
        key={appointment?.appointmentItem?.appointment_id}
        appointmentItem={
          doctorContext?.patientInfo || appointment?.appointmentItem
        }
        classes={classes}
      />
    );
  };

  const apiData = [
    {
      title: "",
      display_message: "",
      seq_num: "",
      img_path: "",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ChiefComplaint = await ServiceCall.getnewv2(
          "/v2/doctor/list/prescription/buttons",
          "",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (ChiefComplaint.status == 200) {
          // const recordMappingObj = {};
          const outputData = {};
          ChiefComplaint.data.records.forEach((record) => {
            const { main_section, sub_section } = record;
            if (!outputData[main_section]) {
              outputData[main_section] = [];
            }
            if (sub_section) {
              if (!outputData[main_section][sub_section]) {
                outputData[main_section][sub_section] = [];
              }
              outputData[main_section][sub_section].push(record);
            } else {
              outputData[main_section].push(record);
            }
          });

          const updatedChiefComplaintData =
            Array.isArray(ChiefComplaint?.data?.records) &&
            ChiefComplaint?.data?.records?.reduce((acc, curr) => {
              let newData = [];
              if (
                curr?.display_name === "Share" ||
                curr?.display_name === "View/Generate"
              ) {
                const generateIndex = acc?.findIndex(
                  (item) => item?.display_name === "Generate"
                );
                if (generateIndex !== -1) {
                  newData = [...acc];
                  newData[generateIndex].children.push(curr);
                  return newData;
                } else {
                  newData = [
                    ...acc,
                    {
                      display_name: "Generate",
                      button_action: "generate",
                      children: [curr],
                    },
                  ];

                  return newData;
                }
              } else {
                newData = [...acc, curr];
                return newData;
              }
            }, []);

          // const newChiefComplaintData = [...ChiefComplaint?.data?.records];
          // const updatedChiefComplaintData = newChiefComplaintData?.filter(
          //   (item) =>
          //     item?.display_name !== "Share" &&
          //     item?.display_name !== "View/Generate"
          // );
          setButtonList(updatedChiefComplaintData);
          setSelectionButtion(updatedChiefComplaintData[0]?.button_action);
        }
      } catch (error) {
        console.log("hellosdfsdfa", error);
      }
    };

    fetchData();
  }, []);

  const printsub = async (apiData) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoading">
          <h3>Select Template To Generate Template</h3>
          <div className="forms">
            <FormGroup column>
              <FormControlLabel
                required
                control={<Switch checked={templateData.print_header} />}
                label="Print header"
                value={templateData.print_header}
                onChange={(e) => {
                  setTemplateData({
                    ...templateData,
                    print_header: !templateData.print_header,
                  });
                }}
              />
              <FormControlLabel
                required
                control={<Switch checked={templateData.print_watermark} />}
                label="Print Watermark"
                value={templateData.print_watermark}
                onChange={(e) => {
                  setTemplateData({
                    ...templateData,
                    print_watermark: !templateData.print_watermark,
                  });
                }}
              />
              <FormControlLabel
                required
                control={<Switch checked={templateData.print_footer} />}
                label="Print Footer"
                value={templateData.print_footer}
                onChange={(e) => {
                  setTemplateData({
                    ...templateData,
                    print_footer: !templateData.print_footer,
                  });
                }}
              />
            </FormGroup>
          </div>

          <Carousel
            nextDisabled={true}
            previousDisabled={true}
            renderCenterLeftControls={({ previousSlide }) => (
              // <div onClick={previousSlide}
              // >
              <div
                onClick={() => {
                  previousSlide();
                  setSelectedTemplateIndex(selectedTemplateIndex - 1);
                }}
              >
                <ChevronLeftIcon />
              </div>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              // <div onClick={nextSlide}>
              <div
                onClick={() => {
                  nextSlide();
                  setSelectedTemplateIndex(selectedTemplateIndex + 1);
                }}
              >
                <ChevronRightIcon />
              </div>
            )}
            selectedIndex={selectedTemplateIndex}
          >
            <div className="medi1">
              {apiData.map((template, index) => (
                <div
                  key={index}
                  className={
                    templateData.print_template == template.title
                      ? "borderAdd"
                      : "mayank"
                  }
                  onClick={() =>
                    setTemplateData({
                      ...templateData,
                      print_template: template.title,
                    })
                  }
                >
                  <img src={template.img_path} alt={`Image ${index + 1}`} />
                  <p
                    className={`legend ${template.selected ? "selected" : ""}`}
                  >
                    {template.display_message}
                  </p>
                </div>
              ))}
            </div>
          </Carousel>
        </div>
      ),

      handleClose: () => {},
    });
  };

  const handleSaveNewTemplate = async () => {
    setLoading(true);

    try {
      const body = {
        template_name: newTemplateName, // Ensure newTemplateName is defined in your scope
      };

      const handledata = await ServiceCall.postv2(
        "/v2/doctor/create/prescription/template/",
        appointmentid,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (handledata) {
        setLoading(false);
        setSelectedTemplate(handledata?.data?.name);

        setOpenModal(false);
        setState({
          msg: handledata?.data?.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      setOpenModal(false);
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleSaveNewTemplates = async (newTemplateName) => {
    setLoading(true);

    try {
      const body = {
        template_name: newTemplateName, // Use the newTemplateName argument
      };

      const handledata = await ServiceCall.putv2(
        `/v2/doctor/update/prescription/template/`,
        `${appointmentid}/${selectedTemplateId}`,
        body,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (handledata) {
        setLoading(false);

        setOpenModals(false);
        setState({
          msg: handledata?.data?.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      setOpenModals(false);
      setState({
        msg: err?.response?.data?.message || "",
        type: "error ",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const deletetemp = await ServiceCall.deletev2(
        "/v2/doctor/prescription/custom/templates/",
        `${templateId}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (deletetemp) {
        // Template deleted successfully
        // You can update the tempdata state to remove the deleted template
        const updatedTemplates = tempdata.templates.filter(
          (template) => template.prescription_template_id !== templateId
        );
        setTempdata({ ...tempdata, templates: updatedTemplates });

        // Show a success message or perform any other necessary actions
        setState({
          msg: deletetemp?.data?.message,
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      // Handle error
      console.error("Error deleting template:", err);
      setState({
        msg: err?.response?.data?.message || "Error deleting template",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  return (
    <PageContentLayout>
      <div className="prescriptionpage">
        <DialogScreen />
        {doctorContext?.preTemplateLoader && (
          <Backdrop
            sx={{
              color: "#fff",
              opacity: "0.6",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={doctorContext?.preTemplateLoader}
            onClick={() => {}}
          >
            <CircularProgress size="20px" color="inherit" />
          </Backdrop>
        )}
        <Navigation
          text="Prescription"
          // close={true}
          navClass="appointmentpage__navigation"
          handleClick={handleClick}
        />

        {appointment ? (
          <React.Fragment>
            <DoctorsAppointmentList
              appointmentDate={
                appointment?.appointmentItem?.appointment_date_formatted
              }
              classes={appointment?.classes}
            />
            <div
              className="appointment__container"
              style={{ background: "#e8f3f1", marginTop: "19px" }}
            >
              <Grid
                item
                container
                xs={10}
                sm={12}
                className="appointment__container__lastsection prescription__container__lastsection"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    padding: "19px 20px", // Add padding to create gap from the outer container
                    gap: "62px",
                  }}
                >
                  <div
                    className="appointment__date__record"
                    onClick={() => {
                      localStorage.setItem('previousPrescriptionRoute', location.pathname);
                      history.push({
                        pathname: "/prescription/patient_notes",
                        search: history?.location?.search,
                      });
                    }}
                    style={{ display: "flex", alignItems: "center", flex: 1 }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Patient Notes{" "}
                      <MedicationRoundedIcon sx={{ fontSize: "25px" }} />
                    </span>
                  </div>
                  <div
                    className="appointment__date__record"
                    
                    onClick={() => {
                    
                      history.push('/vital', {sourceRoute: 'write-prescriptions' });

                    }}
                    style={{ display: "flex", alignItems: "center", flex: 1 }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Patient Vitals{" "}
                      <img
                        src={Vitalimg}
                        style={{
                          fontSize: "25px",
                          height: "26px",
                          width: "41px",
                          filter: "brightness(0) invert(1)",
                        }}
                      />
                      {/* <MedicationRoundedIcon sx={{ fontSize: "25px" }} /> */}
                    </span>
                  </div>
                  <div
                    className="appointment__date__record"
                    onClick={() => {
                      overlay.show(OverlayType?.ClinicalFindings);
                    }}
                    style={{ display: "flex", alignItems: "center", flex: 1 }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      Clinical Findings{" "}
                      <DeviceThermostatRoundedIcon sx={{ fontSize: "30px" }} />
                    </span>
                  </div>
                  <FormControl
                    variant="filled"
                    style={{ flex: 2, minWidth: "250px" }}
                  >
                    <InputLabel id="salutation-label">
                      Select Template
                    </InputLabel>
                    <Select
                      value={selectedTemplate}
                      onChange={handleTemplateChange}
                      placeholder="Select The Template"
                      style={{ width: "100%" }}
                    >
                      {tempdata.templates && tempdata.templates.length > 0 ? (
                        [
                          ...tempdata.templates,
                          { template_name: newTemplateName },
                        ].map((template) => (
                          <MenuItem
                            key={
                              template.prescription_template_id ||
                              template.template_name
                            }
                            value={
                              template.prescription_template_id ||
                              template.template_name
                            }
                          >
                            {template.template_name}
                            {/* Conditionally render the RemoveCircleOutlineIcon */}
                            {selectedTemplate !==
                              (template.prescription_template_id ||
                                template.template_name) && (
                              <RemoveCircleOutlineIcon
                                style={{
                                  marginLeft: "auto",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDeleteTemplate(
                                    template.prescription_template_id
                                  )
                                }
                              />
                            )}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No templates available</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  <div
                    className="appointment__date__record"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={hadledoctordata}
                    disabled={loading}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Save Template"
                      )}
                      <InsertDriveFileIcon sx={{ fontSize: "25px" }} />
                    </span>
                  </div>
                </div>
              </Grid>
            </div>
            {/* <div>
              <QuadrantGrid appointmentid={appointmentid} />
            </div> */}
            {buttonList && Array.isArray(buttonList) && !!buttonList?.length ? (
              <div className="query_container">
                <div className="prescription_toolbar">
                  {buttonList?.map((bl) => (
                    <button
                      className={`prescription_toolbar_button ${
                        bl.button_action === selectedButton
                          ? "prescription_toolbar_button_selected"
                          : ""
                      }`}
                      onClick={() => setSelectionButtion(bl.button_action)}
                    >
                      <span>
                        {bl.display_name}
                        {/* <img src={bl.icon_url} alt={bl.display_name} /> */}
                      </span>
                    </button>
                  ))}
                  {/* </div> */}
                  {/* </>
                    )}
                    </div>
                  ))} */}
                  {/* </Carousel> */}
                </div>
              </div>
            ) : (
              <></>
            )}
          
            <div>
              <PrescriptionNotes id={selectedButton} buttonData={buttonList} />
            </div>
          </React.Fragment>
        ) : (
          <div>
            <Stack
              spacing={2}
              style={{ width: "96%" }}
              className="d-flex justify-content-around flex-row align-items-center m-auto"
            >
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="rectangular" width="80%" height={60} />
            </Stack>
          </div>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <CloseIcon
            onClick={handleCloseMo}
            style={{ float: "right", cursor: "pointer" }}
          />
          <Typography id="modal-title" variant="h6" component="h2">
            Enter Template Name
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Template Name"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveNewTemplate}
            sx={{
              mt: 2,
              color: "white",
              backgroundColor: "#199a8e",
              "&:hover": {
                backgroundColor: "#28b8ab",
              },
            }}
            disabled={loading || newTemplateName.trim() === ""}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save Template"
            )}{" "}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openModals}
        onClose={() => setOpenModals(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <CloseIcon
            onClick={handleCloseMos}
            style={{ float: "right", cursor: "pointer" }}
          />
          <Typography id="modal-title" variant="h6" component="h2">
            Enter Template Name
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Template Name"
            value={newTemplateNameValue} // Use the newTemplateNameValue state
            onChange={(e) => setNewTemplateNameValue(e.target.value)} // Update the newTemplateNameValue state
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleSaveNewTemplates(newTemplateNameValue)} // Pass newTemplateNameValue as an argument
            sx={{
              mt: 2,
              color: "white",
              backgroundColor: "#199a8e",
              "&:hover": {
                backgroundColor: "#28b8ab",
              },
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save Template"
            )}
          </Button>
        </Box>
      </Modal>
    </PageContentLayout>
  );
};

export default Prescription;