import React from 'react';
import { useState } from 'react';
import femalefrontimg from "../static/female_front.png";

const FemaleFrontBody = ({ pointAction, currentPoint, painList = [] }) => {
    const [selectedPoint, setSelectedPoint] = useState(null);
    const currentPoints = Array.isArray(currentPoint) ? currentPoint : [currentPoint?.toString()];

    const bodyParts = [
        { name: 'head', points: ["1", "2"], style: { top: '7%', left: '50.5%', transform: 'translateX(-50%)' } },
        { name: 'neck', points: ["3"], style: { top: '18%', left: '50.7%', transform: 'translateX(-50%)' } },
        { name: 'shoulder', points: ["4", "5"], style: { top: '21%', left: '15px', right: '2px', display: 'flex', justifyContent: 'space-between', padding: '0 42%' } },
        { name: 'chest', points: ["6", "7"], style: { top: '28%', left: '4px', right: '-7px', display: 'flex', justifyContent: 'space-between', padding: '0 46%' } },
        { name: 'arm', points: ["8", "9", "10", "11"], style: { top: '35%', left: '14px', right: '4px', display: 'flex', justifyContent: 'space-between', padding: '0 451%' } },
        { name: 'forearms', points: ["12", "13", "14", "15"], style: { top: '43%', left: '8px', right: '-2px', display: 'flex', justifyContent: 'space-between', padding: '0 41%' } },
        { name: 'hands', points: ["16", "17", "18"], style: { top: '50%', left: '20px', right: '10px', display: 'flex', justifyContent: 'space-between', padding: '0 37%' } },
        { name: 'leftLeg', points: ["19", "20", "21", "22"], style: { top: '64%', left: '47.5%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
        { name: 'rightLeg', points: ["23", "24", "25", "26"], style: { top: '64%', right: '45.7%', display: 'flex', flexDirection: 'column', height: '33%', justifyContent: 'space-between' } },
    ];

    const getPainColor = (point) => {
        if (point === selectedPoint) return "blue";
        if (currentPoints.includes(point)) return "#f1506c";
        if (painList.includes(point)) return "red";
        return "green";
    };

    const handlePointClick = (point) => {
        pointAction(point);
        setSelectedPoint(point);
    };

    const renderPoints = (points) => (
        points.map((point) => (
            <button
                key={point}
                onClick={() => handlePointClick(point)}
                style={{
                    ...styles.pain,
                    backgroundColor: getPainColor(point),
                }}
            />
        ))
    );

    return (
        <div style={{ position: 'relative', width: '100%', height: 460 }}>
            <img src={femalefrontimg} alt="Female front body" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            {bodyParts.map(({ name, points, style }) => (
                <div key={name} style={{ position: 'absolute', ...style }}>
                    {renderPoints(points)}
                </div>
            ))}
        </div>
    );
};

const styles = {
    pain: {
        width: 17,
        height: 17,
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        margin: '2px',
    },
};

export default FemaleFrontBody;
