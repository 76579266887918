import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend
);

const LineChart = ({ data, name, measurename, isLoading, isPainData = false, selectedBodyPart }) => {
  // Check if there's no data and not loading
  const noData =
    !isLoading &&
    (!data ||
      !data.datasets ||
      data.datasets.length === 0 ||
      data.datasets.every((dataset) => dataset.data.length === 0));

  if (noData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <p
          style={{
            fontSize: "18px",
            color: "#666",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          Vitals are not available at the moment. Please request the user to
          track the vitals on RxOne - Care to Cure App (Android/IOS)
        </p>
      </div>
    );
  }

  const chartData = {
    labels: data.labels,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      tension: 0.1,
      pointBackgroundColor: isPainData
        ? (context) => {
            const value = context.raw;
            if (selectedBodyPart) return "blue"; // Changed to always blue when a body part is selected
            if (value <= 3) return "yellow";
            if (value <= 7) return "orange";
            return "red";
          }
        : dataset.backgroundColor,
    })),
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: `${name} (${measurename})`,
        font: {
          size: 16,
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += isPainData ? `${context.parsed.y}/10` : context.parsed.y;
            }
            if (isPainData && selectedBodyPart) {
              label += ` (Body Part: ${selectedBodyPart})`;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Time",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: isPainData,
        max: isPainData ? 10 : undefined,
        title: {
          display: true,
          text:  'Pain Intensity (0-10)'
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 4,
        hoverRadius: 6,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;