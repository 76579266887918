import React from 'react';

const Notification = ({ title, body, onClose }) => (
  <div className="notification">
    <h4>{title}</h4>
    <p>{body}</p>
    <button onClick={onClose}>Close</button>
  </div>
);

export default Notification;