import { useState, useEffect } from "react";
import PageContentLayout from "../../components/PageContentLayout";
import { useHistory } from "react-router-dom";
import { constants } from "../../utility/constants";
import { Ls } from "../../utility/utility";
import { ServiceCall } from "../../utility/service-call";
import AccountIcon from "../../static/images/AccountIcon.svg";
import Notypegif from "../../static/typing_long_text_message.gif"


const Mymessage = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchdata = async () => {
      setIsLoading(true);
      try {
        const getmsg = await ServiceCall.getv2(
          "/v2/doctor/all/patient/conversation/list/",
          `${constants.hospitalId("hosp_id")}`,
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );
        if (getmsg && getmsg.data && getmsg.data.all_patients) {
          setMessages(getmsg.data.all_patients);
        }
      } catch (err) {
        console.error("Error fetching messages:", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchdata();
  }, []);

  const handleClick = async (patient) => {
    // console.log(44333, patient);
    try {
      const switchdata = await ServiceCall.putv2(
        `/v2/doctor/patient/conversation/`,
        `${constants.hospitalId("hosp_id")}/${patient.pt_profile_id}`,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (switchdata) {
        history.push("/Doctor-notes", { profileId: patient });
      }
    } catch (err) {
      console.error("Error switching conversation:", err);
    }
  };

  return (
    <PageContentLayout>
      <div className="msgs">
        {isLoading ? (
          <p>Loading...</p>
        ) : messages.length === 0 ? (
          // <p>No messages available</p>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img 
            style={{ width: '50%' }}
            src={Notypegif} 
            alt="no message" 
          />
        </div>
    
        ) : (
          messages.map((patient) => (
            <div
              key={patient.pt_profile_id}
              className="card"
              onClick={() => handleClick(patient)}
            >
              <div className="card-content">
                <img src={AccountIcon} alt="accountIcon" className="account-icon" />
                <div className="text-content">
                  <div className="patient-name">{patient.pt_name}</div>
                  <div className="card-body">
                    <p>
                      {patient.person === "patient"
                        ? patient.pt_name
                        : patient.person === "doctor"
                        ? "You"
                        : patient.person}
                      {":"} {patient.message.length > 10 ? `${patient.message.slice(0, 10)}...` : patient.message}
                    </p>
                  </div>
                </div>
              </div>
              {patient.unread_msg_count > 0 && (
                <div className="unread-count-container">
                  <div className="unread-count">
                    <span>{patient.unread_msg_count}</span>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      <style jsx>{`
  .msgs {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 511px;
    padding: 13px;
    margin: 3px;
    cursor: pointer;
    height: 101px;
    position: relative;
  }
  .card-content {
    display: flex;
    align-items: center;
  }
  .account-icon {
    height: 61px;
    width: auto;
    margin-right: 15px;
  }
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .patient-name {
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 5px;
  }
  .card-body {
    font-size: 1em;
  }
  .card-body p {
    margin: 0;
  }
  .unread-count-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .unread-count {
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8em;
  }
`}</style>    </PageContentLayout>
  );
};

export default Mymessage;
