import React, { useState, useContext, useEffect } from "react";
import { Box, Tabs, Tab } from "@material-ui/core";
import { useParams } from "react-router-dom";
import AppointmentTab from "./AppointmentTab";
import AppointmentImage from "../static/images/svg/AppointmentImage";
import { Img } from "react-image";
import { PatientpageContext } from "./../context/GlobalContext";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DoctorMyScheduleTab from "../pages/Doctor/DoctorMyScheduleTab";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BottomNavigation from "@mui/material/BottomNavigation";
import JoinTab from "./JoinTab";
import { NavbarImage } from "../static/images/ImageManager";
import Paper from "@mui/material/Paper";
import NewAppointmentTab from "./NewAppointmentTab";
import NewPrescriptionTab from "./NewPrescriptionTab";
// import DoctorMyScheduleTab from "../pages/Doctor/DoctorMyScheduleTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 1 }}>{children}</Box>}
    </div>
  );
}

const tabIndexConst = {
  appointment: 0,
  schedule: 1,
  billings: 2,
  join: 3,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export default function DetailsTabs({
  loader,
  classes,
  history,
  prescriptionList,
}) {
  let { tabIndex } = useParams();
  const doctorContext = useContext(PatientpageContext);
  const [value, setValue] = useState(tabIndex ? parseInt(tabIndex) : 0);
  const [showAppointment, setShowAppointment] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const submitPatientDetails = function () {
    history.push("/payment");
  };

  const toggleAppointment = function () {
    setShowAppointment(!showAppointment);
  };
  useEffect(() => {
    if (doctorContext.currentTab) {
      setValue(doctorContext.currentTab);
    }
  }, [doctorContext.currentTab]);
  return (
    <Box sx={{ width: "100%" }}>
      {/* <FaqModal /> */}
      <Box sx={{ borderColor: "divider" }}>
        {/* <Tabs className="navContainer" value={value} onChange={handleChange}>
          <Tab
            label="Appointments"
            className="navContainer__button"
            icon={<NavbarImage.appointment />}
            {...a11yProps(0)}
          />
          <Tab
            label="Prescription"
            className="navContainer__button"
            icon={<NavbarImage.prescription />}
            {...a11yProps(1)}
          />
          <Tab
            className="navContainer__button"
            label="My Schedule"
            icon={<NavbarImage.today />}
            {...a11yProps(2)}
          />
        </Tabs> */}
        {/* appointment tab start */}
        {/* <TabPanel className="navContainerTabs" value={value} index={0}>
          <Home
            toggleAppointment={toggleAppointment}
            showAppointment={showAppointment}
            appointmentList={doctorContext.appointmentList}
            classes={classes}
            appointMentLoader={loader}
          />
        </TabPanel> */}
        {/* appointment tab end */}
        {/* myRecord tab start */}
        <TabPanel className="navContainerTabs" value={value} index={0}>
          {/* <DoctorMyScheduleTab
            classes={classes}
            submitPatientDetails={submitPatientDetails}
          /> */}
          <NewAppointmentTab
            toggleAppointment={toggleAppointment}
            showAppointment={showAppointment}
            appointmentList={doctorContext.appointmentList}
            classes={classes}
            appointMentLoader={loader}
            navigate={setValue}
          />
          
        </TabPanel>
        {/* myRecord tab end */}
        {/* AppointmentReceipts tab start */}
        <TabPanel className="navContainerTabs" value={value} index={1}>
          <NewPrescriptionTab
            toggleAppointment={toggleAppointment}
            showAppointment={showAppointment}
            appointmentList={prescriptionList}
            classes={classes}
            appointMentLoader={loader}
            navigate={setValue}
          />
          {/* <PrescriptionTab classes={classes} /> */}
        </TabPanel>
        {/* AppointmentReceipts tab end */}
        {/* My Prescription tab start */}
        <TabPanel className="navContainerTabs" value={value} index={2}>
          {/* <BillingTab classes={classes} /> */}
          <DoctorMyScheduleTab />
        </TabPanel>
        {/* AppointmentReceipts tab end */}
        <TabPanel className="navContainerTabs" value={value} index={4}>
          <JoinTab classes={classes} />
        </TabPanel>
      </Box>
    </Box>
  );
}